import { Button, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import { navigate, RouteComponentProps } from '@reach/router';
import { IFacility } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFacilities, selectUser } from '../../redux/appSlice';
import { addressTemplate, useFacilityRooms } from '../../utils';
import DashHarvest from './harvests';
import DashInventory from './inventory';
import DashRoom from './room';
import DashSchedule from './schedule';
import DashTransfers from './transfers';

interface IFacilityProps {
    facility: IFacility;
}

const useStyles = makeStyles( ( theme ) => ({
    root: {
        [theme.breakpoints.down( 'xs' )]: {
            display: 'grid',
        },
        [theme.breakpoints.up( 'md' )]: {
            display: 'flex',
            alignItems: 'baseline',
        },
    },
    item: {
        flex: '1',
    },
}) );

const Facility: React.FC<IFacilityProps> = ( { facility } ) => {
    const classes = useStyles();
    const user = useSelector( selectUser );
    const rooms = useFacilityRooms( facility.id );

    return (
        <>
            <Box mb={2} padding={0}>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <Typography variant="h3" style={{ display: 'inline' }}>
                                <strong>
                                    {facility.metrc.DisplayName}
                                </strong>
                            </Typography>

                            <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                                {` / ${facility.address ? addressTemplate( facility.address ) : ''} / `}
                            </Typography>
                            {user && user.isAllowed( UserPermissions.generalEdit ) && (
                                <Button
                                    color="primary"
                                    onClick={() => navigate('/facility/' + facility.id)}
                                    endIcon={<Edit/>}
                                >
                                    Edit Facility
                                </Button>
                            )}
                        </div>
                    </Grid>

                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} md={6} xl={4}>
                            <DashInventory facility={facility}/>
                        </Grid>
                        <Grid item xs={12} md={6} xl={4}>
                            <DashHarvest facility={facility}/>
                        </Grid>
                        <Grid item xs={12} md={6} xl={4}>
                            <DashTransfers facility={facility}/>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={1} style={{ marginTop: '.5em' }}>
                        {rooms.map( ( room ) => (
                            <Grid key={room.id} item xs={12} md={6} xl={4}>
                                <DashRoom
                                    room={room}
                                    facilityId={facility.id}
                                />
                            </Grid>
                        ) )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

const Dashboard: React.FC<RouteComponentProps> = () => {
    const facilities = useSelector( selectFacilities );

    return (
        <Box mb={2} padding={0}>
            <Grid container justify="center" spacing={2}>
                <Grid item xs={12} md={9}>
                    {facilities.map( ( facility ) => {

                        return (
                            <Facility
                                key={facility.id}
                                facility={facility}
                            />
                        );
                    } )}
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashSchedule/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
