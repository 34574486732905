import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { getIcon, useFacilityRooms } from '../../../../utils';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { selectInventoryDetails } from '../../../../redux/appSlice';
import capitalize from '@material-ui/core/utils/capitalize';
import { IEquipmentItem } from '@shared/interfaces/lib/inventoryInterfaces';
import FacilityContext from '../../facilityContext';

interface IProps {
    inventoryId: string;
    equipment: IEquipmentItem[];
}

const useStyles = makeStyles( () => ({
    listItem: {
        borderBottom: '1px solid lightgrey',
    },
    root: {
        width: '100%',
        '& li:first-child': {
            borderTop: '1px solid lightgrey',
        },
        '& li:last-child': {
            borderBottom: 'none',
        }
    },
}) );

const FacilityEquipmentRow: React.FC<IProps> = ( { inventoryId, equipment = [] } ) => {
    const classes = useStyles();
    const { facility } = useContext( FacilityContext );
    const inventoryDetails = useSelector( selectInventoryDetails );
    const rooms = useFacilityRooms(facility.id);
    const [open, setOpen] = useState( false );

    const inventoryDetail = inventoryDetails.find( ( detail ) => detail.id === inventoryId );

    if ( !inventoryDetail ) {
        return null;
    }

    const groupedInventory = equipment.reduce((acc, item) => {
        const roomId = item.roomId || undefined;
        const found = acc.get(roomId);
        if(found){
            acc.set(roomId, [...found, item]);
        } else {
            acc.set(roomId, [item]);
        }
        return acc;
    }, new Map<string | undefined, IEquipmentItem[]>());

    return (
        <Grid item xs={12} className={classes.listItem}>
            <List
                component="nav"
                className={classes.root}
            >
                <ListItem style={{ 'paddingLeft': '0' }} button onClick={(() => setOpen( !open ))}>
                    <ListItemIcon>
                        {getIcon( capitalize( inventoryDetail.type ) )}
                    </ListItemIcon>
                    <Typography variant="body1" component="span">
                        <strong>
                            { inventoryDetail.name }
                        </strong>
                    </Typography>
                    <Typography variant="body2" component="span" style={{ 'float': 'left' }}>
                        {`${inventoryDetail.brand} / ${inventoryDetail.model}${inventoryDetail.sku ? ` / ${inventoryDetail.sku}` : ''}`}
                    </Typography>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List dense={true} component="div">
                        <ListItem
                            className={classes.listItem}
                            style={{ 'paddingTop': '1em', 'paddingBottom': '1em', 'paddingLeft': '2em' }}
                        >
                            <ListItemText
                                primary="Facility Storage"
                                secondary={(groupedInventory.get(undefined) || []).length + ' Instances'}
                            />
                            {[...groupedInventory].filter(([key]) => !!key).map(([roomId, items]) => {
                                const room = rooms.find(room => room.id === roomId);
                                return (
                                    <ListItemText
                                        key={roomId}
                                        primary={room ? room.metrc.Name : 'Unknown Room'}
                                        secondary={items.length + ' Instances'}
                                    />
                                );
                            })}
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Grid>
    );
};

export default FacilityEquipmentRow;
