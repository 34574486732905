import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Add, OpenWith, Remove } from '@material-ui/icons';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import AddStock from './add';
import MoveStock from './move';
import RemoveStock from './remove';

interface IDialogProps {
    facilityId: string;
    onClose: () => void;
}

enum Views {
    None,
    Add,
    Move,
    Remove,
}

const InventoryStockDialog: React.FC<IDialogProps> = ( { onClose, facilityId } ) => {
    const theme = useTheme();

    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [view, setView] = useState<Views>( Views.None );

    return (
        <>
            <Dialog fullScreen={fullScreen} open={true} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                {view === Views.None && (
                    <>
                        <DialogTitle>Update Stock</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                What action are you taking against the facility stock
                            </DialogContentText>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button
                                    startIcon={<Add/>}
                                    onClick={() => setView(Views.Add)}
                                >
                                    Add
                                </Button>
                                <Button
                                    startIcon={<OpenWith/>}
                                    onClick={() => setView(Views.Move)}
                                >
                                    Move
                                </Button>
                                <Button
                                    startIcon={<Remove/>}
                                    onClick={() => setView(Views.Remove)}
                                >
                                    Remove
                                </Button>
                            </ButtonGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                )}
                {view === Views.Add && <AddStock facilityId={facilityId} onClose={onClose}/>}
                {view === Views.Move && <MoveStock facilityId={facilityId} onClose={onClose}/>}
                {view === Views.Remove && <RemoveStock facilityId={facilityId} onClose={onClose}/>}
            </Dialog>
        </>
    );
};

interface IProps {
    facilityId: string;
}

const InventoryStock: React.FC<IProps> = ( { facilityId } ) => {
    const [open, setOpen] = useState( false );

    return (
        <>
            <Button onClick={() => setOpen( true )} color="primary">
                Update Stock
            </Button>
            {open && (
                <InventoryStockDialog facilityId={facilityId} onClose={() => setOpen( false )}/>
            )}
        </>
    );
};

export default InventoryStock;