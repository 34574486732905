import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Form } from '@happybandit/react-validation';
import List from '@material-ui/core/List';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import { ILight, IRoom } from '@shared/interfaces/lib/interfaces';
import {
    ConsumableTypes,
    EquipmentTypes,
    IConsumableDetail,
    IConsumableItemBase,
    Units,
} from '@shared/interfaces/lib/inventoryInterfaces';
import { selectConsumables, selectInventoryDetails, selectUser, setError } from 'redux/appSlice';
import SelectValid from 'components/SelectValid';
import { ReactComponent as LightIcon } from 'components/Icons/lights.svg';
import { getTimeStamp, isDetailConsumable, uuidv4 } from 'utils';
import { FirebaseContext } from 'firebase_init';
import FacilityContext from '../../../../../facilityContext';
import Confirm from '../confirm';

interface IProps {
    light: ILight;
    room: IRoom;
    systemId: string;
    onEdit: () => void;
    onDelete: ( id: number ) => void;
    onUpdate: ( newLight: ILight ) => void;
}

const View: React.FC<IProps> = ( { light, onUpdate, onDelete, onEdit, room, systemId } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState<boolean | undefined>( undefined );
    const [inventoryId, setInventoryId] = useState( light.inventoryId );
    const [bulbId, setBulbId] = useState( light.bulbId );
    const inventoryDetails = useSelector( selectInventoryDetails );
    const user = useSelector( selectUser );
    const consumables = useSelector( selectConsumables );

    const detail = inventoryDetails.find( ( item ) => item.id === light.inventoryId );
    const lightTypes = inventoryDetails.filter( ( item ) => item.type === EquipmentTypes.Light );
    const bulbTypes: IConsumableDetail[] = inventoryDetails.filter( isDetailConsumable ).filter( ( item ) => item.type === ConsumableTypes.Bulbs );

    if ( !user ) {
        return null;
    }

    const handleSubmit = () => {
        onUpdate( {
            ...light,
            inventoryId,
            bulbId,
        } );
        setConfirm( false );
    };

    const handleDelete = () => {
        onDelete( light.id );
        setConfirm( false );
    };

    const handleReplaceBulb = () => {
        onUpdate( {
            ...light,
            dateBulbInstalled: (new Date()).toISOString(),
        } );

        const bulbs = consumables.find( ( item ) => item.inventoryId === light.bulbId );
        const collection = firestore.collection( Collections.consumables );
        if ( bulbs ) {
            const update: Partial<IConsumableItemBase> = {
                tickets: [...bulbs.tickets, {
                    id: uuidv4(),
                    unit: Units.Single,
                    amount: -1,
                    userId: user.uid,
                    timestamp: getTimeStamp(),
                    facilityId: facility.id,
                    roomId: room.id,
                    systemId: systemId,
                }],
            };

            collection.doc( bulbs.id )
                .update( update )
                .catch( ( e: Error ) => {
                    dispatch( setError( e.message ) );
                } );

        }
    };

    if ( confirm ) {
        return <Confirm
            onCancel={() => setConfirm( undefined )}
            onSave={handleDelete}
        />
    }

    const formatDayTime = ( isoDate: string ) => DateTime.fromISO( isoDate ).toLocaleString( {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
    } );

    return (
        <>
            <DialogTitle id="form-dialog-title">
                <LightIcon height="50" width="50"/>
                {!light.inventoryId && (
                    <>
                        Light not Setup
                    </>
                )}
                {light.inventoryId && (
                    <>
                        Light Details
                        <Button
                            color="primary"
                            size="small"
                            onClick={onEdit}
                            endIcon={<EditIcon/>}
                        >
                            Edit
                        </Button>
                    </>
                )}
            </DialogTitle>
            <DialogContent>
                <Divider/>
                {!light.inventoryId && (
                    <Form id={`light-${light.id}`} onSubmit={handleSubmit}>
                        <Typography style={{ paddingBottom: '0.5em' }} variant="h6">
                            Select a Light from Inventory to assign to the System:
                        </Typography>
                        <Divider/>
                        <SelectValid
                            id={`light-edit-${light.id}`}
                            value={inventoryId}
                            label="Select a Light"
                            fullWidth
                            disabled={lightTypes.length === 0}
                            validators={[]}
                            onChange={( value ) => setInventoryId( value )}
                        >
                            <MenuItem value=""/>
                            {lightTypes && lightTypes.map( ( inventory ) => (
                                <MenuItem
                                    key={inventory.id}
                                    value={inventory.id}
                                >
                                    {inventory.name}
                                </MenuItem>
                            ) )}
                        </SelectValid>
                        <SelectValid
                            id="light-view-bulb"
                            value={bulbId}
                            label="Select a Bulb"
                            fullWidth
                            disabled={bulbTypes.length === 0}
                            validators={[]}
                            onChange={( value ) => setBulbId( value )}
                        >
                            <MenuItem value=""/>
                            {bulbTypes && bulbTypes.map( ( inventory ) => (
                                <MenuItem
                                    key={inventory.id}
                                    value={inventory.id}
                                >
                                    {inventory.name}
                                </MenuItem>
                            ) )}
                        </SelectValid>
                    </Form>
                )}
                <List>
                    {detail && (
                        <>
                            <ListItem>
                                <ListItemText
                                    primary="Name:"
                                    secondary={detail.name}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Brand:"
                                    secondary={detail.brand}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Model:"
                                    secondary={detail.model}
                                />
                            </ListItem>
                        </>
                    )}
                    <ListItem>
                        <ListItemText
                            primary="Light Install Date:"
                            secondary={light.dateInstalled ? formatDayTime( light.dateInstalled ) : 'Unknown'}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Last Bulb Install Date:"
                            secondary={light.dateBulbInstalled ? formatDayTime( light.dateBulbInstalled ) : 'Unknown'}
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                {light.bulbId && (
                    <Button onClick={handleReplaceBulb} color="secondary">
                        {light.dateBulbInstalled ? 'Replace Bulb' : 'Install Bulb'}
                    </Button>
                )}
                {light.dateInstalled && (
                    <Button onClick={() => setConfirm( true )} color="secondary">
                        Light Deactivated
                    </Button>
                )}
                {!light.dateInstalled && (
                    <Button type="submit" onClick={handleSubmit} color="primary">
                        Save
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default View;
