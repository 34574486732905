import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import ExternalContractsEdit from './edit';
import ExternalContractsList from './list';
import ExternalContractsView from './view';

const ExternalContracts: React.FC<RouteComponentProps> = () => {
    return (
        <Router>
            <ExternalContractsList path="/" />
            <ExternalContractsView path="/:contactId" />
            <ExternalContractsEdit path="/:contactId/edit" />
            <ExternalContractsEdit path="/edit" />
        </Router>
    );
};

export default ExternalContracts;
