import { Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import capitalize from '@material-ui/core/utils/capitalize';
import React, { useContext, useState } from 'react';
import { IRoom } from '@shared/interfaces/lib/interfaces';
import NewRoom from '../newRoom';
import FacilityContext from '../../facilityContext';

interface IProps {
    room: IRoom;
}

const RoomHeader: React.FC<IProps> = ( { room } ) => {
    const [edit, setEdit] = useState( false );
    const {facility} = useContext(FacilityContext);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        {room.metrc.Name}
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    {` / ${capitalize( (room.types || []).join(', ') )} Room `}
                </Typography>
                <Link href="#" onClick={() => setEdit( true )} variant="body1">
                    <strong>
                        <span style={{ 'color': 'black' }}>/</span> Edit
                    </strong>
                </Link>
                <hr style={{ 'borderTop': '#eee' }}/>
            </Grid>
            {edit && (
                <NewRoom
                    open={true}
                    facility={facility}
                    room={room}
                    onClose={() => setEdit( false )}
                />
            )}
        </Grid>
    );
};

export default RoomHeader;
