import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IInfoCategory } from '@shared/interfaces/lib/interfaces';
import { unassignedId } from '../../../../dataHooks';
import { User } from '../../../../User';
import { UserPermissions } from '@shared/interfaces/lib/user';

interface IProps {
    infoCategories: IInfoCategory[];
    onSelected: ( articleId: string ) => void;
    user?: User;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        root: {
            width: '100%',
            maxWidth: 360,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        nested: {
            paddingLeft: theme.spacing( 4 ),
        },
    } ),
);

const InfoCategoriesView: React.FC<IProps> = ( { infoCategories, onSelected, user } ) => {
    const classes = useStyles({});
    const [openCategories, setOpenCategories] = useState( new Set() );

    const handleClick = ( id: string ) => {
        const newOpen = new Set( openCategories );
        if ( newOpen.has( id ) ) {
            newOpen.delete( id );
        } else {
            newOpen.add( id );
        }
        setOpenCategories( newOpen );
    };

    return (
        <>
            <List
                className={classes.root}
            >
                {infoCategories.filter( ( category ) => category.articles.length > 0 ).map( ( category ) => (
                    <React.Fragment key={category.id}>
                        {category.id !== unassignedId && (
                            <ListItem button onClick={() => handleClick( category.id )}>
                                <ListItemText primary={<b>{category.name}</b>}/>
                                {openCategories.has( category.id ) ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                        )}
                        <Collapse
                            in={category.id === unassignedId || openCategories.has( category.id )}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List
                                component="div"
                                disablePadding
                            >
                                {category.articles.map( ( article ) => (
                                    <ListItem
                                        key={article.id}
                                        button
                                        onClick={() => onSelected( article.id )}
                                        className={category.id === unassignedId ? '' : classes.nested}
                                    >
                                        <ListItemText primary={article.name}/>
                                    </ListItem>
                                ) )}
                            </List>
                        </Collapse>
                    </React.Fragment>
                ) )}
            </List>
            {user && user.isAllowed(UserPermissions.generalEdit) && (
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => onSelected( 'new' )}
                >
                    Create New Article
                </Button>
            )}
        </>
    );
};

export default InfoCategoriesView;
