import {
    Collections,
    MetrcFacilitySubCollections,
    MetrcLocationSubCollections
} from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MentionsInput, Mention, OnChangeHandlerFunc } from 'react-mentions'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { addHistory, FirebaseContext } from '../../firebase_init';
import { IHistory, IMention, INotification } from '@shared/interfaces/lib/interfaces';
import FacilityContext from '../../pages/facility/facilityContext';
import { selectUser, selectUsers } from '../../redux/appSlice';
import { getTimeStamp, useNotification, convertMessage, useFacilityRooms } from '../../utils';
import UserAvatar from '../userAvatar'

interface IProps {
    roomId: string;
}

const styles = {
    flexGrow: 1,
    display: 'flex',

    control: {
        font: 'inherit',
        color: 'currentColor',
        fontSize: '1rem',
        lineHeight: '1.1875em', // Reset (19px), match the native input line-height
        boxSizing: 'border-box', // Prevent padding issue with fullWidth.
        position: 'relative',
        cursor: 'text',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
    },

    highlighter: {
        overflow: 'hidden',
    },

    input: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        color: 'currentColor',
        padding: `${8 - 2}px 0 ${8 - 1}px`,
        border: 0,
        boxSizing: 'content-box',
        background: 'none',
        height: '1.1875em', // Reset (19px), match the native input line-height
        margin: 0, // Reset for Safari
        WebkitTapHighlightColor: 'transparent',
        display: 'block',
        // Make the flex item shrink with Firefox
        minWidth: 0,
        width: '100%', // Fix IE 11 width issue
        outline: 0,
        top: 'unset',
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
};

const sortDate = ( a: IHistory, b: IHistory ) => {
    return (a.timestamp > b.timestamp) ? -1 : ((a.timestamp < b.timestamp) ? 1 : 0);
};

const Comment: React.FC<IProps> = ( { roomId } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const users = useSelector( selectUsers );
    const user = useSelector( selectUser );
    const rooms = useFacilityRooms( facility.id );
    const [message, setMessage] = useState( '' );
    const [comments, setComments] = useState<IHistory[]>( [] );
    const [mentions, setMentions] = useState<IMention[]>( [] );
    const sendNotification = useNotification();

    useEffect( () => {
        firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.history )
            .orderBy( 'timestamp', 'asc' )
            .onSnapshot( ( snapshot ) => {
                setComments(snapshot.docs.map(doc => doc.data() as IHistory));
            } );
    }, [] );

    const handleChange: OnChangeHandlerFunc = ( event, value, plain, newMentions: any[] ) => {
        setMessage( value );
        setMentions( newMentions as IMention[] );
    };

    const handleSubmit = ( event: React.FormEvent ) => {
        event.preventDefault();

        if ( !user ) {
            return;
        }

        addHistory( firestore, facility.id, roomId, {
            userId: user.uid,
            message,
            timestamp: getTimeStamp()
        } ).then( () => {
            const notification: Omit<INotification, 'id'> = {
                timestamp: Date.now(),
                seen: false,
                userId: user.uid,
                message,
                roomId,
            };

            const usersMentioned = mentions.filter( ( mention ) => mention.display.startsWith( '@' ) );

            if ( usersMentioned.length > 0 ) {
                const ids = usersMentioned.map( ( mention ) => mention.id );
                sendNotification( notification, ( userItem ) => ids.includes( userItem.uid ) );
            }
        } ).finally( () => {
            setMessage( '' );
        } );
    };

    return (
        <Paper>
            <List style={{ overflowY: 'auto', minHeight: 300 }}>
                {comments && [...comments].sort( sortDate ).map( ( comment, index ) => {
                    const foundUser = users.find( ( item ) => item.uid === comment.userId );
                    const date = new Date( comment.timestamp );

                    return (
                        <ListItem key={index}>
                            {foundUser && (
                                <ListItemAvatar>
                                    <UserAvatar id={foundUser.uid} name={foundUser.displayName}/>
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                primary={convertMessage( comment.message )}
                                secondary={`${foundUser ? foundUser.displayName : 'Unknown User'} - ${date.toLocaleString()}`}
                            />
                        </ListItem>
                    );
                } )}
            </List>
            <Divider/>
            <form onSubmit={handleSubmit} className="MuiListItem-gutters" style={{ display: 'flex' }}>
                <InputBase style={{ display: 'none' }}/>
                <MentionsInput
                    singleLine={true}
                    placeholder="Add a Comment (@User, #Room)"
                    style={styles}
                    value={message}
                    onChange={handleChange}
                >
                    <Mention
                        trigger="@"
                        data={users.map( ( item ) => ({ display: item.displayName, id: item.uid }) )}
                        displayTransform={(( id, display ) => '@' + display)}
                    />
                    <Mention
                        trigger="#"
                        data={rooms.map( ( item ) => ({ display: item.metrc.Name, id: item.id }) )}
                        markup="#[__display__](__id__)"
                        displayTransform={(( id, display ) => '#' + display)}
                    />
                </MentionsInput>
                <IconButton
                    type="submit"
                    aria-label="add"
                    disabled={message.trim().length === 0}
                >
                    <AddCommentIcon/>
                </IconButton>
            </form>
        </Paper>
    );
};

export default Comment;
