import React from 'react';
import { IValidatorOutput } from '@happybandit/react-validation/dist/interfaces';
import { useInputValidation } from '@happybandit/react-validation/dist/hooks';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';

interface IProps extends Omit<DatePickerProps, 'onChange' | 'variant' | 'margin'> {
    value: Date | null;
    onChange: ( newValue: Date | null ) => void;
    label?: string;
    id: string;
    validators: IValidatorOutput[];
}

const DateValid: React.FC<IProps> = ( { value, id, label, onChange, validators, ...otherProps } ) => {
    const { valid, message } = useInputValidation( validators );

    return (
        <DatePicker
            label={label}
            id={id}
            inputVariant="outlined"
            margin="normal"
            fullWidth
            value={value}
            onChange={onChange}
            error={!valid}
            helperText={!valid ? message : undefined}
            {...otherProps}
        />
    );
};

export default DateValid;