import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlant, IRoom, ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowPlantDetails } from '../../../../redux/appSlice';
import { PlantDetailPages } from '../../../../redux/interfaces';
import { getPlantState, PlantState } from '../../../../utils';

interface IProps {
    plant: IPlant;
    room?: IRoom;
    system?: ISystem;
}

const PlantDetails: React.FC<IProps> = ( { plant, system } ) => {
    const dispatch = useDispatch();

    if ( !plant ) {
        return null;
    }

    const { state, title } = getPlantState( plant.metrc );

    const handleHarvest = () => {
        dispatch( setShowPlantDetails( {
            key: {
                collection: MetrcFacilitySubCollections.metrcPlants,
                id: plant.id,
            },
            page: PlantDetailPages.harvestPlant,
            other: {}
        } ) );
    };

    const handleDestroy = () => {
        dispatch( setShowPlantDetails( {
            key: {
                collection: MetrcFacilitySubCollections.metrcPlants,
                id: plant.id,
            },
            page: PlantDetailPages.destroyPlant,
        } ) );
    };

    const handleCut = () => {
        dispatch( setShowPlantDetails( {
            key: {
                collection: MetrcFacilitySubCollections.metrcPlants,
                id: plant.id,
            },
            page: PlantDetailPages.clone,
        } ) );
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" color="textSecondary" gutterBottom>
                                {title}
                            </Typography>
                            <Typography>
                                {plant.metrc.StrainName}
                            </Typography>
                            {plant.metrc.DestroyedDate && (
                                <>
                                    <Typography>
                                        Destroyed Date: {plant.metrc.DestroyedDate}
                                    </Typography>
                                    <Typography>
                                        Destroyed By: {plant.metrc.DestroyedByUserName}
                                    </Typography>
                                    <Typography>
                                        Destroyed Note: {plant.metrc.DestroyedNote}
                                    </Typography>
                                </>
                            )}
                            {plant.metrc.FloweringDate && (
                                <Typography>
                                    Flowering Date: {plant.metrc.FloweringDate}
                                </Typography>
                            )}
                        </CardContent>
                        <CardActions>
                            {state === PlantState.flowering && (
                                <Button
                                    color="primary"
                                    onClick={handleHarvest}
                                >
                                    Harvest
                                </Button>
                            )}
                            {state === PlantState.vegetative && system && system.type === RoomTypes.mother && (
                                <Button
                                    color="primary"
                                    onClick={handleCut}
                                >
                                    Cut Clones
                                </Button>
                            )}
                            {(state === PlantState.vegetative || state === PlantState.flowering) && (
                                <Button
                                    color="secondary"
                                    onClick={handleDestroy}
                                >
                                    Destroy Plant
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                </Grid>
                {plant.metrc.HarvestedDate && (
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    Harvests
                                </Typography>
                                <Typography>
                                    Harvested Date: {plant.metrc.HarvestedDate}
                                </Typography>
                                <Typography>
                                    Harvested
                                    Weight: {plant.metrc.HarvestedWetWeight}{plant.metrc.HarvestedUnitOfWeightAbbreviation}
                                </Typography>
                                <Typography>
                                    Drying Room: TODO
                                </Typography>
                            </CardContent>
                            <CardActions>
                            </CardActions>
                        </Card>
                    </Grid>
                )}
                {plant.metrc.PlantBatchName && (
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    {plant.metrc.PlantBatchTypeName}
                                </Typography>
                                <Typography>
                                    {plant.metrc.PlantBatchName}
                                </Typography>
                                <Typography>
                                    Vegetative Date: {plant.metrc.VegetativeDate}
                                </Typography>
                            </CardContent>
                            <CardActions>
                            </CardActions>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </>
    )
};

export default PlantDetails;