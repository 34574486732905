import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles( (theme) => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
    },
    appFrame: {
        position: 'relative',
        height: 150,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid black'
    },
    statusBar: {
        width: '100%',
        height: 24,
    },
    menuButton: {
        marginRight: 20,
    },
    code: {
        margin: theme.spacing(2),
        fontSize: 16,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

interface IProps {
    primaryColor: string;
    secondaryColor: string;
}

const ColorDemo: React.FC<IProps> = ( { primaryColor, secondaryColor }) => {
    const classes = useStyles();
    const theme = useTheme();
    const primary = theme.palette.augmentColor( {
        main: primaryColor,
    });
    const secondary = theme.palette.augmentColor( {
        main: secondaryColor,
    });

    return (
        <div className={classes.root}>
            <div className={classes.appFrame}>
                <div
                    className={classes.statusBar}
                    style={{ backgroundColor: primary.dark }}
                />
                <AppBar position="static" style={{ backgroundColor: primary.main }}>
                    <Toolbar style={{ color: primary.contrastText }}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="div" variant="h6" color="inherit">
                            Color
                        </Typography>
                    </Toolbar>
                </AppBar>
                <p>Text Color</p>
                <Fab
                    className={classes.fab}
                    style={{ backgroundColor: secondary.main }}
                    aria-label="add"
                >
                    <AddIcon htmlColor={secondary.contrastText} />
                </Fab>
            </div>
        </div>
    );
};

export default ColorDemo;