import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import {
    IHarvestToProductBody
} from '@shared/interfaces/lib/contractInterfaces';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IHarvest, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { IMetrcError, IMetrcItem, UnitsOfMeasure } from '@shared/interfaces/lib/metrcInterfaces';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postHarvestToPackage, ResponseError } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import { useFacilityRooms } from '../../../utils';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';
import { useDetailsStyles } from '../details';

interface IIngredient {
    harvest: IHarvest,
    weight: string,
}

interface IIngredientRowProps {
    ingredient: IIngredient;
    onChange: ( ingredient: IIngredient ) => void;
    onDelete: () => void;
}

const IngredientRow: React.FC<IIngredientRowProps> = ( { ingredient, onChange, onDelete } ) => {
    const handleChange = ( weight: string ) => {
        onChange( {
            ...ingredient,
            weight,
        } );
    };

    const { Name, CurrentWeight } = ingredient.harvest.metrc;

    return (
        <TableRow>
            <TableCell>
                {Name}
            </TableCell>
            <TableCell>
                {CurrentWeight}
            </TableCell>
            <TableCell>
                <TextValid
                    onChange={handleChange}
                    value={ingredient.weight}
                    fullWidth
                    id={`package-harvest-weight-${ingredient.harvest.id}`}
                    validators={[]}
                    forcedErrorMessage={
                        !ingredient.weight ||
                        parseFloat( ingredient.weight ) > CurrentWeight ||
                        parseFloat( ingredient.weight ) <= 0 ?
                            'Must be a number under remaining' :
                            undefined
                    }
                />
            </TableCell>
            <TableCell>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={onDelete}
                >
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};


interface IIngredientsProps {
    facilityId: string;
    ingredients: IIngredient[];
    itemStrain: string | null | undefined;
    onChange: ( ingredients: IIngredient[] ) => void;
}

const Ingredients: React.FC<IIngredientsProps> = ( { facilityId, ingredients, onChange, itemStrain } ) => {
    const { firestore } = useContext( FirebaseContext );
    const [name, setName] = useState( '' );
    const [invalid, setInvalid] = useState( false );

    const handleAdd = () => {
        if(itemStrain === undefined){
            return;
        }

        setInvalid( false );
        firestore.collection( Collections.metrcFacilities ).doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcHarvests ).doc( name )
            .get()
            .then( doc => {
                if ( doc.exists ) {
                    const harvest = doc.data() as IHarvest;
                    if ( itemStrain === null || harvest.metrc.SourceStrainNames.includes( itemStrain ) ) {
                        onChange( [...ingredients, {
                            harvest,
                            weight: '',
                        }] );
                        return;
                    }
                }
                setInvalid( true );
            } );
    };

    const handleChange = ( index: number ) => ( ingredient: IIngredient ) => {
        const newIngredients = [...ingredients];
        newIngredients[index] = ingredient;
        onChange( newIngredients );
    };

    const handleDelete = ( index: number ) => () => {
        const newIngredients = [...ingredients];
        newIngredients.splice( index, 1 );
        onChange( newIngredients );
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Remaining (g)
                    </TableCell>
                    <TableCell>
                        To Package (g)
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ingredients.map( ( ingredient, index ) => (
                    <IngredientRow
                        key={ingredient.harvest.id}
                        ingredient={ingredient}
                        onChange={handleChange( index )}
                        onDelete={handleDelete( index )}
                    />
                ) )}
                <TableRow>
                    <TableCell colSpan={2}>
                        <TextValid
                            onChange={setName}
                            value={name}
                            fullWidth
                            id="package-harvest-name"
                            validators={[]}
                            forcedErrorMessage={invalid ? 'Invalid Name or Strain for Product' : undefined}
                        />
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={itemStrain === undefined}
                            onClick={handleAdd}
                        >
                            Add
                        </Button>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

interface IProps {
    harvest: IHarvest;
    facilityId: string;
}

const HarvestToPackage: React.FC<IProps> = ( { harvest, facilityId } ) => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const classes = useDetailsStyles();
    const user = useSelector( selectUser );
    const rooms = useFacilityRooms( facilityId );
    const [label, setLabel] = useState( '' );
    const [locationId, setLocationId] = useState( '' );
    const [itemId, setItemId] = useState( '' );
    const [items, setItems] = useState<IMetrcItem[]>( [] );
    const [note, setNote] = useState( '' );
    const [ingredients, setIngredients] = useState<IIngredient[]>( [{
        harvest,
        weight: '',
    }] );
    const [loading, setLoading] = useState( false );
    const [alert, setAlert] = useState<{errors: IMetrcError[]} | undefined>( undefined );

    const packageRooms = rooms.filter( ( room ) => (room.types || []).includes( RoomTypes.packaging ) );
    const selectedItem = items.find( item => item.id === itemId );

    useEffect( () => {
        if ( !harvest ) {
            return;
        }
        const collection = firestore.collection( Collections.metrcFacilities ).doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcItems );

        const samePromise = collection.where( 'StrainName', '==', harvest.metrc.SourceStrainNames )
            .get()
        const multiPromise = collection.where( 'StrainName', '==', null )
            .get()

        Promise.all( [samePromise, multiPromise] ).then( ( snaps ) => {
            setItems( snaps.reduce<IMetrcItem[]>( ( acc, snap ) =>
                acc.concat( snap.docs.map( doc => doc.data() as IMetrcItem ) ), [] ) );
        } );
    }, [] );

    if ( !harvest ) {
        return null;
    }

    const handleDone = () => {
        dispatch( setShowPlantDetails( {
            key: {
                collection: MetrcFacilitySubCollections.metrcHarvests,
                id: harvest.id,
            },
            page: PlantDetailPages.harvestDetails,
        } ) );
    };

    const handleSubmit = () => {
        const selectedRoom = packageRooms.find( item => item.id === locationId );
        if ( !user || !selectedRoom ) {
            return;
        }

        setLoading( true );

        const body: IHarvestToProductBody = {
            facilityId,
            label,
            locationId: selectedRoom.metrc.Id,
            itemId,
            date: DateTime.local().toISODate(),
            unitOfWeight: UnitsOfMeasure.Grams,
            note,
            harvests: ingredients.map( item => ({
                id: item.harvest.metrc.Id,
                weight: parseFloat( item.weight ),
                unitOfWeight: UnitsOfMeasure.Grams,
            }) )
        }
        postHarvestToPackage( user.token, body )
            .then( () => {
                handleDone();
            } )
            .catch(async (e: ResponseError) => setAlert(await e.json()))
            .finally( () => {
                setLoading( false );
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Create Package from Harvest {harvest.metrc.Name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        <Typography variant="body1">
                            Create a package of sellable product from one or more harvests.
                        </Typography>
                        {alert && (
                            <Alert variant="filled" severity="error">
                                Create Failed: {alert.errors[0].message}
                            </Alert>
                        )}
                        <TextValid
                            value={label}
                            onChange={setLabel}
                            id="package-label"
                            label="Label"
                            validators={[]}
                            fullWidth
                        />
                        <SelectValid
                            id="package-item"
                            value={itemId}
                            label="Product being created"
                            fullWidth
                            validators={[]}
                            onChange={setItemId}
                        >
                            <MenuItem value={''}/>
                            {items.map( ( item ) => (
                                <MenuItem key={item.Id} value={item.id}>{item.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <SelectValid
                            id="package-location"
                            value={locationId}
                            label="Storage Room to send to"
                            fullWidth
                            validators={[]}
                            onChange={setLocationId}
                        >
                            <MenuItem value={''}/>
                            {packageRooms.map( ( item ) => (
                                <MenuItem key={item.id} value={item.id}>{item.metrc.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <TextValid
                            value={note}
                            onChange={setNote}
                            id="package-note"
                            label="Notes"
                            validators={[]}
                            fullWidth
                        />
                        <Divider style={{ marginBottom: 10 }}/>
                        <Ingredients
                            facilityId={facilityId}
                            ingredients={ingredients}
                            itemStrain={selectedItem?.StrainName}
                            onChange={setIngredients}
                        />
                        <Divider style={{ marginBottom: 10 }}/>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!label || !locationId || !itemId || !ingredients.every( item => parseFloat( item.weight ) > 0 && parseFloat( item.weight ) <= item.harvest.metrc.CurrentWeight )}
                            onClick={handleSubmit}
                        >
                            Create Package
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default HarvestToPackage;