import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import { IScheduleTask } from '@shared/interfaces/lib/scheduleInterfaces';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { PlayArrow } from '@material-ui/icons';
import { deepPurple, green } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import { FirebaseContext } from '../../../firebase_init';
import { setError } from '../../../redux/appSlice';
import { removeUndefined } from '../../../utils';

interface IProps {
    task: IScheduleTask;
}

const useStyles = makeStyles( () => ({
    startButton: {
        backgroundColor: green[500],
    },
    purple: {
        backgroundColor: deepPurple[500],
    }
}) );

const SchedulerTaskProgress: React.FC<IProps> = ( { task } ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );

    const handleUpdate = ( updatedTask: IScheduleTask ) => {
        firestore.collection( Collections.scheduleTasks )
            .doc( updatedTask.id ).set( removeUndefined( updatedTask ) )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
    };

    const handleStart = () => {
        handleUpdate( {
            ...task,
            dateStarted: DateTime.local().toISODate(),
        } );
    };

    const handleComplete = () => {
        handleUpdate( {
            ...task,
            dateCompleted: DateTime.local().toISODate(),
        } );
    };

    return (
        <>
            {!task.dateCompleted && task.dateStarted && (
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        endIcon={<PlayArrow/>}
                        className={classes.startButton}
                        onClick={handleComplete}
                    >
                        Complete
                    </Button>
                </>
            )}

            {!task.dateStarted && (
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        endIcon={<PlayArrow/>}
                        className={classes.startButton}
                        onClick={handleStart}
                    >
                        Start
                    </Button>
                </>
            )}
            {(task.dateCompleted || task.dateStarted) && (
                <hr style={{ 'borderTop': '#eee' }}/>
            )}
        </>
    );
};

export default SchedulerTaskProgress;