import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IMetrcStrain, StrainTestStatuses } from '@shared/interfaces/lib/metrcInterfaces';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import { Box, Link } from '@material-ui/core';
import { postUpdateStrain } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectExternalContacts, selectFacilities, selectUser } from '../../../redux/appSlice';
import { useSelector } from 'react-redux';
import { IFacility, IStrain } from '@shared/interfaces/lib/interfaces';
import { geneticsTemplate, useSelectedStrain } from '../../../utils';
import EditStrain from './editStrain';
import LinkStrain from './link';

interface IFacilityStrain {
    facility: IFacility;
    strain?: IMetrcStrain;
}

interface IRowFacility {
    isEmpty: boolean;
    isDifferent: boolean;
    facilityValue: string;
    facilityId: string;
    strainId?: number;
    testingStatus?: StrainTestStatuses;
}

interface IRow {
    label: string;
    cultivatd: string;
    facilities: IRowFacility[]
}

const Row: React.FC<IRow> = ( { label, cultivatd, facilities } ) => {
    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell>{cultivatd}</TableCell>
            {facilities.map( ( item, index ) => (
                <TableCell key={index}>
                    <Typography
                        variant="body2" color={item.isDifferent ? 'error' : 'initial'}
                    >
                        {item.facilityValue}
                    </Typography>
                </TableCell>
            ) )}
        </TableRow>
    );
}

interface IEditRow {
    onUpdate: ( row: IRowFacility ) => void;
    onEdit: () => void;
    rows: IRow[]
    onOpen: ( id: string ) => void;
}

interface IMapping {
    name: string;
    cultivatd: keyof IStrain;
    facility: keyof IMetrcStrain;
}

const EditRow: React.FC<IEditRow> = ( { onEdit, onUpdate, rows, onOpen } ) => (
    <TableRow>
        <TableCell/>
        <TableCell>
            <Button
                color="primary"
                onClick={onEdit}
            >
                Edit
            </Button>
        </TableCell>
        {rows[0].facilities.map( ( row, index ) => {
            const { facilityId, isEmpty } = row;
            const isDifferent = rows.some( ( row ) => {
                const found = row.facilities.find( ( facility ) => facility.facilityId === facilityId );
                return found && found.isDifferent;
            } )
            return (
                <TableCell key={index}>
                    {isEmpty ? (
                        <Button
                            color="primary"
                            onClick={() => onOpen( facilityId )}
                        >
                            Link / Create
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            disabled={!isDifferent} onClick={() => onUpdate( row )}
                        >
                            {isDifferent ? 'Update' : 'Matches'}
                        </Button>
                    )}
                </TableCell>
            );
        } )}
    </TableRow>
);

const mapping: IMapping[] = [{
    name: 'Name',
    cultivatd: 'name',
    facility: 'Name',
}, {
    name: 'THC %',
    cultivatd: 'thc',
    facility: 'ThcLevel',
}, {
    name: 'CBD %',
    cultivatd: 'cbd',
    facility: 'CbdLevel',
}, {
    name: 'Sativa %',
    cultivatd: 'sativaPercent',
    facility: 'SativaPercentage',
}, {
    name: 'Indica %',
    cultivatd: 'indicaPercent',
    facility: 'IndicaPercentage',
}];

const getCompareRows = ( facilities: IFacilityStrain[], cultivatd?: IStrain ) => {
    const compare = facilities.map( ( { strain, facility } ) => {
        const isEmpty = !strain;

        let values: { value: string, isDifferent: boolean }[] = [];
        let strainId: number | undefined = undefined;
        let testingStatus: StrainTestStatuses | undefined = undefined;
        if ( strain && cultivatd ) {
            values = mapping.map( ( map ) => {
                const cultivatdValue = cultivatd[map.cultivatd];
                const facilityValue = strain[map.facility];
                return {
                    value: (facilityValue ?? '').toString(),
                    isDifferent: cultivatdValue !== facilityValue,
                };
            } );
            strainId = strain.Id
            testingStatus = strain.TestingStatus;
        }
        return {
            isEmpty,
            values,
            strainId,
            testingStatus,
            facilityId: facility.id,
        };
    } );

    return mapping.map<IRow>( ( map, index ) => {
        return {
            label: map.name,
            cultivatd: ((cultivatd || {})[map.cultivatd] ?? '').toString(),
            facilities: compare.map( ( { isEmpty, values, facilityId, strainId, testingStatus } ) => {
                const value = values[index];
                return {
                    isDifferent: isEmpty ? false : value.isDifferent,
                    isEmpty,
                    facilityValue: isEmpty ? '' : value.value,
                    facilityId,
                    strainId,
                    testingStatus,
                };
            } ),
        };
    } );
}

const Strain: React.FC<RouteComponentProps<{ strainId: string }>> = ( { strainId } ) => {
    const { firestore } = useContext( FirebaseContext );
    const user = useSelector( selectUser );
    const facilities = useSelector( selectFacilities );
    const externalContacts = useSelector( selectExternalContacts );
    const strain = useSelectedStrain( strain => strain.id === strainId );
    const [edit, setEdit] = useState<boolean>( false );
    const [open, setOpen] = useState( '' );
    const [metrcFacilities, setMetrcFacilities] = useState<IFacilityStrain[]>( [] );
    const compare = useMemo( () => getCompareRows( metrcFacilities, strain ), [strain, metrcFacilities] );

    useEffect( () => {
        if(!strain){
            return;
        }

        facilities.forEach( ( facility ) => {
            if ( !facility.id ) {
                return;
            }
            firestore.collection( Collections.metrcFacilities )
                .doc( facility.id )
                .get()
                .then( ( doc ) => {
                    const facility = doc.data() as IFacility;
                    const strainLink = strain.metrcStrains.find( ( item ) => item.metrcLicense === facility.metrc.License.Number );
                    if ( strainLink ) {
                        doc.ref.collection( MetrcFacilitySubCollections.metrcStrains )
                            .doc( strainLink.metrcStrainId.toString() )
                            .onSnapshot( ( strainDoc ) => {
                                setMetrcFacilities( ( prev ) => {
                                    const index = prev.findIndex( ( item ) => item.facility.id === facility.id );
                                    const newArray = [...prev];
                                    const data = {
                                        facility,
                                        strain: strainDoc.data() as IMetrcStrain,
                                    };
                                    if ( index === -1 ) {
                                        newArray.push( data )
                                    } else {
                                        newArray[index] = data;
                                    }
                                    return newArray;
                                } );
                            } );
                    } else {
                        setMetrcFacilities( ( prev ) => prev.concat( [{
                            facility,
                        }] ) );
                    }
                } );
        } );
    }, [] );

    if ( !strain || !user ) {
        navigate( '/' ).catch();
        return null;
    }

    const onUpdateFacility = ( row: IRowFacility ) => {
        if ( !row || !row.strainId ) {
            return;
        }
        postUpdateStrain( user.token, row.strainId, {
            name: strain.name,
            thc: strain.thc,
            cbd: strain.cbd,
            indica: strain.indicaPercent,
            sativa: strain.sativaPercent,
            testingStatus: row.testingStatus,
            facilityId: row.facilityId,
        } ).catch();
    };

    const contact = externalContacts.find( ( contact ) => contact.id === strain.breederId );

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Strain
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {strain.name}
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    <strong>
                        &nbsp;/ {geneticsTemplate( strain )} {`${contact ? `/ ${contact.name}` : ''}`}
                    </strong>
                </Typography>
                <Link href="#" onClick={() => setEdit( true )} variant="body1">
                    <strong>
                        &nbsp;<span style={{ 'color': 'black' }}>/</span> Edit
                    </strong>
                </Link>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Breeder
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {contact ? contact.name : ''}
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Cross
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {strain.cross ? strain.cross : 'No Cross Entered'}
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        THC
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {strain.thc !== undefined ? strain.thc * 100 + '%' : 'No THC Submitted yet'}
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        CBD
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {strain.cbd !== undefined ? strain.cbd * 100 + '%' : 'No CBD Submitted yet'}
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Harvests
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ No Harvests Yet
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Notes
                    </strong>
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="body2" style={{ display: 'inline' }}>
                    {strain.notes}
                </Typography>
                {edit && (
                    <EditStrain open={true} onClose={() => setEdit( false )} strain={strain}/>
                )}
            </Box>
            <Divider/>
            <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                Strain Mapping Across Metrc Facilities
            </Typography>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                        <TableCell/>
                        <TableCell>Cultivatd</TableCell>
                        {metrcFacilities.map( ( item ) => (
                            <TableCell key={item.facility.id}>
                                {item.facility.metrc.DisplayName}
                            </TableCell>
                        ) )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <EditRow
                        onUpdate={onUpdateFacility}
                        onEdit={() => setEdit( true )}
                        rows={compare}
                        onOpen={setOpen}
                    />
                    {compare.map( ( item ) => (
                        <Row key={item.label} {...item}/>
                    ) )}
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        {metrcFacilities.map( ( item ) => (
                            <TableCell key={item.facility.id}>
                                {item.strain ? item.strain.IsUsed ? 'Used' : 'Not Used' : ''}
                            </TableCell>
                        ) )}
                    </TableRow>
                </TableBody>
            </Table>
            {open && (
                <LinkStrain
                    strain={strain}
                    licenseId={open}
                    onClose={() => setOpen( '' )}
                />
            )}
        </>
    );
};

export default Strain;
