import React from 'react';
import { useSelector } from 'react-redux';
import { navigate, RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UserAvatar from '../../components/userAvatar';
import { selectUsers } from '../../redux/appSlice';

const useStyles = makeStyles( ( theme: Theme ) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing( 8 ),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}) );

const Profile: React.FC<RouteComponentProps<{ userId: string }>> = ({userId}) => {
    const classes = useStyles();

    const users = useSelector(selectUsers);
    const user = users.find((item) => item.uid === userId);

    if ( !user ) {
        navigate('/');
        return null;
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <UserAvatar id={user.uid} name={user.displayName} hasLink={false}/>
                <Typography component="h1" variant="h5">
                    {user.displayName}
                </Typography>
            </div>
        </Container>
    );
};

export default Profile;
