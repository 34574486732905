import { Form, Validators } from '@happybandit/react-validation';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertTitle, ToggleButton } from '@material-ui/lab';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ICloneToVegBody, ICloneToVegItem } from '@shared/interfaces/lib/contractInterfaces';
import { IPlantBatch, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postPlantBatchToVeg, ResponseError } from '../../../api';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { useFacilityRooms } from '../../../utils';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';

interface IProps {
    batch: IPlantBatch;
    facilityId: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            minHeight: 100,
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);

interface IBlockProps {
    label: string;
    setLabel: ( label: string ) => void;
    count: number;
    setCount: ( count: number ) => void;
    startCount: number;
}

const BlockLabels: React.FC<IBlockProps> = ( { label, count, setLabel, setCount, startCount } ) => (
    <>
        <Typography variant="h5">
            Assign a block of Labels to the plants.
            Use when labels are sequential with none missing.
        </Typography>
        <TextValid
            onChange={setLabel}
            value={label}
            label="Starting Label"
            id="clone-veg-label-block"
            fullWidth
            validators={[
                Validators.required( label ),
            ]}
        />
        <TextValid
            type="number"
            value={count.toString()}
            onChange={( value ) => setCount( parseInt( value ) )}
            id="clone-veg-count"
            label="Number of plants to label"
            validators={[
                Validators.required( count.toString() ),
                Validators.positive( count.toString() ),
                Validators.custom( () => count <= startCount, 'Not Enough Clones to use all labels.' )
            ]}
            fullWidth
        />
    </>
);

interface IIndividualProps {
    label: string;
    setLabel: Dispatch<SetStateAction<string>>;
    labels: string[];
    setLabels: Dispatch<SetStateAction<string[]>>;
    startCount: number;
}

const IndividualLabels: React.FC<IIndividualProps> = ( { label, labels, setLabel, setLabels, startCount } ) => {
    const handleAdd = () => {
        setLabels( prevState => [...prevState, label] );
        setLabel( '' );
    };

    const handleDelete = ( index: number ) => {
        setLabels( prevState => {
            const newState = [...prevState];
            newState.splice( index, 1 );
            return newState;
        } );
    };

    return (
        <>
            <Typography variant="h5">
                Assign each label by hand. Use when non-sequential numbers.
            </Typography>
            <List dense={true}>
                {labels.map( ( item, index ) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={item}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDelete( index )}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ) )}
            </List>
            {labels.length >= startCount && (
                <Typography variant="h6">
                    No Clones remaining, cannot add more label's till one is removed.
                </Typography>
            )}
            <div style={{ display: 'flex' }}>
                <div>
                    <TextValid
                        label="Label"
                        onChange={setLabel}
                        value={label}
                        id="clone-veg-label"
                        validators={[]}
                    />
                </div>
                <div>
                    <Button
                        variant="contained"
                        onClick={handleAdd}
                        disabled={!label || labels.length >= startCount}
                    >
                        Add
                    </Button>
                </div>
            </div>
        </>
    );
}

const CloneToVeg: React.FC<IProps> = ( { batch, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector( selectUser );
    const rooms = useFacilityRooms( facilityId );
    const [labels, setLabels] = useState<string[]>( [] );
    const [label, setLabel] = useState<string>( '' );
    const [roomId, setRoomId] = useState( '' );
    const [isOrdered, setIsOrdered] = useState( false );
    const [count, setCount] = useState( 0 );
    const [loading, setLoading] = useState( false );
    const [errors, setErrors] = useState<string[]>( [] );
    const date = useMemo( () => DateTime.local(), [] );

    const vegRooms = rooms.filter( ( room ) => (room.types || []).includes( RoomTypes.vegetation ) );

    const selectedRoom = vegRooms.find( item => item.id === roomId );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user || !selectedRoom ) {
            return;
        }
        setLoading( true );
        setErrors( [] );

        const labelItems: ICloneToVegItem[] = isOrdered ? [{
            label,
            count,
        }] : labels.map( ( item ) => ({
            label: item,
            count: 1,
        }) )

        const body: ICloneToVegBody = {
            batchName: batch.metrc.Name,
            locationId: selectedRoom.metrc.Id,
            facilityId,
            labels: labelItems,
            date: date.toISODate(),
        }
        postPlantBatchToVeg( user.token, body )
            .then( () => {
                dispatch( setShowPlantDetails( undefined ) );
            } )
            .catch( async ( e: ResponseError ) => {
                if ( e.response.status === 400 ) {
                    const body: { errors: string[] } = await e.response.json();
                    setErrors( body.errors );
                }
            } )
            .finally( () => {
                setLoading( false );
            } );
    };

    const startCount = batch.metrc.UntrackedCount;

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Assign Clones to Vegetation
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        {errors.length > 0 && (
                            <Alert variant="filled" severity="error">
                                <AlertTitle>Label Assignment Failed</AlertTitle>
                                {errors.map( ( error ) => (
                                    <div>{error}</div>
                                ) )}
                            </Alert>
                        )}
                        <Typography variant="h5">
                            Assign Clones a Metrc Label and move to Vegetation state.
                        </Typography>
                        <Typography variant="h6">
                            Remaining Clones: {startCount - (isOrdered ? count : labels.length)}
                        </Typography>
                        <ToggleButtonGroup
                            value={isOrdered}
                            exclusive
                            onChange={( event, value ) => setIsOrdered( value )}
                        >
                            <ToggleButton
                                value={false}
                            >
                                Enter Labels Individually
                            </ToggleButton>
                            <ToggleButton
                                value={true}
                            >
                                Enter Block of Labels
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Form onSubmit={handleSubmit}>
                            {isOrdered ? (
                                <BlockLabels
                                    startCount={startCount}
                                    label={label}
                                    count={count}
                                    setLabel={setLabel}
                                    setCount={setCount}
                                />
                            ) : (
                                <IndividualLabels
                                    startCount={startCount}
                                    label={label}
                                    labels={labels}
                                    setLabel={setLabel}
                                    setLabels={setLabels}
                                />
                            )}
                            <hr style={{ 'borderTop': '#eee' }}/>
                            <SelectValid
                                id="clone-veg-location"
                                value={roomId}
                                label="Vegetation Room to send to"
                                fullWidth
                                validators={[
                                    Validators.required( roomId ),
                                ]}
                                onChange={setRoomId}
                            >
                                <MenuItem value={''}/>
                                {vegRooms.map( ( item ) => (
                                    <MenuItem key={item.id} value={item.id}>{item.metrc.Name}</MenuItem>
                                ) )}
                            </SelectValid>
                            <hr style={{ 'borderTop': '#eee' }}/>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Submit New Plants
                            </Button>
                        </Form>
                    </>
                )}
            </div>
        </>
    )
};

export default CloneToVeg;