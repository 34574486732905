import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useDispatch, useSelector } from 'react-redux';
import { INotification } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { Omit } from '@material-ui/core';
import { Form, Validators } from '@happybandit/react-validation';
import { isConsumable, isDetailConsumable, isEquipment, removeUndefined, useNotification } from '../../utils';
import { selectUser, setError } from '../../redux/appSlice';
import { FirebaseContext } from '../../firebase_init';
import TextValid from '../../components/TextValid';
import SelectValid from '../../components/SelectValid';
import {
    ICategorySelected,
    IInventoryDetail,
    Units
} from '@shared/interfaces/lib/inventoryInterfaces';
import CategorySelectValid from '../CategorySelectValid';

interface IProps {
    inventoryDetail?: IInventoryDetail;
    onClose: () => void;
}

const InventoryDetailDialog: React.FC<IProps> = ( { inventoryDetail, onClose } ) => {
    const context = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const theme = useTheme();

    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [name, setName] = useState<string>( (inventoryDetail && inventoryDetail.name) || '' );
    const [sku, setSku] = useState<number>( (inventoryDetail && inventoryDetail.sku) || 0 );
    const [brand, setBrand] = useState<string>( (inventoryDetail && inventoryDetail.brand) || '' );
    const [model, setModel] = useState<string>( (inventoryDetail && inventoryDetail.model) || '' );
    const [category, setCategory] = useState<ICategorySelected | undefined>( inventoryDetail ? {
        type: inventoryDetail.type,
        userCategoryId: inventoryDetail.userCategoryId
    } : undefined );
    const [criticalLimit, setCriticalLimit] = useState<number>( (inventoryDetail && isDetailConsumable( inventoryDetail ) && inventoryDetail.criticalLimit) || 0 );
    const [unit, setUnit] = useState<Units>( (inventoryDetail && isDetailConsumable( inventoryDetail ) && inventoryDetail.unit) || Units.Single );
    const user = useSelector( selectUser );
    const sendNotification = useNotification();

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user || !category ) {
            return;
        }

        const collection = context.firestore.collection( Collections.inventoryDetails );
        let update: IInventoryDetail | undefined;
        if ( isEquipment( category.type ) ) {
            update = {
                ...inventoryDetail,
                id: inventoryDetail ? inventoryDetail.id : collection.doc().id,
                name,
                brand,
                model,
                sku,
                type: category.type,
                userCategoryId: category.userCategoryId,
            };
        } else if ( isConsumable( category.type ) ) {
            update = {
                ...inventoryDetail,
                id: inventoryDetail ? inventoryDetail.id : collection.doc().id,
                name,
                brand,
                model,
                sku,
                ...category,
                criticalLimit,
                unit,
            };
        }

        if ( !update ) {
            return;
        }

        collection.doc( update.id ).set( removeUndefined( update ), {merge: true} )
            .then( () => {
                onClose();
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );

        const notification: Omit<INotification, 'id'> = {
            timestamp: Date.now(),
            seen: false,
            userId: user.uid,
            message: `Updated Inventory Detail - ${name}`,
        };
        sendNotification( notification, ( userItem ) => userItem.permissions.includes( UserPermissions.userAdmin ) );
    };

    return (
        <>
            <Dialog fullScreen={fullScreen} open={true} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle>{!inventoryDetail ? 'Create New' : 'Update'} Inventory Detail</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill in details about this Inventory Option
                    </DialogContentText>
                    <Form id="inventory-item-form" onSubmit={handleSubmit}>
                        <CategorySelectValid
                            id="inventory-item-category"
                            value={category}
                            label="Inventory Type"
                            fullWidth
                            validators={[Validators.custom( () => !!category )]}
                            onChange={( value ) => setCategory( value )}
                            hasEquipment={true}
                            hasConsumables={true}
                        />
                        <TextValid
                            id="inventory-new-name"
                            label="Name"
                            autoComplete='none'
                            value={name}
                            onChange={( value ) => setName( value )}
                            fullWidth
                            validators={[Validators.required( name )]}
                        />
                        <TextValid
                            id="inventory-new-brand"
                            label="Brand"
                            autoComplete='none'
                            value={brand}
                            onChange={( value ) => setBrand( value )}
                            fullWidth
                            validators={[Validators.required( brand )]}
                        />
                        <TextValid
                            id="inventory-new-model"
                            label="Model"
                            autoComplete='none'
                            value={model}
                            onChange={( value ) => setModel( value )}
                            fullWidth
                            validators={[Validators.required( model )]}
                        />
                        <TextValid
                            type="number"
                            id="inventory-new-sku"
                            label="SKU"
                            autoComplete='none'
                            value={sku.toString()}
                            onChange={( value ) => setSku( parseInt( value ) )}
                            fullWidth
                            validators={[]}
                        />
                        {category && isConsumable( category.type ) && (
                            <>
                                <SelectValid
                                    id="inventory-unit"
                                    value={unit}
                                    label="Unit"
                                    fullWidth
                                    onChange={( value ) => setUnit( value as Units )}
                                    validators={[]}
                                >
                                    <MenuItem value=""/>
                                    {Object.entries( Units ).map( ( [key, value] ) => (
                                        <MenuItem
                                            key={key}
                                            value={value}
                                        >
                                            {key}
                                        </MenuItem>
                                    ) )}
                                </SelectValid>
                                <TextValid
                                    id="inventory-new-min-required"
                                    label="Minimum Required"
                                    autoComplete='none'
                                    value={criticalLimit.toString()}
                                    onChange={( value ) => setCriticalLimit( parseInt( value ) )}
                                    fullWidth
                                    validators={[Validators.required( criticalLimit.toString() )]}
                                />
                            </>
                        )}
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" form="inventory-item-form" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const InventoryDetailNew = () => {
    const [open, setOpen] = useState( false );

    return (
        <>
            <Button onClick={() => setOpen( true )} color="primary">
                Add new Inventory Detail
            </Button>
            {open && (
                <InventoryDetailDialog onClose={() => setOpen( false )}/>
            )}
        </>
    );
};

export default InventoryDetailDialog;