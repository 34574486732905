import {
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    ListItemAvatar,
    makeStyles,
    Theme
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { Done, Error } from '@material-ui/icons';
import { IScheduleTask } from '@shared/interfaces/lib/scheduleInterfaces';
import React from 'react';
import { useSelector } from 'react-redux';
import UserAvatar from 'components/userAvatar';
import { selectUsers } from 'redux/appSlice';
import { useTaskName } from 'utils';
import { DateTime } from 'luxon';

interface IProps {
    task: IScheduleTask;
    onClick: () => void;
}

const useStyles = makeStyles( ( theme: Theme ) => ({
    chip: {
        borderRadius: 0,
    },
    complete: {
        color: '#ffffff',
        fontWeight: 'bold',
        backgroundColor: green[500],
        '& svg': {
            color: '#ffffff',
        },
    },
    overdue: {
        color: '#ffffff',
        fontWeight: 'bold',
        backgroundColor: theme.palette.error.dark,
        '& svg': {
            color: '#ffffff',
        },
    },
    warning: {
        color: '#ffffff',
        fontWeight: 'bold',
        backgroundColor: theme.palette.warning.dark,

        '& svg': {
            color: '#ffffff',
        },
    }
}) )

const ScheduleCard: React.FC<IProps> = ( { task, onClick } ) => {
    const classes = useStyles();
    const users = useSelector( selectUsers );
    const getTaskName = useTaskName();

    const dueDate = DateTime.fromISO( task.dueDate );

    const warning = dueDate.diffNow( 'day' ).days < 1;
    const overdue = dueDate < DateTime.local();

    const user = users.find( item => item.uid === task.employeeId );

    return (
        <Grid item xs={12} md={2}>
            <Card raised={false} variant="outlined" elevation={0} square={true} color={task.dateCompleted ? 'grey' : undefined}>
                <CardActionArea onClick={onClick}>
                    <CardHeader
                        avatar={user && (
                            <ListItemAvatar>
                                <UserAvatar id={user.uid} name={user.displayName}/>
                            </ListItemAvatar>
                        )}
                        title={getTaskName(task)}
                        subheader={`Due Date: ${dueDate.toLocaleString()}`}
                    />
                    <CardContent>
                        <Typography variant="body2">
                            {task.description}
                        </Typography>
                        {task.dateCompleted ? (
                            <Chip
                                icon={<Done/>}
                                label="Task Completed"
                                className={classes.complete}
                            />
                        ) : (overdue || warning) && (
                            <Chip
                                icon={<Error/>}
                                label={warning ? 'Task Due Soon' : 'Task is overdue'}
                                className={`${classes.chip} ${warning ? classes.warning : overdue ? classes.overdue : ''}`}
                            />
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default ScheduleCard;
