import { Form, Validators } from '@happybandit/react-validation';
import { capitalize } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Assignment } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Collections, MetrcLocationSubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { ISystem } from '@shared/interfaces/lib/interfaces';
import { ICategorySelected, ILocation } from '@shared/interfaces/lib/inventoryInterfaces';
import {
    ICustomTask,
    IMaintenanceTask,
    IScheduleTask,
    ISubTask,
    ITaskBase,
    TaskLocation,
    TaskType,
} from '@shared/interfaces/lib/scheduleInterfaces';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseContext } from '../../../firebase_init';

import { selectEquipment, selectInventoryDetails, selectUser, selectUsers, setError } from '../../../redux/appSlice';
import { IUser } from '../../../User';
import { removeUndefined, useSelectedFacility, useSelectedRoom } from '../../../utils';
import CategorySelectValid from '../../CategorySelectValid';
import DateValid from '../../DateValid';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';
import SchedulerLocations from '../locations';
import {
    Avatar,
    Box,
    DialogContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Typography
} from '@material-ui/core';

interface ISchedulerDialogProps {
    onClose: () => void;
    task?: IScheduleTask;
}

interface IEquipProps {
    equipmentId: string;
    onChange: ( id: string ) => void;
}

const EquipmentLocation: React.FC<{ location: ILocation }> = ( { location } ) => {
    const { firestore } = useContext( FirebaseContext );
    const facility = useSelectedFacility( facility => location.facilityId === facility.id );
    const room = useSelectedRoom( location.facilityId ?? '', room => location.roomId === room.id );
    const [system, setSystem] = useState<ISystem>();

    useEffect( () => {
        if ( !location.systemId ) {
            return;
        }

        firestore.collectionGroup( MetrcLocationSubCollections.systems )
            .where( 'id', '==', location.systemId )
            .get()
            .then( ( snap ) => {
                if ( snap.size === 1 ) {
                    const doc = snap.docs[0];
                    setSystem( doc.data() as ISystem );
                }
            } );
    }, [] );

    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Typography>{facility ? facility.metrc.DisplayName : 'Unknown Facility'}</Typography>
            {room && <Typography>{room.metrc.Name}</Typography>}
            {system && <Typography>{`${system.name} (${capitalize( system.type )})`}</Typography>}
        </Breadcrumbs>
    );
};

const EquipmentSelector: React.FC<IEquipProps> = ( { equipmentId, onChange } ) => {
    const equipment = useSelector( selectEquipment );
    const details = useSelector( selectInventoryDetails );
    const [cat, setCat] = useState<ICategorySelected>();
    const [detail, setDetail] = useState<string>( '' );

    useEffect( () => {
        if ( !equipmentId ) {
            return;
        }

        const found = equipment.find( item => item.id === equipmentId );
        const foundDetail = details.find( detail => detail.id === found?.inventoryId );

        if ( foundDetail ) {
            setDetail( foundDetail.id );
            setCat( {
                userCategoryId: foundDetail.userCategoryId,
                type: foundDetail.type,
            } )
        }

    }, [] );

    return (
        <>
            <CategorySelectValid
                hasEquipment
                id="new-schedule-task-cat"
                value={cat}
                label="Inventory Type"
                fullWidth
                validators={[Validators.required( cat?.type || '' )]}
                onChange={setCat}
            />
            <SelectValid
                fullWidth
                label="Equipment"
                value={detail}
                onChange={setDetail}
                id="new-schedule-task-type"
                validators={[Validators.required( detail )]}
            >
                {details.filter( item => cat && item.type === cat.type && item.userCategoryId === cat.userCategoryId )
                    .map( item => (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                        >
                            {item.name}
                        </MenuItem>
                    ) )}
            </SelectValid>
            <SelectValid
                fullWidth
                label="Location"
                value={equipmentId}
                onChange={onChange}
                id="new-schedule-task-type"
                validators={[Validators.required( equipmentId )]}
            >
                {equipment.filter( item => item.inventoryId === detail )
                    .map( item => (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                        >
                            <EquipmentLocation location={item}/>
                        </MenuItem>
                    ) )}
            </SelectValid>
        </>
    );
};

const SchedulerTaskEdit: React.FC<ISchedulerDialogProps> = ( { task, onClose } ) => {
    const user = useSelector( selectUser );
    const users = useSelector( selectUsers );
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [newTask, setNewTask] = useState<string>( '' );
    const [name, setName] = useState<string>( (task as ICustomTask)?.name || '' );
    const [equipmentId, setEquipmentId] = useState<string>( (task as IMaintenanceTask)?.equipmentId || '' );
    const [subTasks, setSubTasks] = useState<ISubTask[]>( task?.subTasks || [] );
    const [employeeId, setEmployeeId] = useState<string>( task?.employeeId || '' );
    const [description, setDescription] = useState<string>( task?.description || '' );
    const [locations, setLocations] = useState<TaskLocation[]>( task?.locations || [] );
    const [dueDate, setDueDate] = useState<Date | null>( task?.dueDate ? DateTime.fromISO( task.dueDate ).toJSDate() : new Date() );

    const [type, setType] = useState<TaskType>( task?.type ?? TaskType.Custom );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user || !dueDate ) {
            return;
        }

        const collection = firestore.collection( Collections.scheduleTasks );

        let data: ITaskBase;
        if ( task ) {
            data = {
                ...task,
                type,
                createdBy: user.uid,
                locations,
                facilityIds: [...(new Set( locations.map( loc => loc.facilityId ).filter( Boolean ) ))],
                roomIds: [...(new Set( locations.map( loc => loc.roomId ).filter( Boolean ) as string[] ))],
                employeeId,
                description,
                subTasks,
                dueDate: DateTime.fromJSDate( dueDate ).toISODate(),
            };
        } else {
            data = {
                id: collection.doc().id,
                type,
                createdBy: user.uid,
                locations,
                facilityIds: [...(new Set( locations.map( loc => loc.facilityId ).filter( Boolean ) ))],
                roomIds: [...(new Set( locations.map( loc => loc.roomId ).filter( Boolean ) as string[] ))],
                employeeId,
                description,
                subTasks,
                dateStarted: null,
                dateCompleted: null,
                dueDate: DateTime.fromJSDate( dueDate ).toISODate(),
                dateCreated: DateTime.local().toISODate(),
            };
        }

        if ( type === TaskType.Custom ) {
            (data as ICustomTask).name = name;
        }

        if ( type === TaskType.Maintenance ) {
            (data as IMaintenanceTask).equipmentId = equipmentId;
        }

        collection.doc( data.id ).set( removeUndefined( data ) )
            .then( onClose )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
    };

    const onAddSubTask = ( event: React.MouseEvent<HTMLButtonElement> ): void => {
        event.preventDefault();
        const taskToAdd: ISubTask = {
            text: newTask,
            isComplete: false,
        };
        const newTasks = [...subTasks, taskToAdd];

        setSubTasks( newTasks );
        setNewTask( '' );
    };

    const handleSubTaskDelete = ( index: number ): void => {
        let newTasks = [...subTasks];

        newTasks.splice( index, 1 );
        setSubTasks( newTasks );
    };

    return (
        <>
            <DialogContent>
                <Form id="edit-scheduler-task-form" onSubmit={handleSubmit}>
                    <Typography variant="h5" style={{ display: 'inline' }}>
                        <strong>
                            {task ? 'Update' : 'Create'} Task
                        </strong>
                    </Typography>
                    {task ? type : (
                        <SelectValid
                            fullWidth
                            label="Type"
                            value={type}
                            onChange={( value ) => setType( value as TaskType )}
                            id="new-schedule-task-type"
                            validators={[Validators.required( type )]}
                        >
                            <MenuItem value={TaskType.Custom}>Custom</MenuItem>
                            <MenuItem value={TaskType.Maintenance}>Maintenance</MenuItem>
                        </SelectValid>
                    )}
                    {type === TaskType.Custom && (
                        <TextValid
                            id="new-schedule-task-name"
                            label="Name"
                            value={name}
                            onChange={( value ) => setName( value )}
                            fullWidth
                            validators={[Validators.required( name )]}
                        />
                    )}
                    {type === TaskType.Maintenance && (
                        <EquipmentSelector equipmentId={equipmentId} onChange={setEquipmentId}/>
                    )}
                    <SelectValid
                        fullWidth
                        label="Employee"
                        value={employeeId}
                        onChange={setEmployeeId}
                        id="new-schedule-task-employee"
                        validators={[]}
                    >
                        <MenuItem value="">Unassigned</MenuItem>
                        {users.map( ( user: IUser ) => (
                            <MenuItem value={user.uid} key={user.uid}>{user.displayName}</MenuItem>
                        ) )}
                    </SelectValid>
                    <DateValid
                        fullWidth
                        clearable
                        validators={[]}
                        label="Due Date"
                        value={dueDate}
                        onChange={setDueDate}
                        id="new-schedule-task-due-date"
                    />
                    <TextValid
                        id="new-schedule-task-notes"
                        multiline
                        fullWidth
                        rows={6}
                        label="Description"
                        value={description}
                        aria-label="Description"
                        placeholder="Enter any notes for the task here..."
                        onChange={setDescription}
                        validators={[]}
                    />
                    <br/>
                    <Box pt={2}>
                        <Typography variant="h5" style={{ display: 'inline', paddingTop: '1em' }}>
                            <strong>
                                Sub Tasks
                            </strong>
                        </Typography>
                        <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                            <strong>
                                {` / ${subTasks.length} Sub Tasks `}
                            </strong>
                        </Typography>
                    </Box>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <div className="d-flex">
                        {subTasks.length > 0 && (
                            <List dense={false}>
                                {subTasks.map( ( task, index ) => (
                                    <ListItem divider={true} key={index}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Assignment/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={task.text}
                                        />
                                        <ListItemSecondaryAction>
                                            <DeleteIcon onClick={() => handleSubTaskDelete( index )}/>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) )}
                            </List>
                        )}

                        {subTasks.length === 0 && (
                            <Box pt={1} pb={1}>
                                <Typography variant="body2" style={{ display: 'inline' }}>
                                    No Sub Tasks
                                </Typography>
                            </Box>
                        )}

                        <Box pt={1}>
                            <TextValid
                                fullWidth
                                type="text"
                                validators={[]}
                                value={newTask}
                                onChange={setNewTask}
                                label="Enter Sub Task Name"
                                id="new-schedule-task-new-task"
                            />
                        </Box>

                        <Button
                            type="button"
                            color="primary"
                            endIcon={<AddIcon/>}
                            onClick={onAddSubTask}
                        >
                            Add Sub Task
                        </Button>
                    </div>
                    <SchedulerLocations
                        edit
                        locations={locations}
                        onChange={setLocations}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    disabled={false}
                    form="edit-scheduler-task-form"
                >
                    {task ? 'Update Task' : 'Schedule Task'}
                </Button>
            </DialogActions>
        </>
    );
};

export default SchedulerTaskEdit;