import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { DateTime } from 'luxon';
import {
    Typography,
    Grid,
    DialogContent,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { IScheduleTask, TaskType } from '@shared/interfaces/lib/scheduleInterfaces';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers, setShowTask } from '../../../redux/appSlice';
import { useSelectedEquipment, useSelectedInventoryDetail } from '../../../utils';
import SchedulerLocations from '../locations';
import SchedulerTaskProgress from '../progress';

interface IProps {
    task: IScheduleTask;
    onClose: () => void;
}

const SchedulerTaskView: React.FC<IProps> = ( { task } ) => {
    const dispatch = useDispatch();
    const users = useSelector( selectUsers );
    const equipment = useSelectedEquipment( equipment => task.type === TaskType.Maintenance && task.equipmentId === equipment.id );
    const detail = useSelectedInventoryDetail( detail => detail.id === equipment?.inventoryId );

    const assignedUser = users.find( item => item.uid === task.employeeId );
    const createdUser = users.find( item => item.uid === task.createdBy );
    const name = task.type === TaskType.Custom ? task.name : detail?.name || '';

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    {name}
                                </Typography>
                                <Typography>
                                    Type: {task.type}
                                </Typography>
                                <Typography>
                                    Assigned: {assignedUser?.displayName || 'Unassigned'}
                                </Typography>
                                <Typography>
                                    Due Date: {DateTime.fromISO( task.dueDate ).toLocaleString()}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={false}
                                    onClick={() => dispatch( setShowTask( {
                                        id: task.id,
                                        edit: true,
                                    } ) )}
                                >
                                    Edit Task
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    Details
                                </Typography>
                                <Typography>
                                    Created By: {createdUser?.displayName}
                                </Typography>
                                <Typography>
                                    Created Date: {DateTime.fromISO( task.dateCreated ).toLocaleString()}
                                </Typography>
                                <Typography>
                                    Started Date: {
                                    task.dateStarted ?
                                        DateTime.fromISO( task.dateStarted ).toLocaleString() :
                                        'Not Started'
                                }
                                </Typography>
                                <Typography>
                                    Completed Date: {
                                    task.dateCompleted ?
                                        DateTime.fromISO( task.dateCompleted ).toLocaleString() :
                                        'Not Completed'
                                }
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <SchedulerTaskProgress task={task}/>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    Description
                                </Typography>
                                <Typography>
                                    {task.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    Sub Tasks
                                </Typography>
                                {task.subTasks.map( (subTask, index) => (
                                    <Typography
                                        key={index}
                                        style={subTask.isComplete ? {
                                            textDecoration: 'line-through'
                                        } : undefined}
                                    >
                                        {subTask.text}
                                    </Typography>
                                ) )}
                            </CardContent>
                        </Card>
                    </Grid>
                    {task.locations.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Locations
                            </Typography>
                            <hr style={{ 'borderTop': '#eee', 'marginBottom': '0' }}/>
                            <SchedulerLocations
                                locations={task.locations}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </>
    );
};

export default SchedulerTaskView;