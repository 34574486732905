import { capitalize } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { MetrcLocationSubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlant, IPlantBatch, ISystem, IPackage, IFacility, IRoom } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseContext } from '../../../firebase_init';
import { setShowPlantDetails } from '../../../redux/appSlice';
import { getIndexTitle, getItemType, useFacilityRooms, useSelectedFacility } from '../../../utils';
import PlantBatchDetails from './batch';
import PackageDetails from './package';
import PlantDetails from './plant';

interface IProps {
    item: IPlant | IPlantBatch | IPackage;
    facilityId: string;
}

export const useDetailsStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);

interface INavProps {
    item: IPlant | IPlantBatch | IPackage;
    facility?: IFacility;
    room?: IRoom;
    system?: ISystem;
}

const Location: React.FC<INavProps> = ( { item, facility, room, system } ) => {
    const classes = useDetailsStyles();
    if ( room ) {
        return (
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                <Typography>{facility ? facility.metrc.DisplayName : 'Unknown Facility'}</Typography>
                <Typography>{room ? room.metrc.Name : 'Unknown Room'}</Typography>
                {system && [
                    <Typography key={0}>{system ? `${system.name} (${capitalize( system.type )})` : 'Unknown System'}</Typography>,
                    <Typography key={1}>{ item.location && `Rack ${item.location.rack}`}</Typography>,
                    <Typography
                        key={2}
                        className={classes.level}
                    >
                        {item.location && getIndexTitle( item.location.level, system ? system.size.levelCount : 0 )}
                    </Typography>,
                    <Typography key={3}>{ item.location && `Zone ${item.location.zone}`}</Typography>,
                ]}
            </Breadcrumbs>
        );
    } else {
        return (
            <Typography className={classes.breadcrumb}>Unknown Location</Typography>
        );
    }
};

const Details: React.FC<IProps> = ( { item, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useDetailsStyles();
    const { firestore } = useContext( FirebaseContext );
    const rooms = useFacilityRooms( facilityId );
    const [loading, setLoading] = useState( true );
    const [expanded, setExpanded] = useState( false );
    const [roomId, setRoomId] = useState<string | undefined>();
    const [system, setSystem] = useState<ISystem>();
    const room = rooms.find( iter => iter.id === roomId );
    const facility = useSelectedFacility( item => item.id === facilityId );

    useEffect( () => {
        if ( item.location && item.location.systemId ) {
            getRoomBySystemId( item.location.systemId )
                .then( () => {
                    setLoading( false );
                } );
            return;
        } else if ( item.metrc.LocationId ) {
            const loc = rooms.find( iter => iter.metrc.Id === item.metrc.LocationId );
            if ( loc ) {
                setRoomId( loc.id );
            }
        }
        setLoading( false );
    }, [item] );

    const getRoomBySystemId = ( systemId: string ) => {
        return firestore.collectionGroup( MetrcLocationSubCollections.systems )
            .where( 'id', '==', systemId )
            .get()
            .then( ( snap ) => {
                if ( snap.size === 1 ) {
                    const doc = snap.docs[0];
                    setSystem( doc.data() as ISystem );

                    if ( doc.ref.parent.parent ) {
                        setRoomId( doc.ref.parent.parent.id );
                    }
                }
            } );
    };

    if ( !item ) {
        return null;
    }

    const type = getItemType( item );

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {type} Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        <Location
                            item={item}
                            facility={facility}
                            room={room}
                            system={system}
                        />
                        {type === 'Plant' && (
                            <PlantDetails
                                room={room}
                                system={system}
                                plant={item as IPlant}
                            />
                        )}
                        {type === 'Plant Batch' && (
                            <PlantBatchDetails
                                room={room}
                                system={system}
                                plantBatch={item as IPlantBatch}
                            />
                        )}
                        {type === 'Package' && (
                            <PackageDetails
                                room={room}
                                system={system}
                                packageItem={item as IPackage}
                            />
                        )}
                        <Button onClick={() => setExpanded( !expanded )}>Show JSON</Button>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <pre>{JSON.stringify( item, null, 2 )}</pre>
                        </Collapse>
                    </>
                )}
            </div>
        </>
    )
};

export default Details;