import { Collections, StoragePaths } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Form, Validators } from '@happybandit/react-validation';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { IInfoArticle } from '@shared/interfaces/lib/interfaces';
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/appSlice';
import { FirebaseContext } from '../../../firebase_init';
import RichEditor from '../../richEditor';
import TextValid from '../../TextValid';

interface IProps {
    onBack: () => void;
    onSave: ( name: string, id: string ) => void;
    articleId: string;
}

const blankSlateValue = [{ type: 'paragraph', children: [{ text: '' }] }];

const InfoArticle: React.FC<IProps> = ( { onBack, articleId, onSave } ) => {
    const [article, setArticle] = useState<IInfoArticle | undefined>( undefined );
    const [edit, setEdit] = useState( false );
    const [imageRefs, setImageRefs] = useState<firebase.storage.Reference[]>( [] );
    const user = useSelector( selectUser );
    const context = useContext( FirebaseContext );

    const getArticle = () => {
        setEdit( false );
        context.firestore.collection( Collections.infoArticles )
            .doc( articleId )
            .get()
            .then( ( doc ) => setArticle( { ...doc.data(), id: doc.id } as IInfoArticle ) );
    };

    useEffect( () => {
        if ( articleId === 'new' ) {
            setEdit( true );
            setArticle( {
                name: '',
                content: blankSlateValue,
                id: 'new',
            } );
        } else {
            getArticle();
        }
    }, [articleId] );

    const handleNewImage = ( file: File ) => {
        return context.storage.ref().child( StoragePaths.images + file.name ).put( file, {
            customMetadata: {
                saved: 'false',
            },
        } ).then( ( snapshot ) => {
            const ref = snapshot.ref;
            const newRefs = [...imageRefs];
            newRefs.push( ref );
            setImageRefs( newRefs );
            return ref.getDownloadURL();
        } )
    };

    const handleSave = ( valid: boolean ) => {
        if ( !valid || !article ) {
            return;
        }

        const collection = context.firestore.collection( Collections.infoArticles );

        if ( article.id === 'new' ) {
            article.id = collection.doc().id;
        }
        collection.doc( article.id ).set( article )
            .then( () => onSave( article.name, article.id ) )
            .then( () => {
            setEdit( false );
            imageRefs.forEach( ( ref ) => {
                ref.getMetadata()
                    .then( ( meta ) => {
                        meta.customMetadata.saved = process.env.NODE_ENV === 'development' ? 'false' : 'true';
                        return ref.updateMetadata( meta );
                    } );
            } );
        } );
    };

    return (
        <Box p={2}>
            <Form onSubmit={handleSave}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    {article ? edit ? (
                        <TextValid
                            onChange={( name ) => setArticle( { ...article, name } )}
                            id="add-category"
                            label="Title"
                            value={article.name}
                            validators={[Validators.required( article.name )]}
                        />
                    ) : article.name : 'Loading'}
                </Typography>
                {user && user.isAllowed(UserPermissions.generalEdit) && (edit ? (
                    <>
                        <IconButton
                            style={{ float: 'right' }}
                            color="secondary"
                            onClick={getArticle}
                        >
                            <Clear/>
                        </IconButton>
                        <IconButton
                            type="submit"
                            style={{ float: 'right' }}
                            color="primary"
                        >
                            <Check data-testid="Check"/>
                        </IconButton>
                    </>
                ) : (
                    <IconButton
                        style={{ float: 'right' }}
                        color="primary"
                        onClick={() => setEdit( true )}
                    >
                        <EditIcon/>
                    </IconButton>
                ))}
                <IconButton
                    style={{ float: 'right' }}
                    color="primary"
                    onClick={onBack}
                >
                    <ArrowForward data-testid="ArrowForward"/>
                </IconButton>
                {article ? (
                    <RichEditor
                        readOnly={!edit}
                        value={article.content || blankSlateValue}
                        setValue={( newNodes ) => setArticle( { ...article, content: newNodes } )}
                        onUploadImage={handleNewImage}
                    />
                ) : (
                    <CircularProgress size={24}/>
                )}
            </Form>
        </Box>
    );
};

export default InfoArticle;
