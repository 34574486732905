import { ListItemText, TableCell, TableRow } from '@material-ui/core';
import { ITransfer } from '@shared/interfaces/lib/interfaces';
import React from 'react';

interface IProps {
    transfer: ITransfer;
}

const TransferItem: React.FC<IProps> = ( { transfer } ) => {
    return (
        <TableRow>
            <TableCell>
                <ListItemText
                    primary={transfer.metrc.Name}
                    secondary={`Type: ${transfer.metrc.ShipmentTransactionType}`}
                />
            </TableCell>
            <TableCell>
                <ListItemText
                    primary={`Deliveries: ${transfer.metrcDelivery.length}`}
                    secondary={`Packages: ${transfer.metrcPackages.length}`}
                />
            </TableCell>
            <TableCell>
                <ListItemText
                    primary={`Items: ${transfer.metrcPackages.map(item => item.ProductName).join(', ')}`}
                />
            </TableCell>
        </TableRow>
    );
};

export default TransferItem;