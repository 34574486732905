import { ListItemText, TableCell, TableRow } from '@material-ui/core';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IHarvest } from '@shared/interfaces/lib/interfaces';
import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import { getHarvestStatus, useSelectedRoom } from '../../../utils';

interface IProps {
    facilityId: string;
    harvest: IHarvest;
}

const HarvestItem: React.FC<IProps> = ( { harvest, facilityId } ) => {
    const dispatch = useDispatch();
    const room = useSelectedRoom( facilityId, room => room.metrc.Id === harvest.metrc.DryingLocationId );

    return (
        <TableRow
            onClick={() => dispatch( setShowPlantDetails( {
                page: PlantDetailPages.harvestDetails,
                key: {
                    collection: MetrcFacilitySubCollections.metrcHarvests,
                    id: harvest.id,
                },
            } ) )}
            hover
        >
            <TableCell>
                <ListItemText
                    primary={harvest.metrc.Name}
                    secondary={`Stage: ${getHarvestStatus( harvest )}`}
                />
            </TableCell>
            <TableCell>
                <ListItemText
                    primary={`In Drying Room: ${room ? room.metrc.Name : 'Unknown'}`}
                    secondary={`Strain: ${harvest.metrc.SourceStrainNames}`}
                />
            </TableCell>
            <TableCell>
                <ListItemText
                    primary={`Number of Plants Harvested: ${harvest.metrc.PlantCount}`}
                    secondary={`Harvest Type: ${harvest.metrc.HarvestType === 'Product' ? 'Manicure' : 'Full Plant'}`}
                />
            </TableCell>
            <TableCell>
                <ListItemText
                    primary={`Started: ${DateTime.fromISO( harvest.metrc.HarvestStartDate ).toLocaleString( DateTime.DATETIME_FULL )}`}
                    secondary={`Finished: ${harvest.metrc.FinishedDate ? DateTime.fromISO( harvest.metrc.FinishedDate ).toLocaleString( DateTime.DATETIME_FULL ) : ''}`}
                />
            </TableCell>
            <TableCell>
                <ListItemText
                    primary={`Total Initial Wet Weight: ${harvest.metrc.TotalWetWeight}`}
                    secondary={`Remaining Weight: ${harvest.metrc.CurrentWeight}`}
                />
            </TableCell>
        </TableRow>
    );
};

export default HarvestItem;