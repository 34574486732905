import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseContext } from '../../../firebase_init';
import { setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import TextValid from '../../TextValid';

interface IProps {
}

const GetLabel: React.FC<IProps> = () => {
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [label, setLabel] = useState<string>( '' );
    const [invalid, setInvalid] = useState( false );

    const handleGet = async () => {
        setInvalid( false );

        let item = await firestore.collectionGroup( MetrcFacilitySubCollections.metrcPlants ).where( 'id', '==', label ).get()
        if ( !item.empty ) {
            dispatch( setShowPlantDetails( {
                page: PlantDetailPages.details,
                key: {
                    collection: MetrcFacilitySubCollections.metrcPlants,
                    id: item.docs[0].id,
                },
            } ) );
            return;
        }

        item = await firestore.collectionGroup( MetrcFacilitySubCollections.metrcPackages ).where( 'id', '==', label ).get()
        if ( !item.empty ) {
            dispatch( setShowPlantDetails( {
                page: PlantDetailPages.details,
                key: {
                    collection: MetrcFacilitySubCollections.metrcPackages,
                    id: item.docs[0].id,
                },
            } ) );
            return;
        }

        item = await firestore.collectionGroup( MetrcFacilitySubCollections.metrcPlantBatches ).where( 'metrc.Name', '==', label ).get()
        if ( !item.empty ) {
            dispatch( setShowPlantDetails( {
                page: PlantDetailPages.details,
                key: {
                    collection: MetrcFacilitySubCollections.metrcPlantBatches,
                    id: item.docs[0].id,
                },
            } ) );
            return;
        }

        item = await firestore.collectionGroup( MetrcFacilitySubCollections.metrcHarvests ).where( 'metrc.Name', '==', label ).get()
        if ( !item.empty ) {
            dispatch( setShowPlantDetails( {
                page: PlantDetailPages.harvestDetails,
                key: {
                    collection: MetrcFacilitySubCollections.metrcHarvests,
                    id: item.docs[0].id,
                },
            } ) );
            return;
        }
        setInvalid( true );
    }

    return (
        <>
            <DialogTitle>Enter Item Label or Name</DialogTitle>
            <DialogContent>
                <TextValid
                    onChange={setLabel}
                    value={label}
                    label="Label/Name"
                    fullWidth
                    id="plant-details-label"
                    validators={[]}
                    forcedErrorMessage={invalid ? 'Invalid Label/Name' : undefined}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch( setShowPlantDetails( undefined ) )} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleGet}>
                    Get Item
                </Button>
            </DialogActions>
        </>
    )
};

export default GetLabel;