import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Box } from '@material-ui/core';
import { selectExternalContacts, selectStrains } from '../../../redux/appSlice';
import { useSelector } from 'react-redux';
import { geneticsTemplate } from '../../../utils';
import InitialGenStrains from './generate';
import NewStrain from './newStrain';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Strains: React.FC<RouteComponentProps> = () => {
    const strains = useSelector( selectStrains );
    const externalContacts = useSelector( selectExternalContacts );
    const theme = useTheme();
    const isSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Strains
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {strains.length} strains
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
            </Box>
            {strains.length === 0 && (
                <InitialGenStrains/>
            )}
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Cross</TableCell>
                        <TableCell>THC %</TableCell>
                        <TableCell>CBD %</TableCell>
                        <TableCell>Breeder</TableCell>
                        {!isSmall && (
                            <TableCell>Notes</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {strains.map( strain => {
                        const contact = externalContacts.find( ( contact ) => contact.id === strain.breederId );

                        return (
                            <TableRow hover key={strain.id} onClick={() => navigate( '../strains/' + strain.id )}>
                                <TableCell>
                                    {strain.name}
                                </TableCell>
                                <TableCell>
                                    {geneticsTemplate(strain)}
                                </TableCell>
                                <TableCell>
                                    {strain.cross}
                                </TableCell>
                                <TableCell>
                                    {strain.thc ? strain.thc * 100 + '%' : ''}
                                </TableCell>
                                <TableCell>
                                    {strain.cbd ? strain.cbd * 100 + '%' : ''}
                                </TableCell>
                                <TableCell>
                                    {contact ? contact.name : ''}
                                </TableCell>
                                {!isSmall && (
                                    <TableCell>
                                        {strain.notes}
                                    </TableCell>
                                )}
                            </TableRow>
                        )
                    } )}
                </TableBody>
            </Table>
            <NewStrain/>
        </>
    );
};

export default Strains;
