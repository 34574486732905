import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import isUrl from 'is-url';
import imageExtensions from 'image-extensions'
import { Editable, withReact, Slate, useSlate, RenderLeafProps, useFocused, useSelected } from 'slate-react';
import { createEditor, Editor, Node, Transforms } from 'slate';
import { withHistory } from 'slate-history';

import Box from '@material-ui/core/Box';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import CodeIcon from '@material-ui/icons/Code';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ImageIcon from '@material-ui/icons/Image';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { ReactEditor } from 'slate-react/dist/plugin/react-editor';
import { RenderElementProps } from 'slate-react/dist/components/editable';

const HOTKEYS: Record<string, string> = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code'
};

interface IProps {
    value: Node[];
    readOnly?: boolean;
    setValue: ( newNodes: Node[] ) => void;
    onUploadImage: (file: File) => Promise<string>;
}

const RichEditor: React.FC<IProps> = ( { value, setValue, readOnly = false, onUploadImage } ) => {
    const renderElement = useCallback( props => <Element {...props} />, [] );
    const renderLeaf = useCallback( props => <Leaf {...props} />, [] );
    const editor = useMemo( () => withImages( withHistory( withReact( createEditor() ) ), onUploadImage ), [] );

    return (
        <Box p={1} m={2} border={1} borderColor="grey.500" borderRadius={4}>
            <Slate
                editor={editor}
                value={value}
                onChange={value => {
                    setValue( value );
                }}
            >
                {readOnly ? (
                    <Editable
                        readOnly
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                    />
                ) : (
                    <>
                        <Toolbar>
                            <MarkButton format="bold">
                                <FormatBoldIcon/>
                            </MarkButton>
                            <MarkButton format="italic">
                                <FormatItalicIcon/>
                            </MarkButton>
                            <MarkButton format="underline">
                                <FormatUnderlinedIcon/>
                            </MarkButton>
                            <MarkButton format="code">
                                <CodeIcon/>
                            </MarkButton>
                            <BlockButton format="heading-one">
                                <LooksOneIcon/>
                            </BlockButton>
                            <BlockButton format="heading-two">
                                <LooksTwoIcon/>
                            </BlockButton>
                            <BlockButton format="block-quote">
                                <FormatQuoteIcon/>
                            </BlockButton>
                            <BlockButton format="numbered-list">
                                <FormatListNumberedIcon/>
                            </BlockButton>
                            <BlockButton format="bulleted-list">
                                <FormatListBulletedIcon/>
                            </BlockButton>
                            <InsertImageButton/>
                        </Toolbar>
                        <Box pl={1}>
                            <Editable
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                                placeholder="Enter some rich text…"
                                spellCheck
                                autoFocus
                                onKeyDown={( event ) => {
                                    for ( const hotkey in HOTKEYS ) {
                                        if ( isHotkey( hotkey, event as any ) ) {
                                            event.preventDefault();
                                            const mark = HOTKEYS[hotkey];
                                            toggleMark( editor, mark );
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </>
                )}
            </Slate>
        </Box>
    );
};

export const Element: React.FC<RenderElementProps> = ( { attributes, children, element } ) => {
    switch ( element.type ) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'image':
            return <ImageElement attributes={attributes} element={element}>{children}</ImageElement>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

export const Leaf: React.FC<RenderLeafProps> = ( { attributes, children, leaf } ) => {
    if ( leaf.bold ) {
        children = <strong>{children}</strong>;
    }

    if ( leaf.code ) {
        children = <code>{children}</code>;
    }

    if ( leaf.italic ) {
        children = <em>{children}</em>;
    }

    if ( leaf.underline ) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

interface IButton {
    format: string;
}

const BlockButton: React.FC<IButton> = ( { format, children } ) => {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1}>
            <ToggleButton
                value={format}
                selected={isBlockActive( editor, format )}
                onMouseDown={event => {
                    event.preventDefault();
                    toggleBlock( editor, format );
                }}
                style={{ lineHeight: 1 }}
            >
                {children}
            </ToggleButton>
        </Box>
    );
};

const MarkButton: React.FC<IButton> = ( { format, children } ) => {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1}>
            <ToggleButton
                value={format}
                selected={isMarkActive( editor, format )}
                onMouseDown={event => {
                    event.preventDefault();
                    toggleMark( editor, format );
                }}
                style={{ lineHeight: 1 }}
            >
                {children}
            </ToggleButton>
        </Box>
    );
};

const InsertImageButton = () => {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1}>
            <Button
                onMouseDown={event => {
                    event.preventDefault();
                    const url = window.prompt( 'Enter the URL of the image:' );
                    if ( !url ) return;
                    insertImage( editor, url )
                }}
                style={{ lineHeight: 1 }}
            >
                <ImageIcon/>
            </Button>
        </Box>
    )
};

const Toolbar: React.FC = ( { children } ) => (
    <>
        <Box
            dir="row"
            display="flex"
            justifySelf="flex-start"
            alignItems="center"
            flexWrap="wrap"
        >
            {children}
        </Box>
        <Box pt={2}>
            <Divider variant="middle"/>
        </Box>
    </>
);

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isBlockActive = ( editor: ReactEditor, format: string ): boolean => {
    const [match] = Editor.nodes( editor, {
        match: n => n.type === format
    } );
    return !!match;
};

const isMarkActive = ( editor: ReactEditor, format: string ): boolean => {
    const marks = Editor.marks( editor );
    return marks ? marks[format] === true : false;
};

const toggleBlock = ( editor: ReactEditor, format: string ) => {
    const isActive = isBlockActive( editor, format );
    const isList = LIST_TYPES.includes( format );

    Transforms.unwrapNodes( editor, {
        match: n => LIST_TYPES.includes( n.type ),
        split: true
    } );

    Transforms.setNodes( editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format
    } );

    if ( !isActive && isList ) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes( editor, block );
    }
};

const toggleMark = ( editor: ReactEditor, format: string ) => {
    const isActive = isMarkActive( editor, format );

    if ( isActive ) {
        Editor.removeMark( editor, format );
    } else {
        Editor.addMark( editor, format, true );
    }
};

const withImages = ( editor: ReactEditor, onUploadImage: (file: File) => Promise<string> ) => {
    const { insertData, isVoid } = editor;

    editor.isVoid = ( element ) => {
        return element.type === 'image' ? true : isVoid( element )
    };

    editor.insertData = ( data ) => {
        const text = data.getData( 'text/plain' );
        const { files } = data;

        if ( files && files.length > 0 ) {
            for ( const file of files ) {
                const [mime] = file.type.split( '/' );

                if ( mime === 'image' ) {
                    onUploadImage(file)
                        .then((url) => insertImage( editor, url ));
                }
            }
        } else if ( isImageUrl( text ) ) {
            insertImage( editor, text )
        } else {
            insertData( data )
        }
    };

    return editor
};

const insertImage = ( editor: Editor, url: string | ArrayBuffer | null ) => {
    const text = { text: '' };
    const image = { type: 'image', url, children: [text] };
    Transforms.insertNodes( editor, image )
};

const isImageUrl = ( url?: string ) => {
    if ( !url ) return false;
    if ( !isUrl( url ) ) return false;
    const ext = new URL( url ).pathname.split( '.' ).pop();
    return imageExtensions.includes( ext || '' );
};

const ImageElement: React.FC<RenderElementProps> = ( { attributes, children, element } ) => {
    const selected = useSelected();
    const focused = useFocused();
    return (
        <div {...attributes}>
            <div contentEditable={false}>
                <img
                    src={element.url}
                    alt="basic"
                    style={{
                        display: 'block',
                        maxWidth: '100',
                        maxHeight: '20em',
                        boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                    }}
                />
            </div>
            {children}
        </div>
    )
};

export default RichEditor;
