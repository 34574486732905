import { DateTime } from 'luxon';
import React, { useContext, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { EventSourceFunc, EventClickArg } from '@fullcalendar/common/main'
import { IScheduleTask } from '@shared/interfaces/lib/scheduleInterfaces';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../components/Header';
import { FirebaseContext } from '../../firebase_init';
import { selectUsers, setShowTask } from '../../redux/appSlice';
import { useTaskName } from '../../utils';
import { useRoomStyles } from '../facility/room/shared';

const Schedule: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const listenerRef = useRef<() => void | undefined>();
    const theme = useTheme();
    const classes = useRoomStyles();
    const { firestore } = useContext( FirebaseContext );
    const users = useSelector( selectUsers );
    const getTaskName = useTaskName();

    const getTasks: EventSourceFunc = ( fetchInfo, successCallback ) => {
        if(listenerRef.current){
            listenerRef.current();
        }

        listenerRef.current = firestore.collection( Collections.scheduleTasks )
            .where( 'dueDate', '>=', DateTime.fromISO( fetchInfo.startStr ).toISODate() )
            .where( 'dueDate', '<=', DateTime.fromISO( fetchInfo.endStr ).toISODate() )
            .onSnapshot( ( snap ) => {
                const tasks = snap.docs.map( ( item ) => item.data() as IScheduleTask );
                successCallback( tasks.map( task => {
                    const user = users.find( user => user.uid === task.employeeId );
                    let color = theme.palette.primary.main;

                    if ( task.dateCompleted ) {
                        color = theme.palette.grey['400'];
                    } else if ( task.dateStarted ) {
                        color = theme.palette.success.main;
                    }

                    return {
                        id: task.id,
                        title: `${getTaskName( task )} - ${user ? user.displayName : 'Unassigned'}`,
                        start: task.dueDate,
                        backgroundColor: color,
                        borderColor: color,
                    };
                } ) );
            } );
    };

    const handleCLick = ( info: EventClickArg ) => {
        dispatch( setShowTask( {
            id: info.event.id,
            edit: false,
        } ) )
    };

    return (
        <>
            <section className={classes.section}>
                <SectionHeader items={[
                    {
                        text: 'Tasks Scheduled',
                    },
                    {
                        text: 'Add Task',
                        onClick: () => dispatch( setShowTask( { edit: true } ) ),
                    }
                ]}/>
                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>

                    </strong>
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridWeek"
                    events={getTasks}
                    eventClick={handleCLick}
                />
            </section>
        </>
    );
};

export default Schedule;
