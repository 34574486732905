import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectNotifications, selectRooms, selectUsers } from '../../redux/appSlice';
import { convertMessage, roomTemplate, userTemplate } from '../../utils';

const Notifications: React.FC<RouteComponentProps> = () => {
    const notifications = useSelector(selectNotifications);
    const users = useSelector(selectUsers);
    const rooms = useSelector(selectRooms);

    const allRooms = Object.values(rooms).reduce((acc, arr) => acc.concat(arr), []);

    return (
        <>
            <Box mb={2}>
                <Typography variant="h2">
                    Notifications
                </Typography>
            </Box>
            <Paper>
                <List>
                    {notifications.map( ( notification ) => {
                        const foundUser = users.find((item) => item.uid === notification.userId);
                        const foundRoom = allRooms.find((item) => item.id === notification.roomId);
                        const textUser = foundUser ? userTemplate(foundUser) : 'System';
                        const textRoom = foundRoom ? ' - ' + roomTemplate(foundRoom) : '';

                        const sub = textUser + textRoom + ' - ' + new Date( notification.timestamp ).toLocaleString();
                        return (
                            <ListItem
                                key={notification.id}
                            >
                                <ListItemText
                                    primary={convertMessage( notification.message )}
                                    secondary={convertMessage(sub)}
                                />
                            </ListItem>
                        );
                    } )}
                </List>
            </Paper>
        </>
    );
};

export default Notifications;
