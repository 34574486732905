import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { IRoom } from '@shared/interfaces/lib/interfaces';
import { IScheduleTask } from '@shared/interfaces/lib/scheduleInterfaces';
import SectionHeader , { SectionHeaderItems } from 'components/Header';
import { FirebaseContext } from 'firebase_init';
import { setShowTask } from 'redux/appSlice';
import { useRoomStyles } from '../shared';
import ScheduleCard from './card';

interface IProps {
    room: IRoom;
}

const RoomScheduling: React.FC<IProps> = ( { room } ) => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const classes = useRoomStyles();
    const [tasks, setTasks] = useState<IScheduleTask[]>( [] );

    useEffect( () => {
        firestore.collection( Collections.scheduleTasks )
            .where( 'roomIds', 'array-contains', room.id )
            .where( 'dateCompleted', '==', null )
            .onSnapshot( snap => {
                setTasks( snap.docs.map( doc => doc.data() as IScheduleTask ) );
            } );
    }, [] );

    const headerText: SectionHeaderItems[] = [
        {
            text: 'Scheduling',
        },
        {
            text: 'View All',
            link: '/schedule',
        },
        {
            text: 'Add Task',
            onClick: () => dispatch( setShowTask( { edit: true } ) ),
        },
        {
            text: `${tasks.length} Tasks Scheduled`,
        },
    ];

    return (
        <section id={room.metrc.Name + '-scheduling'} className={classes.section}>
            <SectionHeader items={headerText}/>
            <Grid container direction="row" spacing={1}>
                {tasks.map( ( task: IScheduleTask ) => (
                    <ScheduleCard
                        task={task}
                        key={task.id}
                        onClick={() => dispatch( setShowTask( { id: task.id, edit: false } ) )}
                    />
                ) )}
            </Grid>
        </section>
    );
};

export default RoomScheduling;
