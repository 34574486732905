import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { Form, Validators } from '@happybandit/react-validation';
import { Omit, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { IFacility, INotification } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import TextValid from 'components/TextValid';
import SelectValid from 'components/SelectValid';
import { selectUser, setError } from 'redux/appSlice';
import { FirebaseContext } from 'firebase_init';
import { addressTemplate, diff, statesList, useNotification } from 'utils';

interface IProps {
    facility: IFacility;
}

const getDiff = ( prevProps: Partial<IFacility>, newProps: Partial<IFacility> ): string => {
    return diff( prevProps, newProps ).map( ( change ) => {
        let before: string | undefined;
        let after: string | undefined;
        let key = '';
        switch ( change.key ) {
            case 'address':
                key = 'Address';
                before = prevProps.address ? addressTemplate( prevProps.address ) : 'No Address';
                after = newProps.address ? addressTemplate( newProps.address ) : 'No Address';
                break;
        }
        if ( before && after && before !== after ) {
            return `${key}: ${before} => ${after}`;
        }
        return undefined;
    } ).filter( Boolean ).join( ', ' );
};

const EditFacility: React.FC<IProps> = ( { facility } ) => {
    const context = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [street1, setStreet1] = useState( facility.address?.street1 || '' );
    const [street2, setStreet2] = useState( facility.address?.street2 || '' );
    const [city, setCity] = useState( facility.address?.city || '' );
    const [state, setState] = useState( facility.address?.state || '' );
    const [zipCode, setZipCode] = useState( facility.address?.zipCode || '' );
    const user = useSelector( selectUser );
    const sendNotification = useNotification();

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user ) {
            return;
        }

        const collection = context.firestore.collection( Collections.metrcFacilities );

        const data: Partial<IFacility> = {
            address: {
                street1,
                street2,
                city,
                zipCode,
                state,
            },
        };

        collection.doc( facility.id )
            .update( data )
            .then( () => {
                let notification: Omit<INotification, 'id'> | null = null;
                const changed = getDiff( facility, data );
                if ( !!changed ) {
                    notification = {
                        timestamp: Date.now(),
                        seen: false,
                        userId: user.uid,
                        message: `Updated Facility (${facility.metrc.DisplayName}) - ${changed}`,
                    };
                }

                if ( notification ) {
                    sendNotification( notification, ( userItem ) => userItem.permissions.includes( UserPermissions.userAdmin ) );
                }
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
    };

    return (
        <>
            <Typography variant="body1">
                You can only change the Address in this system, and must go to Metrc for any others.
            </Typography>
            <Form id="new-facility-form" onSubmit={handleSubmit}>
                <TextValid
                    id="facility-new-street1"
                    label="Address Line 1"
                    value={street1}
                    onChange={( value ) => setStreet1( value )}
                    fullWidth
                    validators={[Validators.required( street1 )]}
                />
                <TextValid
                    id="facility-new-street2"
                    label="Address Line 2"
                    value={street2}
                    onChange={( value ) => setStreet2( value )}
                    fullWidth
                    validators={[]}
                />
                <TextValid
                    id="facility-new-city"
                    label="City"
                    value={city}
                    onChange={( value ) => setCity( value )}
                    fullWidth
                    validators={[Validators.required( city )]}
                />
                <SelectValid
                    id="facility-new-state"
                    label="State"
                    value={state}
                    onChange={( value ) => setState( value )}
                    fullWidth
                    validators={[Validators.required( state )]}
                >
                    <MenuItem value=""/>
                    {statesList.map( ( stateItem ) => (
                        <MenuItem
                            key={stateItem.abbreviation}
                            value={stateItem.abbreviation}
                        >
                            {stateItem.name}
                        </MenuItem>
                    ) )}
                </SelectValid>
                <TextValid
                    id="facility-new-zipCode"
                    label="Zip Code"
                    value={zipCode}
                    onChange={( value ) => setZipCode( value )}
                    fullWidth
                    validators={[Validators.required( zipCode )]}
                />
                <Button type="submit" color="primary">
                    Update Address
                </Button>
            </Form>
        </>
    );
};

export default EditFacility;