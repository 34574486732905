import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { IInfoCategory } from '@shared/interfaces/lib/interfaces';
import { FirebaseContext } from '../../firebase_init';
import { unassignedId } from '../../dataHooks';
import { selectInfoCategories, selectShowArticle, setShowArticle } from '../../redux/appSlice';
import InfoCategories from './infoCategories';
import InfoArticle from './infoArticle';

const InfoDrawer: React.FC = () => {
    const dispatch = useDispatch();
    const context = useContext( FirebaseContext );
    const showArticle = useSelector( selectShowArticle );
    const infoCategories = useSelector( selectInfoCategories );

    const updateCategories = ( newCategories: IInfoCategory[] ) => {
        const batch = context.firestore.batch();
        const collection = context.firestore.collection( Collections.infoCategories );
        newCategories.forEach( ( cat ) => {
            batch.set( collection.doc( cat.id ), cat );
        } );
        batch.commit();
    };

    const handleArticleSave = ( name: string, id: string ) => {
        let newCategories = infoCategories.map( ( cat ) => ({
            ...cat,
            articles: cat.articles.map( ( art ) => ({
                ...art
            }) )
        }) );
        let found = false;
        newCategories.forEach( ( category ) => {
            const article = category.articles.find( ( article ) => article.id === id );
            if ( article ) {
                article.name = name;
                found = true;
            }
        } );

        if ( !found ) {
            const unassigned = newCategories.find( ( category ) => category.id === unassignedId );
            if ( unassigned ) {

                unassigned.articles.push( { id, name } )
            }
        }
        updateCategories( newCategories );
    };

    const handleClose = () => {
        dispatch( setShowArticle( { show: false } ) );
    };

    return (
        <Drawer
            anchor="right"
            open={showArticle?.show}
            onClose={handleClose}
        >
            {showArticle?.show && (
                <>
                    {showArticle.articleId ? (
                        <InfoArticle
                            articleId={showArticle.articleId}
                            onBack={() => dispatch( setShowArticle( { show: true } ) )}
                            onSave={handleArticleSave}
                        />
                    ) : (
                        <InfoCategories
                            onSelected={(articleId) => dispatch( setShowArticle( { show: true, articleId } ) )}
                            onBack={handleClose}
                            onCategoryChange={updateCategories}
                        />
                    )}
                </>
            )}
        </Drawer>
    );
};

export default InfoDrawer;
