import { Form, Validators } from '@happybandit/react-validation';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuItem from '@material-ui/core/MenuItem';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IMetrcStrain } from '@shared/interfaces/lib/metrcInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectValid from '../../../../../components/SelectValid';
import { FirebaseContext } from '../../../../../firebase_init';
import { selectFacilities } from '../../../../../redux/appSlice';

export interface ISelectStrain {
    license: string;
    strain: IMetrcStrain;
}

interface IProps {
    onClose: () => void;
    onSelect: ( selection?: ISelectStrain ) => void;
}

const SelectSource: React.FC<IProps> = ( { onClose, onSelect } ) => {
    const { firestore } = useContext( FirebaseContext );
    const facilities = useSelector(selectFacilities);
    const [licenseId, setLicenseId] = useState( '' );
    const [strains, setStrains] = useState<IMetrcStrain[]>( [] );
    const [strainId, setStrainId] = useState( '' );

    useEffect(() => {
        setStrains([]);
        setStrainId('');
        if(licenseId) {
            firestore.collection(Collections.metrcFacilities).doc(licenseId)
                .collection(MetrcFacilitySubCollections.metrcStrains)
                .get()
                .then((snap) => {
                    setStrains(snap.docs.map((doc) => doc.data() as IMetrcStrain));
                });
        }
    }, [licenseId]);

    const handleSubmit = (valid: boolean) => {
        if(!valid) {
            return;
        }

        const strain = strains.find((strain) => strain.Id.toString() === strainId);
        if(strain){
            onSelect( {
                strain,
                license: licenseId,
            });
        }
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Create a new Cultivatd strain from a Metrc strain
                </DialogContentText>
                <Form onSubmit={handleSubmit}>
                    <SelectValid
                        id="new-strain-select-facility"
                        label="Facility"
                        value={licenseId}
                        onChange={( value ) => setLicenseId( value )}
                        fullWidth
                        validators={[Validators.required( licenseId )]}
                    >
                        <MenuItem value=""/>
                        {facilities.map( ( facilityItem ) => (
                            <MenuItem
                                key={facilityItem.id}
                                value={facilityItem.id}
                            >
                                {facilityItem.metrc.DisplayName}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <SelectValid
                        id="new-strain-select-strain"
                        value={strainId}
                        label="Metrc Strain"
                        fullWidth
                        validators={[Validators.required( strainId )]}
                        onChange={( value ) => setStrainId( value )}
                    >
                        <MenuItem value=""/>
                        {strains.map( ( strain ) => (
                            <MenuItem
                                key={strain.Id}
                                value={strain.Id.toString()}
                            >
                                {strain.Name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        Create From Strain
                    </Button>
                </Form>
                <Divider/>
                <Button onClick={() => onSelect()} color="primary">
                    Create New
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default SelectSource;