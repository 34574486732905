import React, { useState } from 'react';
import { DateTime } from 'luxon';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Form, Validators } from '@happybandit/react-validation';
import { ISystem, IWater } from '@shared/interfaces/lib/interfaces';
import { getIcon } from 'utils';
import DateValid from 'components/DateValid';
import TextValid from 'components/TextValid';
import CheckValid from 'components/CheckValid';
import SaveButton from 'components/saveButton';
import WaterInfo from './info';

interface IProps {
    edit: boolean;
    roomId: string;
    system: ISystem;
    onSystemChange: ( newSystem: ISystem ) => Promise<void>;
}

enum ALERT_SEVERITY {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
}

interface IWaterFilledProps {
    system: ISystem;
    roomId: string;
    onWaterChange: ( newWater: IWater ) => Promise<void>;
}

const WaterFilled: React.FC<IWaterFilledProps> = ( { onWaterChange, system, roomId } ) => {
    const now = DateTime.local();
    const water = system.water;
    const lastWatered = water.lastFilledDateTime && DateTime.fromISO( water.lastFilledDateTime );
    const daysSinceFilled = lastWatered && now.diff( lastWatered, 'days' );
    const daysText = daysSinceFilled ? daysSinceFilled.toFormat( 'd' ) : 'Unknown';
    
    const getInfoSeverity = () => {
        const days = parseFloat( daysText );

        if( ( days <= 10 )) {
            return ALERT_SEVERITY.SUCCESS;
        } else if ( days >= 10 && days <= 14  ) {
            return ALERT_SEVERITY.WARNING;
        } else if ( days >= 14 ) {
            return ALERT_SEVERITY.ERROR;
        }
    };

    return (
        <>
            { !water.changeOrFlushInProgress &&
                <>
                    <Alert severity={ getInfoSeverity() }>
                        <AlertTitle>
                            Water Changed
                        </AlertTitle>
                        { getInfoSeverity() === ALERT_SEVERITY.ERROR && (
                            <>
                                Its been <strong>{ daysText }</strong> days since the water was changed last, this is a reminder to change the water in the next <strong>{ 14 - parseFloat( daysText ) }</strong> days.
                                <br /><br />
                            </>
                        )}
                        { getInfoSeverity() === ALERT_SEVERITY.WARNING && (
                            <>
                                Its been <strong>{ daysText }</strong> days since the water was changed last, the water needs to be changed immediately!.
                                <br /><br />
                            </>
                        )}
                        { water.waterChangeCount === 2 &&
                            <>
                                You have changed the water <strong>2 Times</strong> without Flushing, you must Flush the system on the next change.
                                <br /><br />
                            </>
                        }
                        The water for this system was changed <strong>{ daysText }</strong> day(s) ago.
                    </Alert>
                    { water.waterChangeCount !== 2 ? (
                        <Button
                            color="primary"
                            style={{ width: '100%' }}
                            endIcon={ getIcon( 'WaterChange', '30', '30' )}
                            onClick={() => onWaterChange( {
                                isFilled: true,
                                changeOrFlushInProgress: true,
                                waterChangeCount: water.waterChangeCount ? water.waterChangeCount + 1 : 1,
                            } )}
                        >
                            Change Water
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            style={{ width: '100%' }}
                            endIcon={ getIcon( 'WaterChange', '30', '30' )}
                            onClick={() => onWaterChange( {
                                isFilled: true,
                                waterChangeCount: 0,
                                changeOrFlushInProgress: true,
                            } )}
                        >
                            Flush System
                        </Button>
                    )}
                </>
            }

            { water.changeOrFlushInProgress && 
                <>
                    <Alert severity={ ALERT_SEVERITY.WARNING }>
                        <AlertTitle>
                            Water Change / Flush in Progress
                        </AlertTitle>
                        You are currently Changing or Flushing the water for this system and you must enter the PH and Nute doses before setting as complete.
                    </Alert>
                    <WaterInfo
                        roomId={roomId}
                        systemId={system.id}
                        water={ water }
                        onComplete={ onWaterChange }
                    />
                </>
            }
        </>
    );
};

interface IWaterEmptyProps {
    onWaterChange: ( newWater: IWater ) => Promise<void>;
}

const WaterEmpty: React.FC<IWaterEmptyProps> = ( { onWaterChange } ) => {
    return (
        <>
            <Alert severity="warning">
                <AlertTitle>
                    Water Not Filled
                </AlertTitle>
                Make sure to fill the water tank before continuing anything with this
                system.
            </Alert>
            <Button
                color="primary"
                style={{ width: '100%' }}
                endIcon={ getIcon( 'WaterChange', '40', '40' )}
                onClick={() => onWaterChange( {
                    isFilled: true,
                    lastFilledDateTime: (new Date()).toISOString()
                } )}
            >
                Set Water Filled
            </Button>
        </>
    );
};

interface IWaterEditProps {
    system: ISystem;
    onWaterChange: ( newWater: IWater ) => Promise<void>;
}

const WaterEdit: React.FC<IWaterEditProps> = ( { system, onWaterChange } ) => {
    const water = system.water;
    const [ lastFilledDateTime, setLastFilledDateTime ] = useState( water.lastFilledDateTime ? new Date( water.lastFilledDateTime ) : null );
    const [ gallonCapacity, setGallonCapacity ] = useState( water.gallonCapacity );
    const [ isFilled, setIsFilled ] = useState( water.isFilled );
    const [loading, setLoading] = useState( false );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        setLoading( true );

        onWaterChange( {
            isFilled,
            gallonCapacity,
            waterChangeCount: water.waterChangeCount || 0,
            lastFilledDateTime: lastFilledDateTime ? lastFilledDateTime.toISOString() : undefined,
        } ).finally( () => setLoading( false ) );
    };

    const gallons = gallonCapacity ? gallonCapacity.toString() : '';

    return (
        <Form id={`water-${system.id}`} onSubmit={handleSubmit}>
            <TextValid
                id={`water-${system.id}-gallonCapacity`}
                label="Number of Gallons in Tank"
                type="number"
                value={gallons}
                fullWidth
                onChange={( changed ) => setGallonCapacity( parseInt( changed ) || undefined )}
                validators={[Validators.required( gallons ), Validators.positive( gallons )]}
            />
            <DateValid
                id={`water-${system.id}-lastFilled`}
                label="Last Filled Date"
                fullWidth
                clearable
                value={lastFilledDateTime}
                onChange={setLastFilledDateTime}
                validators={[]}
            />
            <CheckValid
                id={`water-${system.id}-isFilled`}
                value={isFilled}
                label="Is Currently Filled"
                fullWidth
                onChange={setIsFilled}
                validators={[]}
            />
            <SaveButton loading={loading}/>
        </Form>
    );
};

const Water: React.FC<IProps> = ( { roomId, system, onSystemChange, edit = false } ) => {
    const handleWaterChange = ( newWater: IWater ) => {
        const newSystem: ISystem = {
            ...system,
            water: {
                ...system.water,
                ...newWater,
            },
        };

        return onSystemChange( newSystem );
    };

    return (
        <Paper elevation={ 0 } variant="outlined" square>
            {edit ? (
                <Box p={2}>
                <WaterEdit
                    system={system}
                    onWaterChange={handleWaterChange}
                />
                </Box>
            ) : (
                <>
                    <Box p={2}>
                        <Typography variant="h6" style={{ display: 'inline' }}>
                            <strong>
                                Tank Size
                            </strong>
                        </Typography>
                        <strong>
                            <span style={{ 'color': 'black' }} >&nbsp;/</span> { system.water.gallonCapacity || '?' } Gallons
                        </strong>
                         
                    </Box>
                    {system.water.isFilled ? (
                        <WaterFilled
                            roomId={roomId}
                            system={system}
                            onWaterChange={handleWaterChange}
                        />
                    ) : (
                        <WaterEmpty
                            onWaterChange={handleWaterChange}
                        />
                    )}
                </>
            )}
        </Paper>
    );
};

export default Water;
