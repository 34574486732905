import { InputAdornment } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton } from '@material-ui/lab';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { IHarvestPostBody } from '@shared/interfaces/lib/contractInterfaces';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { HarvestType, IPlant, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postHarvest } from '../../../api';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import { useFacilityRooms } from '../../../utils';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';

interface IProps {
    plant: IPlant;
    dryRoomId?: string;
    harvestType?: HarvestType;
    facilityId: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            minHeight: 100,
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);


const nameTemplate = (date: DateTime, strain: string, type?: HarvestType, location?: string ) => `${date.toISODate()}|${location || 'Set Dry Room'}|${strain}|${type || 'Set Type'}`;

const PlantHarvest: React.FC<IProps> = ( { plant, dryRoomId = '', harvestType, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector(selectUser);
    const rooms = useFacilityRooms(facilityId);
    const [isDefined, setIsDefined] = useState( false );
    const [definedName, setDefinedName] = useState( '' );
    const [type, setType] = useState<HarvestType | undefined>( harvestType );
    const [roomId, setRoomId] = useState( dryRoomId );
    const [weight, setWeight] = useState( '' );
    const date = useMemo(() => DateTime.local(), []);

    if ( !plant ) {
        return null;
    }

    const dryRooms = rooms.filter((room) => (room.types || []).includes(RoomTypes.dry));

    const selectedRoom = dryRooms.find(item => item.id === roomId);

    const generatedName = nameTemplate(date, plant.metrc.StrainName, type, selectedRoom ? selectedRoom.metrc.Name : undefined);

    const handleClickEdit = () => {
        if ( isDefined ) {
            setDefinedName( '' );
        } else {
            setDefinedName( generatedName );
        }
        setIsDefined( !isDefined );
    };

    const handleSubmit = () => {
        if(!type || !selectedRoom || !user){
            return;
        }

        const body: IHarvestPostBody = {
            type,
            locationId: selectedRoom.metrc.Id,
            facilityId,
            weight: parseFloat(weight),
            name: isDefined ? definedName : generatedName,
            date: date.toISODate(),
            label: plant.id,
        }
        postHarvest(user.token, body)
            .then(() => {
                dispatch( setShowPlantDetails( {
                    key: {
                        collection: MetrcFacilitySubCollections.metrcPlants,
                        id: plant.id,
                    },
                    page: PlantDetailPages.getHarvest,
                    other: {
                        dryRoomId: roomId,
                        type,
                    }
                } ) )
            });
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Harvest Plant
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {plant.metrc.HarvestedDate ? (
                    <Typography variant="h5">Already Harvested</Typography>
                ) : (
                    <>
                        <Typography variant="h5">Harvest name must include date, strain, drying room, and type.</Typography>
                        <TextValid
                            value={isDefined ? definedName : generatedName}
                            onChange={setDefinedName}
                            id="harvest-name"
                            label="Harvest Name"
                            disabled={!isDefined}
                            validators={[]}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            aria-label="toggle password visibility"
                                            onClick={handleClickEdit}
                                            onMouseDown={event => event.preventDefault()}
                                        >
                                            {isDefined ? 'Reset' : 'Edit'}
                                        </Button>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <hr style={{ 'borderTop': '#eee' }} />
                        <FormControlLabel
                            control={
                                <ToggleButtonGroup
                                    value={type}
                                    onChange={(event, value) => setType(value)}
                                    exclusive
                                >
                                    <ToggleButton value="Manicure">Manicure</ToggleButton>
                                    <ToggleButton value="FullPlant">Full Plant</ToggleButton>
                                </ToggleButtonGroup>
                            }
                            label="Harvest Type"
                            labelPlacement="top"
                        />
                        <SelectValid
                            id="harvest-location"
                            value={roomId}
                            label="Drying Room to send to"
                            fullWidth
                            validators={[]}
                            onChange={setRoomId}
                        >
                            <MenuItem value={''}/>
                            {dryRooms.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.metrc.Name}</MenuItem>
                            ))}
                        </SelectValid>
                        <TextValid
                            type="number"
                            value={weight}
                            onChange={setWeight}
                            id="harvest-weight"
                            label="Wet Weight of Harvested Material in Grams"
                            validators={[]}
                            fullWidth
                        />
                        <hr style={{ 'borderTop': '#eee' }} />
                        <Button
                            disabled={!roomId || parseFloat(weight) <= 0 || !type || (isDefined && !definedName) }
                            onClick={handleSubmit}
                        >
                            Submit Harvest
                        </Button>
                    </>
                )}
            </div>
        </>
    )
};

export default PlantHarvest;