import { Form, Validators } from '@happybandit/react-validation';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IPackageAdjustBody } from '@shared/interfaces/lib/contractInterfaces';
import { Collections, DirectPaths, MetrcFacilitySubCollections, MetrcFacilityConstantDoc } from '@shared/interfaces/lib/firebaseConstants';
import { IPackage, IRecord } from '@shared/interfaces/lib/interfaces';
import {
    IPackageAdjustReasons,
    IUnitsOfMeasure
} from '@shared/interfaces/lib/metrcInterfaces';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postAdjustPackage, ResponseError } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';

interface IProps {
    packageItem: IPackage;
    facilityId: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            minHeight: 100,
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);

const AdjustPackage: React.FC<IProps> = ( { packageItem, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { firestore } = useContext( FirebaseContext );
    const user = useSelector( selectUser );
    const [reasons, setReasons] = useState<IPackageAdjustReasons[]>( [] );
    const [units, setUnits] = useState<IUnitsOfMeasure[]>( [] );
    const [reason, setReason] = useState( '' );
    const [note, setNote] = useState( '' );
    const [unit, setUnit] = useState( packageItem.metrc.UnitOfMeasureName );
    const [quantity, setQuantity] = useState( '' );
    const [loading, setLoading] = useState( false );
    const [errors, setErrors] = useState<string[]>( [] );

    useEffect( () => {
        if ( !packageItem ) {
            return;
        }
        firestore.doc( DirectPaths.metrcUnitsOfMeasure )
            .get()
            .then( docs => {
                const data = docs.data() as IRecord<IUnitsOfMeasure>;
                if ( data && data.data ) {
                    setUnits( data.data );

                }
            } );
        firestore.collection( Collections.metrcFacilities ).doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcConstants ).doc( MetrcFacilityConstantDoc.metrcPackageAdjustReasons )
            .get()
            .then( docs => {
                const data = docs.data() as IRecord<IPackageAdjustReasons>;
                if ( data && data.data ) {
                    setReasons( data.data );

                    if(data.data.length === 1){
                        setReason(data.data[0].Name);
                    }
                }
            } );
    }, [] );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user ) {
            return;
        }
        setLoading( true );
        setErrors( [] );

        const body: IPackageAdjustBody = {
            facilityId,
            label: packageItem.metrc.Label,
            quantity: parseFloat(quantity),
            unitOfMeasure: unit,
            reason,
            date: DateTime.local().toISODate(),
            note,
        }
        postAdjustPackage( user.token, body )
            .then( () => {
                dispatch( setShowPlantDetails( {
                    key: {
                        collection: MetrcFacilitySubCollections.metrcPackages,
                        id: packageItem.id,
                    },
                    page: PlantDetailPages.details,
                } ) );
            } )
            .catch( async ( e: ResponseError ) => {
                if ( e.response.status === 400 ) {
                    const body: { errors: string[] } = await e.response.json();
                    setErrors( body.errors );
                }
            } )
            .finally( () => {
                setLoading( false );
            } );
    };

    const selectedReason = reasons.find(item => item.Name === reason);
    const unitGroup = units.find(item => item.Name === packageItem.metrc.UnitOfMeasureName);

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Adjust Package Quantity
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        {errors.length > 0 && (
                            <Alert variant="filled" severity="error">
                                <AlertTitle>Change Failed</AlertTitle>
                                {errors.map( ( error ) => (
                                    <div>{error}</div>
                                ) )}
                            </Alert>
                        )}
                        <Typography variant="h5">
                            Must give reason for why the package quantity is being adjusted.
                        </Typography>
                        <Typography variant="h6">
                            Current Quantity: {packageItem.metrc.Quantity}{packageItem.metrc.UnitOfMeasureAbbreviation}
                        </Typography>
                        <Form onSubmit={handleSubmit}>
                            <SelectValid
                                id="package-reason"
                                value={reason}
                                label="Reason for Adjustment"
                                fullWidth
                                validators={[
                                    Validators.required(reason),
                                ]}
                                onChange={setReason}
                            >
                                <MenuItem value={''}/>
                                {reasons.map( ( item ) => (
                                    <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                                ) )}
                            </SelectValid>
                            <TextValid
                                type="number"
                                value={quantity}
                                onChange={setQuantity}
                                id="package-quantity"
                                label="Quantity of Adjustment"
                                validators={[
                                    Validators.required(quantity),
                                ]}
                                fullWidth
                            />
                            <SelectValid
                                id="package-unit"
                                value={unit}
                                label="Unit of Measure"
                                fullWidth
                                validators={[
                                    Validators.required(unit),
                                ]}
                                onChange={setUnit}
                            >
                                <MenuItem value={''}/>
                                {units.filter( unit => unitGroup && unit.QuantityType === unitGroup.QuantityType ).map( ( item ) => (
                                    <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                                ) )}
                            </SelectValid>
                            <TextValid
                                value={note}
                                onChange={setNote}
                                id="package-note"
                                label="Reason Notes"
                                validators={[
                                    Validators.custom(() => {
                                        if(selectedReason && selectedReason.RequiresNote) {
                                            return note.trim().length > 0;
                                        }
                                        return true;
                                    } )
                                ]}
                                fullWidth
                                multiline
                            />
                            <hr style={{ 'borderTop': '#eee' }}/>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Submit Change
                            </Button>
                        </Form>
                    </>
                )}
            </div>
        </>
    )
};

export default AdjustPackage;