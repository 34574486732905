import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { IInfoArticleHeader } from '@shared/interfaces/lib/interfaces';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
    item: IInfoArticleHeader;
    index: number;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        handle: {
            width: '12px',
            height: '12px',
            color: 'grey',
            display: 'inline-block',
            marginRight: '10px',
        },
        nested: {
            paddingLeft: theme.spacing( 4 ),
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            position: 'relative',
            textDecoration: 'none',
            width: '100%',
            boxSizing: 'border-box',
            textAlign: 'left',
            paddingTop: 8,
            paddingBottom: 8,
        },
    } ),
);

const DragItem: React.FC<IProps> = ( { item, index } ) => {
    const classes = useStyles({});
    return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
            {( provided: DraggableProvided, snapshot: DraggableStateSnapshot ) => (
                <div
                    ref={provided.innerRef}
                    className={classes.nested}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        ...provided.draggableProps.style,
                        opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                >
                    <span className={classes.handle}>
                        <svg viewBox="0 0 320 512">
                            <path xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                  d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"/>
                        </svg>
                    </span>
                    {item.name}
                </div>
            )}
        </Draggable>
    );
};

export default DragItem;
