import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState } from 'react';
import { AddButton } from '../../../../components/addButton';
import CreateStrain from './create';
import SelectSource, { ISelectStrain } from './select';

const NewStrain: React.FC = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [open, setOpen] = useState( false );
    const [create, setCreate] = useState( false );
    const [selection, setSelection] = useState<ISelectStrain | undefined>( undefined );

    const handleClose = () => {
        setOpen( false );
        setCreate(false);
        setSelection(undefined);
    };

    const handleSelect = (copyStrain?: ISelectStrain) => {
        setCreate(true);
        setSelection(copyStrain);
    };

    return (
        <>
            <AddButton onClick={() => setOpen( true )}/>

            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create New Strain</DialogTitle>
                {create ? (
                    <CreateStrain
                        selection={selection}
                        onBack={() => setCreate(false)}
                        onClose={handleClose}
                    />
                ) : (
                  <SelectSource
                      onClose={handleClose}
                      onSelect={handleSelect}
                  />
                )}
            </Dialog>
        </>
    );
};

export default NewStrain;