import firebase from 'firebase/app';
import React from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import {
    MetrcLocationSubCollections,
    Collections,
    MetrcFacilitySubCollections,
} from '@shared/interfaces/lib/firebaseConstants';
import { IHistory } from '@shared/interfaces/lib/interfaces';

export const getFirebase = () => {
    return fetch( '/__/firebase/init.json' ).then( async response => {
        return new Firebase( await response.json() );
    } );
};

export class Firebase {
    auth: app.auth.Auth;
    firestore: app.firestore.Firestore;
    storage: app.storage.Storage;

    constructor( config: any ) {
        if ( !app.apps.length ) {
            app.initializeApp( config );
        }
        this.auth = app.auth();
        this.firestore = app.firestore();
        this.storage = app.storage();

        this.auth.useDeviceLanguage();

        if ( process.env.NODE_ENV === 'development' ) {
            this.firestore.settings( { host: 'localhost:8080', ssl: false } );
        }
    }

    doCreateUserWithEmailAndPassword = ( email: string, password: string ) =>
        this.auth.createUserWithEmailAndPassword( email, password );
    doSignInWithEmailAndPassword = ( email: string, password: string ) =>
        this.auth.signInWithEmailAndPassword( email, password );
    doSignOut = () => this.auth.signOut();
    doPasswordReset = ( email: string ) => this.auth.sendPasswordResetEmail( email );
    doPasswordUpdate = ( password: string ) =>
        this.auth.currentUser && this.auth.currentUser.updatePassword( password );
}

export const FirebaseContext = React.createContext<Firebase>( undefined as any );

export const addHistory = ( firestore: firebase.firestore.Firestore, facilityId: string, roomId: string, history: IHistory ) => {
    return firestore
        .collection( Collections.metrcFacilities )
        .doc( facilityId )
        .collection( MetrcFacilitySubCollections.metrcLocations )
        .doc( roomId )
        .collection( MetrcLocationSubCollections.history )
        .add( history )
};
