import { Card, CardActionArea, CardContent, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPackage, IRoom } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseContext } from 'firebase_init';
import { setShowPlantDetails } from 'redux/appSlice';
import { PlantDetailPages } from 'redux/interfaces';
import FacilityContext from '../../../facilityContext';

interface IProps {
    room: IRoom;
}

enum FilterType {
    strain,
    productCategory,
}

const getFilteredPackages = ( packages: IPackage[], filter?: FilterType ) => {
    return packages
        .sort( ( a, b ) => a.metrc.PackagedDate.localeCompare( b.metrc.PackagedDate ) )
        .reduce<Record<string, IPackage[]>>( ( acc, item ) => {
            if ( filter === FilterType.strain ) {
                acc[item.metrc.ItemStrainName || 'multi-strain'] = [...(acc[item.metrc.ItemStrainName || 'multi-strain'] || []), item];
            } else {
                acc[item.metrc.ProductCategoryName] = [...(acc[item.metrc.ProductCategoryName] || []), item];
            }
            return acc;
        }, {} );
}

interface IItemProps {
    item: IPackage;
}

const PackageItem: React.FC<IItemProps> = ( { item } ) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    return (
        <Grid item xs={12} md={4}>
            <Card variant="outlined" style={{ borderColor: item.metrc.Quantity < 1 ? theme.palette.warning.main : undefined }}>
                <CardActionArea
                    onClick={() => dispatch( setShowPlantDetails( {
                        page: PlantDetailPages.details,
                        key: {
                            id: item.id,
                            collection: MetrcFacilitySubCollections.metrcPackages,
                        },
                    } ) )}
                >
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h4">
                            {item.metrc.Label}
                        </Typography>
                        <Typography color="textSecondary">
                            {item.metrc.ProductName}
                        </Typography>
                        <Typography color="textSecondary">
                            {item.metrc.ItemStrainName || 'Multi Strain'} - {item.metrc.ProductCategoryName}
                        </Typography>
                        <Typography color="textSecondary">
                            Current Quantity: {item.metrc.Quantity} {item.metrc.UnitOfMeasureName}
                        </Typography>
                        {item.metrc.Note && (
                            <Typography variant="caption" color="textSecondary">
                                {item.metrc.Note}
                            </Typography>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

interface ISectionProps {
    title: string,
    packages: IPackage[];
}

const PackageSection: React.FC<ISectionProps> = ( { title, packages } ) => (
    <>
        <Typography variant="h4">
            {title}
        </Typography>
        <Divider style={{ marginBottom: 10 }}/>
        <Grid container spacing={3} style={{ marginBottom: 10 }}>
            {packages.map( ( item ) => <PackageItem
                key={item.id}
                item={item}
            /> )}
        </Grid>
    </>
);

const PackageSystem: React.FC<IProps> = ( { room } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const [packages, setPackages] = useState<IPackage[]>( [] );

    useEffect( () => {
        firestore.collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcPackages )
            .where( 'metrc.LocationId', '==', room.metrc.Id )
            .where( 'metrc.FinishedDate', '==', null )
            .onSnapshot( snapshot => {
                setPackages( snapshot.docs.map( doc => doc.data() as IPackage ) );
            } );
    }, [] );

    const filteredPackages = getFilteredPackages( packages );

    return (
        <>
            {Object.entries( filteredPackages ).map( ( [key, value] ) => (
                <PackageSection
                    key={key}
                    title={key}
                    packages={value}
                />
            ) )}
        </>
    );
};

export default PackageSystem;
