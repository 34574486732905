import { Validators, Form } from '@happybandit/react-validation';
import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import CheckValid from '../../../components/CheckValid';
import SelectValid from '../../../components/SelectValid';
import TextValid from '../../../components/TextValid';
import { FirebaseContext } from '../../../firebase_init';
import { selectExternalContacts, selectUser, setError } from '../../../redux/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { removeUndefined, statesList, uuidv4 } from '../../../utils';
import { ContactServices, IExternalContact, IPersonContact } from '../interfaces';
import { getServiceIcon } from '../utils';

interface IContactProps {
    contacts: IPersonContact[];
    onChange: ( contacts: IPersonContact[] ) => void;
}

const PersonnelContacts: React.FC<IContactProps> = ( { contacts, onChange } ) => {
    const handleAdd = () => {
        onChange( [{
            id: uuidv4(),
            name: '',
            phone: '',
            title: '',
            email: '',
        }, ...contacts] );
    };

    const handleDelete = ( index: number ) => {
        let newContacts = [...contacts];

        newContacts.splice( index, 1 );
        onChange( newContacts );
    };

    const handleChange = ( index: number, change: Partial<IPersonContact> ) => {
        let newContacts = [...contacts];

        newContacts[index] = {
            ...newContacts[index],
            ...change,
        };
        onChange( newContacts );
    };

    return (
        <>
            <Typography variant="h5" style={{ display: 'inline' }}>
                <strong>
                    Personnel Contact Info
                </strong>
            </Typography>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contacts.map( ( item, index ) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <TextValid
                                    id="external-edit-name"
                                    label="Name"
                                    size="small"
                                    value={item.name}
                                    onChange={( value ) => handleChange( index, { name: value } )}
                                    validators={[
                                        Validators.required( item.name ),
                                    ]}
                                />
                            </TableCell>
                            <TableCell>
                                <TextValid
                                    id="external-edit-title"
                                    label="Title"
                                    size="small"
                                    value={item.title}
                                    onChange={( value ) => handleChange( index, { title: value } )}
                                    validators={[]}
                                />
                            </TableCell>
                            <TableCell>
                                <TextValid
                                    type="tel"
                                    id="external-edit-phone"
                                    label="Phone"
                                    size="small"
                                    value={item.phone}
                                    onChange={( value ) => handleChange( index, { phone: value } )}
                                    validators={[]}
                                />
                            </TableCell>
                            <TableCell>
                                <TextValid
                                    type="email"
                                    id="external-edit-email"
                                    label="E-Mail"
                                    size="small"
                                    value={item.email}
                                    onChange={( value ) => handleChange( index, { email: value } )}
                                    validators={[]}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleDelete( index )}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ) )}
                    <TableRow>
                        <TableCell colSpan={1}>
                            <Button
                                type="button"
                                onClick={handleAdd}
                                startIcon={<AddIcon/>}
                                color="primary"
                            >
                                Add Contact
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

interface IServicesProps {
    services: ContactServices[];
    onChange: ( services: ContactServices[] ) => void;
}

const Services: React.FC<IServicesProps> = ( { services, onChange } ) => {
    const handleToggle = ( value: ContactServices ) => () => {
        const currentIndex = services.indexOf( value );
        const newChecked = [...services];

        if ( currentIndex === -1 ) {
            newChecked.push( value );
        } else {
            newChecked.splice( currentIndex, 1 );
        }

        onChange( newChecked );
    };

    return (
        <>
            <Typography variant="h5" style={{ display: 'inline' }}>
                <strong>
                    Services Rendered
                </strong>
            </Typography>
            <List style={{ maxWidth: 500 }}>
                {Object.entries( ContactServices ).map( ( [key, value] ) => (
                    <ListItem key={value} button onClick={handleToggle( value )}>
                        <ListItemIcon>
                            {getServiceIcon( value )}
                        </ListItemIcon>
                        <ListItemText id={value} primary={key}/>
                        <ListItemSecondaryAction>
                            <Checkbox
                                edge="end"
                                onChange={handleToggle( value )}
                                checked={services.indexOf( value ) !== -1}
                                inputProps={{ 'aria-labelledby': value }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                ) )}
            </List>
        </>
    );
};

const ExternalContractsEdit: React.FC<RouteComponentProps<{ contactId?: string }>> = ( { contactId } ) => {
    const dispatch = useDispatch();
    const context = useContext( FirebaseContext );
    const user = useSelector(selectUser);
    const externalContacts = useSelector(selectExternalContacts);
    const externalContact = externalContacts.find( ( contact ) => contact.id === contactId );

    const [name, setName] = useState( externalContact ? externalContact.name : '' );
    const [notes, setNotes] = useState( externalContact ? externalContact.notes : '' );
    const [street1, setStreet1] = useState( externalContact ? externalContact.address.street1 : '' );
    const [street2, setStreet2] = useState( externalContact ? externalContact.address.street2 : '' );
    const [city, setCity] = useState( externalContact ? externalContact.address.city : '' );
    const [state, setState] = useState( externalContact ? externalContact.address.state : '' );
    const [zipCode, setZipCode] = useState( externalContact ? externalContact.address.zipCode : '' );
    const [active, setActive] = useState( externalContact ? externalContact.active : true );
    const [contacts, setContacts] = useState<IPersonContact[]>( externalContact ? externalContact.contacts : [] );
    const [services, setServices] = useState<ContactServices[]>( externalContact ? externalContact.services : [] );

    if ( contactId && !externalContact ) {
        navigate( '/external-contacts' );
        return null;
    }

    const handleClose = () => navigate( `/external-contacts/${contactId}` );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user ) {
            return;
        }
        const collection = context.firestore.collection( Collections.externalContacts );
        const data: IExternalContact = {
            id: externalContact ? externalContact.id : collection.doc().id,
            name,
            notes,
            contacts: contacts,
            services: services,
            address: {
                street1,
                street2,
                city,
                zipCode,
                state,
            },
            active,
        };

        collection.doc( data.id ).set( removeUndefined( data ) )
            .then( () => {
                handleClose();
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
    };

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Edit External Contact
                    </strong>
                </Typography>
                <Link href="#" onClick={handleClose} variant="body1">
                    <strong>
                        &nbsp;<span style={{ 'color': 'black' }}>/</span> Cancel Edit
                    </strong>
                </Link>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <TextValid
                        id="external-name"
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={( changed ) => setName( changed )}
                        validators={[Validators.required( name )]}
                    />
                    <TextValid
                        id="external-street1"
                        label="Address Line 1"
                        value={street1}
                        onChange={( value ) => setStreet1( value )}
                        fullWidth
                        validators={[Validators.required( street1 )]}
                    />
                    <TextValid
                        id="external-street2"
                        label="Address Line 2"
                        value={street2}
                        onChange={( value ) => setStreet2( value )}
                        fullWidth
                        validators={[]}
                    />
                    <TextValid
                        id="external-city"
                        label="City"
                        value={city}
                        onChange={( value ) => setCity( value )}
                        fullWidth
                        validators={[Validators.required( city )]}
                    />
                    <SelectValid
                        id="external-state"
                        label="State"
                        value={state}
                        onChange={( value ) => setState( value )}
                        fullWidth
                        validators={[Validators.required( state )]}
                    >
                        <MenuItem value=""/>
                        {statesList.map( ( stateItem ) => (
                            <MenuItem
                                key={stateItem.abbreviation}
                                value={stateItem.abbreviation}
                            >
                                {stateItem.name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <TextValid
                        id="external-zipCode"
                        label="Zip Code"
                        value={zipCode}
                        onChange={( value ) => setZipCode( value )}
                        fullWidth
                        validators={[Validators.required( zipCode )]}
                    />
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <PersonnelContacts contacts={contacts} onChange={setContacts}/>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <Services services={services} onChange={setServices}/>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <TextValid
                        id="external-notes"
                        label="Notes"
                        multiline
                        rows="4"
                        value={notes}
                        fullWidth
                        onChange={( changed ) => setNotes( changed )}
                        validators={[]}
                    />
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <CheckValid
                        id={'external-active'}
                        value={active}
                        label="Is Active"
                        fullWidth
                        onChange={setActive}
                        validators={[]}
                    />

                    <hr style={{ 'borderTop': '#eee' }}/>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Save
                    </Button>
                </Form>
            </Box>
        </>
    );
};

export default ExternalContractsEdit;
