import {
    Collections,
    MetrcFacilitySubCollections,
    MetrcLocationSubCollections
} from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState, useEffect } from 'react';
import { Form, Validators } from '@happybandit/react-validation';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { IRoomEnvironment } from '../../../../../environmentInterfaces';
import { selectUser, setError } from '../../../../../redux/appSlice';
import { FirebaseContext } from '../../../../../firebase_init';
import TextValid from '../../../../../components/TextValid';
import FacilityContext from '../../../facilityContext';
import RoomContext from '../../roomContext';

interface IProps {
    roomId: string;
    open: boolean;
    onClose: () => void;
}

const EditEnvironment: React.FC<IProps> = ( { open, onClose, roomId } ) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const { room } = useContext( RoomContext );
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [co2, setC02] = useState<number>( 0 );
    const [vpd, setVPD] = useState<number>( 0 );
    const [humidity, setHumidity] = useState<number>( 0 );
    const [temperature, setTemperature] = useState<number>( 0 );
    const [lightLevel, setLightLevel] = useState<number>( 0 );
    const user = useSelector( selectUser );
    

    useEffect( () => {
        firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.environment )
            .where( 'systemId', '==', '' )
            .orderBy( 'dateTime', 'desc' )
            .limit( 1 )
            .onSnapshot( ( snapshot ) => {
                if ( snapshot.docs && snapshot.docs.length > 0 ) {
                    const last = snapshot.docs[0].data() as IRoomEnvironment;
                    setC02( last.co2 );
                    setVPD( last.vpd );
                    setHumidity( last.humidity );
                    setTemperature( last.temperature );
                    setLightLevel( last.lightLevel );
                }
            } );
    }, [] );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.environment )
            .add( {
                co2,
                vpd,
                humidity,
                lightLevel,
                temperature,
                userId: (user && user.uid) || '',
                dateTime: (new Date()).toISOString(),
                systemId: '',
            } )
            .then( () => {
                return onClose();
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        { room.metrc.Name }
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    <strong>
                        / Room / Update Environment
                    </strong>
                </Typography>
                <Typography variant="body2" component="p">
                    Environment includes variables such as Temperature, C02, Humidity, VPD and Light Level.
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
            </DialogTitle>
            <DialogContent>
                <Alert severity="info">
                    Enter the current readings for the environment.
                </Alert>
                <Form id="new-room-form" onSubmit={handleSubmit}>
                    <TextValid
                        id="room-env-temp"
                        label="Temperature"
                        value={temperature.toString()}
                        onChange={( value ) => setTemperature( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( temperature.toString() )]}
                    />
                    <TextValid
                        id="room-env-humidity"
                        label="Humidity"
                        value={humidity.toString()}
                        onChange={( value ) => setHumidity( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( humidity.toString() )]}
                    />
                    <TextValid
                        id="room-env-vpd"
                        label="VPD"
                        value={vpd.toString()}
                        onChange={( value ) => setVPD( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( vpd.toString() )]}
                    />
                    <TextValid
                        id="room-env-c02"
                        label="C02"
                        value={co2.toString()}
                        onChange={( value ) => setC02( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( co2.toString() )]}
                    />
                    <TextValid
                        id="room-env-light-level"
                        label="Light Level"
                        value={lightLevel.toString()}
                        onChange={( value ) => setLightLevel( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( lightLevel.toString() )]}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button type="submit" form="new-room-form" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditEnvironment;