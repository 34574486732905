import { Card, CardActionArea, CardContent, Divider, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { HarvestStatus, IHarvest, IRoom } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseContext } from 'firebase_init';
import { setShowPlantDetails } from 'redux/appSlice';
import { PlantDetailPages } from 'redux/interfaces';
import { getHarvestStatus } from 'utils';
import FacilityContext from '../../../facilityContext';

interface IProps {
    room: IRoom;
}

interface IHarvestFilter {
    todoManicure: IHarvest[];
    inProgressManicure: IHarvest[];
    todoDry: IHarvest[];
    inProgressDry: IHarvest[];
    todoTrim: IHarvest[];
    inProgressTrim: IHarvest[];
    completed: IHarvest[];
}

const getFilteredHarvests = ( harvests: IHarvest[] ) => {
    return harvests.reduce<IHarvestFilter>( ( acc, item ) => {
        const status = getHarvestStatus( item );
        if ( status === HarvestStatus.ToDoManicure ) {
            acc.todoManicure.push( item );
        } else if ( status === HarvestStatus.InProgressManicure ) {
            acc.inProgressManicure.push( item );
        } else if ( status === HarvestStatus.ToDoDry ) {
            acc.todoDry.push( item );
        } else if ( status === HarvestStatus.InProgressDry ) {
            acc.inProgressDry.push( item );
        } else if ( status === HarvestStatus.ToDoTrim ) {
            acc.todoTrim.push( item );
        } else if ( status === HarvestStatus.InProgressTrim ) {
            acc.inProgressTrim.push( item );
        } else {
            acc.completed.push( item );
        }
        return acc;
    }, {
        todoManicure: [],
        inProgressManicure: [],
        todoDry: [],
        inProgressDry: [],
        todoTrim: [],
        inProgressTrim: [],
        completed: [],
    } );
}

interface IItemProps {
    harvest: IHarvest;
    inProgress?: boolean;
}

const HarvestItem: React.FC<IItemProps> = ( { harvest, inProgress = false } ) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    return (
        <Grid item xs={12} md={4} key={harvest.id}>
            <Card variant="outlined" style={{ borderColor: inProgress ? theme.palette.warning.main : undefined }}>
                <CardActionArea
                    onClick={() => dispatch( setShowPlantDetails( {
                        page: PlantDetailPages.harvestDetails,
                        key: {
                            id: harvest.id,
                            collection: MetrcFacilitySubCollections.metrcHarvests,
                        },
                    } ) )}
                >
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h4">
                            {harvest.metrc.Name}
                        </Typography>
                        <Typography color="textSecondary">
                            {harvest.metrc.SourceStrainNames}
                        </Typography>
                        <Typography color="textSecondary">
                            Current Weight: {harvest.metrc.CurrentWeight} {harvest.metrc.UnitOfWeightName}
                        </Typography>
                        <Typography color="textSecondary">
                            Plant Count: {harvest.metrc.PlantCount}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

const DrySystem: React.FC<IProps> = ( { room } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const [harvests, setHarvests] = useState<IHarvest[]>( [] );

    useEffect( () => {
        firestore.collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcHarvests )
            .where( 'metrc.DryingLocationId', '==', room.metrc.Id )
            .where( 'metrc.FinishedDate', '==', null )
            .onSnapshot( snapshot => {
                setHarvests( snapshot.docs.map( doc => doc.data() as IHarvest ) );
            } );
    }, [] );

    const filteredHarvests = getFilteredHarvests( harvests );

    return (
        <>
            <Typography variant="h4">
                Harvest Manicure Trim
            </Typography>
            <Typography variant="body2">
                First step for a Whole Plant harvest, remove excess leaves before drying. Can be dried for trim or
                destroyed.
            </Typography>
            <Divider style={{ marginBottom: 10 }}/>
            {filteredHarvests.todoManicure.length === 0 && filteredHarvests.inProgressManicure.length === 0 && (
                <ListItemText primary="No Harvests To Manicure Trim"/>
            )}
            <Grid container spacing={3}>
                {filteredHarvests.inProgressManicure.map( ( harvest ) => <HarvestItem
                    key={harvest.id}
                    harvest={harvest}
                    inProgress
                /> )}
                {filteredHarvests.todoManicure.map( ( harvest ) => <HarvestItem
                    key={harvest.id}
                    harvest={harvest}
                /> )}
            </Grid>
            <Typography variant="h4" style={{ paddingTop: '1em', paddingBottom: '0.25em' }}>
                Harvest Drying
            </Typography>
            <Typography variant="body2">
                First step for a Manicure harvest and second step for a Whole Plant harvest, dry the plant matter to
                preserve it for packaging.
            </Typography>
            <Divider style={{ marginBottom: 10 }}/>
            {filteredHarvests.inProgressDry.length === 0 && filteredHarvests.todoDry.length === 0 && (
                <ListItemText primary="No Harvests To Dry"/>
            )}
            <Grid container spacing={3}>
                {filteredHarvests.inProgressDry.map( ( harvest ) => <HarvestItem
                    key={harvest.id}
                    harvest={harvest}
                    inProgress
                /> )}
                {filteredHarvests.todoDry.map( ( harvest ) => <HarvestItem
                    key={harvest.id}
                    harvest={harvest}
                /> )}
            </Grid>
            <Typography variant="h4" style={{ paddingTop: '1em', paddingBottom: '0.25em' }}>
                Harvest Full Trim
            </Typography>
            <Typography variant="body2">
                Last step for all harvests, completely trim the plants to separate the buds from trim and waste.
            </Typography>
            <Divider style={{ marginBottom: 10 }}/>
            {filteredHarvests.todoManicure.length === 0 && filteredHarvests.inProgressManicure.length === 0 && (
                <ListItemText primary="No Harvests To Manicure Trim"/>
            )}
            <Grid container spacing={3}>
                {filteredHarvests.inProgressTrim.map( ( harvest ) => <HarvestItem
                    key={harvest.id}
                    harvest={harvest}
                    inProgress
                /> )}
                {filteredHarvests.todoTrim.map( ( harvest ) => <HarvestItem
                    key={harvest.id}
                    harvest={harvest}
                /> )}
            </Grid>
            <Typography variant="h4" style={{ paddingTop: '1em', paddingBottom: '0.25em' }}>
                Completed Harvests
            </Typography>
            <Typography variant="body2">
                Finish a harvest by packaging products and disposing of waste.
            </Typography>
            <Divider style={{ marginBottom: 10 }}/>
            {filteredHarvests.completed.length === 0 && (
                <ListItemText primary="No Completed Harvests"/>
            )}
            <Grid component="section" container spacing={3}>
                {filteredHarvests.completed.map( ( harvest ) => <HarvestItem key={harvest.id} harvest={harvest}/> )}
            </Grid>
        </>
    );
};

export default DrySystem;
