import { Form, Validators } from '@happybandit/react-validation';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    MetrcLocationSubCollections,
    Collections,
    MetrcFacilitySubCollections
} from '@shared/interfaces/lib/firebaseConstants';
import { INotification, IRoom, ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectValid from '../../../../../../components/SelectValid';
import TextValid from '../../../../../../components/TextValid';
import { addHistory, FirebaseContext } from '../../../../../../firebase_init';
import { selectUser, setError } from '../../../../../../redux/appSlice';
import { getTimeStamp, isNormalInteger, removeUndefined, roomTemplate, useNotification, } from '../../../../../../utils';
import FacilityContext from '../../../../facilityContext';

interface IProps {
    room: IRoom;
    system?: ISystem;
    onClose: () => void;
}

const getInitialType = ( room: IRoom, system?: ISystem ): RoomTypes | undefined => {
    if ( system && system.type ) {
        return system.type;
    } else if ( room.types && room.types.length === 1 ) {
        return room.types[0];
    } else {
        return undefined;
    }
};

const NewSystemDialog: React.FC<IProps> = ( { room, system, onClose } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [name, setName] = useState( system ? system.name : '' );
    const [type, setType] = useState<RoomTypes | undefined>( getInitialType( room, system ) );
    const [rackCount, setRackCount] = useState( system ? system.size.rackCount.toString() : '1' );
    const [levelCount, setLevelCount] = useState( system ? system.size.levelCount.toString() : '2' );
    const [zoneCount, setZoneCount] = useState( system ? system.size.zoneCount.toString() : '2' );
    const user = useSelector( selectUser );
    const sendNotification = useNotification();

    const handleSubmit = ( valid: boolean ) => {
        if ( !user || !valid || !type ) {
            return;
        }

        const collection = firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( room.id )
            .collection( MetrcLocationSubCollections.systems );
        const data: ISystem = {
            id: system ? system.id : collection.doc().id,
            name,
            type,
            created: system ? system.created : (new Date()).toISOString(),
            water: system ? system.water : {
                isFilled: false,
                changeOrFlushInProgress: false,
            },
            size: {
                rackCount: parseInt( rackCount ),
                levelCount: parseInt( levelCount ),
                zoneCount: parseInt( zoneCount ),
            },
            lightRows: [],
        }

        collection.doc( data.id ).set( removeUndefined( data ) )
            .then( () => {
                onClose();
                return addHistory( firestore, facility.id, room.id, {
                    userId: user.uid,
                    message: `Created System - ${name}`,
                    timestamp: getTimeStamp(),
                } );
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );

        const notification: Omit<INotification, 'id'> = {
            timestamp: Date.now(),
            seen: false,
            userId: user.uid,
            message: `Added System - ${name} in room ${roomTemplate( room )}`,
        };

        sendNotification( notification, ( userItem ) => userItem.permissions.includes( UserPermissions.userAdmin ) );
    };

    return (
        <Dialog fullScreen={fullScreen} open={true} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle style={{ 'paddingBottom': '0' }}>
                <Typography variant="h5" component="p" style={{ display: 'inline' }}>
                    <strong>
                        {room.metrc.Name}
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    <strong>
                        / Room / New System
                    </strong>
                </Typography>
                <Typography variant="body2" component="p">
                    Systems are a group of parts that complete a closed water flow.
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
            </DialogTitle>
            <DialogContent>
                <Form style={{ 'paddingTop': '0' }} id="system-new-form" onSubmit={handleSubmit}>
                    <TextValid
                        id="system-new-name"
                        label="Name"
                        autoComplete='none'
                        value={name}
                        onChange={( value ) => setName( value )}
                        fullWidth
                        style={{ 'marginTop': '0' }}
                        validators={[Validators.required( name )]}
                    />
                    <SelectValid
                        id="system-new-type"
                        value={type || ''}
                        label="System Type"
                        fullWidth
                        validators={[Validators.required( type ? RoomTypes[type] : '' )]}
                        onChange={( value ) => setType( RoomTypes[value as keyof typeof RoomTypes] || undefined )}
                    >
                        <MenuItem value=""/>
                        {room.types && room.types.filter(item => item !== RoomTypes.packaging && item !== RoomTypes.dry).map( ( item ) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ) )}
                    </SelectValid>
                    <TextValid
                        id="system-new-racks"
                        label="Number of Racks"
                        autoComplete='none'
                        type="number"
                        value={rackCount}
                        onChange={( value ) => setRackCount( value )}
                        fullWidth
                        validators={[
                            Validators.required( rackCount ),
                            Validators.custom( () => isNormalInteger( rackCount ), 'Must be a positive integer' )
                        ]}
                    />
                    <TextValid
                        id="system-new-levels"
                        label="Count of Levels in a Rack"
                        autoComplete='none'
                        value={levelCount}
                        onChange={( value ) => setLevelCount( value )}
                        fullWidth
                        validators={[
                            Validators.required( levelCount ),
                            Validators.custom( () => isNormalInteger( levelCount ), 'Must be a positive integer' )
                        ]}
                    />
                    <TextValid
                        id="system-new-zones"
                        label="Count of Zones in a Level"
                        autoComplete='none'
                        value={zoneCount}
                        onChange={( value ) => setZoneCount( value )}
                        fullWidth
                        style={{ 'marginBottom': '1em' }}
                        validators={[
                            Validators.required( zoneCount ),
                            Validators.custom( () => isNormalInteger( zoneCount ), 'Must be a positive integer' )
                        ]}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button type="submit" form="system-new-form" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewSystemDialog;