import { Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React from 'react';

const EnvironmentRoomView: React.FC<RouteComponentProps> = () => {
    return(
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ display: 'inline' }}>
                        <strong>
                            Environment
                        </strong>
                    </Typography>
                    {/* <strong>
                        <span style={{ 'color': 'black' }}>&nbsp;/</span> {room?.tasks?.length} Completed Scheduled
                    </strong> */}
                    <hr style={{ 'borderTop': '#eee' }}/>
                </Grid>
            </Grid>
        </>
    );
};

export default EnvironmentRoomView;
