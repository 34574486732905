import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IFacility, ITransfer } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import { FirebaseContext } from '../../../firebase_init';
import { Paper } from '@material-ui/core';

interface IProps {
    facility: IFacility;
}

const DashTransfers: React.FC<IProps> = ( { facility } ) => {
    const context = useContext( FirebaseContext );
    const [transfers, setTransfers] = useState<ITransfer[]>( [] );

    useEffect( () => {
        context.firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcTransfers )
            .where( 'metrc.ReceivedDateTime', '==', null )
            .onSnapshot( ( snap ) => {
                setTransfers( snap.docs.map( ( item ) => item.data() as ITransfer ) );
            } );
    }, [facility.id] );

    return (
        <Paper
            onClick={() => navigate( '/facility/' + facility.id + '/transfers' )} elevation={0} variant="outlined" square
            style={{ padding: '1em' }}
        >
            <Typography variant="h5" style={{ display: 'inline' }}>
                <strong>
                    Transfers
                </strong>
            </Typography>
            <Typography variant="body2" component="p" style={{ display: 'inline' }}>
                <strong>
                    &nbsp;/ {transfers.length} Transfers in progress
                </strong>
            </Typography>
        </Paper>
    );
};

export default DashTransfers;
