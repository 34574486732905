import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { HarvestStatus, IHarvest, IHarvestStatus } from '@shared/interfaces/lib/interfaces';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseContext } from '../../../firebase_init';
import { setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import { getHarvestStatus, useSelectedFacility, useSelectedRoom } from '../../../utils';
import { useDetailsStyles } from '../details';

interface IProps {
    harvest: IHarvest;
    facilityId: string;
}

interface IButtonProps {
    status: HarvestStatus;
    onChange: ( newStatus: HarvestStatus ) => void;
}

const NextStepButton: React.FC<IButtonProps> = ( { status, onChange } ) => {
    switch ( status ) {
        case HarvestStatus.ToDoManicure:
            return (
                <Button
                    variant="contained"
                    onClick={() => onChange( HarvestStatus.InProgressManicure )}
                >
                    Start Manicure Trim
                </Button>
            );
        case HarvestStatus.InProgressManicure:
            return (
                <Button
                    variant="contained"
                    onClick={() => onChange( HarvestStatus.ToDoDry )}
                >
                    Finish Manicure Trim
                </Button>
            );
        case HarvestStatus.ToDoDry:
            return (
                <Button
                    variant="contained"
                    onClick={() => onChange( HarvestStatus.InProgressDry )}
                >
                    Start Drying
                </Button>
            );
        case HarvestStatus.InProgressDry:
            return (
                <Button
                    variant="contained"
                    onClick={() => onChange( HarvestStatus.ToDoTrim )}
                >
                    End Drying
                </Button>
            );
        case HarvestStatus.ToDoTrim:
            return (
                <Button
                    variant="contained"
                    onClick={() => onChange( HarvestStatus.InProgressTrim )}
                >
                    Start Final Trim
                </Button>
            );
        case HarvestStatus.InProgressTrim:
            return (
                <Button
                    variant="contained"
                    onClick={() => onChange( HarvestStatus.Complete )}
                >
                    End Final Trim
                </Button>
            );
        default:
            return null;
    }
};

const HarvestDetails: React.FC<IProps> = ( { harvest, facilityId } ) => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const classes = useDetailsStyles();
    const room = useSelectedRoom( facilityId, item => item.metrc.Id === harvest.metrc.DryingLocationId );
    const facility = useSelectedFacility( item => item.id === facilityId );

    if ( !harvest || !facility ) {
        return null;
    }

    const status = getHarvestStatus( harvest );

    const onStatus = ( newStatus: HarvestStatus ) => {
        const data: IHarvestStatus = {
            ...harvest.status,
        };
        const dateTime = DateTime.local().toISODate();

        switch ( newStatus ) {
            case HarvestStatus.InProgressManicure:
                data.manicureStartDateTime = dateTime;
                break;
            case HarvestStatus.ToDoDry:
                data.manicureEndDateTime = dateTime;
                break;
            case HarvestStatus.InProgressDry:
                data.dryStartDateTime = dateTime;
                break;
            case HarvestStatus.ToDoTrim:
                data.dryEndDateTime = dateTime;
                break;
            case HarvestStatus.InProgressTrim:
                data.trimStartDateTime = dateTime;
                break;
            case HarvestStatus.Complete:
                data.trimEndDateTime = dateTime;
                break;
        }

        firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcHarvests ).doc( harvest.id )
            .update( { status: data } )
            .catch();
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Harvest Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                    <Typography>{facility ? facility.metrc.DisplayName : 'Unknown Facility'}</Typography>
                    <Typography>{room ? room.metrc.Name : 'Unknown Room'}</Typography>
                    <Typography>{harvest.metrc.Name}</Typography>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    Stage: {status}
                                </Typography>
                                <Typography>
                                    Type: {harvest.metrc.HarvestType === 'Product' ? 'Manicure' : 'Full Plant'}
                                </Typography>
                                <Typography>
                                    Strain: {harvest.metrc.SourceStrainNames}
                                </Typography>
                                <Typography>
                                    Plant Count: {harvest.metrc.PlantCount}
                                </Typography>
                                <Typography>
                                    Finished: {harvest.metrc.FinishedDate ?? 'Still Open'}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <NextStepButton status={status} onChange={onStatus}/>
                                {status === HarvestStatus.Complete && (
                                    <Button
                                        variant="contained"
                                        onClick={() => dispatch( setShowPlantDetails( {
                                            page: PlantDetailPages.harvestFinish,
                                            key: {
                                                collection: MetrcFacilitySubCollections.metrcHarvests,
                                                id: harvest.id,
                                            },
                                            undo: harvest.metrc.FinishedDate !== null
                                        } ) )}
                                    >
                                        {harvest.metrc.FinishedDate === null ? 'Finish Harvest' : 'UnFinish Harvest'}
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" gutterBottom>
                                    Weights ({harvest.metrc.UnitOfWeightName})
                                </Typography>
                                <Typography>
                                    Weight when Harvested: {harvest.metrc.TotalWetWeight}
                                </Typography>
                                <Typography>
                                    Packaged Weight: {harvest.metrc.TotalPackagedWeight}
                                </Typography>
                                <Typography>
                                    Waste Weight: {harvest.metrc.TotalWasteWeight}
                                </Typography>
                                <Typography>
                                    Remaining Weight: {harvest.metrc.CurrentWeight}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {harvest.metrc.CurrentWeight > 0 && (
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={() => dispatch( setShowPlantDetails( {
                                                page: PlantDetailPages.harvestWaste,
                                                key: {
                                                    collection: MetrcFacilitySubCollections.metrcHarvests,
                                                    id: harvest.id,
                                                },
                                            } ) )}
                                        >
                                            Remove Waste
                                        </Button>
                                        {status === HarvestStatus.Complete && (
                                            <Button
                                                variant="contained"
                                                onClick={() => dispatch( setShowPlantDetails( {
                                                    page: PlantDetailPages.toPackage,
                                                    key: {
                                                        collection: MetrcFacilitySubCollections.metrcHarvests,
                                                        id: harvest.id,
                                                    },
                                                } ) )}
                                            >
                                                Create Package
                                            </Button>
                                        )}
                                    </>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    )
};

export default HarvestDetails;