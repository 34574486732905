import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DirectPaths } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase_init';
import { selectSyncTask, selectUser } from '../../redux/appSlice';

interface IMessage {
    error?: string;
    onClose: () => void;
}

const ErrorMessage: React.FC<IMessage> = ( { error, onClose } ) => (
    <Alert variant="filled" severity="error" onClose={onClose}>
        Sync Failed: {error || 'Unknown Issue'}
    </Alert>
);

const InProgressMessage: React.FC = () => (
    <Alert variant="filled" severity="warning">
        Sync In Progress
    </Alert>
);

const SyncSnack: React.FC = () => {
    const context = useContext( FirebaseContext );
    const task = useSelector( selectSyncTask );
    const user = useSelector( selectUser );

    if ( !user || !task || !task.status ) {
        return null;
    }

    const handleClose = () => {
        context.firestore.doc( DirectPaths.orgMetrcSync ).set( {} ).catch();
    }

    const getAlert = () => {
        switch ( task.status ) {
            case 'errored':
                return <ErrorMessage error={task.error} onClose={handleClose}/>;
            case 'inProgress':
                return <InProgressMessage/>;
            default:
                return <span/>;
        }
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={true}
        >
            {getAlert()}
        </Snackbar>
    );
};

export default SyncSnack;
