import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, RouteComponentProps } from '@reach/router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import WarningRounded from '@material-ui/icons/WarningRounded';
import { getUsers } from '../../api';
import { IUser } from '../../User';
import { UserDetails } from './userDetails';
import { selectUser, selectUsers, setUsers } from '../../redux/appSlice';
import UserAvatar from '../../components/userAvatar';

interface IUserRow extends IUser {
    onClick: () => void;
}

export const UserRow: React.FC<IUserRow> = ( { displayName, email, emailVerified, permissions, uid, onClick } ) => {
    return (
        <TableRow hover onClick={() => onClick()}>
            <TableCell>
                <UserAvatar
                    id={uid}
                    name={displayName}
                />
            </TableCell>
            <TableCell>
                <Link onClick={() => navigate( '/profile/' + uid )}>
                    {displayName}
                </Link>
            </TableCell>
            <TableCell>
                {email}
            </TableCell>
            <TableCell>
                {emailVerified ? (
                    <CheckCircleRounded color="primary"/>
                ) : (
                    <WarningRounded color="secondary"/>
                )}
            </TableCell>
            <TableCell align="right">
                {permissions.map((permission) => (
                    <Chip
                        key={permission}
                        style={{ textTransform: 'capitalize' }}
                        color="primary"
                        label={permission}
                    />
                ))}
            </TableCell>
        </TableRow>
    );
};
const Users: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const user = useSelector(selectUser);
    const [shown, setShown] = useState<IUser | undefined>( undefined );

    const updateUsers = () => {
        if ( user ) {
            getUsers( user.token )
                .then( ( userDetails ) => dispatch( setUsers( userDetails ) ) );
        }
    };

    useEffect( () => {
        updateUsers();
    }, [user] );

    const handleClose = ( reload: boolean ) => {
        setShown( undefined );
        if ( reload ) {
            updateUsers();
        }
    };

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Users
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ { users.length } users
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />
            </Box>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: 65 }}/>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Email Verified</TableCell>
                        <TableCell align="right">Permissions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map( user => (
                        <UserRow
                            key={user.uid}
                            {...user}
                            onClick={() => setShown( user )}
                        />
                    ) )}
                </TableBody>
            </Table>
            <Drawer anchor="bottom" open={!!shown} onClose={handleClose}>
                {shown && (
                    <UserDetails {...shown} onClose={handleClose}/>
                )}
            </Drawer>
        </>
    );
};

export default Users;
