import { Form, Validators } from '@happybandit/react-validation';
import { InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import { IStrain } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import ContactSelectValid from 'components/ContactSelectValid';
import TextValid from 'components/TextValid';
import { FirebaseContext } from 'firebase_init';
import { setError } from 'redux/appSlice';
import { removeUndefined } from 'utils';
import { ContactServices } from '../../../../externalContacts/interfaces';
import { ISelectStrain } from '../select';

interface IProps {
    selection?: ISelectStrain;
    onBack: () => void;
    onClose: () => void;
}

const getMetrcDefault = ( value?: number | string | null ): string => {
    if ( !value ) {
        return '';
    }
    return value.toString();
};

const CreateStrain: React.FC<IProps> = ( { selection, onClose, onBack } ) => {
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [name, setName] = useState( selection?.strain.Name || '' );
    const [indicaPercent, setIndicaPercent] = useState<string>( getMetrcDefault( selection?.strain.IndicaPercentage ) );
    const [sativaPercent, setSativaPercent] = useState<string>( getMetrcDefault( selection?.strain.SativaPercentage ) );
    const [thcLevel, setThcLevel] = useState<string>( getMetrcDefault( selection?.strain.ThcLevel ? selection.strain.ThcLevel * 100 : undefined ) );
    const [cbdLevel, setCbdLevel] = useState<string>( getMetrcDefault( selection?.strain.CbdLevel ? selection.strain.CbdLevel * 100 : undefined ) );
    const [breederId, setBreederId] = useState<string>( '' );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        const collection = firestore.collection( Collections.strains );

        const data: IStrain = {
            id: collection.doc().id,
            name,
            sativaPercent: parseFloat( sativaPercent ),
            indicaPercent: parseFloat( indicaPercent ),
            thc: thcLevel ? parseFloat( thcLevel ) / 100 : undefined,
            cbd: cbdLevel ? parseFloat( cbdLevel ) / 100 : undefined,
            breederId: breederId || undefined,
            metrcStrains: []
        };

        if(selection) {
            data.metrcStrains.push({
                metrcLicense: selection.license,
                metrcStrainId: selection.strain.Id,
            })
        }

        collection.doc( data.id ).set( removeUndefined(data) )
            .then( onClose )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Strains are unique genetic identifiers for plants.
                </DialogContentText>
                <Form id="new-strain-form" onSubmit={handleSubmit}>
                    <TextValid
                        id="new-strain-name"
                        label="Name"
                        value={name}
                        onChange={( value ) => setName( value )}
                        fullWidth
                        validators={[Validators.required( name )]}
                    />
                    <ContactSelectValid
                        id="new-strain-contact"
                        value={breederId}
                        label="Breeding Company"
                        fullWidth
                        validators={[]}
                        onChange={( value ) => setBreederId( value )}
                        serviceToFocus={ContactServices.Genetics}
                    />
                    <TextValid
                        id="new-strain-sativa"
                        label="Genetics Sativa Percent"
                        type="number"
                        value={sativaPercent}
                        onChange={( value ) => setSativaPercent( value )}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        fullWidth
                        validators={[Validators.required( sativaPercent ), Validators.positive( sativaPercent )]}
                    />
                    <TextValid
                        id="new-strain-indica"
                        label="Genetics Indica Percent"
                        type="number"
                        value={indicaPercent}
                        onChange={( value ) => setIndicaPercent( value )}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        fullWidth
                        validators={[Validators.required( indicaPercent ), Validators.positive( indicaPercent )]}
                    />
                    <TextValid
                        id="new-strain-thc"
                        label="THC Percent"
                        type="number"
                        value={thcLevel}
                        onChange={( value ) => setThcLevel( value )}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        fullWidth
                        validators={[Validators.positive( thcLevel )]}
                    />
                    <TextValid
                        id="new-strain-cbd"
                        label="CBD Percent"
                        type="number"
                        value={cbdLevel}
                        onChange={( value ) => setCbdLevel( value )}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        fullWidth
                        validators={[Validators.positive( cbdLevel )]}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack} color="primary">
                    Back
                </Button>
                <Button type="submit" form="new-strain-form" color="primary">
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateStrain;