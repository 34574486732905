import { Form, Validators } from '@happybandit/react-validation';
import { Table } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlantBatch, IPlantLocation, ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { DateTime } from 'luxon';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { postMovePlantBatch } from '../../../../../../../api';
import TextValid from '../../../../../../../components/TextValid';
import { FirebaseContext } from '../../../../../../../firebase_init';
import { selectUser } from '../../../../../../../redux/appSlice';
import { removeForwardSlash } from '../../../../../../../utils';
import FacilityContext from '../../../../../facilityContext';
import RoomContext from '../../../../roomContext';

interface IProps {
    system: ISystem;
    rackIndex: number;
    levelIndex: number;
    zoneIndex: number;
    onDone: () => void;
}

const ZoneDetailsBatchAdd: React.FC<IProps> = ( { system, rackIndex, levelIndex, zoneIndex, onDone } ) => {
    const context = useContext( FirebaseContext );
    const { room } = useContext( RoomContext );
    const { facility } = useContext( FacilityContext );
    const user = useSelector( selectUser );
    const [added, setAdded] = useState<IPlantBatch[]>( [] );
    const [name, setName] = useState<string>( '' );
    const [invalid, setInvalid] = useState( '' );
    const date = useMemo( () => DateTime.local(), [] );

    if ( system.type !== RoomTypes.clone ) {
        return null;
    }

    const handleSave = () => {
        const location: IPlantLocation = {
            systemId: system.id,
            rack: rackIndex,
            level: levelIndex,
            zone: zoneIndex,
        };

        const batch = context.firestore.batch();
        const collection = context.firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcPlantBatches );

        added.forEach( ( add ) => {
            batch.update( collection.doc( add.id ), { location } );
        } );
        batch.commit().then( () => triggerMove() ).then( () => onDone() );
    };

    const triggerMove = async () => {
        if ( !room || !user ) {
            return;
        }

        const changes: string[] = [];
        added.forEach( ( batch ) => {
            if ( batch.metrc.LocationId !== room.metrc.Id ) {
                changes.push( batch.metrc.Name )
            }
        } );

        if ( changes.length > 0 ) {
            await postMovePlantBatch( user.token, {
                facilityId: facility.id,
                locationId: room.metrc.Id,
                date: date.toISODate(),
                batchNames: changes,
            } )
        }
    };

    const handleAdd = ( valid: boolean ) => {
        setInvalid( '' );
        if ( !valid ) {
            return;
        }

        context.firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcPlantBatches )
            .doc( removeForwardSlash(name) )
            .get()
            .then( ( doc ) => {
                if ( doc.exists ) {
                    const batch = doc.data() as IPlantBatch;
                    if ( batch.metrc.UntrackedCount > 0 ) {
                        setAdded( ( prev ) => [...prev].concat( [batch] ) );
                    } else {
                        setInvalid( 'Batch must still have remaining clones' );
                    }
                } else {
                    setInvalid( 'Unknown Plant Batch Name' );
                }
            } )

        setName( '' );
    }

    return (
        <>
            <DialogContent>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {added.map( ( batch ) => (
                            <TableRow key={batch.id}>
                                <TableCell>
                                    {batch.metrc.Name}
                                </TableCell>
                                <TableCell>
                                    {batch.metrc.UntrackedCount}
                                </TableCell>
                            </TableRow>
                        ) )}
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Form onSubmit={handleAdd} style={{ display: 'flex' }}>
                                    <div>
                                        <TextValid
                                            onChange={setName}
                                            value={name}
                                            id="zone-add-name"
                                            validators={[
                                                Validators.required( name ),
                                            ]}
                                            forcedErrorMessage={invalid ? invalid : undefined}
                                        />
                                    </div>
                                    <div>
                                        <Button type="submit">Add</Button>
                                    </div>
                                </Form>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default ZoneDetailsBatchAdd;
