import { IPersonContact } from '../externalContacts/interfaces';

export enum ProductTypes {
    wax = 'wax',
    rosin = 'rosin',
    resin = 'resin',
    flower = 'flower',
    edible = 'edible',
    shatter = 'shatter',
    tincture = 'tincture',
}

export enum HeavyMetals {
    Arsenic = 'Arsenic',
    Cadmium = 'Cadmium',
    Lead = 'Lead',
    Mercury = 'Mercury',
    Barium = 'Barium',
    Chromium = 'Chromium',
    Selenium = 'Selenium',
    Silver = 'Silver',
}

export enum Pesticides {
    Abamectin = 'Abamectin',
    Bifenazate = 'Bifenazate',
    Bifenthrin = 'Bifenthrin',
    Daminozide = 'Daminozide',
    Etoxazole = 'Etoxazole',
    Fenoxycarb = 'Fenoxycarb',
    Imazazil = 'Imazazil',
    Imidacloprid = 'Imidacloprid',
    Myclobutanil = 'Myclobutanil',
    Paclobutrazol = 'Paclobutrazol',
    Pyrethrins = 'Pyrethrins',
    Spinosad = 'Spinosad',
    Spiromesifen = 'Spiromesifen',
    Spirotetramat = 'Spirotetramat',
    Trifloxystrobin = 'Trifloxystrobin',
}

export interface ITest {
    id: string;
    unit: string;
    name: string;
    strainId: string;
    delivereeId: string;
    results: IResults;
    type: ProductTypes;
    inProgress: boolean;
    milesDriven: number;
    totalWeight: number;
    dateDelivered: string;
    weightDelivered: number;
    resultsExpected: string;
    transportVehicleId: string;
    dateDepartedFromFacility: string;
    testingFacilityId: string;
    facilityOriginatedId: string;
}

export interface IResults {
    id: string;
    name: string;
    // bioForeignMatter:
    heavyMetals: HeavyMetals[];
    potency: IStrainPotency;
    // [ TODO ] => get actual results for interfaces below
    chemicalContaminants: string[];
    microbialContaminants: string[];
    terpeneProfile: ITerpeneProfile[];
    pesticides?: Pesticides[];
}

export interface IStrainPotency {
    thcCbdRatio: string;
    cannabinoidPerWeight: number;
    totalCannabinoidsPresent: number;
}

export interface ITerpeneProfile {
    id: string;
    name: string;
}

export interface IVehicle {
    id: string;
    vin: number;
    name: string;
    make: string;
    model: string;
    // [ TODO ] => make required
    insurance?: IVehicleInsurance;
}

export interface IVehicleInsurance {
    id: string;
    name: string;
    address: string;
    contact: IPersonContact;
    policyNumber: number;
}
