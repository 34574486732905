import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { selectInventoryDetails } from '../../../../redux/appSlice';
import { convertUnit, getIcon } from '../../../../utils';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import capitalize from '@material-ui/core/utils/capitalize';
import { IConsumableDetail, IConsumableItem } from '@shared/interfaces/lib/inventoryInterfaces';

interface IProps {
    facilityId: string;
    consumable: IConsumableItem;
}

const useStyles = makeStyles( () => ({
    listItem: {
        borderBottom: '1px solid lightgrey',
    },
    root: {
        width: '100%',
        '& li:first-child': {
            borderTop: '1px solid lightgrey',
        },
        '& li:last-child': {
            borderBottom: 'none',
        }
    },
}) );

const FacilityConsumableRow: React.FC<IProps> = ( { consumable, facilityId  } ) => {
    const classes = useStyles();
    const inventoryDetails = useSelector(selectInventoryDetails);
    const [open, setOpen] = useState( false );

    const inventoryDetail = inventoryDetails.find( ( detail ): detail is IConsumableDetail => detail.id === consumable.inventoryId );

    if ( !inventoryDetail ) {
        return null;
    }

    const facilityTickets = consumable.tickets.filter((ticket) => ticket.facilityId === facilityId);
    const totalRemaining = facilityTickets.reduce((acc, ticket) => {
        const converted = convertUnit(ticket.amount, ticket.unit, inventoryDetail.unit);

        return acc + converted;
    }, 0);

    return (
        <Grid item xs={12} className={classes.listItem}>
            <List
                component="nav"
                className={classes.root}
            >
                <ListItem button onClick={(() => setOpen( !open ))}>
                    <ListItemIcon>
                        {getIcon( capitalize( inventoryDetail.type ) )}
                    </ListItemIcon>
                    <ListItemText
                        primary={inventoryDetail.name}
                        secondary={`${inventoryDetail.brand} • ${inventoryDetail.model}${inventoryDetail.sku ? ` • ${inventoryDetail.sku}` : ''}`}
                    />
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List dense={true} component="div">
                        <ListItem
                            className={classes.listItem}
                            style={{ 'paddingTop': '1em', 'paddingBottom': '1em', 'paddingLeft': '2em' }}
                        >
                            <ListItemText
                                primary="Facility Storage"
                                secondary={`${totalRemaining} ${capitalize(inventoryDetail.unit.toLowerCase())}`}
                            />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Grid>
    );
};

export default FacilityConsumableRow;
