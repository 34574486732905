import { Button, DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IFacility, IStrain } from '@shared/interfaces/lib/interfaces';
import { IMetrcStrain } from '@shared/interfaces/lib/metrcInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectValid from '../../../../components/SelectValid';
import { FirebaseContext } from '../../../../firebase_init';
import { selectFacilities, setError } from '../../../../redux/appSlice';
import { runBatch } from '../../../../utils';

interface IFacilityStrains {
    facility: IFacility;
    strains: IMetrcStrain[];
}

const InitialGenStrains: React.FC = () => {
    const { firestore } = useContext( FirebaseContext );
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const facilities = useSelector( selectFacilities );
    const [selectedFacilityId, setSelectedFacilityId] = useState<string>( '' );
    const [open, setOpen] = useState( false );
    const [metrcStrains, setMetrcStrains] = useState<IFacilityStrains[]>( [] );
    const dispatch = useDispatch();

    useEffect( () => {
        facilities.forEach( ( facility ) => {
            firestore.collection( Collections.metrcFacilities )
                .doc(facility.id)
                .collection( MetrcFacilitySubCollections.metrcStrains )
                .get()
                .then( ( docs ) => {
                    const strains = docs.docs.map( ( doc ) => doc.data() as IMetrcStrain );
                    if ( strains.length > 0 ) {
                        setMetrcStrains( ( prev ) => prev.concat( [{
                            facility,
                            strains: strains,
                        }] ) );
                    }
                } );
        } );
    }, [] )

    if ( metrcStrains.length < 1 ) {
        return null;
    }

    const selectedFacility = metrcStrains.find( ( item ) => item.facility.metrc.License.Number === selectedFacilityId );

    const handleAutoGenerate = () => {
        const collection = firestore.collection( Collections.strains );
        if ( !selectedFacility ) {
            return;
        }

        const strains: IStrain[] = selectedFacility.strains.map<IStrain>( ( item ) => {
            return {
                id: collection.doc().id,
                name: item.Name,
                sativaPercent: item.SativaPercentage,
                indicaPercent: item.IndicaPercentage,
                metrcStrains: [{
                    metrcLicense: selectedFacility.facility.metrc.License.Number,
                    metrcStrainId: item.Id,
                }],
                thc: item.ThcLevel || undefined,
                cbd: item.CbdLevel || undefined,
            };
        } );
        runBatch( firestore, strains, collection )
            .catch( ( error ) => {
                dispatch( setError( error ) )
            } );
    };

    return (
        <>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" onClick={() => setOpen( true )}>
                        Auto-Generate
                    </Button>
                }
            >
                <AlertTitle>No Strains Defined</AlertTitle>
                You can auto generate missing strains from a specific Facility by clicking the button.
            </Alert>
            <Dialog
                fullScreen={fullScreen} open={open} onClose={() => setOpen( false )} aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Generate Strains
                </DialogTitle>
                <DialogContent>
                    <SelectValid
                        id="strain-gen-facility"
                        value={selectedFacilityId}
                        label="Metrc Facility"
                        fullWidth
                        validators={[]}
                        onChange={setSelectedFacilityId}
                    >
                        <MenuItem value=""/>
                        {metrcStrains.map( ( { facility } ) => (
                            <MenuItem
                                key={facility.metrc.License.Number} value={facility.metrc.License.Number}
                            >{facility.metrc.DisplayName}</MenuItem>
                        ) )}
                    </SelectValid>
                    {selectedFacility && (
                        <>
                            <Divider/>
                            <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                                Strains to Generate from {selectedFacility.facility.metrc.DisplayName}
                            </Typography>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedFacility.strains.map( strain => {
                                        return (
                                            <TableRow key={strain.Id}>
                                                <TableCell>
                                                    {strain.Name}
                                                </TableCell>
                                                <TableCell>
                                                    {strain.Genetics}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    } )}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={!selectedFacility} onClick={handleAutoGenerate}>
                        Generate Strains
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InitialGenStrains;
