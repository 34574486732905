import { InputAdornment } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { ICloneCutBody } from '@shared/interfaces/lib/contractInterfaces';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlant, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { CloneTypes } from '@shared/interfaces/lib/metrcInterfaces';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postPlantCloneCut } from '../../../api';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import { getPlantState, PlantState, useFacilityRooms } from '../../../utils';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';

interface IProps {
    plant: IPlant;
    facilityId: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            minHeight: 100,
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);


const nameTemplate = ( date: DateTime, strain: string, type?: CloneTypes, location?: string ) => `${date.toISODate()}|${location || 'Set Clone Room'}|${strain}|${type || 'Set Type'}`;

const PlantClone: React.FC<IProps> = ( { plant, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector( selectUser );
    const rooms = useFacilityRooms( facilityId );
    const [isDefined, setIsDefined] = useState( false );
    const [definedName, setDefinedName] = useState( '' );
    const [roomId, setRoomId] = useState( '' );
    const [count, setCount] = useState( 0 );
    const [loading, setLoading] = useState( false );
    const date = useMemo( () => DateTime.local(), [] );

    const cloneRooms = rooms.filter( ( room ) => (room.types || []).includes( RoomTypes.clone ) );

    const selectedRoom = cloneRooms.find( item => item.id === roomId );

    if ( !plant ) {
        return null;
    }

    const generatedName = nameTemplate( date, plant.metrc.StrainName, 'Clone', selectedRoom ? selectedRoom.metrc.Name : undefined );

    const handleClickEdit = () => {
        if ( isDefined ) {
            setDefinedName( '' );
        } else {
            setDefinedName( generatedName );
        }
        setIsDefined( !isDefined );
    };

    const plantStatus = getPlantState( plant.metrc );

    const handleSubmit = () => {
        if ( !selectedRoom || !user ) {
            return;
        }
        setLoading( true );

        const body: ICloneCutBody = {
            type: 'Clone',
            locationId: selectedRoom.metrc.Id,
            facilityId,
            count,
            name: isDefined ? definedName : generatedName,
            date: date.toISODate(),
            label: plant.id,
            strainName: plant.metrc.StrainName,
        }
        postPlantCloneCut( user.token, body )
            .then( ( plantBatch ) => {
                dispatch( setShowPlantDetails( {
                    key: {
                        collection: MetrcFacilitySubCollections.metrcPlantBatches,
                        id: plantBatch.id,
                    },
                    page: PlantDetailPages.details,
                } ) );
            } )
            .finally( () => {
                setLoading( false );
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Clone Mother Plant
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        {plantStatus.state !== PlantState.vegetative ? (
                            <Typography variant="h5">
                                Not in the Vegetative phase as required for Mother plants
                            </Typography>
                        ) : (
                            <>
                                <Typography variant="h5">
                                    Clone Batch name must include date, strain, and clone room.
                                </Typography>
                                <TextValid
                                    value={isDefined ? definedName : generatedName}
                                    onChange={setDefinedName}
                                    id="clone-name"
                                    label="Clone Name"
                                    disabled={!isDefined}
                                    validators={[]}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    onClick={handleClickEdit}
                                                    onMouseDown={event => event.preventDefault()}
                                                >
                                                    {isDefined ? 'Reset' : 'Edit'}
                                                </Button>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <hr style={{ 'borderTop': '#eee' }}/>
                                <SelectValid
                                    id="clone-location"
                                    value={roomId}
                                    label="Clone Room to send to"
                                    fullWidth
                                    validators={[]}
                                    onChange={setRoomId}
                                >
                                    <MenuItem value={''}/>
                                    {cloneRooms.map( ( item ) => (
                                        <MenuItem key={item.id} value={item.id}>{item.metrc.Name}</MenuItem>
                                    ) )}
                                </SelectValid>
                                <TextValid
                                    type="number"
                                    value={count.toString()}
                                    onChange={( value ) => setCount( parseInt( value ) )}
                                    id="clone-count"
                                    label="Number of clones cut"
                                    validators={[]}
                                    fullWidth
                                />
                                <hr style={{ 'borderTop': '#eee' }}/>
                                <Button
                                    disabled={!roomId || count <= 0 || (isDefined && !definedName)}
                                    onClick={handleSubmit}
                                >
                                    Submit Clone Cut
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
};

export default PlantClone;