import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IItem, IRecord } from '@shared/interfaces/lib/interfaces';
import { IMetrcItemCategory, IUnitsOfMeasure } from '@shared/interfaces/lib/metrcInterfaces';
import { Collections, DirectPaths } from '@shared/interfaces/lib/firebaseConstants';
import { Form, Validators } from '@happybandit/react-validation';
import { MenuItem, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextValid from '../../../../../components/TextValid';
import SelectValid from '../../../../../components/SelectValid';
import { selectStrains, setError } from '../../../../../redux/appSlice';
import { FirebaseContext } from '../../../../../firebase_init';
import { ISelectItem } from '../select';
import { removeUndefined } from '../../../../../utils';

interface IProps {
    selection?: ISelectItem;
    item?: IItem;
    onBack: () => void;
    onClose: () => void;
}

const getMetrcDefault = ( value?: number | string | null ): string => {
    if ( !value ) {
        return '';
    }
    return value.toString();
};

const CreateItem: React.FC<IProps> = ( { selection, item, onClose, onBack } ) => {
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const strains = useSelector( selectStrains );
    const [productCategories, setProductCategories] = useState<IMetrcItemCategory[]>( [] );
    const [units, setUnits] = useState<IUnitsOfMeasure[]>( [] );
    const [name, setName] = useState( '' );
    const [unitOfMeasure, setUnitOfMeasure] = useState<string>( '' );
    const [volumeUnit, setVolumeUnit] = useState<string>( '' );
    const [volumeQuantity, setVolumeQuantity] = useState<string>( '' );
    const [weightUnit, setWeightUnit] = useState<string>( '' );
    const [weightQuantity, setWeightQuantity] = useState<string>( '' );
    const [productCategoryName, setProductCategoryName] = useState<string>( '' );
    const [strainId, setStrainId] = useState<string>( '' );

    const setInitial = () => {
        if ( item ) {
            setName( item.name );
            setUnitOfMeasure( item.unitOfMeasure );
            setVolumeUnit( item.volumeUnit || '' );
            setVolumeQuantity( item.volumeQuantity ? item.volumeQuantity.toString() : '' );
            setWeightUnit( item.weightUnit || '' );
            setWeightQuantity( item.weightQuantity ? item.weightQuantity.toString() : '' );
            setProductCategoryName( item.productCategoryName );
            setStrainId( item.strainId || '' );
        } else if ( selection ) {
            setName( selection.item.Name );
            setUnitOfMeasure( selection.item.UnitOfMeasureName );
            setVolumeUnit( getMetrcDefault( selection.item.UnitVolumeUnitOfMeasureName ) );
            setVolumeQuantity( getMetrcDefault( selection.item.UnitVolume ) );
            setWeightUnit( getMetrcDefault( selection.item.UnitWeightUnitOfMeasureName ) );
            setWeightQuantity( getMetrcDefault( selection.item.UnitWeight ) );
            setProductCategoryName( getMetrcDefault( selection.item.ProductCategoryName ) );
            setStrainId( getMetrcDefault( selection.item.StrainId ) );
        }
    };

    useEffect( () => {
        firestore.doc( DirectPaths.metrcItemCategories )
            .get()
            .then( docs => {
                const data = docs.data() as IRecord<IMetrcItemCategory>;
                if ( data && data.data ) {
                    setProductCategories( data.data );
                }
            } );
        firestore.doc( DirectPaths.metrcUnitsOfMeasure )
            .get()
            .then( docs => {
                const data = docs.data() as IRecord<IUnitsOfMeasure>;
                if ( data && data.data ) {
                    setUnits( data.data );
                }
            } );
        setInitial();
    }, [] );

    const selectedCategory = productCategories.find( cat => cat.Name === productCategoryName );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !selectedCategory ) {
            return;
        }

        const collection = firestore.collection( Collections.packageItems );

        let data: IItem;
        if ( item ) {
            data = {
                ...item,
                name,
                productCategoryName,
                strainId: strainId || null,
                unitOfMeasure,
                weightQuantity: undefined,
                weightUnit: undefined,
                volumeQuantity: undefined,
                volumeUnit: undefined,
            };
        } else {
            data = {
                id: collection.doc().id,
                name,
                productCategoryName,
                strainId: strainId || null,
                unitOfMeasure,
                metrcItems: [],
            };
        }

        if ( selectedCategory.RequiresUnitWeight ) {
            data.weightQuantity = weightQuantity ? parseFloat( weightQuantity ) : undefined;
            data.weightUnit = weightUnit;
        }
        if ( selectedCategory.RequiresUnitVolume ) {
            data.volumeQuantity = volumeQuantity ? parseFloat( volumeQuantity ) : undefined;
            data.volumeUnit = volumeUnit;
        }
        if ( selectedCategory.QuantityType === 'CountBased' ) {
            const unit = units.find(unit => unit.QuantityType === 'CountBased');
            if( unit ){
                data.unitOfMeasure = unit.Name;
            }
        }

        if ( selection ) {
            data.metrcItems.push( {
                metrcLicense: selection.license,
                metrcItemId: selection.item.Id,
            } )
        }

        collection.doc( data.id ).set( removeUndefined( data ) )
            .then( handleClose )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
    };

    const clearData = () => {
        setName( '' );
        setProductCategoryName( '' );
        setStrainId( '' );
        setUnitOfMeasure( '' );
        setVolumeQuantity( '' );
        setVolumeUnit( '' );
        setWeightQuantity( '' );
        setWeightUnit( '' );
    }

    const handleBack = () => {
        clearData();
        onBack();
    };

    const handleClose = () => {
        clearData();
        onClose();
    };

    return (
        <>

            <DialogTitle id="form-dialog-edit-item-title">
                {item && (
                    <>
                        <Typography variant="h5" style={{ display: 'inline' }} component="p">
                            <strong>
                                {item.name}
                            </strong>
                        </Typography>
                        <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                            <strong>
                                / Update
                            </strong>
                        </Typography>
                    </>
                )}
                <Typography variant="body2" component="p">
                    A Package Item is a product line that can be sold or transferred from your facilities.
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
            </DialogTitle>
            <DialogContent>
                <Form autoComplete="off" id="item-edit" onSubmit={handleSubmit}>
                    <TextValid
                        label="Name"
                        fullWidth
                        value={name}
                        id="item-name"
                        onChange={( changed ) => setName( changed )}
                        validators={[Validators.required( name )]}
                    />
                    <SelectValid
                        id="item-category"
                        value={productCategoryName}
                        label="Product Category"
                        fullWidth
                        validators={[]}
                        onChange={setProductCategoryName}
                    >
                        <MenuItem value={''}/>
                        {productCategories.map( ( item ) => (
                            <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                        ) )}
                    </SelectValid>
                    {selectedCategory && (
                        <>
                            <SelectValid
                                id="item-strain"
                                value={strainId}
                                label="Strain"
                                fullWidth
                                onChange={setStrainId}
                                validators={selectedCategory.RequiresStrain ? [Validators.required( strainId || '' )] : []}
                            >
                                <MenuItem
                                    value={undefined}
                                >{selectedCategory.RequiresStrain ? '' : 'Multi Strain'}</MenuItem>
                                {strains.map( ( item ) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ) )}
                            </SelectValid>
                            {selectedCategory.QuantityType !== 'CountBased' && (
                                <SelectValid
                                    id="item-unit"
                                    value={unitOfMeasure}
                                    label="Unit Of Measure"
                                    fullWidth
                                    onChange={setUnitOfMeasure}
                                    validators={[Validators.required( unitOfMeasure )]}
                                >
                                    <MenuItem value={''}/>
                                    {units.filter( unit => selectedCategory && unit.QuantityType === selectedCategory.QuantityType ).map( ( item ) => (
                                        <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                                    ) )}
                                </SelectValid>
                            )}
                            {selectedCategory.RequiresUnitVolume && (
                                <>
                                    <SelectValid
                                        id="item-unit-volume"
                                        value={volumeUnit}
                                        label="Volume Of a Unit"
                                        fullWidth
                                        onChange={setVolumeUnit}
                                        validators={[Validators.required( volumeUnit || '' )]}
                                    >
                                        <MenuItem value={''}/>
                                        {units.filter( unit => unit.QuantityType === 'VolumeBased' ).map( ( item ) => (
                                            <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                                        ) )}
                                    </SelectValid>
                                    <TextValid
                                        id="item-quantity-volume"
                                        value={volumeQuantity}
                                        label="Quantity Of a Unit"
                                        fullWidth
                                        onChange={setVolumeQuantity}
                                        validators={[Validators.required( volumeQuantity || '' )]}
                                    />
                                </>
                            )}
                            {selectedCategory.RequiresUnitWeight && (
                                <>
                                    <SelectValid
                                        id="item-unit-weight"
                                        value={weightUnit}
                                        label="Weight Of a Unit"
                                        fullWidth
                                        onChange={setWeightUnit}
                                        validators={[Validators.required( weightUnit || '' )]}
                                    >
                                        <MenuItem value={''}/>
                                        {units.filter( unit => unit.QuantityType === 'WeightBased' ).map( ( item ) => (
                                            <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                                        ) )}
                                    </SelectValid>
                                    <TextValid
                                        id="item-quantity-weight"
                                        value={weightQuantity}
                                        label="Quantity Of a Unit"
                                        fullWidth
                                        onChange={setWeightQuantity}
                                        validators={[Validators.required( weightQuantity || '' )]}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBack} color="primary">
                    Cancel
                </Button>
                <Button type="submit" form="item-edit" color="primary">
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateItem;