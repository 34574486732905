import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IFacility } from '@shared/interfaces/lib/interfaces';
import { IMetrcHarvest } from '@shared/interfaces/lib/metrcInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import { FirebaseContext } from '../../../firebase_init';
import { Paper } from '@material-ui/core';

interface IProps {
    facility: IFacility;
}

const DashHarvest: React.FC<IProps> = ( { facility } ) => {
    const context = useContext( FirebaseContext );
    const [harvests, setHarvests] = useState<IMetrcHarvest[]>( [] );

    useEffect( () => {
        context.firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcHarvests )
            .where( 'metrc.FinishedDate', '==', null )
            .onSnapshot( ( snap ) => {
                setHarvests( snap.docs.map( ( item ) => item.data() as IMetrcHarvest ) );
            } );
    }, [facility.id] );

    return (
        <Paper
            onClick={() => navigate( '/facility/' + facility.id + '/harvests' )} elevation={0} variant="outlined" square
            style={{ padding: '1em' }}
        >
            <Typography variant="h5" style={{ display: 'inline' }}>
                <strong>
                    Harvests
                </strong>
            </Typography>
            <Typography variant="body2" component="p" style={{ display: 'inline' }}>
                <strong>
                    &nbsp;/ {harvests.length} Harvests in progress
                </strong>
            </Typography>
        </Paper>
    );
};

export default DashHarvest;
