import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IProps } from './interfaces';

const VegetationDetails: React.FC<IProps> = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {/*<WaterEdge systems={room.systems} now={now}/>*/}
            </Grid>
            <Grid item xs={6}>
                {/*<NextTill text="Days Till Full Vegetation" till={edgeTimes.daysTillFullVegetation}/>*/}
            </Grid>
        </Grid>
    );
};

export default VegetationDetails;