import { Form, Validators } from '@happybandit/react-validation';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SelectValid from '../../../../../../components/SelectValid';
import { selectFacilities, selectRooms } from '../../../../../../redux/appSlice';
import { IPlace } from '../index';

interface IProps {
    fromPlace: IPlace;
    toPlace: IPlace;
    onClose: () => void;
    onNext: (from: IPlace, to: IPlace) => void;
}

const MoveStockLocation: React.FC<IProps> = ( { onClose, onNext, toPlace, fromPlace } ) => {
    const [fromFacility, setFromFacility] = useState<string>(fromPlace.facilityId);
    const [toFacility, setToFacility] = useState<string>(toPlace.facilityId);
    const [fromRoom, setFromRoom] = useState<string>('');
    const [toRoom, setToRoom] = useState<string>('');
    const roomRecord = useSelector( selectRooms );
    const facilities = useSelector( selectFacilities );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        onNext({
            facilityId: fromFacility,
            roomId: fromRoom || undefined,
        }, {
            facilityId: toFacility,
            roomId: toRoom || undefined,
        })
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Where from and to will stock be moving
                </DialogContentText>
                <Form id="stock-move-location-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4">From Location</Typography>
                            <SelectValid
                                onChange={(value) => {
                                    setFromFacility(value);
                                    setFromRoom('');
                                }}
                                id="stock-move-from-facility"
                                value={fromFacility}
                                validators={[
                                    Validators.required(fromFacility),
                                ]}
                                fullWidth
                            >
                                {facilities.map((facility) => (
                                    <MenuItem
                                        key={facility.id}
                                        value={facility.id}
                                    >
                                        {facility.metrc.DisplayName}
                                    </MenuItem>
                                ))}
                            </SelectValid>
                            <SelectValid
                                onChange={setFromRoom}
                                id="stock-move-from-room"
                                disabled={!fromFacility}
                                value={fromRoom}
                                validators={[]}
                                fullWidth
                            >
                                <MenuItem value={''}>Facility Storage</MenuItem>
                                {(roomRecord[fromFacility] || []).map((room) => (
                                    <MenuItem
                                        key={room.id}
                                        value={room.id}
                                    >
                                        {room.metrc.Name}
                                    </MenuItem>
                                ))}
                            </SelectValid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4">To Location</Typography>
                            <SelectValid
                                onChange={(value) => {
                                    setToFacility(value);
                                    setToRoom('');
                                }}
                                id="stock-move-to-facility"
                                value={toFacility}
                                validators={[
                                    Validators.required(toFacility),
                                ]}
                                fullWidth
                            >
                                {facilities.map((facility) => (
                                    <MenuItem
                                        key={facility.id}
                                        value={facility.id}
                                    >
                                        {facility.metrc.DisplayName}
                                    </MenuItem>
                                ))}
                            </SelectValid>
                            <SelectValid
                                onChange={setToRoom}
                                id="stock-move-to-room"
                                disabled={!toFacility}
                                value={toRoom}
                                validators={[]}
                                fullWidth
                            >
                                {(roomRecord[toFacility] || []).map((room) => (
                                    <MenuItem
                                        key={room.id}
                                        value={room.id}
                                    >
                                        {room.metrc.Name}
                                    </MenuItem>
                                ))}
                            </SelectValid>
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button type="submit" form="stock-move-location-form" color="primary">
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default MoveStockLocation;