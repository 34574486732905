import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from 'firebase_init';
import { IPlant } from '@shared/interfaces/lib/interfaces';
import { buildArray } from 'utils';
import { selectUser } from '../../../../../redux/appSlice';
import FacilityContext from '../../../facilityContext';
import Rack from '../rack';
import { ISystemProps } from '../index';
import EditButton from '../editButton';
import SystemHeader from '../header';
import Environment from '../environment';
import { AddNutrientsNew } from '../nutrients';
import Water from '../water';
import SystemLights from '../lights';

const PlantSystem: React.FC<ISystemProps> = ( { system, onSystemChange, room } ) => {
    const context = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const [canEdit, setCanEdit] = useState<boolean>( false );
    const [plants, setPlants] = useState<IPlant[]>( [] );
    const user = useSelector(selectUser);

    useEffect( () => {
        context.firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcPlants )
            .where( 'location.systemId', '==', system.id )
            .where( 'metrc.HarvestedDate', '==', null )
            .where( 'metrc.DestroyedDate', '==', null )
            .onSnapshot( ( snap ) => setPlants( snap.docs.map( ( doc ) => doc.data() as IPlant) ) );
    }, [] )

    if ( !user ) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Paper elevation={0} variant="outlined" square>
                <Box p={2}>
                    <SystemHeader system={system}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <EditButton
                                room={room}
                                system={system}
                                onClick={() => setCanEdit( !canEdit )}
                            />
                            <AddNutrientsNew room={room} systemId={system.id}/>
                            <Environment
                                edit={canEdit}
                                systemId={system.id}
                                roomId={room.id}
                            />
                            <Water
                                system={system}
                                roomId={room.id}
                                edit={canEdit}
                                onSystemChange={onSystemChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={10}>
                            {buildArray( system.size.rackCount, ( index ) => {
                                const rackPlants = plants.filter( ( plant ) => plant.location && plant.location.rack === index );
                                return (
                                    <Rack
                                        key={index}
                                        rackIndex={index}
                                        plants={rackPlants}
                                        system={system}
                                    />
                                );
                            } )}
                        </ Grid>
                        <Grid item xs={12}>
                            <SystemLights
                                room={room}
                                system={system}
                                onSystemChange={onSystemChange}
                                edit={canEdit}
                            />
                        </ Grid>
                    </ Grid>
                </ Box>
            </ Paper>
        </ Grid>
    );
};

export default PlantSystem;
