import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IProps } from './interfaces';

const MotherDetails: React.FC<IProps> = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {/*<NextTill text="Hours Till Next Watering" till={edgeTimes.hoursTillNextWatering}/>*/}
            </Grid>
            <Grid item xs={6}>
                {/*<NextTill text="Hours Till Next Feeding" till={edgeTimes.hoursTillNextFeed}/>*/}
            </Grid>
        </Grid>
    );
};

export default MotherDetails;
