import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { selectConsumables, selectEquipment, selectUser } from '../../../redux/appSlice';

import { makeStyles, List, ListItemText } from '@material-ui/core';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { useSelectedFacility } from '../../../utils';
import InventoryStock from './inventoryStock';
import { InventoryDetailNew } from '../../../components/inventoryDetailDialog';
import { IEquipmentItem } from '@shared/interfaces/lib/inventoryInterfaces';
import FacilityEquipmentRow from './equipmentRow';
import FacilityConsumableRow from './consumableRow';

const useStyles = makeStyles( () => ({
    section: {
        paddingTop: '2em',
        paddingBottom: '3em',
    },
    root: {
        width: '100%',
        '& li:first-child': {
            borderTop: '1px solid lightgrey',
        },
        '& li:last-child': {
            borderBottom: 'none',
        }
    },
}) );


const Inventory: React.FC<RouteComponentProps<{ facilityId: string }>> = ( { facilityId } ) => {
    const classes = useStyles();
    const user = useSelector( selectUser );
    const equipment = useSelector( selectEquipment );
    const consumables = useSelector( selectConsumables );
    const facility = useSelectedFacility(  item => item.id === facilityId );
    const facilityEquipment = equipment.filter( ( item ) => item.facilityId === facilityId );

    if ( !facility ) {
        navigate( '/' );
        return null;
    }

    const groupedEquipment = facilityEquipment.reduce( ( acc, item ) => {
        const found = acc.get( item.inventoryId );
        if ( found ) {
            acc.set( item.inventoryId, [...found, item] );
        } else {
            acc.set( item.inventoryId, [item] );
        }
        return acc;
    }, new Map<string, IEquipmentItem[]>() );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: 'inline' }}>
                        <strong>
                            {facility.metrc.DisplayName}
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ Inventory
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    {user && user.isAllowed( UserPermissions.generalEdit ) && (
                        <>
                            <InventoryDetailNew/>
                            <InventoryStock facilityId={facility.id}/>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid component="section" container spacing={3} id={facility.id + '-inventory'} className={classes.section}>
                <List
                    component="nav"
                    className={classes.root}
                >
                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Equipment
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ {groupedEquipment.size} items
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    {groupedEquipment.size === 0 && (
                        <ListItemText primary="No Inventory Setup Yet"/>
                    )}
                    {[...groupedEquipment].map( ( [inventoryId, items] ) => (
                        <FacilityEquipmentRow key={inventoryId} inventoryId={inventoryId} equipment={items}/>
                    ) )}
                    <br/>
                    <br/>
                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Consumables
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ {consumables.length} items
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    {consumables.length === 0 && (
                        <ListItemText primary="No Inventory Setup Yet"/>
                    )}
                    {consumables.map( ( consumable ) => (
                        <FacilityConsumableRow key={consumable.id} facilityId={facility.id} consumable={consumable}/>
                    ) )}
                </List>
            </Grid>
        </>
    );
};

export default Inventory;