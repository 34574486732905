import React from 'react';
import { navigate } from '@reach/router';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { IRoom, RoomTypes } from '@shared/interfaces/lib/interfaces';

import MotherDetails from './Mother';
import FlowerDetails from './Flower';
import VegetationDetails from './Vegetation';
import DryDetails from './Dry';
import { capitalize } from 'lodash';

interface IProps {
    room: IRoom;
    facilityId: string;
}

const DashRoom: React.FC<IProps> = ( { room, facilityId } ) => {
    const types = (room.types || [])
    return (
        <Paper onClick={() => navigate( `facility/${facilityId}/room/${room.id}` )} elevation={ 0 } variant="outlined" square>
            <Box p={ 2 }>
                <Grid container spacing={0}>
                    <Grid item>
                        <Typography variant="h4" style={{ display: 'inline' }}>
                            <strong>
                                {room.metrc.Name}
                            </strong>
                        </Typography>
                        <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                            &nbsp;/ { capitalize( types.join(' | ') ) }
                        </Typography>
                    </Grid>
                    {types.includes(RoomTypes.mother) && (
                        <MotherDetails room={ room } />
                    )}
                    {types.includes(RoomTypes.vegetation) && (
                        <VegetationDetails room={ room } />
                    )}
                    {types.includes(RoomTypes.flower) && (
                        <FlowerDetails room={ room } />
                    )}
                    {types.includes(RoomTypes.dry) && (
                        <DryDetails room={ room } />    
                    )}
                </Grid>
            </Box>
        </Paper>
    );
};

export default DashRoom;