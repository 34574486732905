import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRoom, } from '@shared/interfaces/lib/interfaces';
import { selectUser } from '../../../../redux/appSlice';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { useRoomStyles } from '../shared';
import EditEnvironment from './editEnvironment';
import { navigate } from '@reach/router';

interface IProps {
    room: IRoom;
}

const RoomEnvironment: React.FC<IProps> = ({room}) => {
    const classes = useRoomStyles();
    const [edit, setEdit] = useState( false );
    const user = useSelector( selectUser );

    return (
        <section id={room.metrc.Name + '-environment'} className={classes.section}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ display: 'inline' }}>
                        <strong>
                            Environment
                        </strong>
                    </Typography>
                    {user && user.isAllowed(UserPermissions.generalEdit) && (
                        <>
                           <Link onClick={() => navigate( '/environment/' + room.id )} variant="body1">
                                <strong>
                                    <span style={{ 'color': 'black' }}>&nbsp;/</span> View
                                </strong>
                            </Link>
                            <Link href="#" onClick={() => setEdit(true)} variant="body1">
                                <strong>
                                    <span style={{ 'color': 'black' }}>&nbsp;/</span> Edit
                                </strong>
                            </Link>
                        </>
                    )}
                    <hr style={{ 'borderTop': '#eee' }}/>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
                {/*<EnvironmentHistory*/}
                {/*    roomId={room.id}*/}
                {/*    type="temperature"*/}
                {/*/>*/}
                {/*<EnvironmentHistory*/}
                {/*    roomId={room.id}*/}
                {/*    type="humidity"*/}
                {/*/>*/}
                {/*<EnvironmentHistory*/}
                {/*    roomId={room.id}*/}
                {/*    type="vpd"*/}
                {/*/>*/}
                {/*<EnvironmentHistory*/}
                {/*    roomId={room.id}*/}
                {/*    type="co2"*/}
                {/*/>*/}
                {/*<EnvironmentHistory*/}
                {/*    roomId={room.id}*/}
                {/*    type="lightLevel"*/}
                {/*/>*/}
            </Grid>
            {edit && (
                <EditEnvironment
                    open={true}
                    roomId={room.id}
                    onClose={() => setEdit( false )}
                />
            )}
        </section>
    );
};

export default RoomEnvironment;
