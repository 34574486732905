import { HarvestType, IHarvest, IPackage, IPlant, IPlantBatch } from '@shared/interfaces/lib/interfaces';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';

export enum PlantDetailPages {
    details,
    get,
    getHarvest,
    destroyPlant,
    harvestPlant,
    harvestDetails,
    harvestWaste,
    harvestFinish,
    packageFinish,
    toPackage,
    changeItem,
    changeNote,
    adjustQuantity,
    move,
    clone,
    toVeg,
}

export interface IItemDetailsGet {
    page: PlantDetailPages.get;
}

export interface IPlantDetailsHarvestOther {
    dryRoomId?: string;
    type?: HarvestType;
}

export interface IDetailKeyBase {
    id: string;
}

export interface IPlantDetailKey extends IDetailKeyBase {
    collection: MetrcFacilitySubCollections.metrcPlants;
}

export interface IPlantBatchDetailKey extends IDetailKeyBase {
    collection: MetrcFacilitySubCollections.metrcPlantBatches;
}

export interface IPackageDetailKey extends IDetailKeyBase {
    collection: MetrcFacilitySubCollections.metrcPackages;
}

export interface IHarvestDetailKey extends IDetailKeyBase {
    collection: MetrcFacilitySubCollections.metrcHarvests;
}

export interface IPlantDetailItemFacility {
    facilityId: string;
}

export interface IPlantDetailsHarvest {
    page: PlantDetailPages.getHarvest | PlantDetailPages.harvestPlant;
    key: IPlantDetailKey;
    other: IPlantDetailsHarvestOther;
}

export interface IPlantDetailsHarvestItem extends IPlantDetailsHarvest, IPlantDetailItemFacility {
    item?: IPlant;
}

export interface IPlantDestroy {
    page: PlantDetailPages.destroyPlant;
    key: IPlantDetailKey;
}

export interface IPlantDestroyItem extends IPlantDestroy, IPlantDetailItemFacility {
    item?: IPlant;
}

export interface IItemDetail {
    key: IPlantDetailKey | IPlantBatchDetailKey | IPackageDetailKey;
    page: PlantDetailPages.details | PlantDetailPages.move;
}

export interface IItemDetailItem extends IItemDetail, IPlantDetailItemFacility {
    item?: IPlant | IPlantBatch | IPackage;
}

export interface IHarvestDetail {
    key: IHarvestDetailKey;
    page: PlantDetailPages.harvestDetails | PlantDetailPages.harvestWaste | PlantDetailPages.toPackage | PlantDetailPages.move;
}

export interface IHarvestDetailItem extends IHarvestDetail, IPlantDetailItemFacility {
    item?: IHarvest;
}

export interface IPackageToPackageDetail {
    key: IPackageDetailKey;
    page: PlantDetailPages.toPackage | PlantDetailPages.changeItem | PlantDetailPages.changeNote | PlantDetailPages.adjustQuantity;
}

export interface IPackageToPackageDetailItem extends IPackageToPackageDetail, IPlantDetailItemFacility {
    item?: IPackage;
}

export interface IHarvestFinish {
    key: IHarvestDetailKey;
    page: PlantDetailPages.harvestFinish;
    undo: boolean;
}

export interface IHarvestFinishItem extends IHarvestFinish, IPlantDetailItemFacility {
    item?: IHarvest;
}

export interface IPackageFinish {
    key: IPackageDetailKey;
    page: PlantDetailPages.packageFinish;
    undo: boolean;
}

export interface IPackageFinishItem extends IPackageFinish, IPlantDetailItemFacility {
    item?: IPackage;
}

export interface IPlantClone {
    key: IPlantDetailKey;
    page: PlantDetailPages.clone;
}

export interface IPlantCloneItem extends IPlantClone, IPlantDetailItemFacility {
    item?: IPlant;
}

export interface IPlantCloneToVeg {
    key: IPlantBatchDetailKey;
    page: PlantDetailPages.toVeg;
}

export interface IPlantCloneToVegItem extends IPlantCloneToVeg, IPlantDetailItemFacility {
    item?: IPlantBatch;
}

export type IItemDetails = IItemDetailsGet
    | IPlantDetailsHarvest
    | IItemDetail
    | IPlantClone
    | IPlantCloneToVeg
    | IHarvestDetail
    | IHarvestFinish
    | IPackageFinish
    | IPackageToPackageDetail
    | IPlantDestroy;

export type IItemDetailItems = IItemDetailsGet
    | IPlantDetailsHarvestItem
    | IItemDetailItem
    | IPlantCloneItem
    | IPlantCloneToVegItem
    | IHarvestDetailItem
    | IHarvestFinishItem
    | IPackageFinishItem
    | IPackageToPackageDetailItem
    | IPlantDestroyItem;

export interface IShowTask {
    id?: string;
    edit: boolean;
}

export interface IShowPlantDetailItem {
    item: IPlant | IPlantBatch | IPackage | IHarvest;
    facilityId: string;
}

export interface IShowArticle {
    show: boolean;
    articleId?: string;
}