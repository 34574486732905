import { Form, Validators } from '@happybandit/react-validation/dist';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ToggleButton } from '@material-ui/lab';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import TextValid from '../../TextValid';

const pressMultiplier = 2.75;

enum Directions {
    ToGauge,
    ToPuck,
}

const RosinCalc: React.FC = () => {
    const [direction, setDirection] = useState<Directions>( Directions.ToGauge );
    const [pressure, setPressure] = useState<string>( '' );
    const [width, setWidth] = useState<string>( '' );
    const [depth, setDepth] = useState<string>( '' );
    const [sum, setSum] = useState<number | undefined>( undefined );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );

    const handleClose = () => {
        setAnchorEl( null );
        setWidth('');
        setDepth('');
        setPressure('');
        setSum(undefined);
    };

    const handleDirectionChange = (event: React.MouseEvent<HTMLElement>, value: Directions) => {
        setDirection(value);
        setPressure('');
        setSum(0);
    };

    const getPuckPressure = () => {
        return (pressMultiplier * parseFloat( pressure )) / (parseFloat( width ) * parseFloat( depth ));
    };

    const getGaugePressure = () => {
        return (parseFloat( pressure ) * parseFloat( width ) * parseFloat( depth )) / pressMultiplier;
    };

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        setSum( direction === Directions.ToPuck ? getPuckPressure() : getGaugePressure() );
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean( anchorEl )}
            onClose={handleClose}
        >
            <Box mx={2}>
                <ListItemText
                    primary="Calculate Rosin Pressure"
                    secondary="Use to make presses consistent"
                />
                <ToggleButtonGroup
                    value={direction}
                    exclusive
                    onChange={handleDirectionChange}
                    aria-label="text alignment"
                >
                    <ToggleButton value={Directions.ToGauge}>
                        Get Gauge Pressure
                    </ToggleButton>
                    <ToggleButton value={Directions.ToPuck}>
                        Get Puck Pressure
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Form onSubmit={handleSubmit}>
                <Box mx={2}>
                    <TextValid
                        id="rosin-gauge"
                        label={direction === Directions.ToPuck ? 'Gauge Pressure' : 'Puck Pressure Goal'}
                        type="number"
                        value={pressure}
                        onChange={( value ) => setPressure( value )}
                        fullWidth
                        validators={[
                            Validators.required( pressure ),
                            Validators.positive( pressure ),
                        ]}
                    />
                </Box>
                <Box mx={2}>
                    <TextValid
                        id="rosin-width"
                        label="Puck Width (inches)"
                        type="number"
                        value={width}
                        onChange={( value ) => setWidth( value )}
                        fullWidth
                        validators={[
                            Validators.required( width ),
                            Validators.positive( width ),
                        ]}
                    />
                </Box>
                <Box mx={2}>
                    <TextValid
                        id="rosin-depth"
                        label="Puck Depth (inches)"
                        type="number"
                        value={depth}
                        onChange={( value ) => setDepth( value )}
                        fullWidth
                        validators={[
                            Validators.required( depth ),
                            Validators.positive( depth ),
                        ]}
                    />
                </Box>
                <Divider component="li"/>
                <Button
                    color="primary"
                    type="submit"
                    fullWidth
                >
                    {direction === Directions.ToPuck ? 'Calculate PSI on Puck' : 'Calculate Gauge Reading Needed'}
                </Button>
            </Form>
            <Divider component="li"/>
            <ListItem>
                <ListItemText
                    primary={direction === Directions.ToPuck ? 'PSI on Puck' : 'Gauge Reading Needed'}
                    secondary={sum ? sum + ' psi' : 'Click to Calculate'}
                />
            </ListItem>
        </Menu>
    );

    return (
        <>
            <IconButton
                style={{ marginRight: 10 }}
                edge="end"
                aria-label="rosin calc"
                aria-haspopup="true"
                color="inherit"
                onClick={( event: React.MouseEvent<HTMLButtonElement> ) => setAnchorEl( event.currentTarget )}
            >
                <FitnessCenterIcon/>
            </IconButton>
            {renderMenu}
        </>
    );
};

export default RosinCalc;
