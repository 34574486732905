import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { Form, Validators } from '@happybandit/react-validation';
import firebase from 'firebase/app';
import { FirebaseContext } from '../../firebase_init';
import { setError } from '../../redux/appSlice';
import SuccessSnack from '../../components/successSnack';
import TextValid from '../../components/TextValid';
import { mediumRegex, passwordConfirmText, passwordText } from '../../utils';
import UserAvatar from '../../components/userAvatar';

const useStyles = makeStyles( ( theme: Theme ) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing( 8 ),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    chip: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing( 1 ),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing( 1 ),
    },
    submit: {
        margin: theme.spacing( 3, 0, 2 ),
    },
}) );

const RedChip = withStyles( ( theme: Theme ) => ({
    root: {
        color: theme.palette.getContrastText( red[500] ),
        backgroundColor: red[500],
        '&:focus': {
            backgroundColor: red[700],
        },
    },
}) )( Chip );

const GreenChip = withStyles( ( theme: Theme ) => ({
    root: {
        color: theme.palette.getContrastText( green[500] ),
        backgroundColor: green[500],
    },
}) )( Chip );

const Account: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const context = useContext( FirebaseContext );
    const [showSuccess, setShowSuccess] = useState( false );

    const user = context.auth.currentUser;

    const [email, setEmail] = useState( (user && user.email) || '' );
    const [name, setName] = useState( (user && user.displayName) || '' );
    const [hasPassword, setHasPassword] = useState( false );
    const [password, setPassword] = useState( '' );
    const [confirmPassword, setConfirmPassword] = useState( '' );

    useEffect( () => {
        if ( user && user.email ) {
            context.auth.fetchSignInMethodsForEmail( user.email )
                .then( ( methods ) => setHasPassword( methods.includes( 'password' ) ) );
        }
    }, [user, context] );

    if ( !user ) {
        return null;
    }

    const isValidPassword = () => mediumRegex.test( password );
    const passwordsMatch = () => password === confirmPassword;

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        const emailPromise = user.updateEmail( email )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );
        const accountPromise = user.updateProfile( {
            displayName: name,
        } ).catch( ( e: Error ) => {
            dispatch( setError( e.message ) );
        } );

        Promise.all( [emailPromise, accountPromise] )
            .then( () => {
                setShowSuccess( true );
            } );
    };

    const handlePasswordSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        const credential = firebase.auth.EmailAuthProvider.credential( email, password );
        user.linkWithCredential( credential )
            .then(() => setHasPassword(true))
            .catch( ( e ) => {
            dispatch( setError( e.message ) );
        } );
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <UserAvatar id={user.uid} name={user.displayName} hasLink={false}/>
                <Typography component="h1" variant="h5">
                    User Account
                </Typography>
                <Form method="POST" className={classes.form} noValidate onSubmit={handleSubmit}>
                    <div className={classes.chip}>
                        {user.emailVerified ? (
                            <GreenChip
                                color="primary"
                                label="Email Verified!"
                                icon={<CheckIcon/>}
                            />
                        ) : (
                            <RedChip
                                color="secondary"
                                label="Email not verified, send again?"
                                deleteIcon={<ArrowForwardIcon/>}
                                onDelete={() => user.sendEmailVerification()}
                                icon={<ErrorIcon/>}
                            />
                        )}
                    </div>
                    <TextValid
                        fullWidth
                        name="name"
                        label="Display Name"
                        id="name"
                        autoComplete="full-name"
                        value={name}
                        onChange={( value ) => setName( value )}
                        validators={[Validators.required( name )]}
                    />
                    <TextValid
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={( value ) => setEmail( value )}
                        validators={[Validators.required( email ), Validators.email( email )]}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Update Account
                    </Button>
                </Form>

                {hasPassword ? (
                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={() => context.auth.sendPasswordResetEmail( user.email || '' )}
                    >
                        Send Password Reset Email
                    </Button>
                ) : (
                    <>
                        <Typography component="h1" variant="h5">
                            Set Password
                        </Typography>
                        <Form method="POST" className={classes.form} noValidate onSubmit={handlePasswordSubmit}>
                            <TextValid
                                fullWidth
                                name="password"
                                label="New Password"
                                type="password"
                                id="password"
                                value={password}
                                onChange={( value ) => setPassword( value )}
                                validators={[Validators.required( password ), Validators.custom( isValidPassword, passwordText )]}
                            />
                            <TextValid
                                fullWidth
                                name="confirm_password"
                                label="Confirm Password"
                                type="password"
                                id="confirm_password"
                                value={confirmPassword}
                                onChange={( value ) => setConfirmPassword( value )}
                                validators={[Validators.required( confirmPassword ), Validators.custom( passwordsMatch, passwordConfirmText )]}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Set Password
                            </Button>
                        </Form>
                    </>
                )}
            </div>
            {showSuccess && (
                <SuccessSnack onClose={() => setShowSuccess( false )}/>
            )}
        </Container>
    );
};

export default Account;
