import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        message: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing( 1 ),
        }
    } ),
);

interface IProps {
    onClose: () => void
}

const SuccessSnack: React.FC<IProps> = ( { onClose } ) => {
    const classes = useStyles();
    return (
        <Snackbar
            open={true}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            autoHideDuration={5000}
            onClose={onClose}
        >
            <SnackbarContent
                aria-describedby="message-id"
                message={
                    <span id="message-id" className={classes.message}>
                        <CheckIcon className={classes.icon}/>
                        Saved Successfully!
                    </span>
                }
            />
        </Snackbar>
    )
};

export default SuccessSnack;
