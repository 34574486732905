import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { IDestroyPlantBody } from '@shared/interfaces/lib/contractInterfaces';
import {
    Collections,
    DirectPaths,
    MetrcFacilityConstantDoc,
    MetrcFacilitySubCollections
} from '@shared/interfaces/lib/firebaseConstants';
import { IPlant, IRecord } from '@shared/interfaces/lib/interfaces';
import { IUnitsOfMeasure, IMetrcTypeNames, IPlantWasteReasons } from '@shared/interfaces/lib/metrcInterfaces';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postDestroyPlant } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';

interface IProps {
    plant: IPlant;
    facilityId: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            minHeight: 100,
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);

const PlantDestroy: React.FC<IProps> = ( { plant, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { firestore } = useContext( FirebaseContext );
    const user = useSelector( selectUser );
    const [methods, setMethods] = useState<IMetrcTypeNames[]>( [] );
    const [reasons, setReasons] = useState<IPlantWasteReasons[]>( [] );
    const [units, setUnits] = useState<IUnitsOfMeasure[]>( [] );
    const [method, setMethod] = useState( '' );
    const [reason, setReason] = useState( '' );
    const [note, setNote] = useState( '' );
    const [unit, setUnit] = useState( 'Grams' );
    const [weight, setWeight] = useState( '' );
    const date = useMemo( () => DateTime.local(), [] );

    useEffect( () => {
        firestore.doc( DirectPaths.metrcUnitsOfMeasure )
            .get()
            .then( docs => {
                const data = docs.data() as IRecord<IUnitsOfMeasure>;
                if ( data && data.data ) {
                    setUnits( data.data );
                }
            } );
        firestore.doc( DirectPaths.metrcPlantWasteMethods )
            .get()
            .then( docs => {
                const data = docs.data() as IRecord<IMetrcTypeNames>;
                if ( data && data.data ) {
                    setMethods( data.data );

                    if(data.data.length === 1){
                        setMethod(data.data[0].Name);
                    }
                }
            } );
    }, [] );

    useEffect( () => {
        firestore.collection( Collections.metrcFacilities ).doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcConstants ).doc( MetrcFacilityConstantDoc.metrcPlantWasteReasons )
            .get()
            .then( docs => {
                const data = docs.data() as IRecord<IPlantWasteReasons>;
                if ( data && data.data ) {
                    setReasons( data.data );

                    if(data.data.length === 1){
                        setReason(data.data[0].Name);
                    }
                }
            } );
    }, [facilityId] );

    if ( !plant ) {
        return null;
    }

    const handleSubmit = () => {
        if ( !user ) {
            return;
        }

        const body: IDestroyPlantBody = {
            method,
            reason,
            note,
            unitOfWeight: unit,
            facilityId,
            weight: parseFloat( weight ),
            date: date.toISODate(),
            label: plant.id,
        }
        postDestroyPlant( user.token, body )
            .then( () => {
                dispatch( setShowPlantDetails( {
                    key: {
                        collection: MetrcFacilitySubCollections.metrcPlants,
                        id: plant.id,
                    },
                    page: PlantDetailPages.details,
                } ) )
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Destroy Plant
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {plant.metrc.HarvestedDate || plant.metrc.DestroyedDate ? (
                    <Typography variant="h5">Not Valid for Destroying</Typography>
                ) : (
                    <>
                        <Typography variant="h5">
                            Plant is marked permanently destroyed in the system,
                            be careful as no other changes or actions can happen
                            to the plant after this
                        </Typography>
                        <Typography variant="body2">
                            Plant Label: {plant.metrc.Label}
                        </Typography>
                        <hr style={{ 'borderTop': '#eee' }}/>
                        <SelectValid
                            id="destroy-reason"
                            value={reason}
                            label="Reason for Destruction"
                            fullWidth
                            validators={[]}
                            onChange={setReason}
                        >
                            <MenuItem value={''}/>
                            {reasons.map( ( item ) => (
                                <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <SelectValid
                            id="destroy-method"
                            value={method}
                            label="Method of Destruction"
                            fullWidth
                            validators={[]}
                            onChange={setMethod}
                        >
                            <MenuItem value={''}/>
                            {methods.map( ( item ) => (
                                <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <TextValid
                            type="number"
                            value={weight}
                            onChange={setWeight}
                            id="destroy-weight"
                            label="Weight of Destroyed Material"
                            validators={[]}
                            fullWidth
                        />
                        <SelectValid
                            id="destroy-unit"
                            value={unit}
                            label="Unit of Weight"
                            fullWidth
                            validators={[]}
                            onChange={setUnit}
                        >
                            <MenuItem value={''}/>
                            {units.filter( unit => unit.QuantityType === 'WeightBased' ).map( ( item ) => (
                                <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <TextValid
                            value={note}
                            onChange={setNote}
                            id="destroy-note"
                            label="Extra Notes"
                            validators={[]}
                            fullWidth
                            multiline
                        />
                        <hr style={{ 'borderTop': '#eee' }}/>
                        <Button
                            color="primary"
                            disabled={!method || parseFloat( weight ) <= 0 || !unit || !reason}
                            onClick={handleSubmit}
                        >
                            Submit Plant Destroy
                        </Button>
                    </>
                )}
            </div>
        </>
    )
};

export default PlantDestroy;