import { Collections, DirectPaths, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { useContext, useEffect } from 'react';
import { ISyncTask } from '../../functions/src/sync';
import { FirebaseContext } from './firebase_init';
import { IExternalContact } from './pages/externalContacts/interfaces';
import { ITest } from './pages/testing/interfaces';
import {
    setRooms,
    setStrains,
    setUsers,
    setError,
    setDone,
    setNotifications,
    setFacilities,
    setInfoCategories,
    setInventoryDetails,
    setConsumableItems,
    setEquipmentItems,
    setInventoryCategories,
    setExternalContacts,
    setTests,
    selectUser,
    setOrgSettings,
    setSyncTask, selectFacilities,
} from './redux/appSlice';
import {
    IFacility,
    IInfoCategory,
    INotification,
    IOrgSettings,
    IRoom,
    IStrain
} from '@shared/interfaces/lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from './api';
import {
    IConsumableItem,
    IEquipmentItem,
    IInventoryCategory,
    IInventoryDetail
} from '@shared/interfaces/lib/inventoryInterfaces';

export const unassignedId = 'unassigned';

export const useGetData = () => {
    const dispatch = useDispatch();
    const user = useSelector( selectUser );
    const facilities = useSelector( selectFacilities );
    const context = useContext( FirebaseContext );

    useEffect( () => {
        const listeners: (() => void)[] = [];

        listeners.push( context.firestore.doc( DirectPaths.orgSettings )
            .onSnapshot( ( snapshot ) => {
                dispatch( setOrgSettings( (snapshot.data() || {}) as IOrgSettings ) );
            } ) );

        return () => listeners.forEach( ( listener ) => listener() )
    }, [!user] );

    useEffect( () => {
        if(!user){
            return;
        }

        const listeners: (() => void)[] = [];

        if ( user.isActive() ) {
            listeners.push( context.firestore.doc( DirectPaths.orgMetrcSync )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setSyncTask( snapshot.data() as ISyncTask ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.strains )
                .orderBy( 'name', 'asc' )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setStrains( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as IStrain ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.tests )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setTests( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as ITest ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.inventoryDetails )
                .orderBy( 'name', 'asc' )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setInventoryDetails( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as IInventoryDetail ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.equipment )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setEquipmentItems( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as IEquipmentItem ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.consumables )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setConsumableItems( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as IConsumableItem ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.inventoryCategories )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setInventoryCategories( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as IInventoryCategory ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.externalContacts )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setExternalContacts( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as IExternalContact ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.users )
                .doc( user.uid )
                .collection( Collections.notifications )
                .orderBy( 'timestamp', 'desc' )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setNotifications( snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as INotification ) ) );
                } ) );
            listeners.push( context.firestore.collection( Collections.infoCategories )
                .orderBy( 'index', 'asc' )
                .onSnapshot( ( snapshot ) => {
                    const data = snapshot.docs.map( ( doc ) => ({
                        ...doc.data(),
                        id: doc.id,
                    }) as IInfoCategory );
                    dispatch( setInfoCategories( data.length > 0 ? data : [{
                        id: unassignedId,
                        index: 999,
                        name: 'Unassigned',
                        articles: [],
                    }] ) );
                } ) );

            const roomPromise = new Promise( ( resolve ) => {
                listeners.push( context.firestore.collection( Collections.metrcFacilities )
                    .orderBy( 'metrc.Name', 'asc' )
                    .onSnapshot( ( snapshot ) => {
                        dispatch( setFacilities( snapshot.docs.map( ( doc ) => ({
                            ...doc.data(),
                            id: doc.id,
                        }) as IFacility ) ) );
                        resolve();
                    } ) );
            } );

            const usersPromise = getUsers( user.token )
                .then( ( userDetails ) => dispatch( setUsers( userDetails ) ) )
                .catch( ( e: Error ) => dispatch( setError( e.message ) ) );

            Promise.all( [roomPromise, usersPromise] )
                .finally( () => dispatch( setDone() ) )
        } else {
            dispatch( setDone() );
        }

        return () => listeners.forEach( ( listener ) => listener() )
    }, [!user] );

    useEffect( () => {
        const listeners: (() => void)[] = [];
        facilities.forEach( fac => {
            listeners.push( context.firestore
                .collection( Collections.metrcFacilities )
                .doc( fac.id )
                .collection( MetrcFacilitySubCollections.metrcLocations )
                .where('active', '==', true)
                .orderBy( 'metrc.Name', 'asc' )
                .onSnapshot( ( snapshot ) => {
                    dispatch( setRooms( {
                        [fac.id]: snapshot.docs.map( ( doc ) => ({
                            ...doc.data(),
                            id: doc.id,
                        }) as IRoom )
                    } ) );
                } ) );
        } )
        return () => listeners.forEach( ( listener ) => listener() )
    }, [facilities.map( fac => fac.id ).join(':')] );
};