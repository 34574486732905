import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import TestingList from './list';

const Testing: React.FC<RouteComponentProps> = () => {
    return (
        <Router>
            <TestingList path="/" />
        </Router>
    );
};

export default Testing;
