import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import { IRoom, ISystem } from '@shared/interfaces/lib/interfaces';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../redux/appSlice';
import { UserPermissions } from '@shared/interfaces/lib/user';
import NewSystemDialog from '../newSystem/dialog';

interface IButtonProps {
    room: IRoom;
    system: ISystem;
    onClick: () => void;
}

const EditButton: React.FC<IButtonProps> = ( { room, system } ) => {
    const user = useSelector( selectUser );
    const [open, setOpen] = useState( false );

    if ( !user || !user.isAllowed( UserPermissions.generalEdit ) ) {
        return null;
    }

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                endIcon={<Edit/>}
                onClick={() => setOpen(true)}
                style={{ width: '100%', borderRadius: '0', marginTop: '1em', marginBottom: '1em' }}
            >
                Edit System
            </Button>
            {open && <NewSystemDialog room={room} system={system} onClose={() => setOpen( false )}/>}
        </>
    );
};

export default EditButton;