import React from 'react';
import ReactDOM from 'react-dom';
import {StylesProvider} from '@material-ui/core/styles';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import getStore from './redux/store';

const store = getStore();

ReactDOM.render(
    <StylesProvider injectFirst>
        <Provider store={store}>
            <App/>
        </Provider>
    </StylesProvider>
    , document.getElementById('root'));
