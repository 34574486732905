import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { IValidatorOutput } from '@happybandit/react-validation/dist/interfaces';
import { useInputValidation } from '@happybandit/react-validation/dist/hooks';

interface IProps extends Omit<CheckboxProps, 'onChange' | 'variant' | 'margin'> {
    value?: boolean;
    onChange: ( newValue: boolean ) => void;
    label?: string;
    id: string;
    validators: IValidatorOutput[];
    fullWidth?: boolean;
}

const CheckValid: React.FC<IProps> = ( { value = false, fullWidth = false, id, label, onChange, validators, ...otherProps } ) => {
    const { valid, message } = useInputValidation( validators );

    return (
        <FormControl error={!valid} component="fieldset" fullWidth={fullWidth}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={id}
                            checked={value}
                            onChange={( event ) => onChange( event.target.checked )}
                            {...otherProps}
                        />
                    }
                    label={label}
                />
            </FormGroup>
            {!valid && (
                <FormHelperText>{message}</FormHelperText>
            )}
        </FormControl>
    );
};

export default CheckValid;