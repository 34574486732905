import { Form, Validators } from '@happybandit/react-validation';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IConsumableItem, IEquipmentItem, Units } from '@shared/interfaces/lib/inventoryInterfaces';
import SelectValid from '../../../../../components/SelectValid';
import TextValid from '../../../../../components/TextValid';
import { FirebaseContext } from '../../../../../firebase_init';
import {
    selectConsumables,
    selectEquipment,
    selectInventoryDetails,
    selectUser,
    setError
} from '../../../../../redux/appSlice';
import {
    convertUnit,
    getTimeStamp,
    isConsumable,
    isDetailConsumable,
    isDetailEquipment,
    isEquipment,
    isNormalInteger,
    removeUndefined,
    useFacilityRooms,
    uuidv4
} from '../../../../../utils';

interface IProps {
    facilityId: string;
    onClose: () => void;
}

interface ITableProps {
    options: IOption[];
    currentStock: IStockRow[];
    onChange: ( row: IStockRow[] ) => void;
}

interface IStockRow {
    inventoryId?: string;
    quantity: string;
    unit?: Units;
}

interface IStockRowProps {
    index: number;
    item: IStockRow;
    options: IOption[];
    onChange: ( index: number, row: IStockRow ) => void;
}

interface IOption {
    name: string;
    count: number;
    id: string;
    unit: Units;
}

const emptyRow: IStockRow = {
    inventoryId: undefined,
    quantity: '',
    unit: undefined,
};

const StockRow: React.FC<IStockRowProps> = ( { index, item, options, onChange } ) => {
    const inventoryDetails = useSelector( selectInventoryDetails );

    const handleItemChange = ( row: IStockRow ) => {
        const inventoryDetail = inventoryDetails.find( ( detail ) => detail.id === row.inventoryId );

        onChange( index, {
            ...row,
            unit: inventoryDetail && isDetailConsumable( inventoryDetail ) ? inventoryDetail.unit : Units.Single,
        } );
    };

    const currentOption = options.find( ( option ) => option.id === item.inventoryId );
    const currentDetails = inventoryDetails.find( ( option ) => currentOption && option.id === currentOption.id );

    return (
        <TableRow>
            <TableCell>
                <SelectValid
                    id={`stock-move-item-${index}`}
                    value={item.inventoryId}
                    label="Name"
                    fullWidth
                    validators={[]}
                    onChange={( value ) => handleItemChange( {
                        ...item,
                        inventoryId: value,
                    } )}
                >
                    {options.map( ( option ) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                        >
                            {option.name}
                        </MenuItem>
                    ) )}
                </SelectValid>
            </TableCell>
            <TableCell>
                <SelectValid
                    id={`stock-move-unit-${index}`}
                    value={item.unit}
                    label="Unit"
                    fullWidth
                    onChange={( value ) => onChange( index, {
                        ...item,
                        unit: value as Units,
                    } )}
                    disabled={!currentDetails || isDetailEquipment( currentDetails )}
                    validators={currentOption && currentOption.unit !== Units.Single ? [Validators.required( item.unit || '' )] : []}
                >
                    {Object.entries( Units ).map( ( [key, value] ) => (
                        <MenuItem
                            key={key}
                            value={value}
                        >
                            {key}
                        </MenuItem>
                    ) )}
                </SelectValid>
            </TableCell>
            <TableCell>
                <TextValid
                    type="number"
                    id={`stock-add-quantity-${index}`}
                    label="Quantity"
                    autoComplete='none'
                    value={item.quantity}
                    disabled={!currentOption}
                    onChange={( value ) => onChange( index, {
                        ...item,
                        quantity: value,
                    } )}
                    validators={currentOption ? [
                        Validators.required( item.quantity.toString() ),
                        currentOption && currentOption.unit !== Units.Single ?
                            Validators.custom( () => parseFloat( item.quantity ) > 0, 'Must be a positive Number' ) :
                            Validators.custom( () => isNormalInteger( item.quantity ), 'Must be a positive Integer' ),
                        Validators.custom( () => !!currentOption && convertUnit( parseFloat( item.quantity ), item.unit, currentOption.unit ) <= currentOption.count, 'Not enough available' ),
                    ] : []}
                />
            </TableCell>
            <TableCell>
                {currentOption ? convertUnit( currentOption.count, currentOption.unit, item.unit ) : ''}
            </TableCell>
        </TableRow>
    );
};

const StockTable: React.FC<ITableProps> = ( { currentStock, onChange, options } ) => {
    const handleRow = ( index: number, row: IStockRow ) => {
        let newStock = [...currentStock];
        newStock.splice( index, 1, row );
        onChange( newStock );
    };

    return (
        <>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '30%' }}>Name</TableCell>
                        <TableCell style={{ width: '30%' }}>Unit</TableCell>
                        <TableCell style={{ width: '15%' }}>Needed</TableCell>
                        <TableCell style={{ width: '15%' }}>Available</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...currentStock, { ...emptyRow }].map( ( item, index ) => (
                        <StockRow index={index} item={item} onChange={handleRow} options={options}/>
                    ) )}
                </TableBody>
            </Table>
        </>
    );
};

const RemoveStock: React.FC<IProps> = ( { onClose, facilityId } ) => {
    const context = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [fromRoom, setFromRoom] = useState<string | undefined>( undefined );
    const [rows, setRows] = useState<IStockRow[]>( [] );
    const user = useSelector( selectUser );
    const consumables = useSelector( selectConsumables );
    const equipment = useSelector( selectEquipment );
    const inventoryDetails = useSelector( selectInventoryDetails );
    const rooms = useFacilityRooms(facilityId);

    const availableEquipment = equipment.filter( ( item ) => item.facilityId === facilityId && item.roomId === fromRoom && !item.systemId );
    const groupedEquipment = availableEquipment.reduce( ( acc, item ) => {
        const current = acc.get( item.inventoryId );
        if ( current ) {
            current.count++;
            acc.set( item.inventoryId, current );
        } else {
            const found = inventoryDetails.find( ( detail ) => detail.id === item.inventoryId );
            if ( found ) {
                acc.set( item.inventoryId, {
                    id: item.inventoryId,
                    name: found.name,
                    count: 1,
                    unit: Units.Single,
                } )
            }
        }

        return acc;
    }, new Map<string, IOption>() );
    const groupedAll = consumables.reduce( ( acc, item ) => {
        const availableConsumables = item.tickets.filter( ( item ) => item.facilityId === facilityId && item.roomId === fromRoom && !item.systemId );
        availableConsumables.forEach( ( ticket ) => {
            const current = acc.get( item.inventoryId );
            const found = inventoryDetails.find( ( detail ) => detail.id === item.inventoryId );
            if ( found && isDetailConsumable( found ) ) {
                if ( current ) {
                    current.count += convertUnit( ticket.amount, ticket.unit, found.unit );
                    acc.set( item.inventoryId, current );
                } else {
                    acc.set( item.inventoryId, {
                        id: item.inventoryId,
                        name: found.name,
                        count: convertUnit( ticket.amount, ticket.unit, found.unit ),
                        unit: found.unit,
                    } );
                }
            }
        } );
        return acc;
    }, groupedEquipment );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        rows.forEach( ( row ) => updateInventory( row ) );
    };

    const updateInventory = ( { unit, quantity, inventoryId }: IStockRow ) => {
        const found = inventoryDetails.find( ( detail ) => detail.id === inventoryId );
        if ( !found || !inventoryId || !user ) {
            return;
        }

        if ( isEquipment( found.type ) ) {
            const collection = context.firestore.collection( Collections.equipment );
            const currentStock = availableEquipment.filter( ( item ) => item.inventoryId === inventoryId );
            let removeItems = currentStock.slice( 0, parseInt( quantity ) );

            const promises: Promise<any>[] = [];
            removeItems.forEach( ( removing ) => {
                const update: IEquipmentItem = {
                    ...removing,
                    roomId: undefined,
                    facilityId: null,
                    removed: true,
                };
                promises.push( collection.doc( removing.id ).update( removeUndefined( update ) ) );
            } );

            Promise.all( promises )
                .then( () => {
                    onClose();
                } )
                .catch( ( e: Error ) => {
                    dispatch( setError( e.message ) );
                } );
        } else if ( isConsumable( found.type ) ) {
            const collection = context.firestore.collection( Collections.consumables );
            const consumable = consumables.find( ( item ) => item.inventoryId === inventoryId );

            if ( consumable ) {
                const fromId = uuidv4();
                const toId = uuidv4();

                const update: IConsumableItem = {
                    ...consumable,
                    tickets: [...consumable.tickets, {
                        id: fromId,
                        linkedId: toId,
                        unit: unit || Units.Single,
                        amount: -parseFloat( quantity ),
                        userId: user.uid,
                        timestamp: getTimeStamp(),
                        facilityId,
                    }]
                };

                collection.doc( consumable.id )
                    .update( removeUndefined( update ) )
                    .then( () => {
                        onClose();
                    } )
                    .catch( ( e: Error ) => {
                        dispatch( setError( e.message ) );
                    } );
            }
        }
    };

    return (
        <>
            <DialogTitle>Move inventory between locations</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Pick the location and items that are being removed from inventory permanently
                </DialogContentText>
                <Form id="inventory-stock-form" onSubmit={handleSubmit}>
                    <SelectValid
                        onChange={setFromRoom}
                        label="Location"
                        id="stock-remove-from-room"
                        value={fromRoom}
                        validators={[]}
                        fullWidth
                    >
                        <MenuItem value={undefined}>Facility Storage</MenuItem>
                        {rooms.map( ( room ) => (
                            <MenuItem
                                key={room.id}
                                value={room.id}
                            >
                                {room.metrc.Name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <StockTable currentStock={rows} onChange={setRows} options={[...groupedAll.values()]}/>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    cancel
                </Button>
                <Button type="submit" form="inventory-stock-form" color="primary">
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default RemoveStock;