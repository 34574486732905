import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
    MetrcLocationSubCollections,
    Collections,
    MetrcFacilitySubCollections
} from '@shared/interfaces/lib/firebaseConstants';
import { IRoom, ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from 'firebase_init';
import { selectUser } from 'redux/appSlice';
import FacilityContext from '../../facilityContext';
import { useRoomStyles } from '../shared';
import CloneSystem from './clone';
import DrySystem from './dry';
import MotherSystem from './mother';
import NewSystem from './newSystem';
import PackageSystem from './package';
import PlantSystem from './plant';

export interface ISystemProps {
    room: IRoom;
    system: ISystem;
    onSystemChange: ( newSystem: ISystem ) => Promise<void>;
}

interface IProps {
    room: IRoom;
}

const getSystem = ( system: ISystem ) => {
    switch ( system.type ) {
        case RoomTypes.dry:
            return null;
        case RoomTypes.mother:
            return MotherSystem
        case RoomTypes.clone:
            return CloneSystem;
        case RoomTypes.vegetation:
        case RoomTypes.flower:
        default:
            return PlantSystem;
    }
};

const RoomSystems: React.FC<IProps> = ( { room } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const classes = useRoomStyles();
    const user = useSelector( selectUser );
    const [systems, setSystems] = useState<ISystem[]>( [] );

    useEffect( () => {
        const listener = firestore
            .collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations ).doc( room.id )
            .collection( MetrcLocationSubCollections.systems )
            .onSnapshot( ( docs ) => {
                setSystems( docs.docs.map( ( doc ) => {
                    const data = doc.data() as ISystem;
                    return {
                        ...data,
                        id: doc.id,
                    }
                } ) );
            } );

        return () => listener();
    }, [] );

    const handleSystemUpdate = ( newSystem: ISystem ) => {
        return firestore
            .collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations ).doc( room.id )
            .collection( MetrcLocationSubCollections.systems ).doc( newSystem.id )
            .set( newSystem );
    };

    const types = room.types || [];
    const showSystems = types.filter( type => type !== RoomTypes.dry && type !== RoomTypes.packaging ).length > 0

    return (
        <section id={room.metrc.Name + '-systems'} className={classes.section}>
            {types.includes( RoomTypes.dry ) && (
                <DrySystem room={room}/>
            )}
            {types.includes( RoomTypes.packaging ) && (
                <PackageSystem room={room}/>
            )}
            {showSystems && (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{ display: 'inline' }}>
                                <strong>
                                    Systems
                                </strong>
                            </Typography>
                            {user && user.isAllowed( UserPermissions.generalEdit ) && (
                                <>
                                    <NewSystem room={room}/>
                                </>
                            )}
                            <Divider/>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" spacing={2}>
                        {systems.map( ( system: ISystem ) => {
                            const System = getSystem( system );
                            return System ? (
                                <Grid key={system.id} item xs={12} container justify="center">
                                    <System
                                        room={room}
                                        system={system}
                                        onSystemChange={handleSystemUpdate}
                                    />
                                </Grid>
                            ) : null;
                        } )}
                    </Grid>
                </>
            )}
        </section>
    );
};

export default RoomSystems;
