import React, { useState } from 'react';
import { Form, Validators } from '@happybandit/react-validation';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    DragDropContext,
    Droppable,
    DraggableLocation,
    DropResult,
    DroppableProvided,
} from 'react-beautiful-dnd';
import { IInfoCategory } from '@shared/interfaces/lib/interfaces';
import { uuidv4 } from '../../../../utils';
import TextValid from '../../../TextValid';
import DragItem from './item';

interface IProps {
    infoCategories: IInfoCategory[];
    onChange: ( newCategories: IInfoCategory[] ) => void;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            flexGrow: 1,
        },
        form: {
        }
    } ),
);

/**
 * Moves an item from one list to another list.
 */
const move = ( previous: IInfoCategory[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation ): IInfoCategory[] => {
    const previousClone: IInfoCategory[] = previous.map( ( state ) => ({
        ...state,
        articles: [...state.articles],
    }) );

    const source = previousClone.find( ( cat ) => cat.id === droppableSource.droppableId );
    const destination = previousClone.find( ( cat ) => cat.id === droppableDestination.droppableId );
    if ( source && destination ) {
        const [removed] = source.articles.splice( droppableSource.index, 1 );
        if ( source.id === destination.id ) {
            source.articles.splice( droppableDestination.index, 0, removed );
        } else {
            destination.articles.splice( droppableDestination.index, 0, removed );
        }
    }

    return previousClone;
};

const InfoCategoriesEdit: React.FC<IProps> = ( { infoCategories, onChange } ) => {
    const [newCategory, setNewCategory] = useState( '' );
    const [openCategories, setOpenCategories] = useState( new Set() );
    const classes = useStyles({});

    const onDragEnd = ( result: DropResult ): void => {
        const { source, destination } = result;

        if ( !destination ) {
            return;
        }

        const newState = move(
            infoCategories,
            source,
            destination
        );

        onChange( newState );
    };

    const handleAdd = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        const newCategories: IInfoCategory[] = [...infoCategories, {
            id: uuidv4(),
            index: infoCategories.length,
            name: newCategory,
            articles: [],
        }];

        onChange( newCategories );
    };

    const handleClick = ( id: string ) => {
        const newOpen = new Set( openCategories );
        if ( newOpen.has( id ) ) {
            newOpen.delete( id );
        } else {
            newOpen.add( id );
        }
        setOpenCategories( newOpen );
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <List
                    className={classes.root}
                >
                    {infoCategories.map( ( category ) => (
                        <Droppable key={category.id} droppableId={category.id}>
                            {( provided: DroppableProvided ) => (
                                <>
                                    <ListItem
                                        ref={provided.innerRef} button onClick={() => handleClick( category.id )}>
                                        <ListItemText primary={<b>{category.name}</b>}/>
                                        {openCategories.has( category.id ) ? <ExpandMore/> : <ExpandLess/>}
                                    </ListItem>
                                    <Collapse in={!openCategories.has( category.id )} timeout="auto" unmountOnExit>
                                        <List
                                            component="div"
                                            disablePadding
                                        >
                                            {category.articles.map( ( article, index ) => (
                                                <DragItem
                                                    key={article.id}
                                                    index={index}
                                                    item={article}
                                                />
                                            ) )}
                                            {provided.placeholder}
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        </Droppable>
                    ) )}
                </List>
            </DragDropContext>
            <Form className={classes.form} onSubmit={handleAdd}>
                <TextValid
                    onChange={setNewCategory}
                    fullWidth
                    id="add-category"
                    label="New Category Name"
                    value={newCategory}
                    validators={[Validators.required( newCategory )]}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Add Category
                </Button>
            </Form>
        </>
    );
};

export default InfoCategoriesEdit;
