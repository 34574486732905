import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IConfirm {
    onCancel: () => void;
    onSave: () => void;
}

const Confirm: React.FC<IConfirm> = ( { onCancel, onSave } ) => {
    return (
        <>
            <DialogTitle id="form-dialog-title">
                Are you sure?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSave} color="secondary">
                    Continue
                </Button>
            </DialogActions>
        </>
    );
};

export default Confirm;
