import { Avatar } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { AvatarGroup } from '@material-ui/lab';
import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { selectExternalContacts } from '../../../redux/appSlice';
import { addressTemplate } from '../../../utils';
import { IPersonContact } from '../interfaces';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getServiceIcon } from '../utils';

interface IMainProps {
    contact?: IPersonContact;
}

const MainContact: React.FC<IMainProps> = ({contact}) => {
    if(!contact){
        return <>No Contacts</>;
    }

    return (
        <small>
            {contact.name} <br/>
            {contact.email || contact.phone}
        </small>
    );
};

const ExternalContractsList: React.FC<RouteComponentProps> = () => {
    const externalContacts = useSelector(selectExternalContacts);
    const theme = useTheme();
    const isSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        External Contacts
                    </strong>
                </Typography>
                <Link href="#" onClick={() => navigate('/external-contacts/edit')} variant="body1">
                    <strong>
                        &nbsp;<span style={{ 'color': 'black' }}>/</span> Add New External Contact
                    </strong>
                </Link>
                <hr style={{ 'borderTop': '#eee' }} />
            </Box>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow style={{whiteSpace: 'nowrap'}}>
                        <TableCell>Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Services</TableCell>
                        <TableCell>Main Contact</TableCell>
                        {!isSmall && (
                            <TableCell>Notes</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {externalContacts.map( externalContact => (
                        <TableRow hover key={externalContact.id} onClick={() => navigate( '/external-contacts/' + externalContact.id )}>
                            <TableCell>
                                {externalContact.name}
                            </TableCell>
                            <TableCell>
                                {addressTemplate(externalContact.address)}
                            </TableCell>
                            <TableCell>
                                <AvatarGroup>
                                    {externalContact.services.map((service) => (
                                        <Avatar key={service} alt={service}>
                                            {getServiceIcon(service)}
                                        </Avatar>
                                    ))}
                                </AvatarGroup>
                            </TableCell>
                            <TableCell>
                                <MainContact contact={externalContact.contacts[0]}/>
                            </TableCell>
                            {!isSmall && (
                                <TableCell>
                                    {externalContact.notes}
                                </TableCell>
                            )}
                        </TableRow>
                    ) )}
                </TableBody>
            </Table>
        </>
    );
};

export default ExternalContractsList;
