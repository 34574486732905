import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeWrapper from './components/themeWrapper';
import ExternalContracts from './pages/externalContacts';
import Facility from './pages/facility';
import Organization from './pages/organization';
import Schedule from './pages/schedule';
import Strains from './pages/strains';
import Wrapper from './components/wrapper';
import Dashboard from './pages/dashboard';
import { Firebase, FirebaseContext, getFirebase } from './firebase_init'
import Login from './pages/login';
import SignUp from './pages/signUp';
import Testing from './pages/testing';
import Users from './pages/users';
import { useSelector } from 'react-redux';
import { selectUser } from './redux/appSlice';
import Notifications from './pages/notifications';
import Profile from './pages/profile';
import Account from './pages/account';
import Items from './pages/items';
import InventoryDetails from './pages/inventoryDetails';
import Environment from './pages/environment';
import Equipment from './pages/equipment';

interface IPrivate extends RouteComponentProps {
    as: React.FC<RouteComponentProps>;
}

const PrivateRoute = ( { as: Component, ...props }: IPrivate ) => {
    const user = useSelector(selectUser);
    return user && user.uid ? <Component {...props}/> : <Login/>;
};
const NotFound: React.FC<RouteComponentProps> = () => <p>Sorry, nothing here</p>;

function App() {
    const [firebase, setFirebase] = useState<Firebase>();

    const handleFirebase = async () => {
        setFirebase(await getFirebase());
    };
    useEffect( () => {
        handleFirebase();
    }, []);

    if(!firebase){
        return null;
    }

    return (
        <FirebaseContext.Provider value={firebase}>
            <ThemeWrapper>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Router>
                        <Wrapper path="/">
                            <PrivateRoute as={Facility} path="facility/:facilityId/*"/>
                            <PrivateRoute as={Testing} path="testing/*"/>
                            <PrivateRoute as={InventoryDetails} path="inventory"/>
                            <PrivateRoute as={ExternalContracts} path="external-contacts/*"/>
                            <PrivateRoute as={Equipment} path="equipment/*"/>
                            <PrivateRoute as={Environment} path="environment/*"/>
                            <PrivateRoute as={Profile} path="profile/:userId"/>
                            <PrivateRoute as={Strains} path="strains/*"/>
                            <PrivateRoute as={Items} path="items/*"/>
                            <PrivateRoute as={Schedule} path="schedule" />
                            <Login path="login"/>
                            <SignUp path="sign-up"/>
                            <PrivateRoute as={Users} path="users"/>
                            <PrivateRoute as={Notifications} path="notifications"/>
                            <PrivateRoute as={Account} path="account"/>
                            <PrivateRoute as={Organization} path="organization"/>
                            <PrivateRoute as={Dashboard} path="/"/>
                            <NotFound default/>
                        </Wrapper>
                    </Router>
                </MuiPickersUtilsProvider>
            </ThemeWrapper>
        </FirebaseContext.Provider>
    );
}

export default App;
