import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { IPlant, IPlantBatch, ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import React, { useState } from 'react';
import { buildArray, buildArrayReverse } from '../../../../../utils';
import ZoneDetails from './zoneDetails';

interface IRackProps {
    system: ISystem;
    rackIndex: number;
    plants: (IPlant | IPlantBatch)[];
}

interface ILevelProps {
    system: ISystem;
    rackIndex: number;
    levelIndex: number;
    title: string;
    plants: (IPlant | IPlantBatch)[];
}

interface IZoneProps {
    system: ISystem;
    rackIndex: number;
    levelIndex: number;
    zoneIndex: number;
    showIndex: boolean;
    plants: (IPlant | IPlantBatch)[];
}

const useStyles = makeStyles( ( theme ) => ({
    zone: {
        flexGrow: 1,
        flexBasis: 0,
        margin: '0 2px',
        padding: 5,
        height: '100px',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: 4,
        '&:hover': {
            borderColor: theme.palette.grey[800],
            cursor: 'pointer',
        },
    },
    rack: {
        flex: '1',
    },
    level: {
        display: 'flex',
        height: '100%',
        margin: '15px 0',
    },
    levelTitle: {
        width: 50,
    }
}) );

const Zone: React.FC<IZoneProps> = ( { zoneIndex, showIndex, plants, levelIndex, rackIndex, system } ) => {
    const classes = useStyles();
    const [open, setOpen] = useState( false );

    const handleClick = () => {
        setOpen( true );
    };

    const isPlantBatches = system.type === RoomTypes.clone;

    return (
        <>
            <div className={classes.zone} onClick={handleClick}>
                {showIndex && <div>zone {zoneIndex}</div>}
                {isPlantBatches ? (
                    <>
                        {plants.length > 0 ? (plants as IPlantBatch[]).map((batch) => (
                            <div key={batch.id}>{batch.metrc.Name}: {batch.metrc.UntrackedCount} clones</div>
                        )) : (
                            <div>No Clones</div>
                        )}
                    </>
                ) : (
                    <div>Plants {plants.length}</div>
                )}
            </div>
            {open && (
                <ZoneDetails
                    system={system}
                    rackIndex={rackIndex}
                    levelIndex={levelIndex}
                    zoneIndex={zoneIndex}
                    plants={plants}
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    );
};

const Level: React.FC<ILevelProps> = ( { system, rackIndex, levelIndex, title, plants } ) => {
    const classes = useStyles();
    return (
        <div className={classes.level}>
            <div className={classes.levelTitle}>
                {title}
            </div>
            {buildArray( system.size.zoneCount, ( index ) => {
                const zonePlants = plants.filter( ( plant ) => plant.location && plant.location.zone === index );

                return (
                    <Zone
                        key={index}
                        system={system}
                        rackIndex={rackIndex}
                        levelIndex={levelIndex}
                        zoneIndex={index}
                        showIndex={system.size.zoneCount > 1}
                        plants={zonePlants}
                    />
                );
            } )}
        </div>
    );
};

const Rack: React.FC<IRackProps> = ( { system, rackIndex, plants } ) => {
    return (
        <section id={'rack-' + rackIndex}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ display: 'inline' }}>
                        <strong>
                            Rack
                        </strong>
                    </Typography>
                    <strong>
                        <span style={{ 'color': 'black' }}>&nbsp;/</span> {rackIndex}
                    </strong>
                    <hr style={{ 'borderTop': '#eee' }}/>
                </Grid>
            </Grid>

            <div>
                {buildArrayReverse( system.size.levelCount, ( index, title ) => {
                    const levelPlants = plants.filter( ( plant ) => plant.location && plant.location.level === index );

                    return (
                        <Level
                            key={title}
                            system={system}
                            rackIndex={rackIndex}
                            levelIndex={index}
                            title={title}
                            plants={levelPlants}
                        />
                    );
                } )}
            </div>
        </section>
    );
};

export default Rack;
