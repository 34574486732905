import { Middleware } from 'redux'
import app from 'firebase/app';
import { RootState } from './store';
import { setShowPlantDetails, setShowPlantDetailsItem } from './appSlice';
import { PlantDetailPages } from './interfaces';

let listener: () => void | undefined;

export const detailsSnapshotMiddleware: Middleware<{}, RootState> = storeAPI => next => action => {
    if ( setShowPlantDetails.match( action ) && app.apps.length > 0 ) {
        const prevShowPlantDetails = storeAPI.getState().app.showPlantDetails;
        const nextShowPlantDetails = action.payload;
        if ( prevShowPlantDetails && (!nextShowPlantDetails || nextShowPlantDetails.page === PlantDetailPages.get) ) {
            if(listener){
                listener();
            }
            storeAPI.dispatch( setShowPlantDetailsItem( undefined ) );
        } else if ( nextShowPlantDetails && nextShowPlantDetails.page !== PlantDetailPages.get ) {
            if ( !prevShowPlantDetails ||
                prevShowPlantDetails.page === PlantDetailPages.get ||
                nextShowPlantDetails.key.id !== prevShowPlantDetails.key.id ||
                nextShowPlantDetails.key.collection !== prevShowPlantDetails.key.collection
            ) {
                if(listener){
                    listener();
                }
                listener = app.firestore().collectionGroup( nextShowPlantDetails.key.collection )
                    .where( 'id', '==', nextShowPlantDetails.key.id )
                    .onSnapshot( snapshot => {
                        if ( snapshot.size > 0 ) {
                            const doc = snapshot.docs[0];
                            storeAPI.dispatch( setShowPlantDetailsItem( {
                                facilityId: doc.ref.parent?.parent?.id ?? '',
                                item: doc.data() as any
                            } ) );
                        }
                    } );
            }
        }
    }
    return next( action );
}
