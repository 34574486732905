import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { RouteComponentProps } from '@reach/router';
import { IRoom } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteLocation } from '../../../api';
import ConfirmDialog from '../../../components/confirmDialog';
import SectionHeader from '../../../components/Header';
import { selectUser } from '../../../redux/appSlice';
import { addressTemplate, useFacilityRooms } from '../../../utils';
import FacilityContext from '../facilityContext';
import NewRoom from '../room/newRoom';
import EditFacility from './editForm';

const FacilityView: React.FC<RouteComponentProps> = () => {
    const { facility } = useContext( FacilityContext );
    const rooms = useFacilityRooms( facility.id );
    const user = useSelector( selectUser );
    const [show, setShow] = useState( false );
    const [edit, setEdit] = useState<IRoom | undefined>();
    const [confirm, setConfirm] = useState<IRoom | undefined>();

    const handleDelete = ( room: IRoom ) => (event: React.MouseEvent) => {
        event.stopPropagation();
        setConfirm( room );
    };

    const handleDeleteConfirm = async () => {
        if ( !user || !confirm ) {
            return;
        }
        await deleteLocation( user.token, confirm.metrc.Id, { facilityId: facility.id } )
        setConfirm( undefined );
    };

    const handleClose = () => {
        setEdit( undefined );
        setShow( false )
    };

    const handleEdit = ( room: IRoom ) => () => {
        setEdit( room );
        setShow( true )
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Typography variant="h3">
                    <strong>
                        {facility.metrc.DisplayName}
                    </strong>
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
                <Typography>
                    License: {facility.metrc.License.Number}
                </Typography>
                <Typography>
                    License Type: {facility.metrc.License.LicenseType}
                </Typography>
                <Typography>
                    Credentialed Date: {DateTime.fromISO( facility.metrc.CredentialedDate ).toLocaleString()}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h4">
                    Address
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
                {user?.isAllowed( UserPermissions.orgAdmin ) ? (
                    <EditFacility facility={facility}/>
                ) : (
                    <Typography>
                        {facility.address ? addressTemplate( facility.address ) : 'No Address Set'}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <SectionHeader
                    items={[{
                        text: 'Rooms',
                    }, {
                        text: 'Create New Room',
                        onClick: () => setShow( true ),
                    }]}
                />
                <hr style={{ 'borderTop': '#eee' }}/>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Location Type</TableCell>
                            <TableCell>Room Types</TableCell>
                            {user?.isAllowed( UserPermissions.orgAdmin ) && (
                                <TableCell/>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rooms.map( ( item ) => (
                            <TableRow key={item.id} hover onClick={handleEdit( item )}>
                                <TableCell>
                                    {item.metrc.Name}
                                </TableCell>
                                <TableCell>
                                    {item.metrc.LocationTypeName}
                                </TableCell>
                                <TableCell>
                                    {(item.types || []).join( ', ' )}
                                </TableCell>
                                {user?.isAllowed( UserPermissions.orgAdmin ) && (
                                    <TableCell>
                                        <IconButton
                                            color="secondary"
                                            onClick={handleDelete( item )}
                                            aria-label="delete"
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </Grid>
            {show && (
                <NewRoom
                    open={true}
                    room={edit}
                    facility={facility}
                    onClose={handleClose}
                />
            )}
            {confirm && (
                <ConfirmDialog
                    title="Confirm Deletion of Room"
                    description="This action cannot be undone, make sure to disconnect all entities connected to this room."
                    onCancel={() => setConfirm(undefined)}
                    onConfirm={handleDeleteConfirm}
                />
            )}
        </Grid>
    );
};

export default FacilityView;
