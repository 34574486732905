import Typography from '@material-ui/core/Typography';
import capitalize from '@material-ui/core/utils/capitalize';
import { ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import React from 'react';

interface IProps {
    system: ISystem;
}

const SystemHeader: React.FC<IProps> = ( { system } ) => {
    return (
        <div>
            <Typography variant="h5" style={{ display: 'inline' }}>
                <strong>
                    {system.name}
                </strong>
            </Typography>
            <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                {` / ${capitalize( system.type || RoomTypes.flower )}`}
            </Typography>
        </div>
    );
};

export default SystemHeader;
