import { Table } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlantBatch } from '@shared/interfaces/lib/interfaces';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowPlantDetails } from '../../../../../../../redux/appSlice';
import { PlantDetailPages } from '../../../../../../../redux/interfaces';

interface IProps {
    batches: IPlantBatch[];
    onAdd: () => void;
}


const ZoneDetailsBatchView: React.FC<IProps> = ( { batches, onAdd } ) => {
    const dispatch = useDispatch();

    return (
        <>
            <DialogContent>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {batches.map( ( batch ) => (
                            <TableRow
                                hover
                                key={batch.id}
                                onClick={() => dispatch( setShowPlantDetails( {
                                    page: PlantDetailPages.details,
                                    key: {
                                        id: batch.id,
                                        collection: MetrcFacilitySubCollections.metrcPlantBatches,
                                    },
                                } ) )}
                            >
                                <TableCell>
                                    {batch.metrc.Name}
                                </TableCell>
                                <TableCell>
                                    {batch.metrc.UntrackedCount}
                                </TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onAdd}>
                    Assign Clone Batches
                </Button>
            </DialogActions>
        </>
    );
};

export default ZoneDetailsBatchView;
