import { List, ListItem, ListItemAvatar, ListItemText, Paper } from '@material-ui/core';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import { IScheduleTask } from '@shared/interfaces/lib/scheduleInterfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../../components/Header';
import UserAvatar from '../../../components/userAvatar';
import { FirebaseContext } from '../../../firebase_init';
import { selectUser, selectUsers, setShowTask } from '../../../redux/appSlice';
import { useTaskName } from '../../../utils';

interface IProps {
}

const DashSchedule: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const user = useSelector( selectUser );
    const users = useSelector( selectUsers );
    const [tasks, setTasks] = useState<IScheduleTask[]>( [] );
    const getTaskName = useTaskName();

    useEffect( () => {
        let query = firestore.collection( Collections.scheduleTasks )
            .where( 'dueDate', '==', DateTime.local().toISODate() )
            .where( 'dateCompleted', '==', null )

        if ( user && !user?.isAllowed( UserPermissions.userAdmin ) ) {
            query = query.where( 'employeeId', '==', user.uid );
        }

        query.onSnapshot( ( snap ) => {
            setTasks( snap.docs.map( ( item ) => item.data() as IScheduleTask ) );
        } );
    }, [] );
    return (
        <Paper
            elevation={0}
            variant="outlined"
            square
            style={{ padding: '1em' }}
        >
            <SectionHeader
                items={[{
                    text: 'Tasks Today',
                }, {
                    text: `${tasks.length} Remaining`,
                }, {
                    text: 'View All',
                    link: '/schedule'
                }]}
            />
            <List>
                {tasks.map( task => {
                    const taskUser = users.find( taskUser => taskUser.uid === task.employeeId );
                    return (
                        <ListItem
                            key={task.id}
                            divider={true}
                            button
                            onClick={() => dispatch( setShowTask( {
                                id: task.id,
                                edit: false,
                            } ) )}
                        >
                            {taskUser && (
                                <ListItemAvatar>
                                    <UserAvatar id={taskUser.uid} name={taskUser.displayName}/>
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                primary={getTaskName( task )}
                                secondary={DateTime.fromISO( task.dueDate ).toLocaleString()}
                            />
                        </ListItem>
                    );
                } )}
            </List>
        </Paper>
    );
};

export default DashSchedule;
