import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Box } from '@material-ui/core';
import { selectInventoryCategories, selectInventoryDetails } from '../../redux/appSlice';
import { useSelector } from 'react-redux';
import InventoryDetailDialog, { InventoryDetailNew } from '../../components/inventoryDetailDialog';
import { ConsumableTypes, EquipmentTypes, IInventoryDetail } from '@shared/interfaces/lib/inventoryInterfaces';
import { isDetailEquipment } from '../../utils';
import InventoryCategories from './inventoryCategories';

const InventoryDetails: React.FC<RouteComponentProps> = () => {
    const inventoryCategories = useSelector(selectInventoryCategories);
    const inventoryDetails = useSelector(selectInventoryDetails);
    const [edit, setEdit] = useState<IInventoryDetail | undefined>( undefined );

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Inventory Details
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ { inventoryDetails.length } items
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />
            </Box>
            <InventoryDetailNew/>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                        <TableCell>Storage</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Sku</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inventoryDetails.map( ( inventoryDetail ) => {
                        let type: string = inventoryDetail.type;
                        if ( type === EquipmentTypes.UserDefined || type === ConsumableTypes.UserDefined ) {
                            const found = inventoryCategories.find( ( cat ) => cat.id === inventoryDetail.userCategoryId );
                            if ( found ) {
                                type = found.name;
                            }
                        }
                        return (
                            <TableRow hover key={inventoryDetail.id} onClick={() => setEdit( inventoryDetail )}>
                                <TableCell>
                                    {isDetailEquipment( inventoryDetail ) ? 'Equipment' : 'Consumable'}
                                </TableCell>
                                <TableCell>
                                    {inventoryDetail.name}
                                </TableCell>
                                <TableCell>
                                    {type}
                                </TableCell>
                                <TableCell>
                                    {inventoryDetail.brand}
                                </TableCell>
                                <TableCell>
                                    {inventoryDetail.model}
                                </TableCell>
                                <TableCell>
                                    {inventoryDetail.sku || ''}
                                </TableCell>
                            </TableRow>
                        );
                    } )}
                </TableBody>
            </Table>
            {edit && (
                <InventoryDetailDialog inventoryDetail={edit} onClose={() => setEdit( undefined )}/>
            )}
            <Box mt={5}>
                <InventoryCategories/>
            </Box>
        </>
    );
};

export default InventoryDetails;
