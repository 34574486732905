import { RouteComponentProps } from '@reach/router';
import React, { useContext } from 'react';
import { useSelectedRoom } from '../../../utils';
import FacilityContext from '../facilityContext';
import RoomComment from './comment';
import RoomEnvironment from './environment';
import RoomEquipment from './equipment';
import RoomHeader from './header';
import RoomSystems from './systems';
import RoomScheduling from './scheduling';
import RoomContext from './roomContext';

const Room: React.FC<RouteComponentProps<{ roomId: string }>> = ( { roomId } ) => {
    const { facility } = useContext( FacilityContext );
    const room = useSelectedRoom( facility.id, item => item.id === roomId );

    if ( !room ) {
        return null;
    }

    return (
        <RoomContext.Provider value={{
            room,
        }}>
            <RoomHeader room={room}/>
            <RoomEnvironment room={room}/>
            <RoomScheduling room={room}/>
            <RoomSystems room={room}/>
            <RoomEquipment room={room}/>
            <RoomComment room={room}/>
        </RoomContext.Provider>
    );
};

export default Room;
