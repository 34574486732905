import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';

interface IProps {
	loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			margin: theme.spacing(1),
			position: 'relative',
			display: 'inline-block',
		},
		buttonProgress: {
			color: green[500],
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	}),
);

const SaveButton: React.FC<IProps> = ({loading}) => {
	const classes = useStyles();
	return (
        <Box mt={1} className={classes.wrapper}>
            <Button
                variant="contained"
                color="primary"
                disabled={loading}
                type="submit"
            >
                Save
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </Box>
    )
};

export default SaveButton;
