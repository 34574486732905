import { Form, Validators } from '@happybandit/react-validation';
import { Table } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Alert } from '@material-ui/lab';
import { IPlantMoveBodyItem } from '@shared/interfaces/lib/contractInterfaces';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlant, IPlantLocation, ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { DateTime } from 'luxon';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { postMovePlant } from '../../../../../../../api';
import TextValid from '../../../../../../../components/TextValid';
import { FirebaseContext } from '../../../../../../../firebase_init';
import { selectUser } from '../../../../../../../redux/appSlice';
import { getPlantState, PlantState } from '../../../../../../../utils';
import FacilityContext from '../../../../../facilityContext';
import RoomContext from '../../../../roomContext';

interface IProps {
    system: ISystem;
    rackIndex: number;
    levelIndex: number;
    zoneIndex: number;
    onDone: () => void;
}

const ZoneDetailsAdd: React.FC<IProps> = ( { system, rackIndex, levelIndex, zoneIndex, onDone } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { room } = useContext( RoomContext );
    const { facility } = useContext( FacilityContext );
    const user = useSelector( selectUser );
    const [added, setAdded] = useState<IPlant[]>( [] );
    const [label, setLabel] = useState<string>( '' );
    const [invalid, setInvalid] = useState( '' );
    const date = useMemo( () => DateTime.local(), [] );

    const isFlowerSystem = system.type === RoomTypes.flower;
    const isVegSystem = system.type === RoomTypes.vegetation;
    const isMotherSystem = system.type === RoomTypes.mother;

    const hasVegPlant = added.some( ( plant ) => getPlantState(plant.metrc).state === PlantState.vegetative );

    const handleSave = () => {
        const location: IPlantLocation = {
            systemId: system.id,
            rack: rackIndex,
            level: levelIndex,
            zone: zoneIndex,
        };

        const batch = firestore.batch();
        const collection = firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcPlants );

        added.forEach( ( add ) => {
            batch.update( collection.doc( add.id ), { location } );
        } );
        batch.commit().then( () => triggerMove() ).then( () => onDone() );
    };

    const triggerMove = async () => {
        if ( !room || !user ) {
            return;
        }

        const changes: IPlantMoveBodyItem[] = [];
        added.forEach( ( plant ) => {
            const changePhase = getPlantState(plant.metrc).state === PlantState.vegetative && isFlowerSystem;
            if ( plant.metrc.LocationId !== room.metrc.Id || changePhase ) {
                changes.push( {
                    label: plant.metrc.Label,
                    changePhase: changePhase ? 'Flowering' : null,
                } );
            }
        } );

        if ( changes.length > 0 ) {
            await postMovePlant( user.token, {
                facilityId: facility.id,
                locationId: room.metrc.Id,
                date: date.toISODate(),
                plants: changes,
            } );
        }
    };

    const handleAdd = ( valid: boolean ) => {
        setInvalid( '' );
        if ( !valid ) {
            return;
        }

        firestore.collection( Collections.metrcFacilities ).doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcPlants ).doc( label )
            .get()
            .then( ( doc ) => {
                if ( doc.exists ) {
                    const plant = doc.data() as IPlant;
                    const { state } = getPlantState( plant.metrc );
                    if ( (state === PlantState.flowering && isFlowerSystem) ||
                        (state === PlantState.vegetative && (isVegSystem || isFlowerSystem || isMotherSystem)) ) {
                        setAdded( ( prev ) => [...prev].concat( [plant] ) );
                    } else {
                        setInvalid( `Plant is not in a valid phase (${state}) for system type (${getPlantState(plant.metrc).title})` );
                    }
                } else {
                    setInvalid( 'Unknown Plant Label' );
                }
            } )

        setLabel( '' );
    }

    return (
        <>
            <DialogContent>
                {isMotherSystem && (
                    <Alert variant="outlined" severity="info">
                        Vegetative Plants to be used as Mother Plants for generating more clones.
                    </Alert>
                )}
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Strain</TableCell>
                            <TableCell>Current Phase</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {added.map( ( plant ) => (
                            <TableRow key={plant.id}>
                                <TableCell>
                                    {plant.id}
                                </TableCell>
                                <TableCell>
                                    {plant.metrc.StrainName}
                                </TableCell>
                                <TableCell>
                                    {getPlantState(plant.metrc).title}
                                </TableCell>
                            </TableRow>
                        ) )}
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Form onSubmit={handleAdd} style={{ display: 'flex' }}>
                                    <div>
                                        <TextValid
                                            onChange={setLabel}
                                            value={label}
                                            id="zone-add-label"
                                            validators={[
                                                Validators.required( label ),
                                            ]}
                                            forcedErrorMessage={invalid ? invalid : undefined}
                                        />
                                    </div>
                                    <div>
                                        <Button type="submit">Add</Button>
                                    </div>
                                </Form>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <hr/>
                {isFlowerSystem && hasVegPlant && (
                    <Alert variant="outlined" severity="warning">Vegetative Plants will be Moved to the Flowering Grow Phase.</Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default ZoneDetailsAdd;
