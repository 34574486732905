import { Form, Validators } from '@happybandit/react-validation';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import capitalize from '@material-ui/core/utils/capitalize';
import { navigate } from '@reach/router';
import { ILocationCreateBody } from '@shared/interfaces/lib/contractInterfaces';
import {
    MetrcFacilitySubCollections,
    Collections,
    MetrcFacilityConstantDoc
} from '@shared/interfaces/lib/firebaseConstants';
import { IFacility, IRecord, IRoom, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { IMetrcLocationType } from '@shared/interfaces/lib/metrcInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { postCreateLocation, putUpdateLocation } from '../../../../api';
import SelectValid from '../../../../components/SelectValid';
import TextValid from '../../../../components/TextValid';
import { addHistory, FirebaseContext } from '../../../../firebase_init';
import { selectUser } from '../../../../redux/appSlice';
import { diff, getTimeStamp } from '../../../../utils';

interface IProps {
    open: boolean;
    onClose: () => void;
    room?: IRoom;
    facility: IFacility;
}

const getDiff = ( prevProps: ILocationCreateBody, newProps: ILocationCreateBody ): string => {
    return diff( prevProps, newProps ).map( ( change ) => {
        let before: string | undefined;
        let after: string | undefined;
        let key = '';
        switch ( change.key ) {
            case 'name':
                key = 'Name';
                before = prevProps.name;
                after = newProps.name;
                break;
            case 'locationType':
                key = 'Location Type';
                before = change.before;
                after = change.after;
                break;
            case 'types':
                key = 'Type';
                before = capitalize( RoomTypes[change.before as keyof typeof RoomTypes] || 'Unassigned' );
                after = capitalize( RoomTypes[change.after as keyof typeof RoomTypes] || 'Unassigned' );
                break;
        }
        if ( before && after && before !== after ) {
            return `${key}: ${before} => ${after}`;
        }
        return undefined;
    } ).filter( Boolean ).join( ', ' );
};


const NewRoom: React.FC<IProps> = ( { open, onClose, room, facility } ) => {
    const { firestore } = useContext( FirebaseContext );
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [name, setName] = useState( room ? room.metrc.Name : '' );
    const [locationTypes, setLocationTypes] = useState<IMetrcLocationType[]>( [] );
    const [locationType, setLocationType] = useState<string>( room?.metrc.LocationTypeName ?? '' );
    const [roomTypes, setRoomTypes] = useState<RoomTypes[]>( room?.types ?? [] );
    const user = useSelector( selectUser );

    useEffect( () => {
        firestore.collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcConstants )
            .doc( MetrcFacilityConstantDoc.metrcLocationTypes )
            .get()
            .then( doc => {
                if ( doc.exists ) {
                    const data = doc.data() as IRecord<IMetrcLocationType>;
                    setLocationTypes( data && data.data ? data.data : [] );
                }
            } )
    }, [] );

    const handleMetrcChange = ( value: string ) => {
        if ( value ) {
            setRoomTypes( [] );
        }
        setLocationType( value );
    }

    const handleSubmit = async ( valid: boolean ) => {
        if ( !valid || !user ) {
            return;
        }

        const data: ILocationCreateBody = {
            name,
            locationType,
            types: roomTypes,
            facilityId: facility.id,
        };

        if ( room ) {
            await putUpdateLocation( user.token, room.metrc.Id, data );
            const changed = getDiff( {
                name: room.metrc.Name,
                types: room.types || [],
                facilityId: facility.id,
                locationType: room.metrc.LocationTypeName,
            }, data );
            await addHistory( firestore, facility.id, room.id, {
                userId: user ? user.uid : '',
                message: changed,
                timestamp: getTimeStamp()
            } );
        } else {
            const resp = await postCreateLocation( user.token, data );
            await navigate( `facility/${facility.id}/room/${resp.id}` );
        }

        onClose();
    };

    const handleClose = () => {
        setName( '' );
        onClose();
    };

    const handleTypeChange = ( type: RoomTypes ) => ( e: any, checked: boolean ) => {
        setRoomTypes( ( prev ) => {
            const newTypes = [...prev];

            if ( checked ) {
                newTypes.push( type );
            } else {
                const index = newTypes.indexOf( type );
                if ( index >= 0 ) {
                    newTypes.splice( index, 1 );
                }
            }

            return newTypes;
        } )
    };

    return (
        <>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {room ? (
                        <>
                            <Typography variant="h5" component="p" style={{ display: 'inline' }}>
                                <strong>
                                    {room.metrc.Name}
                                </strong>
                            </Typography>
                            <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                                <strong>
                                    / Room / Update
                                </strong>
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="h5" style={{ display: 'inline' }}>
                            <strong>
                                Create Room
                            </strong>
                        </Typography>
                    )}
                    <Typography variant="body2" component="p">
                        A Room is an enclosed space that can hold one or more grow Systems.
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                </DialogTitle>
                <DialogContent>
                    <Form id="new-room-form" onSubmit={handleSubmit}>
                        <TextValid
                            id="room-new-name"
                            label="Name"
                            value={name}
                            onChange={( value ) => setName( value )}
                            fullWidth
                            validators={[Validators.required( name )]}
                        />
                        <SelectValid
                            id="room-new-metrc"
                            value={locationType}
                            label="Metrc Location Type"
                            disabled={locationTypes.length === 1 && locationTypes[0].Name === locationType}
                            fullWidth
                            validators={[Validators.required( locationType )]}
                            onChange={handleMetrcChange}
                        >
                            <MenuItem value="">Not a Metrc Location</MenuItem>
                            {locationTypes.map( ( type ) => (
                                <MenuItem key={type.Name} value={type.Name}>{type.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">What Activities will this room allow</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={roomTypes.includes( RoomTypes.vegetation )}
                                        onChange={handleTypeChange( RoomTypes.vegetation )}
                                        name="room-types"
                                    />}
                                    label="Plant Vegetation"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={roomTypes.includes( RoomTypes.flower )}
                                        onChange={handleTypeChange( RoomTypes.flower )}
                                        name="room-types"
                                    />}
                                    label="Plant Flowering"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={roomTypes.includes( RoomTypes.mother )}
                                        onChange={handleTypeChange( RoomTypes.mother )}
                                        name="room-types"
                                    />}
                                    label="Mother Plants"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={roomTypes.includes( RoomTypes.clone )}
                                        onChange={handleTypeChange( RoomTypes.clone )}
                                        name="room-types"
                                    />}
                                    label="Clones / Plant Batches"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={roomTypes.includes( RoomTypes.dry )}
                                        onChange={handleTypeChange( RoomTypes.dry )}
                                        name="room-types"
                                    />}
                                    label="Dry Plants"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={roomTypes.includes( RoomTypes.packaging )}
                                        onChange={handleTypeChange( RoomTypes.packaging )}
                                        name="room-types"
                                    />}
                                    label="Package Products"
                                />
                            </FormGroup>
                        </FormControl>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" form="new-room-form" color="primary">
                        {room ? 'Update' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewRoom;