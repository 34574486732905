import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { useSelectedFacility } from '../../utils';
import Harvests from './harvests';
import Inventory from './inventory';
import Room from './room';
import FacilityContext from './facilityContext';
import Transfer from './transfers';
import FacilityView from './view';

const Facility: React.FC<RouteComponentProps<{ facilityId: string }>> = ( { facilityId } ) => {
    const facility = useSelectedFacility( item => item.id === facilityId );
    if ( !facility ) {
        return null;
    }

    return (
        <FacilityContext.Provider
            value={{
                facility,
            }}
        >
            <Router>
                <FacilityView path="/"/>
                <Harvests path="/harvests"/>
                <Inventory path="/inventory"/>
                <Transfer path="/transfers"/>
                <Room path="/room/:roomId"/>
            </Router>
        </FacilityContext.Provider>
    );
};

export default Facility;
