import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import MoveStockLocation from './location';
import MoveStockList from './stock';

interface IProps {
    facilityId: string;
    onClose: () => void;
}

export interface IPlace {
    facilityId: string;
    roomId?: string;
}

enum Pages {
    Location,
    List,
}

const MoveStock: React.FC<IProps> = ( { onClose, facilityId } ) => {
    const [page, setPage] = useState<Pages>( Pages.Location );
    const [fromPlace, setFromPlace] = useState<IPlace>( {facilityId} );
    const [toPlace, setToPlace] = useState<IPlace>( {facilityId} );

    const handlePlaceSet = (from: IPlace, to: IPlace) => {
        setFromPlace(from);
        setToPlace(to);
        setPage(Pages.List);
    };

    return (
        <>
            <DialogTitle>Move inventory between locations</DialogTitle>
            {page === Pages.List ? (
                <MoveStockList
                    facilityId={facilityId}
                    onBack={() => setPage(Pages.Location)}
                    fromPlace={fromPlace}
                    toPlace={toPlace}
                    onClose={onClose}
                />
            ) : (
                <MoveStockLocation
                    fromPlace={fromPlace}
                    toPlace={toPlace}
                    onClose={onClose}
                    onNext={handlePlaceSet}
                />
            )}
        </>
    );
};

export default MoveStock;