import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IMetrcItem } from '@shared/interfaces/lib/metrcInterfaces';
import { Form, Validators } from '@happybandit/react-validation';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { selectFacilities } from '../../../../../redux/appSlice';
import { FirebaseContext } from '../../../../../firebase_init';
import SelectValid from '../../../../../components/SelectValid';

export interface ISelectItem {
    license: string;
    item: IMetrcItem;
}

interface IProps {
    onClose: () => void;
    onSelect: ( selection?: ISelectItem ) => void;
}

const SelectSource: React.FC<IProps> = ( { onClose, onSelect } ) => {
    const { firestore } = useContext( FirebaseContext );
    const facilities = useSelector(selectFacilities);
    const [itemId, setItemId] = useState( '' );
    const [licenseId, setLicenseId] = useState( '' );
    const [items, setItems] = useState<IMetrcItem[]>( [] );

    useEffect(() => {
        setItems([]);
        setItemId('');
        if(licenseId) {
            firestore.collection(Collections.metrcFacilities).doc(licenseId)
                .collection(MetrcFacilitySubCollections.metrcItems)
                .get()
                .then((snap) => {
                    setItems(snap.docs.map((doc) => doc.data() as IMetrcItem));
                });
        }
    }, [licenseId]);

    const handleSubmit = (valid: boolean) => {
        if(!valid) {
            return;
        }

        const item = items.find((item) => item.Id.toString() === itemId);
        if(item){
            onSelect( {
                item,
                license: licenseId,
            });
        }
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Create a new Cultivatd Package Item from a Metrc Item
                </DialogContentText>
                <Form onSubmit={handleSubmit}>
                    <SelectValid
                        id="new-item-select-facility"
                        label="Facility"
                        value={licenseId}
                        onChange={( value ) => setLicenseId( value )}
                        fullWidth
                        validators={[Validators.required( licenseId )]}
                    >
                        <MenuItem value=""/>
                        {facilities.map( ( facilityItem ) => (
                            <MenuItem
                                key={facilityItem.id}
                                value={facilityItem.id}
                            >
                                {facilityItem.metrc.DisplayName}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <SelectValid
                        id="new-item-select-product"
                        value={itemId}
                        label="Metrc Item"
                        fullWidth
                        validators={[Validators.required( itemId )]}
                        onChange={( value ) => setItemId( value )}
                    >
                        <MenuItem value=""/>
                        {items.map( ( item ) => (
                            <MenuItem
                                key={item.Id}
                                value={item.Id.toString()}
                            >
                                {item.Name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        Create From Item
                    </Button>
                </Form>
                <Divider/>
                <Button onClick={() => onSelect()} color="primary">
                    Create New
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default SelectSource;