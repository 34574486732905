import { navigate } from '@reach/router';
import { UserPermissions } from '@shared/interfaces/lib/user';
import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/appSlice';

interface IHeaderBase {
    text: string;
    permission?: UserPermissions[]
}

interface IHeaderLink extends IHeaderBase {
    link: string;
}

interface IHeaderClick extends IHeaderBase {
    onClick: () => void;
}

export type SectionHeaderItems = IHeaderLink | IHeaderClick | IHeaderBase;

interface IProps {
    items: SectionHeaderItems[];
}

const SectionHeader: React.FC<IProps> = ( { items } ) => {
    const user = useSelector( selectUser );

    const getItem = (item: SectionHeaderItems) => {
        const content = (
            <strong>
                {item.text}
            </strong>
        );

        if(!item.permission || user?.isAllowed(item.permission)){
            if(!!(item as IHeaderLink).link){
                return (
                    <Link
                        underline="hover" onClick={() => navigate( (item as IHeaderLink).link )}
                        variant="body1"
                    >
                        {content}
                    </Link>
                );
            } else if ( !!(item as IHeaderClick).onClick ) {
                return (
                    <Link underline="hover" onClick={(item as IHeaderClick).onClick} variant="body1">
                        {content}
                    </Link>
                );
            } else {
                return (
                    <Typography variant="body1" style={{ display: 'inline' }}>
                        {content}
                    </Typography>
                );
            }
        }
    };

    return (
        <Box>
            {items.map( ( item, index ) => (
                <React.Fragment key={index}>
                    {index === 0 && (
                        <Typography variant="h5" style={{ display: 'inline' }}>
                            <strong>
                                {item.text}
                            </strong>
                        </Typography>
                    )}

                    {index !== 0 && (
                        <>
                            <Typography variant="body1" style={{ display: 'inline' }}>
                                <strong>
                                    {' / '}
                                </strong>
                            </Typography>
                            {getItem( item )}
                        </>
                    )}
                </React.Fragment>
            ) )}
            <hr style={{ 'borderTop': '#eee' }}/>
        </Box>
    );
};

export default SectionHeader;
