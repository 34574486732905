import React from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import BusinessIcon from '@material-ui/icons/Business';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import { ContactServices } from './interfaces';

export const getServiceIcon = (service: ContactServices) => {
    switch (service ) {
        case ContactServices.Financial:
            return <AccountBalanceIcon/>;
        case ContactServices.Genetics:
            return <LocalFloristIcon/>;
        case ContactServices.RealEstate:
            return <BusinessIcon/>;
        case ContactServices.Supplies:
        default:
            return <LocalGroceryStoreIcon/>;
    }
};