import { RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import EquipmentRoomView from './roomView';
import EquipmentFacilityView from './facilityView';

const Equipment: React.FC<RouteComponentProps> = () => {
    return (
        <Router>
            <EquipmentFacilityView path="/" />
            <EquipmentRoomView path="/:roomId" />
        </Router>
    );
};

export default Equipment;
