import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { Theme } from '@material-ui/core';
import { ILight, IRoom } from '@shared/interfaces/lib/interfaces';
import { selectInventoryDetails, selectUser } from 'redux/appSlice';
import { ReactComponent as LightIcon } from 'components/Icons/lights.svg';
import FacilityContext from '../../../../facilityContext';
import Edit from './edit';
import View from './view';

interface IProps {
    systemId: string;
    room: IRoom;
    light: ILight;
    onLightDelete: ( id: number ) => void;
    onLightChange: ( newLight: ILight ) => void;
}

enum States {
    view,
    edit,
    noLight,
}

const useStyles = makeStyles( ( theme: Theme ) => ({
    base: {
        border: '1px solid transparent',
        padding: '5px',
        whiteSpace: 'nowrap',
    },
    warn: {
        borderColor: theme.palette.warning.dark,
    },
    danger: {
        borderColor: theme.palette.error.dark,
    },
    success: {
        borderColor: theme.palette.success.dark,
    },
    lightcell: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#f5f5f5',
        },
    }
}) );

const Light: React.FC<IProps> = ( { light, room, onLightChange, onLightDelete, systemId } ) => {
    const classes = useStyles();
    const { facility } = useContext( FacilityContext );
    const [open, setOpen] = useState( false );
    const [state, setState] = useState( light.id ? States.view : States.noLight );
    const user = useSelector( selectUser );
    const inventoryDetails = useSelector( selectInventoryDetails );
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    if ( !user ) {
        return null;
    }

    const handleUpdate = ( newLight: ILight ) => {
        onLightChange( newLight );

        const prevState = state;
        const nextState = light.id ? States.view : States.noLight;
        setState( nextState );

        if ( prevState === nextState ) {
            handleClose();
        }
    };

    const handleDelete = ( id: number ) => {
        onLightDelete( id );
    };

    const handleClose = () => {
        setOpen( false );
    };

    const getModalContent = () => {
        switch ( state ) {
            case States.edit:
                return <Edit
                    light={light}
                    facility={facility}
                    onUpdate={handleUpdate}
                    onCancel={() => setState( light.id ? States.view : States.noLight )}
                />;
            case States.view:
                return <View
                    light={light}
                    room={room}
                    systemId={systemId}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onEdit={() => setState( States.edit )}
                />;
        }

        return null;
    };

    const getCellContent = (): React.ReactNode => {
        let className = '';
        let content: React.ReactNode;
        const detail = inventoryDetails.find( ( item ) => item.id === light.inventoryId );

        if ( !detail ) {
            className = classes.warn;
            content = (
                <>
                    <LightIcon height="50" width="50"/><br/>
                    <strong>No Light</strong>
                </>
            );
        } else {
            content = (
                <>
                    <LightIcon height="50" width="50"/><br/>
                    <strong>
                        {detail.name || 'No Light'}
                    </strong>
                    <br/>
                    <strong>
                        {detail.brand} - {detail.model}
                    </strong>
                    {/* Installed: {DateTime.fromISO( light.dateInstalled ).toFormat( 'd' )} days */}
                </>
            );
        }

        return (
            <div className={`${classes.base} ${className}`}>
                {content}
            </div>
        );
    };

    return (
        <>
            <TableCell
                scope="row"
                key={light.id}
                align="center"
                className={classes.lightcell}
                onClick={() => setOpen( true )}
            >
                {getCellContent()}
            </TableCell>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
            >
                {getModalContent()}
            </Dialog>
        </>
    );
};

export default Light;
