import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import ItemsList from './list';
import ItemsView from './view';

const Items: React.FC<RouteComponentProps> = () => {
    return (
        <Router>
            <ItemsList path="/" />
            <ItemsView path="/:itemId" />
        </Router>
    );
};

export default Items;
