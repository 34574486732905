import { Divider, InputAdornment } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { IHarvestWasteBody } from '@shared/interfaces/lib/contractInterfaces';
import { DirectPaths, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IHarvest } from '@shared/interfaces/lib/interfaces';
import { IMetrcTypeNames } from '@shared/interfaces/lib/metrcInterfaces';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postHarvestWaste } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';
import { useDetailsStyles } from '../details';

interface IProps {
    harvest: IHarvest;
    facilityId: string;
}

interface IRecord {
    data: IMetrcTypeNames[];
}

const HarvestWaste: React.FC<IProps> = ( { harvest, facilityId } ) => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const classes = useDetailsStyles();
    const user = useSelector( selectUser );
    const [reason, setReason] = useState( '' );
    const [weight, setWeight] = useState( '0' );
    const [reasons, setReasons] = useState<IMetrcTypeNames[]>( [] );
    const [loading, setLoading] = useState( false );

    useEffect( () => {
        firestore.doc( DirectPaths.metrcHarvestWasteTypes )
            .get()
            .then( doc => {
                if ( doc.exists ) {
                    const record = doc.data() as IRecord;
                    setReasons( record?.data || [] );
                    if ( record?.data.length === 1 ) {
                        setReason( record.data[0].Name );
                    }
                }
            } )
    }, [] );

    if ( !harvest ) {
        return null;
    }

    const handleSubmit = () => {
        if ( !user ) {
            return;
        }

        setLoading( true );

        const body: IHarvestWasteBody = {
            facilityId,
            id: harvest.metrc.Id,
            wasteType: reason,
            weight: parseFloat( weight ),
            unitOfWeight: 'Grams',
            date: DateTime.local().toISODate(),
        }
        postHarvestWaste( user.token, body )
            .then( () => {
                dispatch( setShowPlantDetails( {
                    key: {
                        collection: MetrcFacilitySubCollections.metrcHarvests,
                        id: harvest.id,
                    },
                    page: PlantDetailPages.harvestDetails,
                } ) );
            } )
            .finally( () => {
                setLoading( false );
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Remove Waste from {harvest.metrc.Name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        <Typography variant="body1">
                            Record the mass of all waste removed from a harvest. <b>Remaining Weight: {harvest.metrc.CurrentWeight}</b>
                        </Typography>
                        <SelectValid
                            label="Reason"
                            value={reason}
                            onChange={setReason}
                            fullWidth
                            id="harvest-waste-reason"
                            validators={[]}
                            disabled={reasons.length === 1}
                        >
                            <MenuItem value=""/>
                            {reasons.map( ( item ) => (
                                <MenuItem key={item.Name} value={item.Name}>{item.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <TextValid
                            type="number"
                            value={weight}
                            onChange={setWeight}
                            id="harvest-waste-weight"
                            label="Weight of Waste (Grams)"
                            validators={[]}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            color="secondary"
                                            onClick={() => setWeight( harvest.metrc.CurrentWeight.toString() )}
                                        >
                                            All
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Divider style={{ marginBottom: 10 }}/>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!reason || parseFloat( weight ) <= 0}
                            onClick={handleSubmit}
                        >
                            Submit Waste Removal
                        </Button>
                    </>
                )}
            </div>
        </>
    )
};

export default HarvestWaste;