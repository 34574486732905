import React, { useContext } from 'react';
import firebase from 'firebase/app';
import { navigate } from '@reach/router';
import Button from '@material-ui/core/Button';
import { FirebaseContext } from '../../firebase_init';
import GoogleIcon from './googleIcon';

interface IProps {
    className?: string;
}

const GoogleLogin: React.FC<IProps> = ( { className } ) => {
    const { auth } = useContext( FirebaseContext );

    return (
        <Button
            className={className}
            variant="contained"
            fullWidth
            size="large"
            startIcon={<GoogleIcon height="20px" width="20px"/>}
            onClick={() => signIn( auth )}
        >
            Google
        </Button>
    );
};

const signIn = ( auth: firebase.auth.Auth ) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope( 'email' );
    provider.addScope( 'profile' );
// Step 1.
// User tries to sign in to Google.
    auth.signInWithPopup( provider )
        .then( () => window.location.href.includes( 'login' ) ? navigate( '/' ) : undefined )
        .catch( function ( error ) {
            // An error happened.
            if ( error.code === 'auth/account-exists-with-different-credential' ) {
                // Step 2.
                // User's email already exists.
                // The pending Google credential.
                const pendingCred = error.credential;
                // The provider account's email address.
                const email = error.email;
                // Get sign-in methods for this email.
                auth.fetchSignInMethodsForEmail( email ).then( function ( methods ) {
                    // Step 3.
                    // If the user has several sign-in methods,
                    // the first method in the list will be the "recommended" method to use.
                    if ( methods[0] === 'password' ) {
                        // Asks the user their password.
                        // In real scenario, you should handle this asynchronously.
                        const password = prompt( 'Enter Password of account with email: \n' + email ); // TODO: implement promptUserForPassword.
                        if ( password ) {
                            auth.signInWithEmailAndPassword( email, password ).then( function ( user ) {
                                // Step 4a.
                                if ( user.user ) {
                                    return user.user.linkWithCredential( pendingCred );
                                }
                            } ).then( function () {
                                // Google account successfully linked to the existing Firebase user.
                                navigate( '/' );
                            } );
                        }
                        return;
                    }
                    // All the other cases are external providers.
                    // Construct provider object for that provider.
                    // TODO: implement getProviderForProviderId.
                    // const provider = getProviderForProviderId(methods[0]);
                    // // At this point, you should let the user know that they already has an account
                    // // but with a different provider, and let them validate the fact they want to
                    // // sign in with this provider.
                    // // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
                    // // so in real scenario you should ask the user to click on a "continue" button
                    // // that will trigger the signInWithPopup.
                    // auth.signInWithPopup(provider).then(function (result) {
                    //     // Remember that the user may have signed in with an account that has a different email
                    //     // address than the first one. This can happen as Firebase doesn't control the provider's
                    //     // sign in flow and the user is free to login using whichever account they own.
                    //     // Step 4b.
                    //     // Link to Google credential.
                    //     // As we have access to the pending credential, we can directly call the link method.
                    //     result.user.linkAndRetrieveDataWithCredential(pendingCred).then(function (usercred) {
                    //         // Google account successfully linked to the existing Firebase user.
                    //         goToApp();
                    //     });
                    // });
                } );
            }
        } );
};
export default GoogleLogin;
