import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import NotificationIcon from '@material-ui/icons/Notifications';
import { FirebaseContext } from '../../../firebase_init';
import { selectNotifications, selectUser, selectUsers, setError } from '../../../redux/appSlice';
import { INotification } from '@shared/interfaces/lib/interfaces';
import { navigate } from '@reach/router';
import { convertMessage, notificationLink, userTemplate } from '../../../utils';
import ListItemText from '@material-ui/core/ListItemText';

const MAX_SHOWN = 10;

const Notifications: React.FC = () => {
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const user = useSelector(selectUser);
    const notifications = useSelector(selectNotifications);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
    const context = useContext( FirebaseContext );

    const handleClose = () => {
        const collection = context.firestore.collection( Collections.users )
            .doc( user && user.uid )
            .collection( Collections.notifications );

        notifications.filter( ( notification ) => !notification.seen )
            .forEach( ( notification ) => {
                collection.doc( notification.id )
                    .update( {
                        ...notification,
                        seen: true,
                    } )
                    .catch( ( e: Error ) => dispatch( setError( e.message ) ) );
            } );

        setAnchorEl( null );
    };

    const handleAll = () => {
        handleClose();
        return navigate( '/notifications' );
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean( anchorEl )}
            onClose={handleClose}
        >
            {notifications.slice( 0, MAX_SHOWN ).map( ( notification: INotification ) => {
                const found = users.find((item) => item.uid === notification.userId);
                const text = found ? userTemplate(found) : 'System';
                const sub = text + ' - ' + new Date( notification.timestamp ).toLocaleString();
                return (
                    <ListItem
                        key={notification.id}
                        disabled={notification.seen}
                        onClick={() => navigate(notificationLink(notification))}
                    >
                        <ListItemText
                            primary={convertMessage( notification.message, false )}
                            secondary={convertMessage(sub)}
                        />
                    </ListItem>
                );
            } )}
            <Divider component="li"/>
            <ListItem
                button
                onClick={handleAll}
            >
                Show All Notifications
            </ListItem>
        </Menu>
    );


    return (
        <>
            <IconButton
                style={{ marginRight: 10 }}
                edge="end"
                aria-label="system notifications"
                aria-haspopup="true"
                color="inherit"
                onClick={( event: React.MouseEvent<HTMLButtonElement> ) => setAnchorEl( event.currentTarget )}
            >
                <Badge
                    badgeContent={notifications.filter( ( notification ) => !notification.seen ).length}
                    color="secondary"
                >
                    <NotificationIcon/>
                </Badge>
            </IconButton>
            {renderMenu}
        </>
    );
};

export default Notifications;
