import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import { IItem } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../../firebase_init';
import { selectStrains } from '../../../redux/appSlice';
import InitialGenStrains from './generate';
import NewItem from './newItem';

const Items: React.FC<RouteComponentProps> = () => {
    const { firestore } = useContext( FirebaseContext );
    const [items, setItems] = useState<IItem[] | undefined>( undefined );
    const strains = useSelector( selectStrains );

    useEffect( () => {
        return firestore.collection( Collections.packageItems )
            .onSnapshot( snap => {
                setItems( snap.docs.map( doc => doc.data() as IItem ) );
            } );
    }, [] );

    if(items === undefined){
        return null;
    }

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Items
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {items.length} items
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
            </Box>
            {items.length === 0 && (
                <InitialGenStrains/>
            )}
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                        <TableCell>Name</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Strain</TableCell>
                        <TableCell>Unit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map( item => {
                        const strain = strains.find( strain => strain.id === item.strainId );
                        return (
                            <TableRow hover key={item.id} onClick={() => navigate( '../items/' + item.id )}>
                                <TableCell>
                                    {item.name}
                                </TableCell>
                                <TableCell>
                                    {item.productCategoryName}
                                </TableCell>
                                <TableCell>
                                    {item.strainId === null && 'Multi Strain'}
                                    {item.strainId === undefined && '(Not Cultivatd Linked)'}
                                    {item.strainId && strain && strain.name}
                                </TableCell>
                                <TableCell>
                                    {item.unitOfMeasure}
                                </TableCell>
                            </TableRow>
                        );
                    } )}
                </TableBody>
            </Table>
            <NewItem/>
        </>
    );
};

export default Items;
