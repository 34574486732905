import { IAddress } from '@shared/interfaces/lib/interfaces';

export enum ContactServices {
    Genetics = 'GENETICS',
    Supplies = 'SUPPLIES',
    Financial = 'FINANCIAL',
    RealEstate = 'REAL_ESTATE',
    ProductTesting = 'PRODUCT_TESTING',
}

export interface IPersonContact{
    id: string;
    name: string;
    title: string;
    phone: string;
    email: string;
}

export interface IExternalContact {
    id: string;
    name: string;
    address: IAddress;
    services: ContactServices[];
    contacts: IPersonContact[];
    notes: string;
    active: boolean;
}
