import {
    Collections,
    MetrcFacilitySubCollections,
    MetrcLocationSubCollections
} from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Form, Validators } from '@happybandit/react-validation';
import { ISystemEnvironment } from '../../../../../environmentInterfaces';
import { FirebaseContext } from '../../../../../firebase_init';
import { ReactComponent as ECIcon } from '../../../../../components/Icons/ec.svg';
import { ReactComponent as PHIcon } from '../../../../../components/Icons/ph.svg';
import { ReactComponent as WaterIcon } from '../../../../../components/Icons/water.svg';
import TextValid from '../../../../../components/TextValid';
import SaveButton from '../../../../../components/saveButton';
import { selectUser } from '../../../../../redux/appSlice';
import FacilityContext from '../../../facilityContext';
import WaterHistory from './waterHistory';

interface IProps {
    edit: boolean;
    systemId: string;
    roomId: string;
}

const Environment: React.FC<IProps> = ( { systemId, roomId, edit } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const [ec, setEC] = useState<number>( 0 );
    const [ph, setPH] = useState<string>( '' );
    const [temperature, setTemperature] = useState<number>( 0 );
    const [lastDate, setLastDate] = useState<string>('');
    const [loading, setLoading] = useState<boolean>( false );
    const user = useSelector( selectUser );

    useEffect( () => {
        firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.environment )
            .where( 'systemId', '==', systemId )
            .orderBy( 'dateTime', 'desc' )
            .limit( 1 )
            .onSnapshot( ( snapshot ) => {
                if ( snapshot.docs && snapshot.docs.length > 0 ) {
                    const last = snapshot.docs[0].data() as ISystemEnvironment;
                    setEC( last.ec );
                    setPH( last.ph );
                    setTemperature( last.temperature );
                    setLastDate(last.dateTime);
                }
            } );
    }, [] );

    if ( !user ) {
        return null;
    }

    const createEnvironment = (): ISystemEnvironment => {
        return {
            userId: user.uid,
            ph,
            ec,
            systemId,
            temperature,
            dateTime: (new Date()).toISOString(),
        };
    };

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user ) {
            return;
        }

        setLoading( true );

        return firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.environment )
            .add( createEnvironment() );
    };

    return edit ? (
        <div style={{ paddingBottom: '1em', paddingTop: '.5em' }}>
            <Form id={`water-env-${systemId}`} onSubmit={handleSubmit}>
                <TextValid
                    id={`water-env-${systemId}-temp`}
                    label="Temperature"
                    type="number"
                    fullWidth
                    value={temperature.toString()}
                    onChange={( changed ) => setTemperature( parseInt( changed ) )}
                    validators={[Validators.required( temperature.toString() ), Validators.positive( temperature.toString() )]}
                />
                <TextValid
                    id={`water-env-${systemId}-ph`}
                    label="PH"
                    type="number"
                    fullWidth
                    value={ph}
                    onChange={( changed ) => setPH( changed )}
                    validators={[Validators.required( ph ), Validators.positive( ph )]}
                />
                <TextValid
                    id={`water-env-${systemId}-ec`}
                    label="EC"
                    type="number"
                    fullWidth
                    value={ec.toString()}
                    onChange={( changed ) => setEC( parseInt( changed ) )}
                    validators={[Validators.required( ec.toString() ), Validators.positive( ec.toString() )]}
                />
                <SaveButton loading={loading}/>
            </Form>
        </div>
    ) : (
        <div style={{ paddingBottom: '1em', paddingTop: '.5em' }}>
            <Paper elevation={0} variant="outlined" square>
                <Box p={2}>
                    <Box p={1} borderBottom={1} style={{ 'borderBottom': '1px solid #eee' }}>
                        <Typography variant="h5">
                            <WaterIcon height="20" width="20"/>
                            {temperature}<sup>&#176;</sup>
                            <WaterHistory
                                systemId={systemId}
                                roomId={roomId}
                                type="temperature"
                            />
                        </Typography>
                    </Box>

                    <Box p={1} borderBottom={1} style={{ 'borderBottom': '1px solid #eee' }}>
                        <Typography variant="h5">
                            <PHIcon height="20" width="20"/>
                            {ph}<sup>pH</sup>
                            <WaterHistory
                                systemId={systemId}
                                roomId={roomId}
                                type="ph"
                            />
                        </Typography>
                    </Box>

                    <Box p={1} style={{ 'borderBottom': '1px solid #eee' }}>
                        <Typography variant="h5">
                            <ECIcon height="20" width="20"/>
                            {ec}<sup>EC</sup>
                            <WaterHistory
                                systemId={systemId}
                                roomId={roomId}
                                type="ec"
                            />
                        </Typography>
                    </Box>
                    <br/>
                    <Typography variant="subtitle2">
                        Last Recorded Data:
                        <br/>
                        {lastDate ? `${new Date( lastDate ).toLocaleDateString()} at ${new Date( lastDate ).toLocaleTimeString()}` : 'No changes recorded yet'}
                    </Typography>
                </Box>
            </Paper>
        </div>
    );
};

export default Environment;
