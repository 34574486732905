import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IPlant, IPlantBatch, ISystem, RoomTypes } from '@shared/interfaces/lib/interfaces';
import React, { useState } from 'react';
import ZoneDetailsAdd from './add';
import ZoneDetailsBatchAdd from './addBatch';
import ZoneDetailsView from './view';
import ZoneDetailsBatchView from './viewBatch';

interface IProps {
    system: ISystem;
    rackIndex: number;
    levelIndex: number;
    zoneIndex: number;
    plants: (IPlant | IPlantBatch)[];
    onClose: () => void;
}

enum DialogStates {
    view,
    add,
}


const ZoneDetails: React.FC<IProps> = ( { rackIndex, levelIndex, zoneIndex, system, plants, onClose } ) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [state, setState] = useState( DialogStates.view );

    const getContent = () => {
        if ( system.type === RoomTypes.clone ) {
            if ( state === DialogStates.view ) {
                return <ZoneDetailsBatchView
                    batches={plants as IPlantBatch[]}
                    onAdd={() => setState( DialogStates.add )}
                />;
            } else {
                return <ZoneDetailsBatchAdd
                    system={system}
                    rackIndex={rackIndex}
                    levelIndex={levelIndex}
                    zoneIndex={zoneIndex}
                    onDone={() => setState( DialogStates.view )}
                />;
            }
        } else {
            if ( state === DialogStates.view ) {
                return <ZoneDetailsView
                    plants={plants as IPlant[]}
                    onAdd={() => setState( DialogStates.add )}
                />;
            } else {
                return <ZoneDetailsAdd
                    system={system}
                    rackIndex={rackIndex}
                    levelIndex={levelIndex}
                    zoneIndex={zoneIndex}
                    onDone={() => setState( DialogStates.view )}
                />;
            }
        }
    }

    return (
        <Dialog fullScreen={fullScreen} open={true} onClose={onClose}>
            <DialogTitle style={{ 'paddingBottom': '0' }}>
                <Typography variant="h5" component="p" style={{ display: 'inline' }}>
                    <strong>
                        Rack {rackIndex} / Level {levelIndex} / Zone {zoneIndex}
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    <strong>
                        {' '}/ Details
                    </strong>
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
            </DialogTitle>
            {getContent()}
        </Dialog>
    );
};

export default ZoneDetails;
