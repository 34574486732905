import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISyncTask } from '../../../functions/src/sync';
import { RootState } from './store';
import {
    IFacility,
    IInfoCategory,
    INotification,
    IOrgSettings,
    IRoom,
    IStrain,
} from '@shared/interfaces/lib/interfaces';
import {
    IConsumableItem,
    IEquipmentItem,
    IInventoryCategory,
    IInventoryDetail
} from '@shared/interfaces/lib/inventoryInterfaces';
import { IExternalContact } from '../pages/externalContacts/interfaces';
import { ITest } from '../pages/testing/interfaces';
import { ICurrentUser, IUser, User } from '../User';
import {
    IItemDetailItems,
    IItemDetails,
    IShowArticle,
    IShowPlantDetailItem,
    IShowTask,
    PlantDetailPages
} from './interfaces';

export type AppState = {
    rooms: Record<string, IRoom[]>;
    strains: IStrain[];
    facilities: IFacility[];
    tests: ITest[];
    inventoryDetails: IInventoryDetail[];
    inventoryCategories: IInventoryCategory[];
    equipment: IEquipmentItem[];
    consumables: IConsumableItem[];
    externalContacts: IExternalContact[];
    users: IUser[];
    shownStrain?: string;
    showArticle?: IShowArticle;
    error?: string;
    user?: ICurrentUser;
    firstLoadComplete: boolean;
    notifications: INotification[];
    infoCategories: IInfoCategory[];
    orgSettings?: IOrgSettings;
    syncTask?: ISyncTask;
    showPlantDetailsItem?: IShowPlantDetailItem;
    showPlantDetails: IItemDetails | undefined;
    showTask?: IShowTask;
}

let initialState: AppState = {
    rooms: {},
    tests: [],
    users: [],
    strains: [],
    equipment: [],
    facilities: [],
    consumables: [],
    showArticle: undefined,
    user: undefined,
    error: undefined,
    notifications: [],
    infoCategories: [],
    syncTask: undefined,
    externalContacts: [],
    inventoryDetails: [],
    shownStrain: undefined,
    orgSettings: undefined,
    inventoryCategories: [],
    firstLoadComplete: false,
    showPlantDetails: undefined,
    showPlantDetailsItem: undefined,
    showTask: undefined,
};

const appSlice = createSlice( {
    name: 'app',
    initialState,
    reducers: {
        setRooms( state, action: PayloadAction<Record<string, IRoom[]>> ) {
            state.rooms = {
                ...state.rooms,
                ...action.payload
            };
        },
        setStrains( state, action: PayloadAction<IStrain[]> ) {
            state.strains = action.payload;
        },
        setFacilities( state, action: PayloadAction<IFacility[]> ) {
            state.facilities = action.payload;
        },
        setTests( state, action: PayloadAction<ITest[]> ) {
            state.tests = action.payload;
        },
        setError( state, action: PayloadAction<string | undefined> ) {
            state.error = action.payload;
        },
        setUser( state, action: PayloadAction<ICurrentUser | undefined> ) {
            state.user = action.payload;
        },
        setUsers( state, action: PayloadAction<IUser[]> ) {
            state.users = action.payload;
        },
        setDone( state ) {
            state.firstLoadComplete = true;
        },
        showStrain( state, action: PayloadAction<string | undefined> ) {
            state.shownStrain = action.payload;
        },
        setShowArticle( state, action: PayloadAction<IShowArticle | undefined> ) {
            state.showArticle = action.payload;
        },
        setNotifications( state, action: PayloadAction<INotification[]> ) {
            state.notifications = action.payload;
        },
        setInfoCategories( state, action: PayloadAction<IInfoCategory[]> ) {
            state.infoCategories = action.payload;
        },
        setInventoryDetails( state, action: PayloadAction<IInventoryDetail[]> ) {
            state.inventoryDetails = action.payload;
        },
        setInventoryCategories( state, action: PayloadAction<IInventoryCategory[]> ) {
            state.inventoryCategories = action.payload;
        },
        setEquipmentItems( state, action: PayloadAction<IEquipmentItem[]> ) {
            state.equipment = action.payload;
        },
        setConsumableItems( state, action: PayloadAction<IConsumableItem[]> ) {
            state.consumables = action.payload;
        },
        setExternalContacts( state, action: PayloadAction<IExternalContact[]> ) {
            state.externalContacts = action.payload;
        },
        setOrgSettings( state, action: PayloadAction<IOrgSettings | undefined> ) {
            state.orgSettings = action.payload;
        },
        setSyncTask( state, action: PayloadAction<ISyncTask | undefined> ) {
            state.syncTask = action.payload;
        },
        setShowPlantDetails( state, action: PayloadAction<IItemDetails | undefined> ) {
            state.showPlantDetails = action.payload;
        },
        setShowPlantDetailsItem( state, action: PayloadAction<IShowPlantDetailItem | undefined> ) {
            state.showPlantDetailsItem = action.payload;
        },
        setShowTask( state, action: PayloadAction<IShowTask | undefined> ) {
            state.showTask = action.payload;
        },
    }
} );

export const selectApp = ( state: RootState ) => state.app;

export const selectDone = createSelector(
    selectApp,
    ( app ) => app.firstLoadComplete,
);

export const selectUser = createSelector(
    selectApp,
    ( app ) => app.user ? new User( app.user ) : undefined,
);

export const selectUsers = createSelector(
    selectApp,
    ( app ) => app.users,
);

export const selectNotifications = createSelector(
    selectApp,
    ( app ) => app.notifications,
);

export const selectOrgSettings = createSelector(
    selectApp,
    ( app ) => app.orgSettings,
);

export const selectRooms = createSelector(
    selectApp,
    ( app ) => app.rooms,
);

export const selectAllFacilities = createSelector(
    selectApp,
    ( app ) => app.facilities,
);

export const selectFacilities = createSelector(
    selectAllFacilities,
    selectOrgSettings,
    ( all, org ) => {
        const allowedIds = org?.activeFacilityIds ?? [];
        return all.filter(fac => allowedIds.includes(fac.id));
    },
);

export const selectStrains = createSelector(
    selectApp,
    ( app ) => app.strains,
);

export const selectTests = createSelector(
    selectApp,
    ( app ) => app.tests,
);

export const selectExternalContacts = createSelector(
    selectApp,
    ( app ) => app.externalContacts,
);

export const selectEquipment = createSelector(
    selectApp,
    ( app ) => app.equipment,
);

export const selectInventoryCategories = createSelector(
    selectApp,
    ( app ) => app.inventoryCategories,
);

export const selectConsumables = createSelector(
    selectApp,
    ( app ) => app.consumables,
);

export const selectInfoCategories = createSelector(
    selectApp,
    ( app ) => app.infoCategories,
);

export const selectSyncTask = createSelector(
    selectApp,
    ( app ) => app.syncTask,
);

export const selectShowPlantDetails = createSelector(
    selectApp,
    ( app ) => {
        if(app.showPlantDetails){
            return {
                ...app.showPlantDetails,
                ...(app.showPlantDetails.page !== PlantDetailPages.get ? app.showPlantDetailsItem : undefined),
            } as IItemDetailItems;
        }
    },
);

export const selectInventoryDetails = createSelector(
    selectApp,
    ( app ) => app.inventoryDetails,
);

export const selectShowTask = createSelector(
    selectApp,
    ( app ) => app.showTask,
);

export const selectShowArticle = createSelector(
    selectApp,
    ( app ) => app.showArticle,
);

export const selectIsMetrcCustomer = createSelector(
    selectOrgSettings,
    ( orgSettings ) => orgSettings && orgSettings.syncUserKey,
);

export const selectError = createSelector(
    selectApp,
    ( app ) => app.error,
);

export const {
    setStrains,
    setError,
    setUser,
    setUsers,
    setRooms,
    showStrain,
    setShowArticle,
    setDone,
    setNotifications,
    setFacilities,
    setInfoCategories,
    setInventoryDetails,
    setInventoryCategories,
    setEquipmentItems,
    setConsumableItems,
    setExternalContacts,
    setTests,
    setOrgSettings,
    setSyncTask,
    setShowPlantDetails,
    setShowPlantDetailsItem,
    setShowTask,
} = appSlice.actions;

export default appSlice.reducer