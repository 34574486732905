import React, { useLayoutEffect, useRef, useState } from 'react';
import { IValidatorOutput } from '@happybandit/react-validation/dist/interfaces';
import { useInputValidation } from '@happybandit/react-validation/dist/hooks';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { SelectProps } from '@material-ui/core/Select/Select';

interface IProps extends Omit<SelectProps, 'onChange' | 'variant' | 'margin' | 'labelWidth' | 'native'> {
    value?: string;
    onChange: ( newValue: string ) => void;
    label?: string;
    id: string;
    validators: IValidatorOutput[];
    disabled?: boolean;
}

const SelectValid: React.FC<IProps> = ( { fullWidth, value, disabled = false, id, label, onChange, validators, children, ...otherProps } ) => {
    const { valid, message } = useInputValidation( validators );
    const inputLabel = useRef<HTMLLabelElement>( null );
    const [labelWidth, setLabelWidth] = useState( 0 );
    useLayoutEffect( () => {
        if ( inputLabel.current && labelWidth === 0 ) {
            setLabelWidth( inputLabel.current.offsetWidth );
        }
    }, [labelWidth] );

    return (
        <FormControl variant="outlined" error={!valid} margin="normal" fullWidth={fullWidth} disabled={disabled}>
            <InputLabel ref={inputLabel} htmlFor={`select-valid-${id}`} id={`select-valid-${id}-label`}>
                {label}
            </InputLabel>
            <Select
                labelWidth={labelWidth}
                variant="outlined"
                id={`select-valid-${id}`}
                value={value || ''}
                labelId={`select-valid-${id}-label`}
                {...otherProps}
                onChange={( event ) => onChange( event.target.value as string )}
            >
                {children}
            </Select>
            {!valid && (
                <FormHelperText>
                    {message}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default SelectValid;