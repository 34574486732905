import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@happybandit/react-validation';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, Divider } from '@material-ui/core';
import { ILight, IFacility } from '@shared/interfaces/lib/interfaces';
import SelectValid from 'components/SelectValid';
import DateValid from 'components/DateValid';
import { ReactComponent as LightIcon } from 'components/Icons/lights.svg';
import { selectInventoryDetails } from 'redux/appSlice';
import { ConsumableTypes, EquipmentTypes, IConsumableDetail } from '@shared/interfaces/lib/inventoryInterfaces';
import { isDetailConsumable } from 'utils';

interface IProps {
    light: ILight;
    onCancel: () => void;
    facility: IFacility | undefined;
    onUpdate: ( newLight: ILight ) => void;
}

const Edit: React.FC<IProps> = ( { light, onUpdate, onCancel } ) => {
    const [inventoryId, setInventoryId] = useState( light.inventoryId );
    const [bulbId, setBulbId] = useState( light.bulbId );
    const [lightDateInstalled, setLightDateInstalled] = useState( light.dateInstalled ? new Date( light.dateInstalled ) : null );
    const [bulbDateInstalled, setBulbDateInstalled] = useState( light.dateBulbInstalled ? new Date( light.dateBulbInstalled ) : null );
    const inventoryDetails = useSelector(selectInventoryDetails);

    const lightTypes = inventoryDetails.filter( ( item ) => item.type === EquipmentTypes.Light );
    const bulbTypes: IConsumableDetail[] = inventoryDetails.filter(  isDetailConsumable ).filter((item) => item.type === ConsumableTypes.Bulbs);

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        onUpdate( {
            ...light,
            inventoryId,
            bulbId,
            dateBulbInstalled: bulbDateInstalled ? bulbDateInstalled.toISOString() : undefined,
            dateInstalled: lightDateInstalled ? lightDateInstalled.toISOString() : undefined,
        } );
    };

    return (
        <>
            <DialogTitle id="form-dialog-title">
                <LightIcon height="50" width="50"/>
                Edit Light
            </DialogTitle>
            <DialogContent>
                <Form id={`light-${light.id}`} onSubmit={handleSubmit}>
                    <Typography style={{ paddingBottom: '0.5em' }} variant="h6">
                        Select a Light from Inventory to assign to the System:
                    </Typography>
                    <Divider/>
                    <SelectValid
                        id={`light-edit-${light.id}`}
                        value={inventoryId}
                        label="Select a Light"
                        fullWidth
                        disabled={lightTypes.length === 0}
                        validators={[]}
                        onChange={( value ) => setInventoryId( value )}
                    >
                        <MenuItem value=""/>
                        {lightTypes && lightTypes.map( ( inventory ) => (
                            <MenuItem
                                key={inventory.id}
                                value={inventory.name}
                            >
                                {inventory.name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <SelectValid
                        id={`light-edit-${light.id}`}
                        value={bulbId}
                        label="Type of Bulb"
                        fullWidth
                        disabled={bulbTypes.length === 0}
                        validators={[]}
                        onChange={( value ) => setBulbId( value )}
                    >
                        <MenuItem value=""/>
                        {bulbTypes && bulbTypes.map( ( inventory ) => (
                            <MenuItem
                                key={inventory.id}
                                value={inventory.name}
                            >
                                {inventory.name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <Typography style={{ paddingBottom: '0.5em' }} variant="h6">
                        Edit this Light:
                    </Typography>
                    <Divider/>
                    <DateValid
                        id={`site-${light.id}-bulb-installed`}
                        label="Bulb Installed"
                        fullWidth
                        clearable
                        validators={[]}
                        onChange={setBulbDateInstalled}
                        value={bulbDateInstalled}
                    />
                    <DateValid
                        id={`site-${light.id}-installed`}
                        label="Date Installed"
                        fullWidth
                        clearable
                        validators={[]}
                        onChange={setLightDateInstalled}
                        value={lightDateInstalled}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button type="submit" form={`light-${light.id}`} color="primary">
                    Update
                </Button>
            </DialogActions>
        </>
    );
};

export default Edit;