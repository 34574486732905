import React, { useState } from 'react';
import { groupBy, isEmpty, Dictionary } from 'lodash';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { getIcon, useSelectedFacility, useSelectedStrain } from '../../../utils';

import {
    selectExternalContacts,
    selectTests,
    selectUser,
    selectUsers
} from '../../../redux/appSlice';
import { ITest } from '../interfaces';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { makeStyles, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import NewTest from './newTest';
import ViewTest from './view';

const useStyles = makeStyles( () => ({
    section: {
        paddingTop: '2em',
        paddingBottom: '3em',
    },
    root: {
        width: '100%',
        '& li:first-child': {
            borderTop: '1px solid lightgrey',
        },
        '& li:last-child': {
            borderBottom: 'none',
        }
    },
    listItem: {
        borderBottom: '1px solid lightgrey',
    }
}) );

interface IBlockProps {
    test: ITest;
}

const TestBlock: React.FC<IBlockProps> = ( { test } ) => {
    const classes = useStyles();
    const users = useSelector( selectUsers );
    const externalContacts = useSelector( selectExternalContacts );
    const strain = useSelectedStrain( ( strain ) => strain.id === test.strainId );
    const facility = useSelectedFacility( ( facility ) => facility.id === test.facilityOriginatedId );

    const user = users.find( ( user ) => user.uid === test.delivereeId );
    const contact = externalContacts.find( ( contact ) => contact.id === test.strainId );

    return (
        <ListItem
            key={test.name} className={classes.listItem}
            style={{ 'paddingTop': '1em', 'paddingBottom': '1em', 'paddingLeft': '2em' }}
        >
            <ListItemIcon style={{ 'paddingRight': '1em' }}>
                {getIcon( !isEmpty( test.dateDelivered ) ? 'Delivered' : 'In Transit', '40', '40' )}
            </ListItemIcon>
            <ListItemText secondary={ facility ? facility.metrc.DisplayName : ''} primary="ORIGINATING" />
            <ListItemText secondary={strain ? strain.name : ''} primary="STRAIN"/>
            <ListItemText secondary={test.type} primary="TYPE"/>
             <ListItemText secondary={contact ? contact.name : ''} primary="TESTING AT" />
            <ListItemText secondary={user ? user.displayName : ''} primary="DELIVEREE"/>
            <ListItemText
                secondary={test.dateDelivered ? test.dateDelivered : 'IN TRANSIT'}
                primary="DATE DELIVERED"
            />
            <ListItemText secondary={test.weightDelivered.toString()} primary="WEIGHT DELIVERED"/>
            <ListItemText>
                <ViewTest
                    test={test}
                />
            </ListItemText>
        </ListItem>
    );
};

interface ICollapseProps {
    name: string;
    icon: React.ReactElement;
}

const TestCollapse: React.FC<ICollapseProps> = ( { name, icon, children } ) => {
    const classes = useStyles();
    const [open, setOpen] = useState( false );

    return (
        <Grid item xs={12} className={classes.listItem}>
            <List
                component="nav"
                className={classes.root}
                aria-labelledby={name}
            >
                <ListItem button onClick={() => setOpen( ( value ) => !value )}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={name}/>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List dense={true} component="div">
                        {children}
                    </List>
                </Collapse>
            </List>
        </Grid>
    );
};

const TestingList: React.FC<RouteComponentProps> = () => {
    const classes = useStyles();
    const user = useSelector( selectUser );
    const tests = useSelector( selectTests );

    const testsInProgress = tests.filter( test => test.inProgress );
    const completedGroupedTests: Dictionary<ITest[]> = groupBy( tests.filter( test => !test.inProgress ), 'strain.name' );
    const completedGroupedTestsArray: [string, ITest[]][] = Object.entries( completedGroupedTests );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: 'inline' }}>
                        <strong>
                            External Testing
                        </strong>
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    {user && user.isAllowed( UserPermissions.generalEdit ) && <NewTest/>}
                </Grid>
            </Grid>

            <Grid component="section" container spacing={3} className={classes.section}>
                <List
                    component="nav"
                    className={classes.root}
                    aria-labelledby="Testing"
                >
                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Tests In Progress
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ {testsInProgress.length} in progress
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    {testsInProgress && testsInProgress.length === 0 && (
                        <ListItemText primary="No Tests In Progress"/>
                    )}
                    {testsInProgress && testsInProgress.map( ( test: ITest ) => (
                        <TestCollapse
                            name={test.name}
                            icon={getIcon( 'Test' )}
                        >
                            <TestBlock
                                key={test.id}
                                test={test}
                            />
                        </TestCollapse>
                    ) )}
                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Completed Tests
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ {completedGroupedTestsArray.length} completed
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    {completedGroupedTestsArray && completedGroupedTestsArray.length === 0 && (
                        <ListItemText primary="No Completed Tests"/>
                    )}
                    {completedGroupedTestsArray && completedGroupedTestsArray.map( ( [strainName, tests] ) => (
                        <TestCollapse
                            name={strainName}
                            icon={getIcon( 'TestComplete', '40', '40' )}
                        >
                            {tests.map( ( test: ITest ) => (
                                <TestBlock
                                    key={test.id}
                                    test={test}
                                />
                            ) )}
                        </TestCollapse>
                    ) )}
                </List>
            </Grid>
        </>
    );
};

export default TestingList;
