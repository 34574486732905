import React, { useEffect, useState } from 'react';
import {
    createMuiTheme,
    ThemeProvider,
    useTheme,
    Theme,
} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectOrgSettings } from '../../redux/appSlice';

const ThemeWrapper: React.FC = ( { children } ) => {
    const defaultTheme = useTheme();
    const orgSettings = useSelector( selectOrgSettings );
    const [theme, setTheme] = useState<Theme>();

    useEffect( () => {
        if ( orgSettings ) {
            const newTheme = createMuiTheme( {
                palette: {
                    primary: {
                        main: orgSettings.primaryColor || defaultTheme.palette.primary.main,
                    },
                    secondary: {
                        main: orgSettings.secondaryColor || defaultTheme.palette.secondary.main,
                    },
                },
            } );
            setTheme( newTheme );
        } else {
            setTheme( defaultTheme );
        }
    }, [orgSettings] );

    if ( !theme ) {
        return null;
    }

    return (
        <ThemeProvider
            theme={theme}
        >
            {children}
        </ThemeProvider>
    );
};

export default ThemeWrapper;
