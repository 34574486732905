import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/appSlice';
import { startSync } from './api';

const Sync = (): any => {
    const user = useSelector( selectUser );

    if ( !user || process.env.NODE_ENV === 'production' ) {
        return null;
    }

    const handleSync = () => {
        startSync( user.token )
            .catch();
    };

    return (
        <MenuItem onClick={handleSync}>
            Force Sync with Metrc
        </MenuItem>
    );
};

export default Sync;
