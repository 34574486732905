import React from 'react';
import { navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import { IFacility } from '@shared/interfaces/lib/interfaces';
import { Paper, Grid } from '@material-ui/core';

interface IProps {
    facility: IFacility;
}

const DashInventory: React.FC<IProps> = ( { facility } ) => {
    return (
        <Paper onClick={() => navigate( '/facility/' + facility.id + '/inventory' )} elevation={ 0 } variant="outlined" square style={{ padding: '1em' }}>
             <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                    <Typography variant="h5" style={{ display: 'inline' }}>
                        <strong>
                            Inventory
                        </strong>
                    </Typography>
                    <Typography variant="body2" component="p" style={{ display: 'inline' }}>
                        <strong>
                            &nbsp;/ 26 Items in Inventory
                        </strong>
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DashInventory;
