import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import CreateItem from '../../list/newItem/create';
import { IProps } from './interfaces';

const EditProduct: React.FC<IProps> = ( { open, onClose, item } ) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    return (
        <>
            <Dialog
                fullScreen={fullScreen} open={open} onClose={onClose} aria-labelledby="form-dialog-edit-item-title"
            >
                <CreateItem
                    item={item}
                    onClose={onClose}
                    onBack={onClose}
                />
            </Dialog>
        </>
    );
};

export default EditProduct;