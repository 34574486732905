import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { navigate, RouteComponentProps } from '@reach/router'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form, Validators } from '@happybandit/react-validation';
import { setError } from '../../redux/appSlice';
import { FirebaseContext } from '../../firebase_init';
import TextValid from '../../components/TextValid';
import { mediumRegex, passwordConfirmText, passwordText } from '../../utils';

const useStyles = makeStyles( ( theme: Theme ) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing( 8 ),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing( 1 ),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing( 1 ),
    },
    submit: {
        margin: theme.spacing( 3, 0, 2 ),
    },
}) );

const SignUp: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState( '' );
    const [name, setName] = useState( '' );
    const [password, setPassword] = useState( '' );
    const [confirmPassword, setConfirmPassword] = useState( '' );
    const classes = useStyles();
    const context = useContext( FirebaseContext );

    const isValidPassword = () => mediumRegex.test( password );
    const passwordsMatch = () => password === confirmPassword;

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        context.doCreateUserWithEmailAndPassword( email, password )
            .then( ( cred ) => {
                if ( cred && cred.user ) {
                    const profilePromise = cred.user.updateProfile( {
                        displayName: name,
                    } ).then( () => cred.user && cred.user.sendEmailVerification() );

                    const reference = context.firestore.collection( Collections.users )
                        .doc( cred.user ? cred.user.uid : '' );
                    const collectionPromise = reference.set( {} );

                    return Promise.all( [profilePromise, collectionPromise] );
                }
            } )
            .then( () => {
                return navigate( '/' );
            } )
            .catch( (error: Error) => {
                dispatch(setError( error.message ));
            } );
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <Form className={classes.form} onSubmit={handleSubmit}>
                    <TextValid
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        onChange={( value ) => setName( value )}
                        validators={[Validators.required( name )]}
                    />
                    <TextValid
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={( value ) => setEmail( value )}
                        validators={[Validators.required( email ), Validators.email( email )]}
                    />
                    <TextValid
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={( value ) => setPassword( value )}
                        validators={[Validators.required( password ), Validators.custom( isValidPassword,  passwordText)]}
                    />
                    <TextValid
                        fullWidth
                        name="confirm_password"
                        label="Confirm Password"
                        type="password"
                        id="confirm_password"
                        autoComplete="current-password"
                        value={confirmPassword}
                        onChange={( value ) => setConfirmPassword( value )}
                        validators={[Validators.required( confirmPassword ), Validators.custom( passwordsMatch, passwordConfirmText )]}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2" onClick={() => navigate( '/login' )}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Form>
            </div>
        </Container>
    );
};

export default SignUp;
