import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { navigate } from '@reach/router';

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        avatar: {
            margin: theme.spacing( 1 ),
        },
    } ),
);

interface IProps {
    id: string;
    name: string | null;
    className?: string;
    style?: React.CSSProperties;
    hasLink?: boolean;
}

const colors: { [key: string]: string } = {
    a: '#e6194B',
    b: '#3cb44b',
    c: '#ffe119',
    d: '#4363d8',
    e: '#f58231',
    f: '#911eb4',
    g: '#42d4f4',
    h: '#f032e6',
    i: '#bfef45',
    j: '#469990',
    k: '#fabebe',
    l: '#e6beff',
    m: '#9A6324',
    n: '#fffac8',
    o: '#800000',
    p: '#aaffc3',
    q: '#808000',
    r: '#ffd8b1',
    s: '#000075',
    t: '#a9a9a9',
    u: '#e6194B',
    v: '#3cb44b',
    w: '#ffe119',
    x: '#4363d8',
    y: '#f58231',
    z: '#911eb4',
    '0': '#42d4f4',
    '1': '#f032e6',
    '2': '#bfef45',
    '3': '#000075',
    '4': '#469990',
    '5': '#808000',
    '6': '#9A6324',
    '7': '#fffac8',
    '8': '#800000',
    '9': '#aaffc3',
};

const getInitials = ( name: string | null ): string | null => {
    let initials = null;
    if ( name ) {
        const parts = (name || '').split( ' ' );

        const first = parts[0];
        const second = parts[1];

        if ( second && second.length > 0 ) {
            initials = first[0].toUpperCase();
        }

        if ( second && second.length > 0 ) {
            initials = initials + second[0].toUpperCase();
        }
    }

    return initials;
};

export const getColors = ( id: string ): { backgroundColor: string, color: string } => {
    const firstChar = id[0].toLowerCase();
    const background = colors[firstChar];

    const color = background.replace( '#', '' );
    const r = parseInt( color.substr( 0, 2 ), 16 );
    const g = parseInt( color.substr( 2, 2 ), 16 );
    const b = parseInt( color.substr( 4, 2 ), 16 );
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    return {
        backgroundColor: background,
        color: (yiq >= 128) ? 'black' : 'white',
    }
};

const UserAvatar: React.FC<IProps> = ( { id, name, className = '', style, hasLink = true } ) => {
    const classes = useStyles();

    const content = (
        <Avatar
            className={`${className || ''} ${classes.avatar}`}
            style={{
                ...style,
                ...getColors( id )
            }}
            title={name || undefined}
        >
            {getInitials( name )}
        </Avatar>
    );

    if ( !hasLink ) {
        return content;
    }

    return (
        <ButtonBase
            onClick={() => navigate( '/profile/' + id )}
        >
            {content}
        </ButtonBase>
    );
};

export default UserAvatar;
