import React from 'react';
import TextField from '@material-ui/core/TextField';
import { IValidatorOutput } from '@happybandit/react-validation/dist/interfaces';
import { useInputValidation } from '@happybandit/react-validation/dist/hooks';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';

interface IProps extends Omit<OutlinedTextFieldProps, 'onChange' | 'variant' | 'margin'> {
    value?: string;
    onChange: ( newValue: string ) => void;
    label?: string;
    id: string;
    validators: IValidatorOutput[];
    forcedErrorMessage?: string;
}

const TextValid: React.FC<IProps> = ( { value, id, label, onChange, validators, forcedErrorMessage, ...otherProps } ) => {
    const { valid, message } = useInputValidation( validators );

    return (
        <TextField
            variant="outlined"
            value={value || ''}
            id={id}
            label={label}
            onChange={( event ) => onChange( event.target.value )}
            margin="normal"
            error={!valid || !!forcedErrorMessage}
            helperText={!valid ? message : forcedErrorMessage}
            {...otherProps}
        />
    );
};

export default TextValid;