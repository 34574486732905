import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';
import { selectError, setError } from '../../redux/appSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        close: {
            padding: theme.spacing(0.5),
        },
        message: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(1),
        }
    }),
);

const ErrorSnack = () => {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const error = useSelector(selectError);

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setError(undefined));
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={!!error}
            autoHideDuration={60000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={classes.error}
                aria-describedby="message-id"
                message={
                    <span id="message-id" className={classes.message}>
                    <ErrorIcon className={classes.icon}/>
                        {error}
                </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon/>
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

export default ErrorSnack;