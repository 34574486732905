import { Divider, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import { IPackageToProductBody } from '@shared/interfaces/lib/contractInterfaces';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPackage, RoomTypes } from '@shared/interfaces/lib/interfaces';
import { IMetrcError, IMetrcItem } from '@shared/interfaces/lib/metrcInterfaces';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postPackageToPackage, ResponseError } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import { useFacilityRooms } from '../../../utils';
import SelectValid from '../../SelectValid';
import TextValid from '../../TextValid';
import { useDetailsStyles } from '../details';

interface IIngredient {
    packageItem: IPackage,
    quantity: string,
}

interface IIngredientRowProps {
    ingredient: IIngredient;
    onChange: ( ingredient: IIngredient ) => void;
    onDelete: () => void;
}

const IngredientRow: React.FC<IIngredientRowProps> = ( { ingredient, onChange, onDelete } ) => {
    const handleChange = ( quantity: string ) => {
        onChange( {
            ...ingredient,
            quantity,
        } );
    };

    const { Label, Quantity } = ingredient.packageItem.metrc;

    return (
        <TableRow>
            <TableCell>
                {Label}
            </TableCell>
            <TableCell>
                {Quantity}
            </TableCell>
            <TableCell>
                <TextValid
                    onChange={handleChange}
                    value={ingredient.quantity}
                    fullWidth
                    id={`package-harvest-weight-${ingredient.packageItem.id}`}
                    validators={[]}
                    forcedErrorMessage={
                        !ingredient.quantity ||
                        parseFloat( ingredient.quantity ) > Quantity ||
                        parseFloat( ingredient.quantity ) <= 0 ?
                            'Must be a number under remaining' :
                            undefined
                    }
                />
            </TableCell>
            <TableCell>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={onDelete}
                >
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};


interface IIngredientsProps {
    facilityId: string;
    ingredients: IIngredient[];
    itemStrain: string | null | undefined;
    onChange: ( ingredients: IIngredient[] ) => void;
}

const Ingredients: React.FC<IIngredientsProps> = ( { facilityId, ingredients, onChange, itemStrain } ) => {
    const { firestore } = useContext( FirebaseContext );
    const [name, setName] = useState( '' );
    const [invalid, setInvalid] = useState( false );

    const handleAdd = () => {
        if ( itemStrain === undefined ) {
            return;
        }

        setInvalid( false );
        firestore.collection( Collections.metrcFacilities ).doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcHarvests ).doc( name )
            .get()
            .then( doc => {
                if ( doc.exists ) {
                    const packageItem = doc.data() as IPackage;
                    if ( itemStrain === null || (packageItem.metrc.ItemStrainName && packageItem.metrc.ItemStrainName.includes( itemStrain )) ) {
                        onChange( [...ingredients, {
                            packageItem,
                            quantity: '',
                        }] );
                        return;
                    }
                }
                setInvalid( true );
            } );
    };

    const handleChange = ( index: number ) => ( ingredient: IIngredient ) => {
        const newIngredients = [...ingredients];
        newIngredients[index] = ingredient;
        onChange( newIngredients );
    };

    const handleDelete = ( index: number ) => () => {
        const newIngredients = [...ingredients];
        newIngredients.splice( index, 1 );
        onChange( newIngredients );
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Remaining (g)
                    </TableCell>
                    <TableCell>
                        To Package (g)
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ingredients.map( ( ingredient, index ) => (
                    <IngredientRow
                        key={ingredient.packageItem.id}
                        ingredient={ingredient}
                        onChange={handleChange( index )}
                        onDelete={handleDelete( index )}
                    />
                ) )}
                <TableRow>
                    <TableCell colSpan={2}>
                        <TextValid
                            onChange={setName}
                            value={name}
                            fullWidth
                            id="package-harvest-name"
                            validators={[]}
                            forcedErrorMessage={invalid ? 'Invalid Name or Strain for Product' : undefined}
                        />
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={itemStrain === undefined}
                            onClick={handleAdd}
                        >
                            Add
                        </Button>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

interface IProps {
    packageItem: IPackage;
    facilityId: string;
}

const PackageToPackage: React.FC<IProps> = ( { packageItem, facilityId } ) => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const classes = useDetailsStyles();
    const user = useSelector( selectUser );
    const rooms = useFacilityRooms( facilityId );
    const [label, setLabel] = useState( '' );
    const [locationId, setLocationId] = useState( '' );
    const [quantity, setQuantity] = useState( '' );
    const [itemId, setItemId] = useState( '' );
    const [items, setItems] = useState<IMetrcItem[]>( [] );
    const [note, setNote] = useState( '' );
    const [ingredients, setIngredients] = useState<IIngredient[]>( [{
        packageItem,
        quantity: '',
    }] );
    const [loading, setLoading] = useState( false );
    const [alert, setAlert] = useState<{ errors: IMetrcError[] } | undefined>( undefined );

    const packageRooms = rooms.filter( ( room ) => (room.types || []).includes( RoomTypes.packaging ) );
    const selectedItem = items.find( item => item.id === itemId );

    useEffect( () => {
        if ( !packageItem ) {
            return;
        }
        const collection = firestore.collection( Collections.metrcFacilities ).doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcItems );

        const samePromise = collection.where( 'StrainName', '==', packageItem.metrc.ItemStrainName )
            .get()
        const multiPromise = collection.where( 'StrainName', '==', null )
            .get()

        Promise.all( [samePromise, multiPromise] ).then( ( snaps ) => {
            setItems( snaps.reduce<IMetrcItem[]>( ( acc, snap ) =>
                acc.concat( snap.docs.map( doc => doc.data() as IMetrcItem ) ), [] ) );
        } );
    }, [] );

    if ( !packageItem ) {
        return null;
    }

    const handleDone = () => {
        dispatch( setShowPlantDetails( {
            key: {
                collection: MetrcFacilitySubCollections.metrcPackages,
                id: packageItem.id,
            },
            page: PlantDetailPages.details,
        } ) );
    };

    const handleSubmit = () => {
        const selectedRoom = packageRooms.find( item => item.id === locationId );
        if ( !user || !selectedRoom || !selectedItem ) {
            return;
        }

        setLoading( true );

        const body: IPackageToProductBody = {
            facilityId,
            label,
            locationId: selectedRoom.metrc.Id,
            itemId,
            date: DateTime.local().toISODate(),
            quantity: parseFloat( quantity ),
            unitOfMeasure: selectedItem.UnitOfMeasureName,
            note,
            packages: ingredients.map( item => ({
                label: item.packageItem.metrc.Label,
                quantity: parseFloat( item.quantity ),
                unitOfMeasure: item.packageItem.metrc.UnitOfMeasureName,
            }) )
        }
        postPackageToPackage( user.token, body )
            .then( () => {
                handleDone();
            } )
            .catch( async ( e: ResponseError ) => setAlert( await e.json() ) )
            .finally( () => {
                setLoading( false );
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Create New Package from Package {packageItem.metrc.Label}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        <Typography variant="body1">
                            Create a package of sellable product from one or more other packages.
                        </Typography>
                        {alert && (
                            <Alert variant="filled" severity="error">
                                Create Failed: {alert.errors[0].message}
                            </Alert>
                        )}
                        <TextValid
                            value={label}
                            onChange={setLabel}
                            id="package-label"
                            label="Label"
                            validators={[]}
                            fullWidth
                        />
                        <SelectValid
                            id="package-item"
                            value={itemId}
                            label="Product being created"
                            fullWidth
                            validators={[]}
                            onChange={setItemId}
                        >
                            <MenuItem value={''}/>
                            {items.map( ( item ) => (
                                <MenuItem key={item.Id} value={item.id}>{item.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <SelectValid
                            id="package-location"
                            value={locationId}
                            label="Storage Room to send to"
                            fullWidth
                            validators={[]}
                            onChange={setLocationId}
                        >
                            <MenuItem value={''}/>
                            {packageRooms.map( ( item ) => (
                                <MenuItem key={item.id} value={item.id}>{item.metrc.Name}</MenuItem>
                            ) )}
                        </SelectValid>
                        <TextValid
                            value={quantity}
                            type="number"
                            onChange={setQuantity}
                            id="package-quantity"
                            label="Package Quantity"
                            disabled={!selectedItem}
                            validators={[]}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {selectedItem?.UnitOfMeasureName ?? ''}
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextValid
                            value={note}
                            onChange={setNote}
                            id="package-note"
                            label="Notes"
                            validators={[]}
                            fullWidth
                        />
                        <Divider style={{ marginBottom: 10 }}/>
                        <Ingredients
                            facilityId={facilityId}
                            ingredients={ingredients}
                            itemStrain={selectedItem?.StrainName}
                            onChange={setIngredients}
                        />
                        <Divider style={{ marginBottom: 10 }}/>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!label || !locationId || !itemId || !ingredients.every( item => parseFloat( item.quantity ) > 0 && parseFloat( item.quantity ) <= item.packageItem.metrc.Quantity )}
                            onClick={handleSubmit}
                        >
                            Create Package
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default PackageToPackage;