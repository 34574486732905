import { Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { IPackageFinishBody } from '@shared/interfaces/lib/contractInterfaces';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPackage } from '@shared/interfaces/lib/interfaces';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postPackageFinish } from '../../../api';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import { useDetailsStyles } from '../details';

interface IProps {
    packageItem: IPackage;
    facilityId: string;
    undo: boolean;
}

const PackageFinish: React.FC<IProps> = ( { packageItem, undo, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useDetailsStyles();
    const user = useSelector( selectUser );
    const [loading, setLoading] = useState( false );

    if ( !packageItem ) {
        return null;
    }

    const handleDone = () => {
        dispatch( setShowPlantDetails( {
            key: {
                collection: MetrcFacilitySubCollections.metrcPackages,
                id: packageItem.id,
            },
            page: PlantDetailPages.details,
        } ) );
    };

    const handleSubmit = () => {
        if ( !user ) {
            return;
        }

        setLoading( true );

        const body: IPackageFinishBody = {
            facilityId,
            label: packageItem.metrc.Label,
            date: DateTime.local().toISODate(),
            undo,
        }
        postPackageFinish( user.token, body )
            .then( () => {
                handleDone();
            } )
            .finally( () => {
                setLoading( false );
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Finish Package {packageItem.metrc.Label}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        <Typography variant="body1">
                            This package will no longer be able to be modified or removed from.
                        </Typography>
                        <Typography variant="body1">
                            <b>Remaining Quantity: {packageItem.metrc.Quantity}</b>
                        </Typography>
                        <Divider style={{ marginBottom: 10 }}/>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDone}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Confirm Finish
                        </Button>
                    </>
                )}
            </div>
        </>
    )
};

export default PackageFinish;