import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import StrainsList from './list';
import StrainsView from './view';

const Strains: React.FC<RouteComponentProps> = () => {
    return (
        <Router>
            <StrainsList path="/" />
            <StrainsView path="/:strainId" />
        </Router>
    );
};

export default Strains;
