import Link from '@material-ui/core/Link';
import { navigate } from '@reach/router';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import {
    HarvestStatus,
    IAddress,
    IFacility,
    IHarvest,
    INotification,
    IPackage,
    IPlant,
    IPlantBatch,
    IRoom,
    IStrain
} from '@shared/interfaces/lib/interfaces';
import {
    ConsumableTypes,
    EquipmentTypes,
    IConsumableDetail,
    IEquipmentDetail,
    IEquipmentItem,
    IInventoryDetail,
    InventoryTypes,
    Units
} from '@shared/interfaces/lib/inventoryInterfaces';
import { IMetrcPlant } from '@shared/interfaces/lib/metrcInterfaces';
import { IScheduleTask, TaskType } from '@shared/interfaces/lib/scheduleInterfaces';
import firebase from 'firebase';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ACIcon } from './components/Icons/ac.svg';
import { ReactComponent as C02BurnerIcon } from './components/Icons/burner.svg';
import { ReactComponent as C02Icon } from './components/Icons/c02.svg';
import { ReactComponent as CalendarIcon } from './components/Icons/calendar.svg';
import { ReactComponent as CloningIcon } from './components/Icons/cloning.svg';
import { ReactComponent as TestCompleteIcon } from './components/Icons/complete.svg';
import { ReactComponent as DehumidifierIcon } from './components/Icons/dehumidifier.svg';
import { ReactComponent as DeliveredIcon } from './components/Icons/delivered.svg';
import { ReactComponent as TestIcon } from './components/Icons/experiment.svg';
import { ReactComponent as FanIcon } from './components/Icons/fan.svg';
import { ReactComponent as FeedingIcon } from './components/Icons/feeding.svg';

import { ReactComponent as ROIcon } from './components/Icons/filtration.svg';
import { ReactComponent as FlowerIcon } from './components/Icons/flower.svg';
import { ReactComponent as HarvestIcon } from './components/Icons/harvest.svg';
import { ReactComponent as HumidityIcon } from './components/Icons/humidity.svg';
import { ReactComponent as InTransitIcon } from './components/Icons/intransit.svg';
import { ReactComponent as InventoryIcon } from './components/Icons/inventory.svg';
import { ReactComponent as LightLevelIcon } from './components/Icons/lightlevel.svg';
import { ReactComponent as LightsIcon } from './components/Icons/lights.svg';
import { ReactComponent as NutrientsIcon } from './components/Icons/nutrients.svg';
import { ReactComponent as ResultsIcon } from './components/Icons/results.svg';
import { ReactComponent as SiteFarrowIcon } from './components/Icons/site-farrow.svg';
import { ReactComponent as SitesIcon } from './components/Icons/site.svg';
import { ReactComponent as TemperatureIcon } from './components/Icons/temperature.svg';
import { ReactComponent as VegetationIcon } from './components/Icons/vegetation.svg';
import { ReactComponent as VPDIcon } from './components/Icons/vpd.svg';
import { ReactComponent as WaterChangeIcon } from './components/Icons/water-change.svg';
import { ReactComponent as WaterChillerIcon } from './components/Icons/water-chiller.svg';
import { ReactComponent as WateringIcon } from './components/Icons/watering.svg';
import { FirebaseContext } from './firebase_init';
import {
    selectEquipment,
    selectFacilities,
    selectInventoryDetails,
    selectRooms,
    selectStrains,
    selectUsers,
    setError
} from './redux/appSlice';
import { IUser } from './User';

export const getTimeStamp = () => {
    return (new Date()).toISOString();
};


export const diff = <T extends Record<string, any>>( o1: T, o2: T ): { key: keyof T, before: any, after: any }[] => {
    const dir1 = Object.keys( o2 ).reduce<{ key: string, before: any, after: any }[]>( ( acc, key ) => {
        if ( o1[key] === o2[key] ) return acc;
        acc.push( { key, before: o1[key], after: o2[key] } );
        return acc;
    }, [] );
    return Object.keys( o1 ).reduce<{ key: string, before: any, after: any }[]>( ( acc, key ) => {
        if ( o1[key] === o2[key] || acc.some( ( item ) => item.key === key ) ) return acc;
        acc.push( { key, before: o1[key], after: o2[key] } );
        return acc;
    }, dir1 )
};

export const diffArray = ( arr1: any[], arr2: any[] ) => {
    return [...diff( arr1, arr2 ), ...diff( arr2, arr1 )];

    function diff( a: any[], b: any[] ) {
        return a.filter( item => b.indexOf( item ) === -1 );
    }
};

export const removeUndefined = <T extends { [key: string]: any }>( obj: T ): T => {
    const newObj = { ...obj };
    Object.keys( newObj ).forEach( key => newObj[key] === undefined && delete newObj[key] );
    return newObj;
};

/*eslint-disable*/
export const uuidv4 = () => {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace( /[018]/g, c =>
        (c ^ crypto.getRandomValues( new Uint8Array( 1 ) )[0] & 15 >> c / 4).toString( 16 )
    );
};
/*eslint-enable*/

export const mediumRegex = new RegExp( '^((?=.*[a-z,A-Z])(?=.*[0-9]))(?=.{6,})' );
export const passwordText = 'Password must be at least 6 long and include a letter and a number';
export const passwordConfirmText = 'Passwords must match';
export const addressTemplate = ( address: IAddress ) => `${address.street1} ${address.street2 ? address.street2 + ' ' : ''}${address.city}, ${address.state} ${address.zipCode}`;
export const geneticsTemplate = ( strain: IStrain ) => {
    const sativa = strain.sativaPercent ? `Sativa ${strain.sativaPercent}%` : '';
    const indica = strain.indicaPercent ? `Indica ${strain.indicaPercent}%` : '';
    const space = !!sativa && !!indica;
    return `${sativa}${space ? ' ' : ''}${indica}`;
};

export const useNotification = () => {
    const dispatch = useDispatch();
    const context = useContext( FirebaseContext );
    const users = useSelector( selectUsers );

    return ( notification: Omit<INotification, 'id'>, userCondition: ( userItem: IUser ) => boolean = () => true ) => {
        const notificationColl = context.firestore.collection( Collections.users );
        users.forEach( ( userItem ) => {
            if ( userCondition( userItem ) ) {
                notificationColl.doc( userItem.uid )
                    .set( {}, { merge: true } )
                    .then( () => {
                        notificationColl.doc( userItem.uid )
                            .collection( Collections.notifications )
                            .add( notification )
                            .catch( ( e: Error ) => {
                                dispatch( setError( e.message ) );
                            } );
                    } )
            }
        } )
    };
};

const regex = /([@|#])\[(.*?)]\((.*?)\)/gm;

export const convertMessage = ( message: string, link: boolean = true ): React.ReactNode[] => {
    let content: React.ReactNode[] = [message];
    const parts = message.split( regex );
    if ( parts.length >= 5 ) {
        content = [parts[0]];
        for ( let i = 1; i < parts.length - 1; i += 4 ) {
            if ( link && parts[i] === '@' ) {
                content.push(
                    <Link key={parts[i + 2]} href="#" onClick={() => navigate( '/profile/' + parts[i + 2] )}>
                        {parts[i + 1]}
                    </Link>
                );
            } else if ( link && parts[i] === '#' ) {
                content.push(
                    <Link key={parts[i + 2]} href="#" onClick={() => navigate( '/room/' + parts[i + 2] )}>
                        {parts[i + 1]}
                    </Link>
                );
            } else {
                content.push( parts[i + 1] );
            }

            content.push( parts[i + 3] );
        }
    }

    return content;
};

export const userTemplate = ( user: IUser ) => `@[${user.displayName}](${user.uid})`;
export const roomTemplate = ( room: IRoom ) => `#[${room.metrc.Name}](${room.id})`;

export const notificationLink = ( notification: INotification ) => {
    if ( notification.roomId ) {
        return '/room/' + notification.roomId;
    } else {
        return '/';
    }
};

export const isEquipment = ( type: InventoryTypes | undefined ): type is EquipmentTypes => {
    return Object.values( EquipmentTypes ).some( ( x: any ) => x === type );
};

export const isConsumable = ( type: InventoryTypes | undefined ): type is ConsumableTypes => {
    return Object.values( ConsumableTypes ).some( ( x: any ) => x === type );
};

export const isDetailEquipment = ( detail: IInventoryDetail ): detail is IEquipmentDetail => {
    return isEquipment( detail.type );
};

export const isDetailConsumable = ( detail: IInventoryDetail ): detail is IConsumableDetail => {
    return isConsumable( detail.type );
};

const fluidOzToLiter = 0.0295735;
const fluidOzToGallon = 0.0078125;
const gallonToLiter = 3.78541;
const LiterToMilliliter = 1000;

export const convertUnit = ( amount?: number, fromUnit?: Units, toUnit?: Units ) => {
    if ( !amount || amount === 0 || !fromUnit || !toUnit ) {
        return 0;
    } else if ( fromUnit === toUnit ) {
        return amount;
    } else {
        switch ( fromUnit ) {
            case Units.FluidOunce:
                switch ( toUnit ) {
                    case Units.Liter:
                        return amount * fluidOzToLiter;
                    case Units.Milliliter:
                        return amount * fluidOzToLiter * LiterToMilliliter;
                    case Units.Gallon:
                        return amount * fluidOzToGallon;
                }
                break;
            case Units.Gallon:
                switch ( toUnit ) {
                    case Units.Liter:
                        return amount * gallonToLiter;
                    case Units.Milliliter:
                        return amount * gallonToLiter * LiterToMilliliter;
                    case Units.FluidOunce:
                        return amount / fluidOzToGallon;
                }
                break;
            case Units.Liter:
                switch ( toUnit ) {
                    case Units.Gallon:
                        return amount / gallonToLiter;
                    case Units.Milliliter:
                        return amount * LiterToMilliliter;
                    case Units.FluidOunce:
                        return amount / fluidOzToLiter;
                }
                break;
            case Units.Milliliter:
                switch ( toUnit ) {
                    case Units.Gallon:
                        return amount / LiterToMilliliter / gallonToLiter;
                    case Units.Liter:
                        return amount / LiterToMilliliter;
                    case Units.FluidOunce:
                        return amount / LiterToMilliliter / fluidOzToLiter;
                }
                break;
        }
        return amount;
    }
};

export const isNormalInteger = ( str: string ): boolean => {
    str = str.trim();
    if ( !str ) {
        return false;
    }
    str = str.replace( /^0+/, '' ) || '0';
    const n = Math.floor( Number( str ) );
    return n !== Infinity && String( n ) === str && n >= 0;
};

export const buildArray = ( count: number, callback: ( index: number, title: string ) => React.ReactNode ): React.ReactNode[] => {
    const content = [];
    for ( let i = 1; i <= count; i++ ) {
        content.push( callback( i, getIndexTitle( i, count ) ) );
    }
    return content;
};

export const getIndexTitle = ( index: number, count: number ) => {
    if ( count <= 1 ) {
        return '';
    }
    if ( count === 2 ) {
        return index === 2 ? 'upper' : 'lower';
    }
    if ( count === 3 ) {
        switch ( index ) {
            case 3:
                return 'upper';
            case 2:
                return 'middle';
            default:
                return 'lower';
        }
    }
    return `level ${index}`;
};

export const buildArrayReverse = ( count: number, callback: ( index: number, title: string ) => React.ReactNode ): React.ReactNode[] => {
    const content = [];
    for ( let i = count; i >= 1; i-- ) {
        content.push( callback( i, getIndexTitle( i, count ) ) );
    }
    return content;
};

const MAX_BATCH_SIZE = 400;

export const runBatch = async ( firestore: firebase.firestore.Firestore, array: { id: string }[], collection: firebase.firestore.CollectionReference ) => {
    for ( let i = 0; i < array.length && array.length > 0; i += MAX_BATCH_SIZE ) {
        const temp = array.slice( i, i + MAX_BATCH_SIZE );
        const batch = firestore.batch();
        temp.forEach( ( item ) => {
            batch.set( collection.doc( item.id ), removeUndefined( item ) );
        } );
        await batch.commit();
    }
};

export const statesList = [
    {
        'name': 'Alabama',
        'abbreviation': 'AL'
    },
    {
        'name': 'Alaska',
        'abbreviation': 'AK'
    },
    {
        'name': 'American Samoa',
        'abbreviation': 'AS'
    },
    {
        'name': 'Arizona',
        'abbreviation': 'AZ'
    },
    {
        'name': 'Arkansas',
        'abbreviation': 'AR'
    },
    {
        'name': 'California',
        'abbreviation': 'CA'
    },
    {
        'name': 'Colorado',
        'abbreviation': 'CO'
    },
    {
        'name': 'Connecticut',
        'abbreviation': 'CT'
    },
    {
        'name': 'Delaware',
        'abbreviation': 'DE'
    },
    {
        'name': 'District Of Columbia',
        'abbreviation': 'DC'
    },
    {
        'name': 'Federated States Of Micronesia',
        'abbreviation': 'FM'
    },
    {
        'name': 'Florida',
        'abbreviation': 'FL'
    },
    {
        'name': 'Georgia',
        'abbreviation': 'GA'
    },
    {
        'name': 'Guam',
        'abbreviation': 'GU'
    },
    {
        'name': 'Hawaii',
        'abbreviation': 'HI'
    },
    {
        'name': 'Idaho',
        'abbreviation': 'ID'
    },
    {
        'name': 'Illinois',
        'abbreviation': 'IL'
    },
    {
        'name': 'Indiana',
        'abbreviation': 'IN'
    },
    {
        'name': 'Iowa',
        'abbreviation': 'IA'
    },
    {
        'name': 'Kansas',
        'abbreviation': 'KS'
    },
    {
        'name': 'Kentucky',
        'abbreviation': 'KY'
    },
    {
        'name': 'Louisiana',
        'abbreviation': 'LA'
    },
    {
        'name': 'Maine',
        'abbreviation': 'ME'
    },
    {
        'name': 'Marshall Islands',
        'abbreviation': 'MH'
    },
    {
        'name': 'Maryland',
        'abbreviation': 'MD'
    },
    {
        'name': 'Massachusetts',
        'abbreviation': 'MA'
    },
    {
        'name': 'Michigan',
        'abbreviation': 'MI'
    },
    {
        'name': 'Minnesota',
        'abbreviation': 'MN'
    },
    {
        'name': 'Mississippi',
        'abbreviation': 'MS'
    },
    {
        'name': 'Missouri',
        'abbreviation': 'MO'
    },
    {
        'name': 'Montana',
        'abbreviation': 'MT'
    },
    {
        'name': 'Nebraska',
        'abbreviation': 'NE'
    },
    {
        'name': 'Nevada',
        'abbreviation': 'NV'
    },
    {
        'name': 'New Hampshire',
        'abbreviation': 'NH'
    },
    {
        'name': 'New Jersey',
        'abbreviation': 'NJ'
    },
    {
        'name': 'New Mexico',
        'abbreviation': 'NM'
    },
    {
        'name': 'New York',
        'abbreviation': 'NY'
    },
    {
        'name': 'North Carolina',
        'abbreviation': 'NC'
    },
    {
        'name': 'North Dakota',
        'abbreviation': 'ND'
    },
    {
        'name': 'Northern Mariana Islands',
        'abbreviation': 'MP'
    },
    {
        'name': 'Ohio',
        'abbreviation': 'OH'
    },
    {
        'name': 'Oklahoma',
        'abbreviation': 'OK'
    },
    {
        'name': 'Oregon',
        'abbreviation': 'OR'
    },
    {
        'name': 'Palau',
        'abbreviation': 'PW'
    },
    {
        'name': 'Pennsylvania',
        'abbreviation': 'PA'
    },
    {
        'name': 'Puerto Rico',
        'abbreviation': 'PR'
    },
    {
        'name': 'Rhode Island',
        'abbreviation': 'RI'
    },
    {
        'name': 'South Carolina',
        'abbreviation': 'SC'
    },
    {
        'name': 'South Dakota',
        'abbreviation': 'SD'
    },
    {
        'name': 'Tennessee',
        'abbreviation': 'TN'
    },
    {
        'name': 'Texas',
        'abbreviation': 'TX'
    },
    {
        'name': 'Utah',
        'abbreviation': 'UT'
    },
    {
        'name': 'Vermont',
        'abbreviation': 'VT'
    },
    {
        'name': 'Virgin Islands',
        'abbreviation': 'VI'
    },
    {
        'name': 'Virginia',
        'abbreviation': 'VA'
    },
    {
        'name': 'Washington',
        'abbreviation': 'WA'
    },
    {
        'name': 'West Virginia',
        'abbreviation': 'WV'
    },
    {
        'name': 'Wisconsin',
        'abbreviation': 'WI'
    },
    {
        'name': 'Wyoming',
        'abbreviation': 'WY'
    }
];

export const getIcon = ( name: string, height?: string, width?: string ) => {
    switch ( name ) {
        case 'Nutrients':
            return <NutrientsIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Inventory':
            return <InventoryIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Flower':
            return <FlowerIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Feeding':
            return <FeedingIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Watering':
            return <WateringIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Harvest':
            return <HarvestIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Calendar':
            return <CalendarIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Vegetation':
            return <VegetationIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Cloning':
            return <CloningIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Lights':
            return <LightsIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Temperature':
            return <TemperatureIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Humidity':
            return <HumidityIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'VPD':
            return <VPDIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Air Conditioners':
            return <ACIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Fans':
            return <FanIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Dehumidifiers':
            return <DehumidifierIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Water Chillers':
            return <WaterChillerIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Light Level':
            return <LightLevelIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'C02 Burners':
            return <C02BurnerIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'C02':
            return <C02Icon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Sites':
            return <SitesIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Farrow':
            return <SiteFarrowIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Test':
            return <TestIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'In Transit':
            return <InTransitIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Delivered':
            return <DeliveredIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'TestComplete':
            return <TestCompleteIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'Results':
            return <ResultsIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'RO':
            return <ROIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        case 'WaterChange':
            return <WaterChangeIcon height={height ? height : '50'} width={width ? width : '50'}/>;
        default:
            return <NutrientsIcon height={height ? height : '50'} width={width ? width : '50'}/>;
    }
};

export enum PlantState {
    destroyed,
    vegetative,
    flowering,
    harvested,
    young,
}

export const getPlantState = ( plant: IMetrcPlant ): { state: PlantState; title: string } => {
    if ( plant.DestroyedDate ) {
        return { state: PlantState.destroyed, title: 'Destroyed' };
    } else if ( plant.HarvestedDate ) {
        return { state: PlantState.harvested, title: 'Harvested' };
    } else if ( plant.FloweringDate ) {
        return { state: PlantState.flowering, title: 'Flowering' };
    } else if ( plant.VegetativeDate ) {
        return { state: PlantState.vegetative, title: 'Vegetative' };
    } else {
        return { state: PlantState.young, title: 'Young / Unknown' };
    }
}

export const getItemType = ( item: IPlant | IPlantBatch | IPackage ): 'Plant' | 'Plant Batch' | 'Package' => {
    if ( (item as IPlant).metrc.GrowthPhase ) {
        return 'Plant';
    } else if ( (item as IPackage).metrc.ProductName ) {
        return 'Package';
    } else {
        return 'Plant Batch';
    }
};

export const removeForwardSlash = ( str: string = '' ): string => str.replace( /\//g, '' );

export const getHarvestStatus = ( harvest: IHarvest ): HarvestStatus => {
    if ( harvest.metrc.HarvestType === 'WholePlant' && !harvest.status?.manicureStartDateTime ) {
        return HarvestStatus.ToDoManicure;
    } else if ( harvest.metrc.HarvestType === 'WholePlant' && !harvest.status?.manicureEndDateTime ) {
        return HarvestStatus.InProgressManicure;
    } else if ( !harvest.status?.dryStartDateTime ) {
        return HarvestStatus.ToDoDry;
    } else if ( !harvest.status?.dryEndDateTime ) {
        return HarvestStatus.InProgressDry;
    } else if ( !harvest.status?.trimStartDateTime ) {
        return HarvestStatus.ToDoTrim;
    } else if ( !harvest.status?.trimEndDateTime ) {
        return HarvestStatus.InProgressTrim;
    } else {
        return HarvestStatus.Complete;
    }
};

export const useTaskName = () => {
    const equipments = useSelector( selectEquipment );
    const details = useSelector( selectInventoryDetails );

    return ( task: IScheduleTask ) => {
        const equipment = equipments.find( equipment => task.type === TaskType.Maintenance && task.equipmentId === equipment.id );
        const detail = details.find( detail => detail.id === equipment?.inventoryId );
        return task.type === TaskType.Custom ? task.name : `Maintain: ${detail?.name}`;
    };

};

export const useFacilityRooms = ( facilityId: string ) => {
    const roomRecords = useSelector( selectRooms );
    return roomRecords[facilityId] || [];
};

export const useSelectedRoom = ( facilityId: string, filter: ( room: IRoom ) => boolean ) => {
    const rooms = useFacilityRooms( facilityId );
    return rooms.find( filter );
};

export const useSelectedFacility = ( filter: ( facility: IFacility ) => boolean ) => {
    const facilities = useSelector( selectFacilities );
    return facilities.find( filter );
};

export const useSelectedStrain = ( filter: ( strain: IStrain ) => boolean ) => {
    const strains = useSelector( selectStrains );
    return strains.find( filter );
};

export const useSelectedEquipment = ( filter: ( equipment: IEquipmentItem ) => boolean ) => {
    const equipment = useSelector( selectEquipment );
    return equipment.find( filter );
};

export const useSelectedInventoryDetail = ( filter: ( inventoryDetail: IInventoryDetail ) => boolean ) => {
    const detail = useSelector( selectInventoryDetails );
    return detail.find( filter );
};