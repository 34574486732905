import Link from '@material-ui/core/Link';
import { IRoom } from '@shared/interfaces/lib/interfaces';
import React, { useState } from 'react';
import NewSystemDialog from './dialog';

interface IProps {
    room: IRoom;
}

const NewSystem: React.FC<IProps> = ( { room } ) => {
    const [open, setOpen] = useState( false );

    return (
        <>
            <Link href="#" onClick={() => setOpen( true )} variant="body1">
                <strong>
                    <span style={{ 'color': 'black' }}>&nbsp;/</span> Add New System
                </strong>
            </Link>

            {open && <NewSystemDialog room={room} onClose={() => setOpen( false )}/>}
        </>
    );
};

export default NewSystem;