import { ListSubheader } from '@material-ui/core';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { IValidatorOutput } from '@happybandit/react-validation/dist/interfaces';
import { useInputValidation } from '@happybandit/react-validation/dist/hooks';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { SelectProps } from '@material-ui/core/Select/Select';
import { useSelector } from 'react-redux';
import { ContactServices, IExternalContact } from '../../pages/externalContacts/interfaces';
import { selectExternalContacts } from '../../redux/appSlice';

interface IProps extends Omit<SelectProps, 'onChange' | 'variant' | 'margin' | 'labelWidth' | 'native'> {
    value?: string;
    onChange: ( newValue: string ) => void;
    label?: string;
    id: string;
    validators: IValidatorOutput[];
    serviceToFocus?: ContactServices;
}

const ContactItem = ( contact: IExternalContact ) => (
    <MenuItem
        key={contact.id}
        value={contact.id}
    >
        {contact.name}
    </MenuItem>
);

const ContactSelectValid: React.FC<IProps> = ( { serviceToFocus, fullWidth, value, id, label, onChange, validators, ...otherProps } ) => {
    const externalContacts = useSelector(selectExternalContacts);
    const { valid, message } = useInputValidation( validators );
    const inputLabel = useRef<HTMLLabelElement>( null );
    const [labelWidth, setLabelWidth] = useState( 0 );
    useLayoutEffect( () => {
        if ( inputLabel.current && labelWidth === 0 ) {
            setLabelWidth( inputLabel.current.offsetWidth );
        }
    }, [labelWidth] );

    const filterContacts = () => {
        if ( serviceToFocus ) {
            return externalContacts.reduce<{ matched: IExternalContact[], unmatched: IExternalContact[] }>( ( acc, contact ) => {
                if ( contact.services.includes( serviceToFocus ) ) {
                    acc.matched.push( contact );
                } else {
                    acc.unmatched.push( contact );
                }

                return acc;
            }, { matched: [], unmatched: [] } );
        } else {
            return { matched: [], unmatched: [...externalContacts] };
        }
    };

    const getOptions = () => {
        const filteredContacts = filterContacts();
        let items: React.ReactElement[] = [];
        if ( serviceToFocus ) {
            items.push(
                <ListSubheader key={serviceToFocus}>
                    {serviceToFocus}
                </ListSubheader>
            );
            items = items.concat( filteredContacts.matched.map( ContactItem ) );
            items.push(
                <ListSubheader key="other">
                    Other Companies
                </ListSubheader>
            );
        }
        return items.concat( filteredContacts.unmatched.map( ContactItem ) );
    };

    const handleChange = ( event: React.ChangeEvent<{ name?: string; value: unknown }> ) => {
        const value = event.target.value;
        onChange( value as string || '' );
    };

    return (
        <FormControl variant="outlined" error={!valid} margin="normal" fullWidth={fullWidth}>
            <InputLabel ref={inputLabel} htmlFor={`select-valid-${id}`}>
                {label}
            </InputLabel>
            <Select
                labelWidth={labelWidth}
                variant="outlined"
                id={`select-valid-${id}`}
                value={value || ''}
                {...otherProps}
                onChange={handleChange}
            >
                {getOptions()}
            </Select>
            {!valid && (
                <FormHelperText>
                    {message}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default ContactSelectValid;