import {
    Collections,
    MetrcFacilitySubCollections,
    MetrcLocationSubCollections
} from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { capitalize } from 'lodash';
import { ISystemEnvironment } from '../../../../../../environmentInterfaces';
import { FirebaseContext } from '../../../../../../firebase_init';
import FacilityContext from '../../../../facilityContext';

interface IProps {
    systemId: string;
    roomId: string;
    type: string;
}

const WaterHistory: React.FC<IProps> = ( { type, roomId, systemId } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const [ open, setOpen ] = useState< boolean >( false );
    const [envData, setEnvData] = useState<ISystemEnvironment[]>( [] );

    useEffect( () => {
        firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.environment )
            .where( 'systemId', '==', systemId )
            .orderBy( 'dateTime', 'asc' )
            .onSnapshot( ( snapshot ) => {
                setEnvData( snapshot.docs.map( ( doc ) => ({
                    ...doc.data(),
                }) as ISystemEnvironment ) );
            } );
    }, [] );

    const getData = () => {
        return  envData.map(( history ) => {
            return {
                [ type ]: ( history as any )[ type ],
                name: `${ new Date( history.dateTime ).toDateString() } ${ new Date( history.dateTime ).toLocaleTimeString()}`,
            };
        });
    };

    return (
        <>
            <Button
                color="primary"
                style={{ 'float': 'right' }}
                onClick={() => setOpen( true )}
            >
                View History
            </Button>
            <Dialog maxWidth="xl" open={open} onClose={() => setOpen( false )} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{ capitalize( type )} History</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Historical Data for { capitalize( type )}
                    </DialogContentText>
                    <LineChart
                        width={ 1000 }
                        height={ 500 }
                        data={ getData() }
                        margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey={ type } stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen( false )} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WaterHistory;