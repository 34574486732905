import { Form } from '@happybandit/react-validation';
import { Divider } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { DirectPaths } from '@shared/interfaces/lib/firebaseConstants';
import { ColorPicker } from 'material-ui-color';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps } from '@reach/router';
import { IOrgSettings } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuccessSnack from '../../components/successSnack';
import TextValid from '../../components/TextValid';
import { startSync } from '../../components/wrapper/sync/api';
import { FirebaseContext } from '../../firebase_init';
import { selectAllFacilities, selectOrgSettings, selectUser, setError } from '../../redux/appSlice';
import { diffArray } from '../../utils';
import ColorDemo from './colorDemo';

const useStyles = makeStyles( ( theme: Theme ) => ({
    paper: {
        marginTop: theme.spacing( 8 ),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing( 1 ),
    },
    submit: {
        margin: theme.spacing( 3, 0, 2 ),
    },
}) );

const isGrowerOrProcessor = ( type: string ) => {
    const compare = type.toLowerCase();
    return compare.includes( 'grower' ) || compare.includes( 'processor ' );
};

const OrganizationForm: React.FC<{ orgSettings: IOrgSettings }> = ( { orgSettings } ) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();
    const context = useContext( FirebaseContext );
    const facilities = useSelector( selectAllFacilities );
    const user = useSelector( selectUser );
    const [showSuccess, setShowSuccess] = useState( false );

    const [primaryColor, setPrimaryColor] = useState( orgSettings.primaryColor ?? theme.palette.primary.main );
    const [secondaryColor, setSecondaryColor] = useState( orgSettings.secondaryColor ?? theme.palette.secondary.main );
    const [syncUserKey, setSyncUserKey] = useState( orgSettings.syncUserKey ?? '' );
    const [activeFacilityIds, setActiveFacilityIds] = useState( orgSettings.activeFacilityIds ?? [] );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        const data: IOrgSettings = {
            primaryColor,
            secondaryColor,
            syncUserKey,
            activeFacilityIds
        };

        const facilitiesChanged = data.activeFacilityIds && orgSettings.activeFacilityIds &&
            diffArray(data.activeFacilityIds, orgSettings.activeFacilityIds).length > 0

        context.firestore.doc( DirectPaths.orgSettings )
            .set( data )
            .then( () => {
                setShowSuccess( true );
                if(facilitiesChanged && user){
                    return startSync(user.token);
                }
            } )
            .catch( ( e ) => dispatch( setError( e ) ) );
    };

    const handleAllowChange = ( id: string ) => ( e: any, checked: boolean ) => {
        setActiveFacilityIds( ( prev ) => {
            const newIds = [...prev];

            if ( checked ) {
                newIds.push( id );
            } else {
                const index = newIds.indexOf( id );
                if ( index >= 0 ) {
                    newIds.splice( index, 1 );
                }
            }

            return newIds;
        } )
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h4">
                    Organization Settings
                </Typography>
                <Form method="POST" className={classes.form} noValidate onSubmit={handleSubmit}>
                    <Typography variant="h5">
                        Metrc User Key to Sync Metrc and Cultivated
                    </Typography>
                    <Typography variant="body1">
                        This is required to link Metrc data and Cultivated data. This must be updated if the Metrc key
                        ever changes.
                    </Typography>
                    <TextValid
                        label="User Key"
                        onChange={setSyncUserKey}
                        value={syncUserKey}
                        id="org-settings-key"
                        validators={[]}
                        fullWidth
                    />
                    <Divider/>
                    <br/>
                    <br/>
                    <Typography variant="h5">
                        Primary Theme Color
                    </Typography>
                    <ColorPicker
                        onChange={( color ) => setPrimaryColor( color ? '#' + color.hex : theme.palette.primary.main )}
                        value={primaryColor}
                        hideTextfield
                    />

                    <Typography variant="h5">
                        Secondary Theme Color
                    </Typography>
                    <ColorPicker
                        onChange={( color ) => setSecondaryColor( color ? '#' + color.hex : theme.palette.secondary.main )}
                        value={secondaryColor}
                        hideTextfield
                    />
                    <ColorDemo
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                    />

                    <Divider/>
                    <br/>
                    <br/>
                    <Typography variant="h5">
                        Active Facilities
                    </Typography>
                    <Typography variant="body1">
                        Select the Facilities to connect to Cultivatd
                    </Typography>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {facilities
                                .filter( fac => isGrowerOrProcessor( fac.metrc.License.LicenseType ) )
                                .map( fac => (
                                    <FormControlLabel
                                        key={fac.id}
                                        control={<Checkbox
                                            checked={activeFacilityIds.includes( fac.id )}
                                            onChange={handleAllowChange( fac.id )}
                                            name="room-types"
                                        />}
                                        label={`${fac.metrc.DisplayName} - ${fac.metrc.License.LicenseType}`}
                                    />
                                ) )}
                        </FormGroup>
                    </FormControl>

                    <Divider/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Update Organization Settings
                    </Button>
                </Form>
            </div>
            {showSuccess && (
                <SuccessSnack onClose={() => setShowSuccess( false )}/>
            )}
        </Container>
    );
};

const Organization: React.FC<RouteComponentProps> = () => {
    const user = useSelector( selectUser );
    const orgSettings = useSelector( selectOrgSettings );

    if ( !orgSettings || !user || !user.isAllowed( UserPermissions.orgAdmin ) ) {
        return null;
    }

    return (
        <OrganizationForm orgSettings={orgSettings}/>
    );
};

export default Organization;
