import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { Form, Validators } from '@happybandit/react-validation';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, InputAdornment } from '@material-ui/core';
import { INotification, IStrain } from '@shared/interfaces/lib/interfaces';
import { selectUser, setError } from 'redux/appSlice';
import { FirebaseContext } from 'firebase_init';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { removeUndefined, useNotification } from 'utils';
import TextValid from 'components/TextValid';
import { IProps } from './interfaces';

const EditStrain: React.FC<IProps> = ( { open, onClose, strain } ) => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
 
    const [name, setName] = useState( strain.name );
    const [indicaPercent, setIndicaPercent] = useState<string>( strain.indicaPercent.toString() );
    const [sativaPercent, setSativaPercent] = useState<string>( strain.sativaPercent.toString() );
    const [cross, setCross] = useState( strain.cross );
    const [thc, setTHC] = useState<string>( strain.thc ? strain.thc.toString() : '' );
    const [cbd, setCBD] = useState<string>( strain.cbd ? strain.cbd.toString() : '' );
    const [notes, setNotes] = useState( strain.notes );
    const [vegDays, setVegDays] = useState( strain.vegDays );
    const [harvestDays, setHarvestDays] = useState( strain.harvestDays );
    const [waterHours, setWaterHours] = useState( strain.waterHours );
    const [feedHours, setFeedHours] = useState( strain.feedHours );
    
    const user = useSelector( selectUser );
    const sendNotification = useNotification();

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user ) {
            return;
        }

        const data: Omit<IStrain, 'id'> = {
            ...strain,
            name,
            sativaPercent: parseFloat( sativaPercent ),
            indicaPercent: parseFloat( indicaPercent ),
            cross,
            vegDays,
            harvestDays,
            waterHours,
            feedHours,
            notes,
            thc: parseFloat(thc),
            cbd: parseFloat(cbd),
        };

        const collection = firestore.collection( Collections.strains );
        collection.doc( strain.id ).update( removeUndefined( data ) ).then( () => {
            onClose();
        } ).catch( ( e: Error ) => {
            dispatch( setError( e.message ) );
        } );

        const notification: Omit<INotification, 'id'> = {
            timestamp: Date.now(),
            seen: false,
            userId: user.uid,
            message: `Updated Strain - ${ name }`,
        };
        sendNotification( notification, ( userItem ) => userItem.permissions.includes(UserPermissions.userAdmin) );
    };

    const handleClose = () => {
        setName( '' );
        setSativaPercent( '' );
        setIndicaPercent( '' );
        setCross( '' );
        setTHC( '' );
        setCBD( '' );
        setNotes( '' );
        setVegDays( 0 );
        setHarvestDays( 0 );
        setWaterHours( 0 );
        setFeedHours( 0 );
        onClose();
    };

    return (
        <>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    { strain &&
                        <>
                            <Typography variant="h5" style={{ display: 'inline' }} component="p">
                                <strong>
                                    { strain.name }
                                </strong>
                            </Typography>
                            <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                                <strong>
                                    / Update
                                </strong>
                            </Typography>
                        </>
                    }
                    <Typography variant="body2" component="p">
                        Cannabis strains are either pure or hybrid varieties of the plant genus Cannabis, which encompasses the species C. sativa, C. indica, and C. ruderalis.
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                </DialogTitle>
                <DialogContent>
                    <Form autoComplete="off" id="cultivatd-strain--edit" onSubmit={handleSubmit}>
                        <TextValid
                            id="cultivatd-strain--edit-name"
                            label="Name"
                            fullWidth
                            value={name}
                            onChange={( changed ) => setName( changed )}
                            validators={[Validators.required( name )]}
                        />
                        <TextValid
                            id="edit-strain-sativa"
                            label="Genetics Sativa Percent"
                            type="number"
                            value={sativaPercent}
                            onChange={( value ) => setSativaPercent( value )}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            fullWidth
                            validators={[Validators.required( sativaPercent ), Validators.positive( sativaPercent )]}
                        />
                        <TextValid
                            id="edit-strain-indica"
                            label="Genetics Indica Percent"
                            type="number"
                            value={indicaPercent}
                            onChange={( value ) => setIndicaPercent( value )}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            fullWidth
                            validators={[Validators.required( indicaPercent ), Validators.positive( indicaPercent )]}
                        />
                        <TextValid
                            id="cultivatd-strain--edit-cross"
                            label="Cross"
                            value={cross}
                            fullWidth
                            onChange={( changed ) => setCross( changed )}
                            validators={[]}
                        />
                        <TextValid
                            id="cultivatd-strain--edit-thc"
                            label="THC Level"
                            type="number"
                            value={thc ? thc.toString() : ''}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            onChange={setTHC}
                            validators={[Validators.positive( thc )]}
                        />
                        <TextValid
                            id="cultivatd-strain--edit-cbd"
                            label="CBD Level"
                            type="number"
                            value={cbd ? cbd.toString() : ''}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            onChange={setCBD}
                            validators={[Validators.positive( cbd )]}
                        />
                        <TextValid
                            id="cultivatd-strain--edit-vegDays"
                            label="Days for Vegetation"
                            type="number"
                            value={vegDays ? vegDays.toString() : ''}
                            fullWidth
                            onChange={( changed ) => setVegDays( parseInt( changed ) || undefined )}
                            validators={[Validators.positive( vegDays ? vegDays.toString() : '' )]}
                        />
                        <TextValid
                            id="cultivatd-strain--edit-harvestDays"
                            label="Days to Harvest"
                            type="number"
                            value={harvestDays ? harvestDays.toString() : ''}
                            fullWidth
                            onChange={( changed ) => setHarvestDays( parseInt( changed ) || undefined )}
                            validators={[Validators.positive( harvestDays ? harvestDays.toString() : '' )]}
                        />
                        <TextValid
                            id="cultivatd-strain--edit-waterHours"
                            label="Hours between Mother Watering"
                            type="number"
                            value={waterHours ? waterHours.toString() : ''}
                            fullWidth
                            onChange={( changed ) => setWaterHours( parseInt( changed ) || undefined )}
                            validators={[Validators.positive( waterHours ? waterHours.toString() : '' )]}
                        />
                        <TextValid
                            id="cultivatd-strain--edit-feedHours"
                            label="Hours between Mother Feeding"
                            type="number"
                            value={feedHours ? feedHours.toString() : ''}
                            fullWidth
                            onChange={( changed ) => setFeedHours( parseInt( changed ) || undefined )}
                            validators={[Validators.positive( feedHours ? feedHours.toString() : '' )]}
                        />
                        <TextValid
                            id="cuktivatd-strain--edit-notes"
                            label="Notes"
                            multiline
                            rows="4"
                            value={notes}
                            fullWidth
                            onChange={( changed ) => setNotes( changed )}
                            validators={[]}
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" form="cultivatd-strain--edit" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditStrain;