import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Comment from '../../../../components/comment';
import { IRoom } from '@shared/interfaces/lib/interfaces';

interface IProps {
    room: IRoom;
}

const RoomComment: React.FC<IProps> = ({room}) => {
    return (
        <Grid item xs={12} md={4}>
            <Paper elevation={0} variant="outlined" square>
                <Box p={2}>
                    <Comment roomId={room.id}/>
                </Box>
            </Paper>
        </Grid>
    );
};

export default RoomComment;
