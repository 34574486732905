import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

interface IDialogProps {
    title: string;
    description: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmDialog: React.FC<IDialogProps> = ( { title, description, onCancel, onConfirm } ) => {
    const theme = useTheme();

    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={true}
                onClose={onCancel}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onConfirm} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmDialog;
