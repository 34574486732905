import React, { useLayoutEffect, useRef, useState } from 'react';
import { IValidatorOutput } from '@happybandit/react-validation/dist/interfaces';
import { useInputValidation } from '@happybandit/react-validation/dist/hooks';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { SelectProps } from '@material-ui/core/Select/Select';
import { ConsumableTypes, EquipmentTypes, ICategorySelected, InventoryTypes } from '@shared/interfaces/lib/inventoryInterfaces';
import { useSelector } from 'react-redux';
import { selectInventoryCategories } from '../../redux/appSlice';

interface IProps extends Omit<SelectProps, 'onChange' | 'variant' | 'margin' | 'labelWidth' | 'native'> {
    value?: ICategorySelected;
    onChange: ( newValue: ICategorySelected ) => void;
    label?: string;
    id: string;
    validators: IValidatorOutput[];
    hasEquipment?: boolean;
    hasConsumables?: boolean;
}

const CategorySelectValid: React.FC<IProps> = ( { hasEquipment = false, hasConsumables = false, fullWidth, value, id, label, onChange, validators, ...otherProps } ) => {
    const inventoryCategories = useSelector(selectInventoryCategories);
    const { valid, message } = useInputValidation( validators );
    const inputLabel = useRef<HTMLLabelElement>( null );
    const [labelWidth, setLabelWidth] = useState( 0 );
    useLayoutEffect( () => {
        if ( inputLabel.current && labelWidth === 0 ) {
            setLabelWidth( inputLabel.current.offsetWidth );
        }
    }, [labelWidth] );

    const getOptions = (type: typeof EquipmentTypes | typeof ConsumableTypes) => {
        const items = Object.entries( type ).map( ( [key, value] ) => key === 'UserDefined' ? null : (
            <MenuItem
                key={key}
                value={value}
            >
                {key}
            </MenuItem>
        ) );
        return  items.concat(inventoryCategories.filter( ( cat ) => cat.type === type['UserDefined'] ).map( ( category ) => (
            <MenuItem
                key={category.id}
                value={category.id}
            >
                {category.name}
            </MenuItem>
        ) ));
    };

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = event.target.value;

        const found = inventoryCategories.find((cat) => cat.id === value);
        if(found){
            onChange({
                type: found.type,
                userCategoryId: found.id,
            });
        } else {
            onChange({
                type: value as InventoryTypes,
                userCategoryId: undefined,
            });
        }
    };

    return (
        <FormControl variant="outlined" error={!valid} margin="normal" fullWidth={fullWidth}>
            <InputLabel ref={inputLabel} htmlFor={`select-valid-${id}`} id={`select-valid-${id}-label`}>
                {label}
            </InputLabel>
            <Select
                labelWidth={labelWidth}
                variant="outlined"
                id={`select-valid-${id}`}
                labelId={`select-valid-${id}-label`}
                value={value ? value.userCategoryId || value.type : ''}
                {...otherProps}
                onChange={handleChange}
            >
                {hasEquipment && getOptions(EquipmentTypes)}
                {hasConsumables && getOptions(ConsumableTypes)}
            </Select>
            {!valid && (
                <FormHelperText>
                    {message}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default CategorySelectValid;