import { IRoom } from '@shared/interfaces/lib/interfaces';
import React from 'react';

interface IRoomContext {
    room: IRoom;
}

const RoomContext = React.createContext<IRoomContext>(undefined as any);

export default RoomContext;
