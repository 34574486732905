import { Form, Validators } from '@happybandit/react-validation';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import { IRoom } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CategorySelectValid from '../../../../../components/CategorySelectValid';
import SelectValid from '../../../../../components/SelectValid';
import TextValid from '../../../../../components/TextValid';
import { FirebaseContext } from '../../../../../firebase_init';
import { ICategorySelected, IConsumableItem, IEquipmentItem, Units } from '@shared/interfaces/lib/inventoryInterfaces';
import {
    selectConsumables,
    selectInventoryDetails,
    selectUser,
    setError
} from '../../../../../redux/appSlice';
import {
    getTimeStamp,
    isConsumable,
    isDetailConsumable,
    isEquipment,
    isNormalInteger,
    removeUndefined,
    useFacilityRooms,
    uuidv4
} from '../../../../../utils';

interface IProps {
    facilityId: string;
    onClose: () => void;
}

interface ITableProps {
    rooms: IRoom[];
    currentStock: IStockRow[];
    onChange: ( row: IStockRow[] ) => void;
}

interface IStockRow {
    category?: ICategorySelected;
    inventoryId?: string;
    quantity: string;
    unit?: Units;
    roomId?: string;
}

interface IStockRowProps {
    rooms: IRoom[];
    index: number;
    item: IStockRow;
    onChange: ( index: number, row: IStockRow ) => void;
}

const emptyRow: IStockRow = {
    category: undefined,
    inventoryId: undefined,
    quantity: '1',
    unit: undefined,
    roomId: undefined,
};

const StockRow: React.FC<IStockRowProps> = ( { index, item, onChange, rooms } ) => {
    const inventoryDetails = useSelector( selectInventoryDetails );

    const handleCatChange = ( row: IStockRow ) => {
        onChange( index, {
            ...row,
            unit: undefined,
            inventoryId: undefined,
            roomId: undefined,
        } );
    };

    const handleItemChange = ( row: IStockRow ) => {
        const inventoryDetail = inventoryDetails.find( ( detail ) => detail.id === row.inventoryId );

        onChange( index, {
            ...row,
            unit: inventoryDetail && isDetailConsumable( inventoryDetail ) ? inventoryDetail.unit : Units.Single,
        } );
    };

    const filteredDetails = inventoryDetails.filter( ( detail ) => item.category &&
        detail.type === item.category.type && detail.userCategoryId === item.category.userCategoryId );

    return (
        <TableRow>
            <TableCell>
                <CategorySelectValid
                    id={`stock-add-category-${index}`}
                    value={item.category}
                    label="Inventory Type"
                    fullWidth
                    validators={[Validators.custom( () => item.inventoryId ? !!item.category : true, 'Please enter a value' )]}
                    onChange={( value ) => handleCatChange( {
                        ...item,
                        category: value,
                    } )}
                    hasEquipment
                    hasConsumables
                />
            </TableCell>
            <TableCell>
                <SelectValid
                    id={`stock-add-item-${index}`}
                    value={item.inventoryId}
                    label="Name"
                    fullWidth
                    disabled={!item.category || filteredDetails.length === 0}
                    validators={[Validators.custom( () => item.category ? !!item.inventoryId : true, 'Please enter a value' )]}
                    onChange={( value ) => handleItemChange( {
                        ...item,
                        inventoryId: value,
                    } )}
                >
                    {filteredDetails.map( ( detail ) => (
                        <MenuItem
                            key={detail.id}
                            value={detail.id}
                        >
                            {detail.name}
                        </MenuItem>
                    ) )}
                </SelectValid>
            </TableCell>
            <TableCell>
                <SelectValid
                    id={`stock-add-unit-${index}`}
                    value={item.unit}
                    label="Unit"
                    fullWidth
                    onChange={( value ) => onChange( index, {
                        ...item,
                        unit: value as Units,
                    } )}
                    disabled={!item.category || isEquipment( item.category.type )}
                    validators={item.category && isConsumable( item.category.type ) ? [Validators.required( item.unit || '' )] : []}
                >
                    {Object.entries( Units ).map( ( [key, value] ) => (
                        <MenuItem
                            key={key}
                            value={value}
                        >
                            {key}
                        </MenuItem>
                    ) )}
                </SelectValid>
            </TableCell>
            <TableCell>
                <TextValid
                    type="number"
                    id={`stock-add-quantity-${index}`}
                    label="Quantity"
                    autoComplete='none'
                    value={item.quantity}
                    onChange={( value ) => onChange( index, {
                        ...item,
                        quantity: value,
                    } )}
                    validators={[
                        Validators.required( item.quantity.toString() ),
                        item.category && isConsumable( item.category.type ) ?
                            Validators.custom( () => parseFloat( item.quantity ) > 0, 'Must be a positive Number' ) :
                            Validators.custom( () => isNormalInteger( item.quantity ), 'Must be a positive Integer' ),
                    ]}
                />
            </TableCell>
            <TableCell>
                <SelectValid
                    id={`stock-add-room-${index}`}
                    value={item.roomId}
                    label="Location"
                    fullWidth
                    validators={[]}
                    onChange={( value ) => onChange( index, {
                        ...item,
                        roomId: value,
                    } )}
                    disabled={!item.category || isConsumable( item.category.type )}
                >
                    <MenuItem value="">Facility Storage</MenuItem>
                    {rooms.map( ( room ) => (
                        <MenuItem
                            key={room.id}
                            value={room.id}
                        >
                            {room.metrc.Name}
                        </MenuItem>
                    ) )}
                </SelectValid>
            </TableCell>
        </TableRow>
    );
};

const StockTable: React.FC<ITableProps> = ( { currentStock, onChange, rooms } ) => {
    const handleRow = ( index: number, row: IStockRow ) => {
        let newStock = [...currentStock];
        newStock.splice( index, 1, row );
        onChange( newStock );
    };

    return (
        <>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '25%' }}>Category</TableCell>
                        <TableCell style={{ width: '25%' }}>Item</TableCell>
                        <TableCell style={{ width: '15%' }}>Unit</TableCell>
                        <TableCell style={{ width: '15%' }}>Quantity</TableCell>
                        <TableCell style={{ width: '20%' }}>Location</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...currentStock, { ...emptyRow }].map( ( item, index ) => (
                        <StockRow index={index} item={item} onChange={handleRow} rooms={rooms}/>
                    ) )}
                </TableBody>
            </Table>
        </>
    );
};

const AddStock: React.FC<IProps> = ( { onClose, facilityId } ) => {
    const context = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [rows, setRows] = useState<IStockRow[]>( [] );
    const user = useSelector( selectUser );
    const consumables = useSelector( selectConsumables );
    const rooms = useFacilityRooms( facilityId );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        rows.forEach( ( row ) => updateInventory( row ) );
    };

    const updateInventory = ( { category, roomId, unit, quantity, inventoryId }: IStockRow ) => {
        if ( !category || !inventoryId || !user ) {
            return;
        }

        if ( isEquipment( category.type ) ) {
            const collection = context.firestore.collection( Collections.equipment );
            const update: Omit<IEquipmentItem, 'id'> = {
                inventoryId,
                roomId: roomId || undefined,
                type: category.type,
                facilityId,
            };

            const promises: Promise<any>[] = [];
            for ( let i = 0; i < parseInt( quantity ); i++ ) {
                promises.push( collection.add( removeUndefined( update ) ) );
            }

            Promise.all( promises )
                .then( () => {
                    onClose();
                } )
                .catch( ( e: Error ) => {
                    dispatch( setError( e.message ) );
                } );
        } else if ( isConsumable( category.type ) ) {
            const collection = context.firestore.collection( Collections.consumables );
            const found = consumables.find( ( item ) => item.inventoryId === inventoryId );

            if ( found ) {
                const update: IConsumableItem = {
                    ...found,
                    tickets: [...found.tickets, {
                        id: uuidv4(),
                        unit: unit || Units.Single,
                        amount: parseFloat( quantity ),
                        userId: user.uid,
                        timestamp: getTimeStamp(),
                        facilityId: facilityId,
                    }]
                };

                collection.doc( found.id )
                    .update( removeUndefined( update ) )
                    .then( () => {
                        onClose();
                    } )
                    .catch( ( e: Error ) => {
                        dispatch( setError( e.message ) );
                    } );
            } else {
                const update: Omit<IConsumableItem, 'id'> = {
                    type: category.type,
                    inventoryId,
                    tickets: [{
                        id: uuidv4(),
                        unit: unit || Units.Single,
                        amount: parseFloat( quantity ),
                        userId: user.uid,
                        timestamp: getTimeStamp(),
                        facilityId: facilityId,
                    }]
                };

                collection.add( removeUndefined( update ) )
                    .then( () => {
                        onClose();
                    } )
                    .catch( ( e: Error ) => {
                        dispatch( setError( e.message ) );
                    } );
            }
        }
    };

    return (
        <>
            <DialogTitle>Add new Inventory to Stock</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Fill in details about these new items.
                </DialogContentText>
                <Form id="inventory-stock-form" onSubmit={handleSubmit}>
                    <StockTable
                        rooms={rooms}
                        currentStock={rows}
                        onChange={setRows}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button type="submit" form="inventory-stock-form" color="primary">
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default AddStock;