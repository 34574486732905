import { UserPermissions } from '@shared/interfaces/lib/user';

export interface IUser {
    uid: string;
    email: string;
    displayName: string;
    permissions: UserPermissions[];
    emailVerified: boolean;
    lastSignInTime?: string;
    creationTime?: string;
}

export interface ICurrentUser extends IUser {
    token: string,
}

export class User {
    user: ICurrentUser;

    constructor( user: ICurrentUser ) {
        this.user = { ...user };

        const currentPermissions = Object.values( UserPermissions );
        this.user.permissions = user.permissions.filter( ( permission ) => currentPermissions.includes( permission ) );
    }

    get uid() {
        return this.user.uid;
    }

    get token() {
        return this.user.token;
    }

    get displayName() {
        return this.user.displayName;
    }

    get email() {
        return this.user.email;
    }

    get emailVerified() {
        return this.user.emailVerified;
    }

    isAllowed = ( permissions: UserPermissions | UserPermissions[] ) => {
        if ( this.user.permissions.includes( UserPermissions.orgAdmin ) ) {
            return true;
        }

        if ( Array.isArray( permissions ) ) {
            return permissions.some( ( permission ) => this.user.permissions.includes( permission ) );
        }

        return this.user.permissions.includes( permissions );
    };

    isActive = () => {
        return this.user.permissions.length > 0;
    };
}