import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IMetrcItem } from '@shared/interfaces/lib/metrcInterfaces';
import { IItem } from '@shared/interfaces/lib/interfaces';
import {
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Divider,
    Button,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Validators } from '@happybandit/react-validation';
import { useDispatch, useSelector } from 'react-redux';
import { postCreateItem } from '../../../../api';
import SelectValid from '../../../../components/SelectValid';
import { selectUser, setError } from '../../../../redux/appSlice';
import { FirebaseContext } from '../../../../firebase_init';

interface IProps {
    item: IItem;
    licenseId: string;
    onClose: () => void;
}

const LinkItem: React.FC<IProps> = ( { item, licenseId, onClose } ) => {
    const dispatch = useDispatch();
    const { firestore } = useContext( FirebaseContext );
    const user = useSelector( selectUser );
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    const [linked, setLinked] = useState( '' );
    const [items, setItems] = useState<IMetrcItem[]>( [] );
    const [loading, setLoading] = useState( false );

    useEffect( () => {
        if ( licenseId ) {
            firestore.collection( Collections.metrcFacilities ).doc( licenseId )
                .collection( MetrcFacilitySubCollections.metrcItems )
                .get()
                .then( ( snap ) => {
                    setItems( snap.docs.map( ( doc ) => doc.data() as IMetrcItem ) );
                } );
        }
    }, [licenseId] );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        link( parseInt( linked ) );
    };

    const link = ( itemId: number ) => {
        setLoading( true );
        const metrcItems = item.metrcItems.concat( [{
            metrcLicense: licenseId,
            metrcItemId: itemId,
        }] );

        const collection = firestore.collection( Collections.packageItems );
        collection.doc( item.id ).update( { metrcItems } ).then( () => {
            onClose();
        } ).catch( ( e: Error ) => {
            setLoading( false );
            dispatch( setError( e.message ) );
        } );
    };

    if ( !user ) {
        return null;
    }

    const createStrain = () => {
        setLoading( true );
        postCreateItem( user.token, {
            name: item.name,
            category: item.productCategoryName,
            strain: item.strainId === undefined ? null : item.strainId,
            unitOfMeasure: item.unitOfMeasure,
            facilityId: licenseId,
            weightQuantity: item.weightQuantity,
            weightUnit: item.weightUnit,
            volumeQuantity: item.volumeQuantity,
            volumeUnit: item.volumeUnit,
        } )
            .then( () => {
                firestore.collection( Collections.metrcFacilities ).doc( licenseId )
                    .collection( MetrcFacilitySubCollections.metrcItems )
                    .where( 'Name', '==', item.name )
                    .onSnapshot( ( snap ) => {
                        if ( snap.size > 0 ) {
                            const data = snap.docs[0].data() as IMetrcItem
                            link( data.Id );
                        }
                    } );
            } )
            .catch( ( e ) => {
                setLoading( false );
                dispatch( setError( e.message ) );
            } );
    };

    return (
        <>
            <Dialog fullScreen={fullScreen} open={true} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography variant="h5" style={{ display: 'inline' }} component="p">
                        <strong>
                            {item.name}
                        </strong>
                    </Typography>
                    <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                        <strong>
                            / Link
                        </strong>
                    </Typography>
                    <Typography variant="body2" component="p">
                        Link Package Items across systems and facilities to keep a single source of truth.
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                </DialogTitle>
                <DialogContent>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <SelectValid
                            id="link-select-items"
                            value={linked}
                            label="Metrc Item"
                            fullWidth
                            validators={[Validators.required( linked )]}
                            onChange={( value ) => setLinked( value )}
                        >
                            <MenuItem value=""/>
                            {items.map( ( item ) => (
                                <MenuItem
                                    key={item.Id}
                                    value={item.Id.toString()}
                                >
                                    {item.Name}
                                </MenuItem>
                            ) )}
                        </SelectValid>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            Link to Package Item
                        </Button>
                    </Form>
                    <Divider/>
                    <Button disabled={loading} onClick={createStrain} color="primary">
                        Create New
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LinkItem;