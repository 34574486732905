import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IHarvest, IPackage, IPlant } from '@shared/interfaces/lib/interfaces';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowPlantDetails, setShowPlantDetails } from '../../redux/appSlice';
import { PlantDetailPages } from '../../redux/interfaces';
import AdjustPackage from './adjustPackage';
import ChangePackageItem from './changePackageItem';
import ChangePackageNote from './changePackagenote';
import PlantClone from './clone';
import PlantDestroy from './destroyPlant';
import Details from './details';
import HarvestFinish from './finishHarvest';
import PackageFinish from './finishPackage';
import GetHarvest from './getHarvest';
import GetLabel from './getLabel';
import HarvestDetails from './harvestDetails';
import PlantHarvest from './harvestPlant';
import HarvestWaste from './harvestWaste';
import HarvestToPackage from './harvestToPackage';
import MovePackage from './movePackage';
import PackageToPackage from './packageToPackage';
import CloneToVeg from './veg';

interface IProps {
}

const Transition = React.forwardRef( function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
} );

const ALLOW_CLICK_OUT = [
    PlantDetailPages.get,
    PlantDetailPages.details,
    PlantDetailPages.harvestDetails,
];

const PlantDetailsDialog: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const showPlantDetails = useSelector( selectShowPlantDetails );

    const getContent = () => {
        if ( !showPlantDetails ) {
            return null;
        }

        if ( showPlantDetails.page === PlantDetailPages.get ) {
            return <GetLabel/>;
        } else if ( !showPlantDetails.item ) {
            return <CircularProgress size={24}/>;
        } else {
            switch ( showPlantDetails.page ) {
                case PlantDetailPages.getHarvest:
                    return <GetHarvest
                        plant={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                        other={showPlantDetails.other}
                    />;
                case PlantDetailPages.details:
                    return <Details
                        item={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.harvestDetails:
                    return <HarvestDetails
                        harvest={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.harvestPlant:
                    return <PlantHarvest
                        plant={showPlantDetails.item as IPlant}
                        dryRoomId={showPlantDetails.other.dryRoomId}
                        harvestType={showPlantDetails.other.type}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.clone:
                    return <PlantClone
                        plant={showPlantDetails.item as IPlant}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.toVeg:
                    return <CloneToVeg
                        batch={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.harvestWaste:
                    return <HarvestWaste
                        harvest={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.harvestFinish:
                    return <HarvestFinish
                        harvest={showPlantDetails.item}
                        undo={showPlantDetails.undo}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.packageFinish:
                    return <PackageFinish
                        packageItem={showPlantDetails.item}
                        undo={showPlantDetails.undo}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.toPackage:
                    if(showPlantDetails.key.collection === MetrcFacilitySubCollections.metrcHarvests){
                        return <HarvestToPackage
                            harvest={showPlantDetails.item as IHarvest}
                            facilityId={showPlantDetails.facilityId}
                        />;
                    } else {
                        return <PackageToPackage
                            packageItem={showPlantDetails.item as IPackage}
                            facilityId={showPlantDetails.facilityId}
                        />;
                    }
                case PlantDetailPages.destroyPlant:
                    return <PlantDestroy
                        plant={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.changeItem:
                    return <ChangePackageItem
                        packageItem={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.changeNote:
                    return <ChangePackageNote
                        packageItem={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.adjustQuantity:
                    return <AdjustPackage
                        packageItem={showPlantDetails.item}
                        facilityId={showPlantDetails.facilityId}
                    />;
                case PlantDetailPages.move:
                    if(showPlantDetails.key.collection === MetrcFacilitySubCollections.metrcPackages) {
                        return <MovePackage
                            packageItem={showPlantDetails.item as IPackage}
                            facilityId={showPlantDetails.facilityId}
                        />;
                    }
            }
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={!!showPlantDetails}
            onClose={() => dispatch( setShowPlantDetails( undefined ) )}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="md"
            style={{
                minHeight: 100
            }}
            disableBackdropClick={showPlantDetails && !ALLOW_CLICK_OUT.includes(showPlantDetails.page)}
        >
            {getContent()}
        </Dialog>
    )
};

export default PlantDetailsDialog;