import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPackage, IRoom, ISystem } from '@shared/interfaces/lib/interfaces';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowPlantDetails } from '../../../../redux/appSlice';
import { PlantDetailPages } from '../../../../redux/interfaces';

interface IProps {
    room?: IRoom;
    system?: ISystem;
    packageItem: IPackage;
}

const PackageDetails: React.FC<IProps> = ( { packageItem } ) => {
    const dispatch = useDispatch();

    if ( !packageItem ) {
        return null;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" color="textSecondary" gutterBottom>
                                {packageItem.metrc.PackageType}
                            </Typography>
                            <Typography>
                                {packageItem.metrc.ProductName}
                            </Typography>
                            <Typography>
                                <b>
                                    Current Quantity:
                                </b> {packageItem.metrc.Quantity} {packageItem.metrc.UnitOfMeasureName}
                            </Typography>
                            <Typography>
                                <b>Packaged:</b> {packageItem.metrc.PackagedDate}
                            </Typography>
                            <Typography>
                                <b>Source Harvests:</b> {packageItem.metrc.SourceHarvestNames}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {packageItem.metrc.Quantity === 0 && (
                                <Button
                                    variant="contained"
                                    onClick={() => dispatch( setShowPlantDetails( {
                                        page: PlantDetailPages.packageFinish,
                                        key: {
                                            collection: MetrcFacilitySubCollections.metrcPackages,
                                            id: packageItem.id,
                                        },
                                        undo: packageItem.metrc.FinishedDate !== null
                                    } ) )}
                                >
                                    {packageItem.metrc.FinishedDate === null ? 'Finish Package' : 'UnFinish Package'}
                                </Button>
                            )}
                            {packageItem.metrc.Quantity > 0 && packageItem.metrc.FinishedDate !== null && (
                                <Button
                                    variant="contained"
                                    onClick={() => dispatch( setShowPlantDetails( {
                                        page: PlantDetailPages.toPackage,
                                        key: {
                                            collection: MetrcFacilitySubCollections.metrcPackages,
                                            id: packageItem.id,
                                        },
                                    } ) )}
                                >
                                    Create New Package
                                </Button>
                            )}
                            {packageItem.metrc.FinishedDate !== null && (
                                <>
                                    <Button
                                        variant="contained"
                                        onClick={() => dispatch( setShowPlantDetails( {
                                            page: PlantDetailPages.adjustQuantity,
                                            key: {
                                                collection: MetrcFacilitySubCollections.metrcPackages,
                                                id: packageItem.id,
                                            },
                                        } ) )}
                                    >
                                        Adjust Quantity
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => dispatch( setShowPlantDetails( {
                                            page: PlantDetailPages.move,
                                            key: {
                                                collection: MetrcFacilitySubCollections.metrcPackages,
                                                id: packageItem.id,
                                            },
                                        } ) )}
                                    >
                                        Move
                                    </Button>
                                </>
                            )}
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" color="textSecondary" gutterBottom>
                                Item
                            </Typography>
                            <Typography>
                                <b>Category:</b> {packageItem.metrc.ProductCategoryName}
                            </Typography>
                            <Typography>
                                <b>Strain:</b> {packageItem.metrc.ItemStrainName || 'Multi Strain'}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {packageItem.metrc.FinishedDate !== null && (
                                <Button
                                    variant="contained"
                                    onClick={() => dispatch( setShowPlantDetails( {
                                        page: PlantDetailPages.changeItem,
                                        key: {
                                            collection: MetrcFacilitySubCollections.metrcPackages,
                                            id: packageItem.id,
                                        },
                                    } ) )}
                                >
                                    Change Product/Item
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                    <Card style={{ marginTop: 15 }}>
                        <CardContent>
                            <Typography variant="h5" color="textSecondary" gutterBottom>
                                Note
                            </Typography>
                            <Typography>
                                {packageItem.metrc.Note}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {packageItem.metrc.FinishedDate !== null && (
                                <Button
                                    variant="contained"
                                    onClick={() => dispatch( setShowPlantDetails( {
                                        page: PlantDetailPages.changeNote,
                                        key: {
                                            collection: MetrcFacilitySubCollections.metrcPackages,
                                            id: packageItem.id,
                                        },
                                    } ) )}
                                >
                                    Change Note
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
};

export default PackageDetails;