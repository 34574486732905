import { Table } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlant } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseContext } from '../../../firebase_init';
import { setShowPlantDetails } from '../../../redux/appSlice';
import { IPlantDetailsHarvestOther, PlantDetailPages } from '../../../redux/interfaces';
import TextValid from '../../TextValid';

interface IProps {
    plant: IPlant;
    facilityId: string;
    other: IPlantDetailsHarvestOther;
}

const GetHarvest: React.FC<IProps> = ( { plant, other, facilityId } ) => {
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [zonePlants, setZonePlants] = useState<IPlant[]>( [] );
    const [label, setLabel] = useState<string>( '' );
    const [invalid, setInvalid] = useState( false );

    useEffect(() => {
        if ( plant.location ) {
            firestore.collection(Collections.metrcFacilities)
                .doc(facilityId)
                .collection( MetrcFacilitySubCollections.metrcPlants )
                .where( 'location.systemId', '==', plant.location.systemId )
                .where( 'location.rack', '==', plant.location.rack )
                .where( 'location.level', '==', plant.location.level )
                .where( 'location.zone', '==', plant.location.zone )
                .where( 'metrc.HarvestedDate', '==', null )
                .where( 'metrc.Label', '!=', plant.metrc.Label )
                .get()
                .then((snap) => {
                    setZonePlants(snap.docs.map(doc => doc.data() as IPlant));
                });
        }
    }, []);

    const handleGet = () => {
        setInvalid( false );

        firestore.collection( Collections.metrcFacilities )
            .doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcPlants )
            .where( 'metrc.Label', '==', label )
            .where( 'metrc.HarvestedDate', '==', null )
            .get()
            .then( ( snap ) => {
                if ( snap.size > 0 ) {
                    dispatch( setShowPlantDetails( {
                        key: {
                            collection: MetrcFacilitySubCollections.metrcPlants,
                            id: snap.docs[0].id,
                        },
                        page: PlantDetailPages.harvestPlant,
                        other,
                    } ) );
                } else {
                    setInvalid( true );
                }
            } )
    }

    return (
        <>
            <DialogTitle>Enter Plant Label</DialogTitle>
            <DialogContent>
                <Typography>Get Plant in the Same Zone for Harvest</Typography>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Strain</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {zonePlants.map( ( zonePlant ) => (
                            <TableRow
                                hover
                                key={zonePlant.id}
                                onClick={() => dispatch( setShowPlantDetails( {
                                    key: {
                                        collection: MetrcFacilitySubCollections.metrcPlants,
                                        id: zonePlant.id,
                                    },
                                    page: PlantDetailPages.harvestPlant,
                                    other,
                                } ) )}
                            >
                                <TableCell>
                                    {zonePlant.metrc.Label}
                                </TableCell>
                                <TableCell>
                                    {zonePlant.metrc.StrainName}
                                </TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
                <hr/>
                <Typography>Get Plant by Label to Harvest</Typography>
                <TextValid
                    onChange={setLabel}
                    value={label}
                    label="Label"
                    fullWidth
                    id="plant-details-label"
                    validators={[]}
                    forcedErrorMessage={invalid ? 'Invalid Label' : undefined}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch( setShowPlantDetails( undefined ) )} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleGet}>
                    Get Plant for Harvest
                </Button>
            </DialogActions>
        </>
    )
};

export default GetHarvest;