import {Zoom} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import appStyles from '../../App.module.css';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';

interface IProps {
    onClick: () => void
}

export const AddButton: React.FC<IProps> = ({onClick}) => {
    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <Zoom
            in={true}
            timeout={transitionDuration}
            style={{
                transitionDelay: transitionDuration.exit + 'ms',
            }}
            unmountOnExit
        >
            <Fab
                className={appStyles.fab as any}
                aria-label="Add"
                color="primary"
                onClick={onClick}
            >
                <AddIcon/>
            </Fab>
        </Zoom>
    )
};