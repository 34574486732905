import { Form, Validators } from '@happybandit/react-validation';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IPackageChangeItemBody } from '@shared/interfaces/lib/contractInterfaces';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPackage } from '@shared/interfaces/lib/interfaces';
import { IMetrcItem } from '@shared/interfaces/lib/metrcInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postChangePackageItem, ResponseError } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import SelectValid from '../../SelectValid';

interface IProps {
    packageItem: IPackage;
    facilityId: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            minHeight: 100,
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);

const ChangePackageItem: React.FC<IProps> = ( { packageItem, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { firestore } = useContext( FirebaseContext );
    const user = useSelector( selectUser );
    const [items, setItems] = useState<IMetrcItem[]>( [] );
    const [itemId, setItemId] = useState( '' );
    const [loading, setLoading] = useState( false );
    const [errors, setErrors] = useState<string[]>( [] );

    useEffect( () => {
        if ( !packageItem ) {
            return;
        }
        const collection = firestore.collection( Collections.metrcFacilities ).doc( facilityId )
            .collection( MetrcFacilitySubCollections.metrcItems );

        const samePromise = collection.where( 'StrainName', '==', packageItem.metrc.ItemStrainName )
            .get()
        const multiPromise = collection.where( 'StrainName', '==', null )
            .get()

        Promise.all( [samePromise, multiPromise] ).then( ( snaps ) => {
            setItems( snaps.reduce<IMetrcItem[]>( ( acc, snap ) =>
                acc.concat( snap.docs.map( doc => doc.data() as IMetrcItem ) ), [] ) );
        } );
    }, [] );

    const selectedItem = items.find( item => item.id === itemId );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user || !selectedItem ) {
            return;
        }
        setLoading( true );
        setErrors( [] );

        const body: IPackageChangeItemBody = {
            facilityId,
            label: packageItem.metrc.Label,
            itemName: selectedItem.Name,
        }
        postChangePackageItem( user.token, body )
            .then( () => {
                dispatch( setShowPlantDetails( {
                    key: {
                        collection: MetrcFacilitySubCollections.metrcPackages,
                        id: packageItem.id,
                    },
                    page: PlantDetailPages.details,
                } ) );
            } )
            .catch( async ( e: ResponseError ) => {
                if ( e.response.status === 400 ) {
                    const body: { errors: string[] } = await e.response.json();
                    setErrors( body.errors );
                }
            } )
            .finally( () => {
                setLoading( false );
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Change Package Product/Item
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        {errors.length > 0 && (
                            <Alert variant="filled" severity="error">
                                <AlertTitle>Change Failed</AlertTitle>
                                {errors.map( ( error ) => (
                                    <div>{error}</div>
                                ) )}
                            </Alert>
                        )}
                        <Typography variant="h5">
                            Change the Product of the package
                        </Typography>
                        <Typography variant="h6">
                            Current Product: {packageItem.metrc.Item.Name}
                        </Typography>
                        <Typography variant="h6">
                            Current Product Strain: {packageItem.metrc.ItemStrainName}
                        </Typography>
                        <Form onSubmit={handleSubmit}>
                            <SelectValid
                                id="package-item"
                                value={itemId}
                                label="New Product Designation"
                                fullWidth
                                validators={[
                                    Validators.required( itemId ),
                                ]}
                                onChange={setItemId}
                            >
                                <MenuItem value={''}/>
                                {items.map( ( item ) => (
                                    <MenuItem key={item.Id} value={item.id}>{item.Name}</MenuItem>
                                ) )}
                            </SelectValid>
                            <hr style={{ 'borderTop': '#eee' }}/>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Submit Change
                            </Button>
                        </Form>
                    </>
                )}
            </div>
        </>
    )
};

export default ChangePackageItem;