import { Form } from '@happybandit/react-validation';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IPackageChangeNoteBody } from '@shared/interfaces/lib/contractInterfaces';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPackage } from '@shared/interfaces/lib/interfaces';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postChangePackageNote, ResponseError } from '../../../api';
import { selectUser, setShowPlantDetails } from '../../../redux/appSlice';
import { PlantDetailPages } from '../../../redux/interfaces';
import TextValid from '../../TextValid';

interface IProps {
    packageItem: IPackage;
    facilityId: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing( 2 ),
            flex: 1,
        },
        level: {
            textTransform: 'capitalize',
        },
        content: {
            padding: theme.spacing( 2 ),
            minHeight: 100,
            backgroundColor: theme.palette.grey[200],
        },
        breadcrumb: {
            marginBottom: theme.spacing( 2 ),
        }
    } ),
);

const ChangePackageNote: React.FC<IProps> = ( { packageItem, facilityId } ) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector( selectUser );
    const [note, setNote] = useState( packageItem.metrc.Note || '' );
    const [loading, setLoading] = useState( false );
    const [errors, setErrors] = useState<string[]>( [] );

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user ) {
            return;
        }
        setLoading( true );
        setErrors( [] );

        const body: IPackageChangeNoteBody = {
            facilityId,
            label: packageItem.metrc.Label,
            note,
        }
        postChangePackageNote( user.token, body )
            .then( () => {
                dispatch( setShowPlantDetails( {
                    key: {
                        collection: MetrcFacilitySubCollections.metrcPackages,
                        id: packageItem.id,
                    },
                    page: PlantDetailPages.details,
                } ) );
            } )
            .catch( async ( e: ResponseError ) => {
                if ( e.response.status === 400 ) {
                    const body: { errors: string[] } = await e.response.json();
                    setErrors( body.errors );
                }
            } )
            .finally( () => {
                setLoading( false );
            } );
    };

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start" color="inherit" onClick={() => dispatch( setShowPlantDetails( undefined ) )}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Change Package Note
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {loading ? (
                    <CircularProgress size={24}/>
                ) : (
                    <>
                        {errors.length > 0 && (
                            <Alert variant="filled" severity="error">
                                <AlertTitle>Change Failed</AlertTitle>
                                {errors.map( ( error ) => (
                                    <div>{error}</div>
                                ) )}
                            </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <TextValid
                                id="package-note"
                                value={note}
                                label="Note"
                                fullWidth
                                validators={[]}
                                onChange={setNote}
                            />
                            <hr style={{ 'borderTop': '#eee' }}/>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Submit Change
                            </Button>
                        </Form>
                    </>
                )}
            </div>
        </>
    )
};

export default ChangePackageNote;