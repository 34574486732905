import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { useSelector } from 'react-redux';
import { selectExternalContacts } from '../../../redux/appSlice';
import { addressTemplate } from '../../../utils';
import { getServiceIcon } from '../utils';

const ExternalContractsView: React.FC<RouteComponentProps<{contactId: string}>> = ({contactId}) => {
    const externalContacts = useSelector(selectExternalContacts);
    const externalContact = externalContacts.find((contact) => contact.id === contactId);

    if(!externalContact) {
        navigate('/external-contacts');
        return null;
    }

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        View External Contact
                    </strong>
                </Typography>
                <Link href="#" onClick={() => navigate(`/external-contacts/${contactId}/edit`)} variant="body1">
                    <strong>
                        &nbsp;<span style={{ 'color': 'black' }}>/</span> Edit
                    </strong>
                </Link>
                <hr style={{ 'borderTop': '#eee' }} />

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Company Name
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ { externalContact.name }
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Address
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ { addressTemplate(externalContact.address) }
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Services
                    </strong>
                </Typography>
                <br/>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    { externalContact.services.map((contact) => (
                        <>
                            {getServiceIcon(contact)}: {contact}
                        </>
                    )) }
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Contacts
                    </strong>
                </Typography>
                <br/>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    { externalContact.contacts.map((contact) => (
                        <>
                            {contact.name} / {contact.title} / {contact.phone || 'No Phone'} / {contact.email || 'No Email'}
                        </>
                    )) }
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Notes
                    </strong>
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />

                <Typography variant="body2" style={{ display: 'inline' }}>
                    { externalContact.notes }
                </Typography>
            </Box>
        </>
    );
};

export default ExternalContractsView;
