import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setError } from '../../redux/appSlice';
import { FirebaseContext } from '../../firebase_init';
import { INotification } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { removeUndefined, useNotification } from '../../utils';
import { Omit } from '@material-ui/core';
import { Form, Validators } from '@happybandit/react-validation';
import TextValid from '../../components/TextValid';
import { ConsumableTypes, EquipmentTypes, IInventoryCategory } from '@shared/interfaces/lib/inventoryInterfaces';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '@material-ui/lab';

interface IProps {
    inventoryCategory?: IInventoryCategory;
    onClose: () => void;
}

const InventoryCategoryDialog: React.FC<IProps> = ( { inventoryCategory, onClose } ) => {
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const theme = useTheme();

    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [name, setName] = useState<string>( (inventoryCategory && inventoryCategory.name) || '' );
    const [type, setType] = useState<ConsumableTypes.UserDefined | EquipmentTypes.UserDefined | undefined>( inventoryCategory ? inventoryCategory.type : undefined );
    const user = useSelector(selectUser);
    const sendNotification = useNotification();

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid || !user || !type ) {
            return;
        }

        const collection = firestore.collection( Collections.inventoryCategories );
        let update: IInventoryCategory = {
            id: inventoryCategory ? inventoryCategory.id : collection.doc().id,
            name,
            type,
        };
        collection.doc( update.id ).set( removeUndefined( update ) )
            .then( () => {
                onClose();
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );

        const notification: Omit<INotification, 'id'> = {
            timestamp: Date.now(),
            seen: false,
            userId: user.uid,
            message: `${inventoryCategory ? 'Created' : 'Updated'} Inventory Category - ${name}`,
        };
        sendNotification( notification, ( userItem ) => userItem.permissions.includes( UserPermissions.userAdmin ) );
    };

    return (
        <>
            <Dialog fullScreen={fullScreen} open={true} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle>{!inventoryCategory ? 'Create New' : 'Update'} Inventory Category</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill in the user defined Category for sorting Inventory
                    </DialogContentText>
                    <Form id="inventory-item-form" onSubmit={handleSubmit}>
                        <ToggleButtonGroup
                            value={type}
                            exclusive
                            onChange={( event, value ) => setType( value )}
                        >
                            <ToggleButton
                                value={EquipmentTypes.UserDefined}
                            >
                                Equipment
                            </ToggleButton>
                            <ToggleButton
                                value={ConsumableTypes.UserDefined}
                            >
                                Consumable
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <TextValid
                            id="inventory-new-name"
                            label="Name"
                            autoComplete='none'
                            value={name}
                            onChange={( value ) => setName( value )}
                            fullWidth
                            validators={[Validators.required( name )]}
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!type} type="submit" form="inventory-item-form" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const InventoryCategoryNew = () => {
    const [open, setOpen] = useState( false );

    return (
        <>
            <Button onClick={() => setOpen( true )} color="primary">
                Add new Inventory Category
            </Button>
            {open && (
                <InventoryCategoryDialog onClose={() => setOpen( false )}/>
            )}
        </>
    );
};

export default InventoryCategoryDialog;