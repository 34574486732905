import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IInventoryCategory } from '@shared/interfaces/lib/inventoryInterfaces';
import { selectInventoryCategories } from '../../../redux/appSlice';
import { isEquipment } from '../../../utils';
import InventoryCategoryDialog, { InventoryCategoryNew } from '../../../components/inventoryCategoryDialog';

const InventoryCategories: React.FC<RouteComponentProps> = () => {
    const inventoryCategories = useSelector(selectInventoryCategories);
    const [edit, setEdit] = useState<IInventoryCategory | undefined>( undefined );

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Inventory Categories
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ { inventoryCategories.length } items
                </Typography>
                <hr style={{ 'borderTop': '#eee' }} />
            </Box>
            <InventoryCategoryNew/>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                        <TableCell>Storage</TableCell>
                        <TableCell>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inventoryCategories.map( ( inventoryCategory ) => (
                        <TableRow hover key={inventoryCategory.id} onClick={() => setEdit( inventoryCategory )}>
                            <TableCell>
                                {isEquipment(inventoryCategory.type) ? 'Equipment' : 'Consumable'}
                            </TableCell>
                            <TableCell>
                                {inventoryCategory.name}
                            </TableCell>
                        </TableRow>
                    ) )}
                </TableBody>
            </Table>
            {edit && (
                <InventoryCategoryDialog inventoryCategory={edit} onClose={() => setEdit( undefined )}/>
            )}
        </>
    );
};

export default InventoryCategories;
