import { Table, TableBody } from '@material-ui/core';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { ITransfer } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FirebaseContext } from '../../../firebase_init';

import FacilityContext from '../facilityContext';

import TransferItem from './item';

const useStyles = makeStyles( () => ({
    section: {
        paddingTop: '2em',
        paddingBottom: '3em',
    },
    root: {
        width: '100%',

        '& li:last-child': {
            borderBottom: 'none',
        }
    },
}) );

const Transfer: React.FC<RouteComponentProps> = () => {
    const context = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const classes = useStyles();
    const [transfers, setTransfers] = useState<ITransfer[]>( [] );

    useEffect( () => {
        if ( facility ) {
            context.firestore.collection( Collections.metrcFacilities ).doc( facility.id )
                .collection( MetrcFacilitySubCollections.metrcTransfers )
                .get()
                .then( ( snap ) => {
                    const result = snap.docs.map( item => item.data() as ITransfer );
                    setTransfers( result );
                } )
        }
    }, [facility.id] );

    if ( !facility ) {
        navigate( '/' );
        return null;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: 'inline' }}>
                        <strong>
                            {facility.metrc.DisplayName}
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ Transfers
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                </Grid>
            </Grid>

            <Grid component="section" container spacing={3} id={facility.id + '-transfer'} className={classes.section}>
                <List
                    component="nav"
                    className={classes.root}
                >
                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Incoming
                        </strong>
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <Table>
                        <TableBody>
                            {transfers.filter(item => item.type === 'incoming').map( ( transfer ) => (
                                <TransferItem
                                    key={transfer.id}
                                    transfer={transfer}
                                />
                            ) )}
                        </TableBody>
                    </Table>

                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Outgoing
                        </strong>
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <Table>
                        {transfers.filter(item => item.type === 'outgoing').map( ( transfer ) => (
                            <TransferItem
                                key={transfer.id}
                                transfer={transfer}
                            />
                        ) )}
                    </Table>

                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Rejected
                        </strong>
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <Table>
                        {transfers.filter(item => item.type === 'rejected').map( ( transfer ) => (
                            <TransferItem
                                key={transfer.id}
                                transfer={transfer}
                            />
                        ) )}
                    </Table>
                </List>
            </Grid>
        </>
    );
};

export default Transfer;
