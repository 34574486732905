import { IFacility } from '@shared/interfaces/lib/interfaces';
import React from 'react';

interface IFacilityContext {
    facility: IFacility;
}

const FacilityContext = React.createContext<IFacilityContext>(undefined as any);

export default FacilityContext;
