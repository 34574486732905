import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import { cloneDeep } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import { selectExternalContacts, selectUser, setError } from '../../../../redux/appSlice';
import { FirebaseContext } from '../../../../firebase_init';
import { INotification } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { addressTemplate, useNotification, useSelectedFacility, useSelectedStrain } from '../../../../utils';
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Validators } from '@happybandit/react-validation';
import TextValid from '../../../../components/TextValid';
import { ITest } from '../../interfaces';

interface IProps {
    test: ITest;
}

const TestResults: React.FC<IProps> = ( { test } ) => {
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [open, setOpen] = useState<boolean>( false );
    const [thcCbdRatio, setTHCCBDRatio] = useState<string>( '' );
    const [cannabinoidPerWeight, setCannabinoidPerWeight] = useState<number>( 0 );
    const [totalCannabinoidsPresent, setTotalCannabinoidsPresent] = useState<number>( 0 );
    const user = useSelector( selectUser );
    const externalContacts = useSelector( selectExternalContacts );
    const facility = useSelectedFacility( ( facility ) => facility.id === test.facilityOriginatedId );
    const strain = useSelectedStrain( ( strain ) => strain.id === test.strainId );
    const sendNotification = useNotification();

    const contact = externalContacts.find( ( facility ) => facility.id === test.testingFacilityId );

    const handleClose = () => {
        setOpen( false );
    };

    const getResultsObject = () => {
        return {
            name: `${test.name} - ${test.id} - ${contact ? contact.name : ''}`,
            heavyMetals: [],
            potency: {
                thcCbdRatio,
                cannabinoidPerWeight,
                totalCannabinoidsPresent,
            },
            chemicalContaminants: [],
            microbialContaminants: [],
            terpeneProfile: [],
            pesticides: [],
        }
    };

    const handleSubmit = () => {
        if ( !user ) {
            return;
        }

        let updateTest = cloneDeep( test );

        Object.assign( updateTest, { inProgress: false } );
        Object.assign( updateTest.results, getResultsObject() );

        firestore.collection( Collections.tests )
            .doc( test.id ).update( updateTest )
            .then( () => {
                handleClose();
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );

        const notification: Omit<INotification, 'id'> = {
            timestamp: Date.now(),
            seen: false,
            userId: user.uid,
            message: `Added Test Results for Test - ${test.name} in facility ${facility ? facility.metrc.DisplayName : ''}`,
        };

        sendNotification( notification, ( userItem ) => userItem.permissions.includes( UserPermissions.userAdmin ) );
    };

    return (
        <>
            <Button
                color="primary"
                startIcon={<AddIcon/>}
                onClick={() => setOpen( true )}
                style={{ paddingTop: '1.5em', paddingBottom: '1.5em' }}
            >
                Test Results Received
            </Button>

            <Dialog
                maxWidth="lg" fullScreen={fullScreen} open={open} onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>Test {test.name} Results </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Test Results are the charateristics of a Strain for every 15 Pounds
                        <br/>
                        <strong>
                            This is serious and extremely important, please verify all information is correct.
                        </strong>
                    </DialogContentText>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                            Test Results for Test
                            #{test.inProgress} at {contact ? contact.name : ''} for {facility ? facility.metrc.DisplayName : ''}
                        </Typography>
                        <div>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <AddIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={contact ? contact.name : ''}
                                        secondary="Testing Facility"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <AddIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={contact ? addressTemplate( contact.address ) : ''}
                                        secondary="Testing Address"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <AddIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={contact && contact.contacts.length > 0 ? contact.contacts[0].name + ' - ' + contact.contacts[0].phone + ' - ' + contact.contacts[0].email : ''}
                                        secondary="Testing Facility Contact"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <AddIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={strain ? strain.name : ''}
                                        secondary="Strain Tested"
                                    />
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                    <TextValid
                        id={`test-results-${test.id}-potency-thc-cbd-ratio`}
                        label="Potency - THC / CBD Ratio"
                        autoComplete='none'
                        value={thcCbdRatio}
                        onChange={( value ) => setTHCCBDRatio( value )}
                        fullWidth
                        validators={[Validators.required( thcCbdRatio )]}
                    />
                    <TextValid
                        id={`test-results-${test.id}-cannabinoid-per-weight`}
                        label="Potency - Cannabinoid Per Weight"
                        autoComplete='none'
                        value={cannabinoidPerWeight !== 0 ? cannabinoidPerWeight.toString() : ''}
                        onChange={( value ) => setCannabinoidPerWeight( parseFloat( value ) )}
                        fullWidth
                        validators={[Validators.required( cannabinoidPerWeight.toString() )]}
                    />
                    <TextValid
                        id={`test-results-${test.id}-total-cannabinoids-present`}
                        label="Potency - Total Cannabinoids Present"
                        autoComplete='none'
                        value={totalCannabinoidsPresent !== 0 ? cannabinoidPerWeight.toString() : ''}
                        onChange={( value ) => setTotalCannabinoidsPresent( parseFloat( value ) )}
                        fullWidth
                        validators={[Validators.required( totalCannabinoidsPresent.toString() )]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TestResults;