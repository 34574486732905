import React, { useContext, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form, Validators } from '@happybandit/react-validation';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { FirebaseContext } from '../../firebase_init';
import TextValid from '../../components/TextValid';
import GoogleLogin from '../../components/GoogleLogin';

const useStyles = makeStyles( theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing( 8 ),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing( 1 ),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing( 1 ),
    },
    submit: {
        margin: theme.spacing( 3, 0, 2 ),
    },
}) );

const Login: React.FC<RouteComponentProps> = () => {
    const [email, setEmail] = useState( '' );
    const [password, setPassword] = useState( '' );
    const [error, setError] = useState( false );
    const [open, setOpen] = React.useState( false );
    const classes = useStyles();
    const context = useContext( FirebaseContext );
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    const handleSubmit = ( event: React.FormEvent ) => {
        event.preventDefault();
        setError( false );
        context.doSignInWithEmailAndPassword( email, password )
            .then( () => {
                return navigate( '/' );
            } )
            .catch( () => {
                setError( true );
            } );
    };

    const handleReset = ( valid: boolean ) => {
        if ( !valid ) {
            return;
        }

        setOpen( false );
        return context.auth.sendPasswordResetEmail( email );
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form method="POST" className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={( event ) => setEmail( event.target.value )}
                        error={error}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={( event ) => setPassword( event.target.value )}
                        error={error}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <GoogleLogin
                        className={classes.submit}
                    />
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2" onClick={() => setOpen( true )}>
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" onClick={() => navigate( '/sign-up' )}>
                                {'Don\'t have an account? Sign Up'}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => setOpen( false )}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Create New System for the Room
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the email address on file for your account. In a few minutes you should get a Password
                        Reset email.
                    </DialogContentText>
                    <Form id="reset-form" onSubmit={handleReset}>
                        <TextValid
                            id="system-new-name"
                            name="email"
                            label="Email Address"
                            value={email}
                            onChange={( value ) => setEmail( value )}
                            fullWidth
                            validators={[Validators.required( email ), Validators.email( email )]}
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen( false )} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" form="reset-form" color="primary">
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Login;
