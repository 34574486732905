import {
    ICloneCutBody,
    ICloneToVegBody,
    IDestroyPlantBody,
    IHarvestFinishBody,
    IHarvestPostBody,
    IHarvestToProductBody,
    IHarvestWasteBody,
    IItemCreateBody,
    ILocationCreateBody,
    IPackageAdjustBody, IPackageChangeItemBody, IPackageChangeNoteBody,
    IPackageFinishBody,
    IPackageMoveBody,
    IPackageToProductBody,
    IPlantBatchMoveBody,
    IPlantMoveBody,
    IStrainCreateBody
} from '@shared/interfaces/lib/contractInterfaces';
import { IPlantBatch } from '@shared/interfaces/lib/interfaces';
import { IUser } from './User';

export class ResponseError extends Error {
    public readonly response: Response;

    constructor(resp: Response) {
        super(resp.statusText);
        this.response = resp;
        this.name = 'ResponseError';
    }

    public json = async () => {
        try {
            return await this.response.json();
        } catch {
            return null;
        }
    }
}

export const validateResponse = (response: Response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw new ResponseError(response);
    }
};

export const getHeaders = (idToken: string): HeadersInit => {
    return {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
    };
};

export const getUsers = (idToken: string): Promise<IUser[]> => {
    return fetch('/api/users', {
        headers: getHeaders(idToken),
    }).then(validateResponse);
};

export const updateUser = (idToken: string, user: IUser) => {
    return fetch('/api/users/' + user.uid, {
        method: 'PATCH',
        headers: getHeaders(idToken),
        body: JSON.stringify({
            displayName: user.displayName,
            email: user.email,
            permissions: user.permissions
        })
    }).then(validateResponse);
};

export const postHarvest = (idToken: string, body: IHarvestPostBody) => {
    return fetch('/api/harvest/add', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postMovePlant = (idToken: string, body: IPlantMoveBody) => {
    return fetch('/api/plant/move', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postMovePlantBatch = (idToken: string, body: IPlantBatchMoveBody) => {
    return fetch('/api/plant-batch/move', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postCreateStrain = (idToken: string, body: IStrainCreateBody) => {
    return fetch('/api/strain/create', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postUpdateStrain = (idToken: string, strainId: number, body: IStrainCreateBody) => {
    return fetch('/api/strain/' + strainId, {
        method: 'PUT',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postCreateItem = (idToken: string, body: IItemCreateBody) => {
    return fetch('/api/item/create', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postUpdateItem = (idToken: string, itemId: number, body: IItemCreateBody) => {
    return fetch('/api/item/' + itemId, {
        method: 'PUT',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postCreateLocation = (idToken: string, body: ILocationCreateBody): Promise<{id: number}> => {
    return fetch('/api/location/create', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const putUpdateLocation = (idToken: string, locationId: number, body: ILocationCreateBody): Promise<{id: number}> => {
    return fetch('/api/location/' + locationId, {
        method: 'PUT',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const deleteLocation = (idToken: string, locationId: number, body: { facilityId: string }) => {
    return fetch('/api/location/' + locationId, {
        method: 'DELETE',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postPlantCloneCut = (idToken: string, body: ICloneCutBody): Promise<IPlantBatch> => {
    return fetch('/api/plant/cut-clones', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postHarvestWaste = (idToken: string, body: IHarvestWasteBody) => {
    return fetch('/api/harvest/waste', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postHarvestFinish = (idToken: string, body: IHarvestFinishBody) => {
    return fetch('/api/harvest/finish', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postPackageFinish = (idToken: string, body: IPackageFinishBody) => {
    return fetch('/api/package/finish', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postHarvestToPackage = (idToken: string, body: IHarvestToProductBody) => {
    return fetch('/api/harvest/create-package', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postPackageToPackage = (idToken: string, body: IPackageToProductBody) => {
    return fetch('/api/package/create-package', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postMovePackage = (idToken: string, body: IPackageMoveBody) => {
    return fetch('/api/package/move', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postChangePackageItem = (idToken: string, body: IPackageChangeItemBody) => {
    return fetch('/api/package/change-item', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postChangePackageNote = (idToken: string, body: IPackageChangeNoteBody) => {
    return fetch('/api/package/change-note', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postAdjustPackage = (idToken: string, body: IPackageAdjustBody) => {
    return fetch('/api/package/adjust', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postPlantBatchToVeg = (idToken: string, body: ICloneToVegBody) => {
    return fetch('/api/plant-batch/to-veg', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};

export const postDestroyPlant = (idToken: string, body: IDestroyPlantBody) => {
    return fetch('/api/plant/destroy', {
        method: 'POST',
        headers: getHeaders(idToken),
        body: JSON.stringify(body)
    }).then(validateResponse);
};