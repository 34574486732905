import { createStyles, Divider, Drawer, List, ListItem, ListItemIcon, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Folder } from '@material-ui/icons';
import CategoryIcon from '@material-ui/icons/Category';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CropFree from '@material-ui/icons/CropFree';
import GroupIcon from '@material-ui/icons/Group';
import Help from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import TonalityIcon from '@material-ui/icons/Tonality';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { navigate, RouteComponentProps } from '@reach/router';
import { UserPermissions } from '@shared/interfaces/lib/user';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetData } from '../../dataHooks';
import { FirebaseContext } from '../../firebase_init';
import { selectDone, selectUser, setDone, setShowArticle, setShowPlantDetails, setUser } from '../../redux/appSlice';
import { PlantDetailPages } from '../../redux/interfaces';
import ErrorSnack from '../errorSnack';
import InfoDrawer from '../infoDrawer';
import PlantDetailsDrawer from '../plantDetails';
import Scheduler from '../scheduler';
import SyncSnack from '../sync';
import UserAvatar from '../userAvatar';
import Notifications from './notifications';
import RosinCalc from './rosin';
import Sync from './sync';

const drawerWidth: number = 240;

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        grow: {
            flexGrow: 1,
        },
        avatar: {
            padding: 0,
        },
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create( ['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            } ),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create( ['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            } ),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create( 'width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            } ),
        },
        drawerClose: {
            transition: theme.transitions.create( 'width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            } ),
            overflowX: 'hidden',
            width: theme.spacing( 7 ) + 1,
            [theme.breakpoints.up( 'sm' )]: {
                width: theme.spacing( 9 ) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing( 0, 1 ),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing( 3 ),
        },
    } ),
);

function Copyright(): JSX.Element {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://vaxt.co/">
                Cultivatd Solutions LLC
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Wrapper: React.FC<RouteComponentProps> = ( { children, location } ) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
    const context = useContext( FirebaseContext );
    const classes = useStyles();
    const [open, setOpen] = React.useState( false );
    const user = useSelector( selectUser );
    const firstLoadComplete = useSelector( selectDone );

    const currentLocation = location ? location.pathname : '';

    useGetData();

    useEffect( () => {
        const listener = context.auth.onAuthStateChanged( authUser => {
            if ( authUser ) {
                authUser.getIdTokenResult()
                    .then( ( result ) => {
                        dispatch( setUser( {
                            displayName: authUser.displayName || '',
                            email: authUser.email || '',
                            permissions: result.claims.permissions || [],
                            token: result.token,
                            uid: authUser.uid,
                            emailVerified: authUser.emailVerified,
                        } ) );
                    } )
                    .catch( () => dispatch( setUser( undefined ) ) );
            } else {
                dispatch( setUser( undefined ) );
                if ( !firstLoadComplete ) {
                    dispatch( setDone() );
                }
            }
        } );

        return () => listener();
        // eslint-disable-next-line
    }, [] );

    const handleDrawerOpen = () => {
        setOpen( true );
    };

    const handleDrawerClose = () => {
        setOpen( false );
    };

    const handleSignOut = () => {
        setAnchorEl( null );
        context.doSignOut()
            .then( () => navigate( '/login' ) );
    };

    const handleMenu = ( link: string ) => () => {
        setAnchorEl( null );
        return navigate( link );
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean( anchorEl )}
            onClose={() => setAnchorEl( null )}
        >
            <MenuItem onClick={handleMenu( '/account' )}>Account</MenuItem>
            {user && user.isAllowed( UserPermissions.orgAdmin ) && (
                <MenuItem onClick={handleMenu( '/organization' )}>Org Settings</MenuItem>
            )}
            {user && user.isAllowed( UserPermissions.orgAdmin ) && <Sync/>}
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </Menu>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx( classes.appBar, {
                    [classes.appBarShift]: open,
                } )}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx( classes.menuButton, {
                            [classes.hide]: open,
                        } )}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" style={{ display: 'inline' }}>
                        <strong>
                            Cultivatd
                        </strong>
                    </Typography>
                    <Typography variant="body1" style={{ display: 'inline' }}>
                        <strong>
                            &nbsp;/ Grow Maintenance System
                        </strong>
                    </Typography>
                    <div className={classes.grow}/>
                    <RosinCalc/>
                    {user ? (
                        <>
                            <IconButton
                                edge="end"
                                aria-label="show plant details"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={() => dispatch( setShowPlantDetails( {
                                    page: PlantDetailPages.get,
                                } ) )}
                            >
                                <CropFree/>
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="show help panel"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={() => dispatch( setShowArticle( { show: true } ) )}
                            >
                                <Help/>
                            </IconButton>
                            <Notifications/>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-haspopup="true"
                                color="inherit"
                                className={classes.avatar}
                                onClick={( event: React.MouseEvent<HTMLButtonElement> ) => setAnchorEl( event.currentTarget )}
                            >
                                <UserAvatar id={user.uid} name={user.displayName} hasLink={false}/>
                            </IconButton>
                            {renderMenu}
                        </>
                    ) : (
                        <Button
                            color="inherit"
                            onClick={() => navigate && navigate( '/login' )}
                        >
                            Login
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx( classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                } )}
                classes={{
                    paper: clsx( {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    } ),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <ListItem
                        button
                        selected={currentLocation === '/'}
                        onClick={() => navigate( '/' )}
                    >
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <Typography variant="body1" style={{ display: 'inline' }}>
                            <strong>
                                Home
                            </strong>
                        </Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={currentLocation === '/external-contacts'}
                        onClick={handleMenu( '/external-contacts' )}
                    >
                        <ListItemIcon>
                            <WorkOutlineIcon/>
                        </ListItemIcon>
                        <Typography variant="body1" style={{ display: 'inline' }}>
                            <strong>
                                External Contacts
                            </strong>
                        </Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={currentLocation === '/inventory'}
                        onClick={handleMenu( '/inventory' )}
                    >
                        <ListItemIcon>
                            <CategoryIcon/>
                        </ListItemIcon>
                        <Typography variant="body1" style={{ display: 'inline' }}>
                            <strong>
                                Inventory Details
                            </strong>
                        </Typography>
                    </ListItem>
                    {user && user.isAllowed( UserPermissions.strainView ) && (
                        <ListItem
                            button
                            selected={currentLocation === '/strains'}
                            onClick={handleMenu( '/strains' )}
                        >
                            <ListItemIcon>
                                <TonalityIcon/>
                            </ListItemIcon>
                            <Typography variant="body1" style={{ display: 'inline' }}>
                                <strong>
                                    Strains
                                </strong>
                            </Typography>
                        </ListItem>
                    )}
                    {user && user.isAllowed( UserPermissions.strainView ) && (
                        <ListItem
                            button
                            selected={currentLocation === '/items'}
                            onClick={handleMenu( '/items' )}
                        >
                            <ListItemIcon>
                                <Folder/>
                            </ListItemIcon>
                            <Typography variant="body1" style={{ display: 'inline' }}>
                                <strong>
                                    Items
                                </strong>
                            </Typography>
                        </ListItem>
                    )}
                    {user && user.isAllowed( UserPermissions.strainView ) && (
                        <ListItem
                            button
                            selected={currentLocation === '/testing'}
                            onClick={handleMenu( '/testing' )}
                        >
                            <ListItemIcon>
                                <TonalityIcon/>
                            </ListItemIcon>
                            <Typography variant="body1" style={{ display: 'inline' }}>
                                <strong>
                                    Testing
                                </strong>
                            </Typography>
                        </ListItem>
                    )}
                    {user && user.isAllowed( UserPermissions.userAdmin ) && (
                        <ListItem
                            button
                            selected={currentLocation === '/users'}
                            onClick={handleMenu( '/users' )}
                        >
                            <ListItemIcon>
                                <GroupIcon/>
                            </ListItemIcon>
                            <Typography variant="body1" style={{ display: 'inline' }}>
                                <strong>
                                    Users
                                </strong>
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Box p={2}>
                    {firstLoadComplete && children}
                </Box>
                <Box mt={8}>
                    <Copyright/>
                </Box>
                {user && user.isActive() && (
                    <>
                        <InfoDrawer/>
                        <PlantDetailsDrawer/>
                        <Scheduler/>
                    </>
                )}
                {!firstLoadComplete && (
                    <Backdrop
                        open={true}
                        style={{ zIndex: 100 }}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                )}
                <ErrorSnack/>
                <SyncSnack/>
            </main>
        </div>
    );
};

export default Wrapper;
