import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import { Form, Validators } from '@happybandit/react-validation';
import { IRoom } from '@shared/interfaces/lib/interfaces';
import { IConsumableItemBase, ConsumableTypes, Units } from '@shared/interfaces/lib/inventoryInterfaces';
import { selectConsumables, selectInventoryDetails, selectUser } from 'redux/appSlice';
import { setError } from 'redux/appSlice';
import { FirebaseContext } from 'firebase_init';
import TextValid from 'components/TextValid';
import SelectValid from 'components/SelectValid';
import { convertUnit, getTimeStamp, isDetailConsumable, uuidv4 } from 'utils';
import FacilityContext from '../../../facilityContext';

interface IProps {
    systemId: string;
    room: IRoom;
    onClose: () => void;
}

interface INutrient {
    inventoryId: string;
    quantity: string;
    unit: Units;
}

interface ISelectorProps {
    currentNutrients: INutrient[];
    onChange: ( nutrients: INutrient[] ) => void;
}


const NutrientSelector: React.FC<ISelectorProps> = ( { currentNutrients, onChange } ) => {
    const inventoryDetails = useSelector( selectInventoryDetails );
    const { facility } = useContext( FacilityContext );
    const consumables = useSelector( selectConsumables );
    const [inventoryId, setInventoryId] = useState<string>( '' );

    const handleAdd = () => {
        if ( !inventoryId ) {
            return;
        }
        const inventoryDetail = inventoryDetails.find( ( detail ) => detail.id === inventoryId );
        if(!inventoryDetail || !isDetailConsumable(inventoryDetail)){
            return;
        }

        onChange( [ ...currentNutrients, {
            inventoryId: inventoryId,
            unit: inventoryDetail.unit,
            quantity: '1',
        }] );
        setInventoryId( '' );
    };

    const handleDelete = ( index: number ) => {
        let newNutrient = [...currentNutrients];

        newNutrient.splice( index, 1 );
        onChange( newNutrient );
    };

    const handleQuantityChange = ( index: number, value: string ) => {
        let newNutrient = [...currentNutrients];

        newNutrient[index].quantity = value;
        onChange( newNutrient );
    };

    const handleUnitChange = ( index: number, value: Units ) => {
        let newNutrient = [...currentNutrients];

        newNutrient[index].unit = value;
        onChange( newNutrient );
    };

    const filteredDetails = inventoryDetails.filter( ( detail ) => detail.type === ConsumableTypes.Nutrients );

    return (
        <>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell style={{ width: 100 }}>Needed</TableCell>
                        <TableCell>Available</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentNutrients.map( ( item, index ) => {
                        const inventoryDetail = inventoryDetails.find( ( detail ) => detail.id === item.inventoryId );
                        if(!inventoryDetail){
                            return null;
                        }

                        const consumable = consumables.find( ( detail ) => detail.inventoryId === item.inventoryId );
                        let totalRemaining = 0;
                        if(consumable){
                            const facilityTickets = consumable.tickets.filter((ticket) => ticket.facilityId === facility.id);
                            totalRemaining = facilityTickets.reduce((acc, ticket) => {
                                const converted = convertUnit(ticket.amount, ticket.unit, item.unit);

                                return acc + converted;
                            }, 0);
                        }

                        return (
                            <TableRow key={index}>
                                <TableCell>{inventoryDetail.name}</TableCell>
                                <TableCell>
                                    <SelectValid
                                        id="system-nutrient-unit"
                                        value={item.unit}
                                        label="Unit"
                                        fullWidth
                                        onChange={( value ) => handleUnitChange( index, value as Units )}
                                        validators={[]}
                                    >
                                        <option value=""/>
                                        {Object.entries( Units ).map( ( [key, value] ) => (
                                            <option
                                                key={key}
                                                value={value}
                                            >
                                                {key}
                                            </option>
                                        ) )}
                                    </SelectValid>
                                </TableCell>
                                <TableCell>
                                    <TextValid
                                        type="number"
                                        id="system-nutrient-quantity"
                                        label="Quantity"
                                        autoComplete='none'
                                        value={item.quantity.toString()}
                                        onChange={( value ) => handleQuantityChange( index, value )}
                                        validators={[
                                            Validators.required( item.quantity.toString() ),
                                            Validators.custom( () => parseFloat(item.quantity) > 0, 'Must be a positive number' ),
                                            Validators.custom( () => parseFloat(item.quantity) <= totalRemaining, 'Not enough available' )
                                        ]}
                                    />
                                </TableCell>
                                <TableCell>{totalRemaining}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDelete( index )}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    } )}
                    <TableRow>
                        <TableCell colSpan={2}>
                            <SelectValid
                                id="system-nutrient-detail"
                                value={inventoryId}
                                label="Name"
                                disabled={filteredDetails.length === 0}
                                validators={[]}
                                onChange={( value ) => setInventoryId( value )}
                                fullWidth
                            >
                                <option value=""/>
                                {filteredDetails.map( ( detail ) => (
                                    <option
                                        key={detail.id}
                                        value={detail.id}
                                    >
                                        {detail.name}
                                    </option>
                                ) )}
                            </SelectValid>
                        </TableCell>
                        <TableCell colSpan={3}>
                            <Button
                                type="button"
                                onClick={handleAdd}
                                startIcon={<AddIcon/>}
                                color="primary"
                                disabled={!inventoryId}
                            >
                                Add Nutrient
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};
const AddNutrientsDialog: React.FC<IProps> = ( { systemId, room, onClose } ) => {
    const context = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const dispatch = useDispatch();

    const [nutrients, setNutrients] = useState<INutrient[]>( [] );
    const user = useSelector( selectUser );
    const consumables = useSelector( selectConsumables );

    const handleSubmit = ( valid: boolean ) => {
        if ( !user || !valid ) {
            return;
        }

        nutrients.forEach( ( nutrient ) => {
            const found = consumables.find( ( item ) => item.inventoryId === nutrient.inventoryId );
            if ( found ) {
                const collection = context.firestore.collection( Collections.consumables );

                const update: Partial<IConsumableItemBase> = {
                    tickets: [...found.tickets, {
                        id: uuidv4(),
                        unit: nutrient.unit,
                        amount: parseFloat(nutrient.quantity),
                        userId: user.uid,
                        timestamp: getTimeStamp(),
                        facilityId: facility.id,
                        roomId: room.id,
                        systemId: systemId,
                    }],
                };

                collection.doc( found.id )
                    .update( update )
                    .then( () => {
                        onClose();
                    } )
                    .catch( ( e: Error ) => {
                        dispatch( setError( e.message ) );
                    } );
            }
        } );
    };

    return (
        <Dialog maxWidth="lg" open={true} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        { room.metrc.Name }
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    <strong>
                        / Room / Add Nutrients
                    </strong>
                </Typography>
                <Typography variant="body2" component="p">
                    How much of each nutrient was added.
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>
            </DialogTitle>
            <DialogContent>
                <Form id="inventory-item-form" onSubmit={handleSubmit}>
                    <NutrientSelector currentNutrients={nutrients} onChange={setNutrients}/>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={nutrients.length === 0} type="submit" form="inventory-item-form" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const AddNutrientsNew: React.FC<Omit<IProps, 'onClose'>> = ( props ) => {
    const [open, setOpen] = useState( false );

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                endIcon={ <AddIcon/> }
                onClick={() => setOpen( true )}
                style={{ width: '100%', borderRadius: '0' }}
            >
                Add Nutrients
            </Button>
            {open && (
                <AddNutrientsDialog {...props} onClose={() => setOpen( false )}/>
            )}
        </>
    );
};

export default AddNutrientsDialog;