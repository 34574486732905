import React, { useMemo } from 'react';
import { navigate } from '@reach/router';
import {
    Grid,
    Paper,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@material-ui/core';
import { IRoom } from '@shared/interfaces/lib/interfaces';
import { IEquipmentItem } from '@shared/interfaces/lib/inventoryInterfaces';
import { useSelector } from 'react-redux';
import { selectEquipment, selectInventoryDetails } from '../../../../redux/appSlice';
import { getIcon } from '../../../../utils';
import { useRoomStyles } from '../shared';

interface IProps {
    room: IRoom;
}

const RoomEquipment: React.FC<IProps> = ( { room } ) => {
    const classes = useRoomStyles();
    const equipment = useSelector( selectEquipment );
    const inventoryDetails = useSelector( selectInventoryDetails );

    const groupedEquipment = useMemo( () => equipment.filter( ( item ) => item.roomId === room.id )
        .reduce( ( acc, item ) => {
            const found = acc.get( item.inventoryId );
            if ( found ) {
                acc.set( item.inventoryId, [...found, item] );
            } else {
                acc.set( item.inventoryId, [item] );
            }
            return acc;
        }, new Map<string, IEquipmentItem[]>() ), [equipment] );

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ display: 'inline' }}>
                        <strong>
                            Equipment
                        </strong>
                    </Typography>
                    <Link onClick={() => navigate( '/equipment/' + room.id )} variant="body1">
                        <strong>
                            <span style={{ 'color': 'black' }}>&nbsp;/</span> View
                        </strong>
                    </Link>
                    <hr style={{ 'borderTop': '#eee' }}/>
                </Grid>
            </Grid>

            <section id={room.metrc.Name + '-equipment'} className={classes.section}>
                <Grid container direction="row" spacing={1}>
                    {[...groupedEquipment].map( ( [inventoryId, items], index: number ) => {
                        const inventoryDetail = inventoryDetails.find( ( details ) => details.id === inventoryId );
                        return inventoryDetail ? (
                            <Grid item xs={12} sm={4} key={index}>
                                <Paper elevation={0} variant="outlined" square>
                                    <List dense={true}>
                                        <ListItem>
                                            <ListItemIcon>
                                                {getIcon( inventoryDetail.type )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1" style={{ display: 'inline' }}>
                                                    <strong>
                                                        {inventoryDetail.name}
                                                    </strong>
                                                </Typography>
                                                <br/>
                                                <Typography variant="body2" component="p" style={{ display: 'inline' }}>
                                                    {`${inventoryDetail.brand} / ${inventoryDetail.model}${inventoryDetail.sku ? ` / ${inventoryDetail.sku}` : ''}`}
                                                </Typography>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <ListItemText>
                                                    <strong>
                                                        Quantity / {items.length}
                                                    </strong>
                                                </ListItemText>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Grid>
                        ) : null;
                    } )}
                </Grid>
            </section>
        </Grid>
    );
};

export default RoomEquipment;
