import { Form, Validators } from '@happybandit/react-validation';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { updateUser } from '../../../api';
import SaveButton from '../../../components/saveButton';
import TextValid from '../../../components/TextValid';
import { selectUser } from '../../../redux/appSlice';
import { IUser } from '../../../User';
import { UserPermissions } from '@shared/interfaces/lib/user';

interface IUserDetails extends IUser {
    onClose: ( reload: boolean ) => void;
}

export const UserDetails: React.FC<IUserDetails> = ( props ) => {
    const user = useSelector( selectUser );
    const [name, setName] = useState( props.displayName );
    const [email, setEmail] = useState( props.email );
    const [permissions, setPermissions] = useState( props.permissions );
    const [loading, setLoading] = useState( false );

    if(!user || !user.isAllowed(UserPermissions.userAdmin)) {
        return null;
    }

    const handleSubmit = ( valid: boolean ) => {
        if ( !valid ) {
            return null;
        }

        setLoading( true );
        updateUser( user ? user.token : '', {
            ...props,
            displayName: name,
            email,
            permissions,
        } )
            .then( () => props.onClose( true ) )
            .finally( () => setLoading( false ) );
    };

    const handleToggle = ( value: UserPermissions ) => () => {
        const currentIndex = permissions.indexOf( value );
        let newChecked = [...permissions];

        if ( currentIndex === -1 ) {
            if(value === UserPermissions.orgAdmin){
                newChecked = [value];
            } else {
                newChecked.push( value );
            }
        } else {
            newChecked.splice( currentIndex, 1 );
        }

        setPermissions( newChecked );
    };

    return (
        <Box p={2}>
            <Typography variant="h3">
                {props.displayName ? `User ${props.displayName}` : 'New User'}
            </Typography>

            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextValid
                            id="user-name"
                            label="Name"
                            fullWidth
                            value={name}
                            onChange={( value ) => setName( value )}
                            validators={[Validators.required( name )]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextValid
                            id="user-email"
                            label="Email"
                            fullWidth
                            value={email}
                            onChange={( value ) => setEmail( value )}
                            validators={[Validators.required( email ), Validators.email( email )]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Assign responsibility</FormLabel>
                            <FormGroup>
                                {Object.entries( UserPermissions )
                                    .filter(([, permission]) => user.isAllowed(UserPermissions.orgAdmin) || permission !== UserPermissions.orgAdmin)
                                    .map( ( [key, permission] ) => (
                                    <FormControlLabel
                                        key={key}
                                        control={(
                                            <Checkbox
                                                disabled={permission !== UserPermissions.orgAdmin && permissions.includes( UserPermissions.orgAdmin )}
                                                checked={permissions.includes( permission )}
                                                onChange={handleToggle( permission )}
                                                name={permission}
                                            />
                                        )}
                                        label={permission}
                                    />
                                ) )}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider/>
                <Box mt={1}>
                    <SaveButton loading={loading}/>
                </Box>
            </Form>
        </Box>
    );
};