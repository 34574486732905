import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import { Box, Link } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IFacility, IItem, IStrain } from '@shared/interfaces/lib/interfaces';
import { IMetrcItem } from '@shared/interfaces/lib/metrcInterfaces';
import { postUpdateItem } from '../../../api';
import { FirebaseContext } from '../../../firebase_init';
import { selectFacilities, selectStrains, selectUser } from '../../../redux/appSlice';
import LinkItem from './link';
import EditProduct from './edit';

interface IFacilityItem {
    item?: IMetrcItem;
    facility: IFacility;
}

interface IRowFacility {
    isEmpty: boolean;
    isDifferent: boolean;
    facilityValue: string;
    facilityId: string;
    itemId?: number;
}

interface IRow {
    label: string;
    cultivatd: string;
    facilities: IRowFacility[];
}

const Row: React.FC<IRow> = ( { label, cultivatd, facilities } ) => {
    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell>{cultivatd}</TableCell>
            {facilities.map( ( item, index ) => (
                <TableCell key={index}>
                    <Typography
                        variant="body2" color={item.isDifferent ? 'error' : 'initial'}
                    >
                        {item.facilityValue}
                    </Typography>
                </TableCell>
            ) )}
        </TableRow>
    );
}

interface IEditRow {
    rows: IRow[];
    onEdit: () => void;
    onOpen: ( id: string ) => void;
    onUpdate: ( row: IRowFacility ) => void;
}

interface IMapping {
    name: string;
    cultivatd: keyof IItem;
    facility: keyof IMetrcItem;
}

const EditRow: React.FC<IEditRow> = ( { onEdit, onUpdate, rows, onOpen } ) => (
    <TableRow>
        <TableCell/>
        <TableCell>
            <Button
                color="primary"
                onClick={onEdit}
            >
                Edit
            </Button>
        </TableCell>
        {rows[0].facilities.map( ( row, index ) => {
            const { facilityId, isEmpty } = row;
            const isDifferent = rows.some( ( row ) => {
                const found = row.facilities.find( ( facility ) => facility.facilityId === facilityId );
                return found && found.isDifferent;
            } )
            return (
                <TableCell key={index}>
                    {isEmpty ? (
                        <Button
                            color="primary"
                            onClick={() => onOpen( facilityId )}
                        >
                            Link / Create
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            disabled={!isDifferent} onClick={() => onUpdate( row )}
                        >
                            {isDifferent ? 'Update' : 'Matches'}
                        </Button>
                    )}
                </TableCell>
            );
        } )}
    </TableRow>
);

const mapping: IMapping[] = [{
    name: 'Name',
    cultivatd: 'name',
    facility: 'Name',
}, {
    name: 'Product Category',
    cultivatd: 'productCategoryName',
    facility: 'ProductCategoryName',
}, {
    name: 'Strain Name',
    cultivatd: 'strainId',
    facility: 'StrainName',
}, {
    name: 'Unit of Measure',
    cultivatd: 'unitOfMeasure',
    facility: 'UnitOfMeasureName',
}, {
    name: 'Volume of Single Unit',
    cultivatd: 'volumeQuantity',
    facility: 'UnitVolume',
}, {
    name: 'Volume Measure of Single Unit',
    cultivatd: 'volumeUnit',
    facility: 'UnitVolumeUnitOfMeasureName',
}, {
    name: 'Weight of Single Unit',
    cultivatd: 'weightQuantity',
    facility: 'UnitWeight',
}, {
    name: 'Weight Measure of Single Unit',
    cultivatd: 'weightUnit',
    facility: 'UnitWeightUnitOfMeasureName',
}];

const getStrainName = ( strains: IStrain[], item?: IItem ): string => {
    if ( !item ) {
        return '';
    } else if ( item.strainId === null ) {
        return 'Multi Strain';
    } else {
        const strain = strains.find( strain => strain.id === item.strainId );
        if ( strain ) {
            return strain.name;
        } else {
            return 'Unknown (Not Cultivatd Linked)';
        }
    }
};

const getCompareRows = ( facilities: IFacilityItem[], strains: IStrain[], cultivatd?: IItem ) => {
    const compare = facilities.map( ( { item, facility } ) => {
        const isEmpty = !item;

        let values: { value: string, isDifferent: boolean }[] = [];
        let itemId: number | undefined = undefined;
        if ( item && cultivatd ) {
            values = mapping.map( ( map ) => {
                if ( map.cultivatd === 'strainId' ) {
                    const strain = strains.find( strain => strain.id === cultivatd.strainId );
                    return {
                        value: item.StrainName ?? 'Multi Strain',
                        isDifferent: cultivatd.strainId !== item.StrainId &&
                            !(strain && strain.metrcStrains.some( metrc => metrc.metrcStrainId === item.StrainId )),
                    };
                } else {
                    let cultivatdValue = cultivatd[map.cultivatd];
                    let facilityValue = item[map.facility];
                    return {
                        value: (facilityValue ?? '').toString(),
                        isDifferent: cultivatdValue !== facilityValue,
                    };
                }
            } );
            itemId = item.Id
        }
        return {
            isEmpty,
            values,
            itemId,
            facilityId: facility.id,
        };
    } );

    return mapping.map<IRow>( ( map, index ) => {
        let cultivatdValue;
        if ( map.cultivatd === 'strainId' ) {
            cultivatdValue = getStrainName( strains, cultivatd );
        } else {
            cultivatdValue = ((cultivatd || {})[map.cultivatd] ?? '').toString();
        }

        return {
            label: map.name,
            cultivatd: cultivatdValue,
            facilities: compare.map<IRowFacility>( ( { isEmpty, values, facilityId, itemId } ) => {
                const value = values[index];
                return {
                    isDifferent: isEmpty ? false : value.isDifferent,
                    isEmpty,
                    facilityValue: isEmpty ? '' : value.value,
                    facilityId,
                    itemId,
                };
            } ),
        };
    } );
}

const replace = ( prev: IFacilityItem[], facility: IFacility, data: IFacilityItem ) => {
    const index = prev.findIndex( ( item ) => item.facility.id === facility.id );
    const newArray = [...prev];
    if ( index === -1 ) {
        newArray.push( data )
    } else {
        newArray[index] = data;
    }
    return newArray;
}

const Item: React.FC<RouteComponentProps<{ itemId: string }>> = ( { itemId } ) => {
    const { firestore } = useContext( FirebaseContext );
    const user = useSelector( selectUser );
    const facilities = useSelector( selectFacilities );
    const strains = useSelector( selectStrains );
    const [item, setItem] = useState<IItem | undefined>( undefined );
    const [edit, setEdit] = useState<boolean>( false );
    const [open, setOpen] = useState( '' );
    const [metrcFacilities, setMetrcFacilities] = useState<IFacilityItem[]>( [] );
    const compare = useMemo( () => getCompareRows( metrcFacilities, strains, item ), [item, metrcFacilities, strains] );

    const getFacilityData = ( record: IItem ) => {
        facilities.forEach( ( facility ) => {
            firestore.collection( Collections.metrcFacilities )
                .doc( facility.id )
                .get()
                .then( ( doc ) => {
                    const facility = doc.data() as IFacility;
                    const itemLink = record.metrcItems.find( ( iter ) => iter.metrcLicense === facility.id );
                    if ( itemLink ) {
                        doc.ref.collection( MetrcFacilitySubCollections.metrcItems )
                            .doc( itemLink.metrcItemId.toString() )
                            .onSnapshot( ( strainDoc ) => {
                                setMetrcFacilities( ( prev ) => {
                                    return replace( prev, facility, {
                                        facility,
                                        item: strainDoc.data() as IMetrcItem,
                                    } );
                                } );
                            } );
                    } else {
                        setMetrcFacilities( ( prev ) => {
                            return replace( prev, facility, { facility } );
                        } );
                    }
                } );
        } );
    };

    useEffect( () => {
        if ( !itemId ) {
            return;
        }

        firestore.collection( Collections.packageItems )
            .doc( itemId )
            .onSnapshot( snap => {
                if ( snap.exists ) {
                    const record = snap.data() as IItem;
                    setItem( record );
                    return getFacilityData( record );
                }
            } );
    }, [] );

    if ( !item || !user ) {
        return null;
    }

    const onUpdateFacility = ( row: IRowFacility ) => {
        if ( !row || !row.itemId ) {
            return;
        }

        const facility = metrcFacilities.find( fac => fac.facility.id === row.facilityId )

        postUpdateItem( user.token, row.itemId, {
            name: item.name,
            category: item.productCategoryName,
            strain: item.strainId === undefined ? facility?.item?.StrainName ?? null : item.strainId,
            unitOfMeasure: item.unitOfMeasure,
            facilityId: row.facilityId,
        } ).catch();
    };

    return (
        <>
            <Box mb={2}>
                <Typography variant="h3" style={{ display: 'inline' }}>
                    <strong>
                        Product
                    </strong>
                </Typography>
                <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {item.name}
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    <strong>
                        &nbsp;/ {item.productCategoryName}
                    </strong>
                </Typography>
                <Link href="#" onClick={() => setEdit( true )} variant="body1">
                    <strong>
                        &nbsp;<span style={{ 'color': 'black' }}>/</span> Edit
                    </strong>
                </Link>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Strain
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/
                    {getStrainName( strains, item )}
                </Typography>
                <hr style={{ 'borderTop': '#eee' }}/>

                <Typography variant="h5" style={{ display: 'inline' }}>
                    <strong>
                        Unit of Measure
                    </strong>
                </Typography>
                <Typography variant="body1" component="p" style={{ display: 'inline' }}>
                    &nbsp;/ {item.unitOfMeasure}
                </Typography>
                {edit && (
                    <EditProduct open={true} onClose={() => setEdit( false )} item={item}/>
                )}
            </Box>
            <Divider/>
            <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                Package Item Mapping Across Metrc Facilities
            </Typography>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                        <TableCell/>
                        <TableCell>Cultivatd</TableCell>
                        {metrcFacilities.map( ( item ) => (
                            <TableCell key={item.facility.id}>
                                {item.facility.metrc.DisplayName}
                            </TableCell>
                        ) )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <EditRow
                        onUpdate={onUpdateFacility}
                        onEdit={() => setEdit( true )}
                        rows={compare}
                        onOpen={setOpen}
                    />
                    {compare.map( ( item ) => (
                        <Row key={item.label} {...item}/>
                    ) )}
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        {metrcFacilities.map( ( item ) => (
                            <TableCell key={item.facility.id}>
                                {item.item ? item.item.IsUsed ? 'Used' : 'Not Used' : ''}
                            </TableCell>
                        ) )}
                    </TableRow>
                </TableBody>
            </Table>
            {open && (
                <LinkItem
                    item={item}
                    licenseId={open}
                    onClose={() => setOpen( '' )}
                />
            )}
        </>
    );
};

export default Item;
