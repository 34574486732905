import { Button, DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IFacility, IItem, IStrain } from '@shared/interfaces/lib/interfaces';
import { IMetrcItem } from '@shared/interfaces/lib/metrcInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectValid from '../../../../components/SelectValid';
import { FirebaseContext } from '../../../../firebase_init';
import { selectFacilities, selectStrains, setError } from '../../../../redux/appSlice';
import { runBatch } from '../../../../utils';

interface IFacilityItems {
    facility: IFacility;
    items: {
        metrc: IMetrcItem;
        strain: IStrain | null | undefined;
    }[];
}

const InitialGenItems: React.FC = () => {
    const { firestore } = useContext( FirebaseContext );
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const strains = useSelector( selectStrains );
    const facilities = useSelector( selectFacilities );
    const [selectedFacilityId, setSelectedFacilityId] = useState<string>( '' );
    const [open, setOpen] = useState( false );
    const [metrcItems, setMetrcItems] = useState<IFacilityItems[]>( [] );
    const dispatch = useDispatch();

    useEffect( () => {
        facilities.forEach( ( facility ) => {
            firestore.collection( Collections.metrcFacilities )
                .doc(facility.id)
                .collection( MetrcFacilitySubCollections.metrcItems )
                .get()
                .then( ( docs ) => {
                    const items = docs.docs.map( ( doc ) => doc.data() as IMetrcItem );
                    if ( items.length > 0 ) {
                        setMetrcItems( ( prev ) => prev.concat( [{
                            facility,
                            items: items.map( item => {
                                const strain = strains.find( strain => strain.metrcStrains.some( metrc => metrc.metrcStrainId === item.StrainId ) );
                                return {
                                    metrc: item,
                                    strain: item.StrainId !== null ?
                                        strain ? strain : undefined :
                                        null,
                                };
                            } ),
                        }] ) );
                    }
                } );
        } );
    }, [] )

    if ( metrcItems.length < 1 ) {
        return null;
    }

    const selectedFacility = metrcItems.find( ( item ) => item.facility.metrc.License.Number === selectedFacilityId );

    const handleAutoGenerate = () => {
        const collection = firestore.collection( Collections.packageItems );
        if ( !selectedFacility ) {
            return;
        }

        const items = selectedFacility.items.map<IItem>( ( item ) => {
            return {
                id: collection.doc().id,
                name: item.metrc.Name,
                unitOfMeasure: item.metrc.UnitOfMeasureName,
                strainId: item.strain === null ? null : item.strain?.id,
                productCategoryName: item.metrc.ProductCategoryName,
                volumeQuantity: item.metrc.UnitVolume || undefined,
                volumeUnit: item.metrc.UnitVolumeUnitOfMeasureName || undefined,
                weightQuantity: item.metrc.UnitWeight || undefined,
                weightUnit: item.metrc.UnitWeightUnitOfMeasureName || undefined,
                metrcItems: [{
                    metrcLicense: selectedFacility.facility.metrc.License.Number,
                    metrcItemId: item.metrc.Id,
                }],
            };
        } );
        runBatch( firestore, items, collection )
            .catch( ( error ) => {
                dispatch( setError( error ) )
            } );
    };

    return (
        <>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" onClick={() => setOpen( true )}>
                        Auto-Generate
                    </Button>
                }
            >
                <AlertTitle>No Items Defined</AlertTitle>
                You can <strong>Auto-Generate</strong> missing items from a specific Facility by clicking the button.
            </Alert>
            <Dialog
                fullScreen={fullScreen} open={open} onClose={() => setOpen( false )} aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Generate Items
                </DialogTitle>
                <DialogContent>
                    <SelectValid
                        id="items-gen-facility"
                        value={selectedFacilityId}
                        label="Metrc Facility"
                        fullWidth
                        validators={[]}
                        onChange={setSelectedFacilityId}
                    >
                        <MenuItem value=""/>
                        {metrcItems.map( ( { facility } ) => (
                            <MenuItem
                                key={facility.metrc.License.Number} value={facility.metrc.License.Number}
                            >{facility.metrc.DisplayName}</MenuItem>
                        ) )}
                    </SelectValid>
                    {selectedFacility && (
                        <>
                            <Divider/>
                            <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                                Items to Generate from {selectedFacility.facility.metrc.DisplayName}
                            </Typography>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Stain</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedFacility.items.map( ( { metrc, strain } ) => {
                                        return (
                                            <TableRow key={metrc.Id}>
                                                <TableCell>
                                                    {metrc.Name}
                                                </TableCell>
                                                <TableCell>
                                                    {metrc.ProductCategoryName}
                                                </TableCell>
                                                <TableCell>
                                                    {strain === null && 'Multi Strain'}
                                                    {strain === undefined && (
                                                        <>
                                                            {metrc.StrainName}<br/>
                                                            (Not Cultivatd Linked)
                                                        </>
                                                    )}
                                                    {strain && strain.name}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    } )}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={!selectedFacility} onClick={handleAutoGenerate}>
                        Generate Items
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InitialGenItems;
