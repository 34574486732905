import React from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import { Checkbox, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IFacility, IRoom } from '@shared/interfaces/lib/interfaces';
import { TaskLocation } from '@shared/interfaces/lib/scheduleInterfaces';
import { selectFacilities } from '../../../redux/appSlice';
import { useFacilityRooms } from '../../../utils';

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing( 0.5, 0 ),
        },
        labelIcon: {
            marginRight: theme.spacing( 1 ),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
        },
    } ) );



interface ILocationBaseProps {
    edit: boolean;
    locations: TaskLocation[];
    onChange: ( newLocations: TaskLocation[] ) => void;
}

interface IItemProps {
    edit: boolean;
    id: string;
    text: string;
    selected: boolean;
    indeterminate: boolean;
    onSelected: ( selected: boolean ) => void;
    onExpand?: ( expanded: boolean ) => void;
}

const LocationItem: React.FC<IItemProps> = ( { id, edit, children, indeterminate, onSelected, selected, text } ) => {
    const classes = useStyles();

    const onItemSelected = ( event: React.MouseEvent | React.ChangeEvent ) => {
        event.preventDefault();

        onSelected( !selected );
    };

    if(!selected && !edit){
        return null;
    }

    return (
        <TreeItem
            nodeId={id}
            label={
                <div className={classes.labelRoot}>
                    <Checkbox
                        className={classes.labelIcon}
                        checked={selected}
                        onChange={onItemSelected}
                        indeterminate={indeterminate}
                    />
                    <Typography variant="body2" className={classes.labelText}>
                        {text}
                    </Typography>
                </div>
            }
            onLabelClick={edit ? onItemSelected : undefined}
        >
            {children}
        </TreeItem>
    );
};

interface IRoomProps extends ILocationBaseProps{
    room: IRoom;
    facilityId: string;
}

const RoomTreeItem: React.FC<IRoomProps> = ( { edit, room, locations, facilityId, onChange } ) => {
    const selected = locations.length === 1 && !!locations[0].roomId && !locations[0].systemId;

    const handleSelected = () => {
        if ( selected ) {
            onChange( [] );
        } else {
            onChange( [{
                facilityId,
                roomId: room.id,
            }] );
        }
    };

    return (
        <LocationItem
            id={room.id}
            edit={edit}
            text={room.metrc.Name}
            selected={locations.length > 0}
            indeterminate={locations.length > 0 && !selected}
            onSelected={handleSelected}
        />
    );
};

interface IFacilityProps extends ILocationBaseProps{
    facility: IFacility;
}

const RoomLocations: React.FC<IFacilityProps> = ( { edit, locations, onChange, facility } ) => {
    const rooms = useFacilityRooms( facility.id );

    const handleChange = ( room: IRoom ) => ( newLocation: TaskLocation[] ) => {
        onChange( [
            ...locations.filter( location => location.roomId && location.roomId !== room.id ),
            ...newLocation,
        ] );
    };

    return (
        <>
            {rooms.map( room => {
                const found = locations.filter( location => location.roomId === room.id );

                return (
                    <RoomTreeItem
                        key={room.id}
                        facilityId={facility.id}
                        edit={edit}
                        room={room}
                        locations={found}
                        onChange={handleChange( room )}
                    />
                );
            } )}
        </>
    );
}

const FacilityTreeItem: React.FC<IFacilityProps> = ( { edit, facility, locations, onChange } ) => {
    const selected = locations.length === 1 && !!locations[0].facilityId && !locations[0].roomId;

    const handleSelected = () => {
        if ( selected ) {
            onChange( [] );
        } else {
            onChange( [{
                facilityId: facility.id,
            }] );
        }
    };

    return (
        <LocationItem
            id={facility.id}
            edit={edit}
            text={facility.metrc.DisplayName}
            selected={locations.length > 0}
            indeterminate={locations.length > 0 && !selected}
            onSelected={handleSelected}
        >
            <RoomLocations
                edit={edit}
                facility={facility}
                locations={locations}
                onChange={onChange}
            />
        </LocationItem>
    );
};

interface ILocationProps {
    edit?: boolean;
    locations: TaskLocation[];
    onChange?: ( newLocation: TaskLocation[] ) => void;
}

const SchedulerLocations: React.FC<ILocationProps> = ( { edit = false, locations, onChange = () => null } ) => {
    const facilities = useSelector( selectFacilities );

    const handleChange = ( facility: IFacility ) => ( newLocation: TaskLocation[] ) => {
        onChange( [
            ...locations.filter( location => location.facilityId !== facility.id ),
            ...newLocation,
        ] );
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
        >
            {facilities.map( fac => {
                const found = locations.filter( location => location.facilityId === fac.id );

                return (
                    <FacilityTreeItem
                        key={fac.id}
                        edit={edit}
                        facility={fac}
                        locations={found}
                        onChange={handleChange( fac )}
                    />
                );
            } )}
        </TreeView>
    );
}

export default SchedulerLocations;
