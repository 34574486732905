import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import Save from '@material-ui/icons/Save';
import Add from '@material-ui/icons/Add';
import { Validators } from '@happybandit/react-validation';
import { IInventoryDetail, ConsumableTypes, Units, IConsumableDetail } from '@shared/interfaces/lib/inventoryInterfaces';
import { Collections, MetrcLocationSubCollections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IWater, IFeedingNutrients, IFeeding } from '@shared/interfaces/lib/interfaces';
import { ISystemEnvironment } from 'environmentInterfaces';
import { FirebaseContext } from 'firebase_init';
import TextValid from 'components/TextValid';
import SelectValid from 'components/SelectValid';
import {  selectInventoryDetails, selectUser } from 'redux/appSlice';
import FacilityContext from '../../../../facilityContext';

interface IProps {
    // props \\
    roomId: string;
    systemId: string;
    water: IWater;

    // functions \\
    onComplete: ( newWater: IWater ) => void;
}

const createNutrient = (): IFeedingNutrients => {
    return {
        doseML: 0,
        nutrient: {
            type: ConsumableTypes.Nutrients,
            criticalLimit: 0,
            unit: Units.Milliliter,
            id: '',
            name: '',
            brand: '',
            model: '',
            sku: 0,
        }
    };
};

const WaterInfo: React.FC<IProps> = ( { water, onComplete, roomId, systemId } ) => {
    const { firestore } = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const theme = useTheme();
    const [ec, setEC] = useState<number>( 0 );
    const [ph, setPH] = useState<string>( '' );
    const [temperature, setTemperature] = useState<number>( 0 );
    const [dosage, setDosage] = useState<number>( 0 );
    const [open, setOpen] = useState<boolean>( false );
    const [nutrients, setNutrients] = useState<IFeedingNutrients[]>( [createNutrient()] );
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const inventoryDetails = useSelector( selectInventoryDetails );
    const user = useSelector(selectUser);

    useEffect( () => {
        firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.environment )
            .where( 'systemId', '==', systemId )
            .orderBy( 'dateTime', 'desc' )
            .limit( 1 )
            .onSnapshot( ( snapshot ) => {
                if ( snapshot.docs && snapshot.docs.length > 0 ) {
                    const last = snapshot.docs[0].data() as ISystemEnvironment;
                    setEC( last.ec );
                    setPH( last.ph );
                    setTemperature( last.temperature );
                }
            } );
    }, [] );

    if(!user){
        return null;
    }

    const inventoryNutrients: IInventoryDetail[] = inventoryDetails.filter( ( inventory: IInventoryDetail ) => inventory.type === ConsumableTypes.Nutrients );

    const createChange = (): IFeeding => {
        return {
            userId: user.uid,
            nutrients,
            dateTime: (new Date()).toISOString(),
        };
    };

    const createEnvironment = (): ISystemEnvironment => {
        return {
            userId: user.uid,
            ph,
            ec,
            systemId,
            temperature,
            dateTime: (new Date()).toISOString(),
        };
    };

    const handleSubmit = ( event: React.FormEvent ) => {
        event.preventDefault();
        if ( !user ) {
            return;
        }

        onComplete( {
            ...water,
            waterChangeCount: 0,
            changeOrFlushInProgress: false,
            lastFlushDateTime: (new Date()).toISOString(),
            changes: water.changes ? [...water.changes, createChange()] : [createChange()],
        } );

        return firestore
            .collection( Collections.metrcFacilities )
            .doc( facility.id )
            .collection( MetrcFacilitySubCollections.metrcLocations )
            .doc( roomId )
            .collection( MetrcLocationSubCollections.environment )
            .add( createEnvironment() )
            .finally( () => {
                setOpen( false );
            });

    };

    const handleClose = () => {
        setOpen( false );
    };

    const onNutrientChange = ( value: string, index: number ) => {
        const nute = inventoryNutrients.find( nute => nute.id === value );
        Object.assign( nutrients[index].nutrient, nute as IConsumableDetail );

        setNutrients( nutrients );
    };

    return (
        <>
            <Button
                color="primary"
                onClick={() => setOpen( true )}
            >
                Enter PH / Nutes
            </Button>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Enter PH / Nutes
                </DialogTitle>
                <DialogContent>
                    <Alert severity="info">When Changing or Flushing Water in a system we need to get the readings of
                        the PH and Nutrients dosed. </Alert>
                    <form id="water-change">
                        <TextValid
                            id="water-ph"
                            label="PH"
                            type="number"
                            value={ph}
                            fullWidth
                            onChange={( changed ) => setPH( changed )}
                            validators={[Validators.positive( ph.toString() )]}
                        />

                        <TextValid
                            id="water-ec"
                            label="EC"
                            type="number"
                            value={ec.toString()}
                            fullWidth
                            onChange={( changed ) => setEC( parseFloat( changed ) )}
                            validators={[Validators.positive( ec.toString() )]}
                        />

                        <TextValid
                            id="water-temperature"
                            label="Temperature"
                            type="number"
                            value={temperature.toString()}
                            fullWidth
                            onChange={( changed ) => setTemperature( parseFloat( changed ) )}
                            validators={[Validators.positive( temperature.toString() )]}
                        />

                        <h3 style={{ marginBottom: 0 }}>Nutrients</h3>
                        <Button
                            color="primary"
                            endIcon={<Add/>}
                            onClick={() => setNutrients( ( prev ) => [...prev, createNutrient()] )}
                        >
                            Add Nutrient Dose
                        </Button>
                        {nutrients.map( ( nutrient: IFeedingNutrients, index: number ) => (
                            <>
                                <SelectValid
                                    fullWidth
                                    id="select-water-nutrients"
                                    value={nutrients[index].nutrient.name}
                                    onChange={(( value ) => onNutrientChange( value, index ))}
                                    validators={[]}
                                >
                                    <MenuItem value=""/>
                                    {inventoryNutrients.map( ( nutrient: IInventoryDetail, key: number ) => (
                                        <MenuItem
                                            key={key}
                                            value={nutrient.id}
                                        >
                                            {nutrient.name}
                                        </MenuItem>
                                    ) )}
                                </SelectValid>

                                <TextValid
                                    id="water-change-nutrient-dose"
                                    label="Dosage ( ML )"
                                    type="number"
                                    value={dosage.toString()}
                                    fullWidth
                                    onChange={( changed ) => setDosage( parseFloat( changed ) )}
                                    validators={[Validators.positive( dosage.toString() )]}
                                />
                            </>
                        ) )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen( false )} color="primary">
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        endIcon={<Save/>}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WaterInfo;
