import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import { selectInfoCategories, selectUser } from '../../../redux/appSlice';
import { IInfoCategory } from '@shared/interfaces/lib/interfaces';
import { UserPermissions } from '@shared/interfaces/lib/user';
import InfoCategoriesEdit from './edit';
import InfoCategoriesView from './view';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
    onSelected: ( articleId: string ) => void;
    onCategoryChange: ( newCategories: IInfoCategory[] ) => void;
    onBack: () => void;
}

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        root: {
            flexDirection: 'column',
            display: 'flex',
            height: '100%',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        }
    } )
);

const sortIndex = ( a: IInfoCategory, b: IInfoCategory ): number => a.index - b.index;

const InfoCategories: React.FC<IProps> = ( { onSelected, onCategoryChange, onBack } ) => {
    const classes = useStyles();
    const user = useSelector( selectUser );
    const infoCategories = useSelector( selectInfoCategories );
    const [edit, setEdit] = useState( false );
    const [newCategories, setNewCategories] = useState( infoCategories );

    const handleSave = () => {
        setEdit( false );
        onCategoryChange( newCategories );
    };

    return (
        <Box p={2} className={classes.root}>
            <div>
                <Typography variant="h4" style={{ display: 'inline' }}>
                    Articles
                </Typography>
                <IconButton
                    style={{ float: 'right' }}
                    color="primary"
                    onClick={onBack}
                >
                    <ArrowForward/>
                </IconButton>
                {user && user.isAllowed(UserPermissions.generalEdit) && (edit ? (
                    <>
                        <IconButton
                            style={{ float: 'right' }}
                            color="secondary"
                            onClick={() => setEdit( false )}
                        >
                            <Clear/>
                        </IconButton>
                        <IconButton
                            style={{ float: 'right' }}
                            color="primary"
                            onClick={handleSave}
                        >
                            <Check/>
                        </IconButton>
                    </>
                ) : (
                    <IconButton
                        style={{ float: 'right' }}
                        color="primary"
                        onClick={() => setEdit( true )}
                    >
                        <EditIcon data-testid="Edit"/>
                    </IconButton>
                ))}
            </div>
            <div className={classes.content}>
                {edit ? (
                    <InfoCategoriesEdit
                        infoCategories={[...newCategories].sort( sortIndex )}
                        onChange={setNewCategories}
                    />
                ) : (
                    <InfoCategoriesView
                        infoCategories={[...infoCategories].sort( sortIndex )} onSelected={onSelected}
                        user={user}
                    />
                )}
            </div>
        </Box>
    );
};

export default InfoCategories;
