import { RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import EnvironmentRoomView from './roomView';
import EnvironmentFacilityView from './facilityView';

const Environment: React.FC<RouteComponentProps> = () => {
    return (
        <Router>
            <EnvironmentFacilityView path="/" />
            <EnvironmentRoomView path="/:roomId" />
        </Router>
    );
};

export default Environment;
