import React from 'react';
import { sumBy } from 'lodash';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import {
    ISystem,
    ILight,
    IRoom,
} from '@shared/interfaces/lib/interfaces';
import { selectUser } from 'redux/appSlice';
import { removeUndefined, uuidv4 } from 'utils';
import Light from './light';

interface IProps {
    room: IRoom;
    edit: boolean;
    system: ISystem;
    onSystemChange: ( newSystem: ISystem ) => Promise<void>;
}

const SystemLights: React.FC<IProps> = ( { edit, system, onSystemChange, room } ) => {
    const user = useSelector(selectUser);

    if ( !user ) {
        return null;
    }

    const handleAdd = ( rowIndex: number ) => {
        const newSystem: ISystem = {
            ...system,
            lightRows: system.lightRows.map( ( row ) => ({ ...row, lights: [...row.lights] }) ),
        };

        const light = { id: uuidv4() };
        const foundLight = newSystem.lightRows[rowIndex];

        if ( foundLight ) {
            foundLight.lights = foundLight.lights.concat( light );
        } else {
            newSystem.lightRows.push( {
                lights: [light],
            } );
        }

        return onSystemChange( newSystem );
    };

    const handleDelete = ( id: number ) => {
        const newSystem: ISystem = {
            ...system,
            lightRows: system.lightRows.map( ( row ) => ({
                ...row,
                lights: [...row.lights.filter( light => light.id !== id )]
            }) ),
        };

        return onSystemChange( newSystem );
    };

    const handleChange = ( rowIndex: number ) => ( newLight: ILight ) => {
        const newSystem: ISystem = {
            ...system,
            lightRows: system.lightRows.map( ( row ) => ({ ...row, lights: [...row.lights] }) ),
        };
        const found = newSystem.lightRows[rowIndex];

        if ( found ) {
            const index = found.lights.findIndex( ( light ) => light.id === newLight.id );
            found.lights[index] = removeUndefined( newLight );

            return onSystemChange( newSystem );
        }
    };

    return (
        <>
            <Typography variant="h5" style={{ display: 'inline' }}>
                <strong>
                    Lights
                </strong>
            </Typography>
            <strong>
                <span
                    style={{ 'color': 'black' }}
                >&nbsp;/</span> {sumBy( system.lightRows, ( rows ) => rows.lights.length )} Lights
            </strong>
            <TableContainer>
                <Table>
                    <TableBody>
                        {system.lightRows.map( ( row, index: number ) => (
                            <TableRow key={index}>
                                {row.lights.map( ( light: ILight ) => (
                                    <Light
                                        systemId={system.id}
                                        room={room}
                                        key={light.id}
                                        light={light}
                                        onLightChange={handleChange( index )}
                                        onLightDelete={handleDelete}
                                    />
                                ) )}
                                {edit && (
                                    <TableCell align="right">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            onClick={() => handleAdd( index )}
                                        >
                                            <AddCircle/>
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ) )}
                    </TableBody>
                    {edit && (
                        <TableFooter>
                            <TableRow>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleAdd( system.lightRows.length )}
                                    >
                                        <AddCircle/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
        </>
    );
};

export default SystemLights;
