import CircularProgress from '@material-ui/core/CircularProgress';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import { IScheduleTask } from '@shared/interfaces/lib/scheduleInterfaces';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { selectShowTask, setShowTask } from '../../redux/appSlice';
import { FirebaseContext } from '../../firebase_init';
import SchedulerTaskView from './view';
import SchedulerTaskEdit from './edit';

const Scheduler: React.FC = () => {
    const showTask = useSelector( selectShowTask );
    const { firestore } = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const [task, setTask] = useState<IScheduleTask | undefined>( undefined );

    useEffect( () => {
        if ( showTask && showTask.id ) {
            firestore.collection( Collections.scheduleTasks )
                .doc( showTask.id )
                .onSnapshot( snap => {
                    if ( snap.exists ) {
                        setTask( snap.data() as IScheduleTask );
                    } else {
                        dispatch( setShowTask( undefined ) );
                        setTask( undefined );
                    }
                } );
        } else {
            setTask( undefined );
        }
    }, [showTask?.id] );

    if ( !showTask ) {
        return null;
    }

    const handleClose = () => {
        dispatch( setShowTask( undefined ) );
    };

    return (
        <Dialog maxWidth="md" open={true} onClose={handleClose}>
            <DialogTitle>
                Task Details
                <hr style={{ 'borderTop': '#eee' }}/>
            </DialogTitle>
            {showTask.id && !task ? (
                <CircularProgress size={24}/>
            ) : showTask.edit ? (
                <SchedulerTaskEdit
                    task={task}
                    onClose={handleClose}
                />
            ) : task ? (
                <SchedulerTaskView
                    task={task}
                    onClose={handleClose}
                />
            ) : null}
        </Dialog>
    );
};

export default Scheduler;