import {combineReducers, configureStore} from '@reduxjs/toolkit';
import appSliceReducer from './appSlice';
import { detailsSnapshotMiddleware } from './middleware';

export const rootReducer = combineReducers({
    app: appSliceReducer,
});
export type RootState = ReturnType<typeof rootReducer>

const getStore = () => configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .prepend(detailsSnapshotMiddleware),
});

export default getStore;
