import { Table } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlant } from '@shared/interfaces/lib/interfaces';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowPlantDetails } from '../../../../../../../redux/appSlice';
import { PlantDetailPages } from '../../../../../../../redux/interfaces';
import { getPlantState } from '../../../../../../../utils';

interface IProps {
    plants: IPlant[];
    onAdd: () => void;
}


const ZoneDetailsView: React.FC<IProps> = ( { plants, onAdd } ) => {
    const dispatch = useDispatch();

    return (
        <>
            <DialogContent>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Strain</TableCell>
                            <TableCell>Current Phase</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {plants.map( ( plant ) => (
                            <TableRow
                                hover
                                key={plant.id}
                                onClick={() => dispatch( setShowPlantDetails( {
                                    page: PlantDetailPages.details,
                                    key: {
                                        id: plant.id,
                                        collection: MetrcFacilitySubCollections.metrcPlants,
                                    },
                                } ) )}
                            >
                                <TableCell>
                                    {plant.id}
                                </TableCell>
                                <TableCell>
                                    {plant.metrc.StrainName}
                                </TableCell>
                                <TableCell>
                                    {getPlantState(plant.metrc).title}
                                </TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onAdd}>
                    Assign Plants
                </Button>
            </DialogActions>
        </>
    );
};

export default ZoneDetailsView;
