import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IPlantBatch, IRoom, ISystem } from '@shared/interfaces/lib/interfaces';
import React from 'react';
import { useDispatch } from 'react-redux';
import { PlantDetailPages } from '../../../../redux/interfaces';
import { setShowPlantDetails } from '../../../../redux/appSlice';

interface IProps {
    room?: IRoom;
    system?: ISystem;
    plantBatch: IPlantBatch;
}

const PlantBatchDetails: React.FC<IProps> = ( { plantBatch } ) => {
    const dispatch = useDispatch();

    if ( !plantBatch ) {
        return null;
    }

    const handleVeg = () => {
        dispatch( setShowPlantDetails( {
            key: {
                collection: MetrcFacilitySubCollections.metrcPlantBatches,
                id: plantBatch.id,
            },
            page: PlantDetailPages.toVeg,
        } ));
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" color="textSecondary" gutterBottom>
                                {plantBatch.metrc.Name}
                            </Typography>
                            <Typography>
                                {plantBatch.metrc.StrainName}
                            </Typography>
                            <Typography>
                                Vegetative Count: {plantBatch.metrc.TrackedCount}
                            </Typography>
                            <Typography>
                                Destroyed Count: {plantBatch.metrc.DestroyedCount}
                            </Typography>
                            <Typography>
                                Harvested Count: {plantBatch.metrc.HarvestedCount}
                            </Typography>
                            <Typography>
                                Packaged Count: {plantBatch.metrc.PackagedCount}
                            </Typography>
                            <Typography>
                                Remaining Count: {plantBatch.metrc.UntrackedCount}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {plantBatch.metrc.UntrackedCount > 0 && (
                                <Button
                                    onClick={handleVeg}
                                >
                                    Move Clones to Vegetative Phase
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" color="textSecondary" gutterBottom>
                                {plantBatch.metrc.Type}
                            </Typography>
                            <Typography>
                                Source Plant Label: {plantBatch.metrc.SourcePlantLabel}
                            </Typography>
                            <Typography>
                                Planted Date: {plantBatch.metrc.PlantedDate}
                            </Typography>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
};

export default PlantBatchDetails;