import { Table, TableBody } from '@material-ui/core';
import { Collections, MetrcFacilitySubCollections } from '@shared/interfaces/lib/firebaseConstants';
import { IHarvest } from '@shared/interfaces/lib/interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FirebaseContext } from '../../../firebase_init';

import FacilityContext from '../facilityContext';

import HarvestItem from './item';

const useStyles = makeStyles( () => ({
    section: {
        paddingTop: '2em',
        paddingBottom: '3em',
    },
    root: {
        width: '100%',

        '& li:last-child': {
            borderBottom: 'none',
        }
    },
}) );

interface IReduce {
    active: IHarvest[];
    hold: IHarvest[];
}

const Harvest: React.FC<RouteComponentProps> = () => {
    const context = useContext( FirebaseContext );
    const { facility } = useContext( FacilityContext );
    const classes = useStyles();
    const [activeHarvests, setActiveHarvests] = useState<IHarvest[]>( [] );
    const [holdHarvests, setHoldHarvests] = useState<IHarvest[]>( [] );

    useEffect( () => {
        if ( facility ) {
            context.firestore.collection( Collections.metrcFacilities ).doc( facility.id )
                .collection( MetrcFacilitySubCollections.metrcHarvests )
                .where( 'metrc.FinishedDate', '==', null )
                .get()
                .then( ( snap ) => {
                    const result = snap.docs.reduce<IReduce>( ( acc, item ) => {
                        const doc = item.data() as IHarvest;
                        if ( doc.metrc.IsOnHold ) {
                            acc.hold.push( doc );
                        } else {
                            acc.active.push( doc );
                        }
                        return acc;
                    }, { active: [], hold: [] } );

                    setActiveHarvests( result.active );
                    setHoldHarvests( result.hold );
                } )
        }
    }, [facility.id] );

    if ( !facility ) {
        navigate( '/' );
        return null;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: 'inline' }}>
                        <strong>
                            {facility.metrc.DisplayName}
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ Harvests
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                </Grid>
            </Grid>

            <Grid component="section" container spacing={3} id={facility.id + '-harvest'} className={classes.section}>
                <List
                    component="nav"
                    className={classes.root}
                >

                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            Active
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ {activeHarvests.length} harvests
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <Table>
                        <TableBody>
                            {activeHarvests.map( ( harvest ) => (
                                <HarvestItem
                                    key={harvest.id}
                                    harvest={harvest}
                                    facilityId={facility.id}
                                />
                            ) )}
                        </TableBody>
                    </Table>

                    <Typography variant="h4" style={{ display: 'inline' }}>
                        <strong>
                            On Hold
                        </strong>
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ {holdHarvests.length} harvests
                    </Typography>
                    <Typography variant="h6" component="p" style={{ display: 'inline' }}>
                        &nbsp;/ These must be maintained in Metrc directly
                    </Typography>
                    <hr style={{ 'borderTop': '#eee' }}/>
                    <Table>
                        {holdHarvests.map( ( harvest ) => (
                            <HarvestItem
                                key={harvest.id}
                                harvest={harvest}
                                facilityId={facility.id}
                            />
                        ) )}
                    </Table>
                </List>
            </Grid>
        </>
    );
};

export default Harvest;
