import { Validators } from '@happybandit/react-validation';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import { Collections } from '@shared/interfaces/lib/firebaseConstants';
import { isEmpty, isUndefined } from 'lodash';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactSelectValid from '../../../../components/ContactSelectValid';
import SelectValid from '../../../../components/SelectValid';
import TextValid from '../../../../components/TextValid';
import { FirebaseContext } from '../../../../firebase_init';
import { INotification, IStrain } from '@shared/interfaces/lib/interfaces';
import {
    selectFacilities,
    selectStrains,
    selectUser,
    selectUsers,
    setError
} from '../../../../redux/appSlice';
import { UserPermissions } from '@shared/interfaces/lib/user';
import { useNotification, uuidv4 } from '../../../../utils';
import { ContactServices } from '../../../externalContacts/interfaces';
import { ITest, ProductTypes } from '../../interfaces';
import TestResults from '../results';

interface IProps {
    test: ITest;
}

const ViewTest: React.FC<IProps> = ( { test } ) => {
    const context = useContext( FirebaseContext );
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [open, setOpen] = useState<boolean>( false );
    const [name, setName] = useState<string>( test.name );
    const [unit, setUnit] = useState<string>( test.unit );
    const [strain, setStrain] = useState<string>( test.strainId );
    const [type, setType] = useState<ProductTypes>( test.type );
    const [deliveree, setDeliveree] = useState<string>( test.delivereeId );
    const [milesDriven, setMilesDriven] = useState<number>( test.milesDriven );
    const [totalWeight, setTotalWeight] = useState<number>( test.totalWeight );
    const [weightDelivered, setWeightDelivered] = useState<number>( test.weightDelivered );
    const [resultsExpected, setResultsExpected] = useState<string>( test.resultsExpected );
    const [dateDepartedFromFacility, setDateDepartedFromFacility] = useState<string>( test.dateDepartedFromFacility ? test.dateDepartedFromFacility : '' );
    const [dateDelivered, setDateDelivered] = useState<string>( !isEmpty( test.dateDelivered ) ? test.dateDelivered : '' );
    const [transportVehicle, setVehicleTransport] = useState<string>( test.transportVehicleId );
    const [testingFacility, setTestingFacility] = useState<string | undefined>( test.testingFacilityId );
    const [originFacility, setOriginFacility] = useState<string>( test.facilityOriginatedId );
    const user = useSelector( selectUser );
    const strains = useSelector( selectStrains );
    const users = useSelector( selectUsers );
    const facilities = useSelector( selectFacilities );
    const sendNotification = useNotification();

    const facility = facilities.find( ( facility ) => facility.id === originFacility );

    const vehicles = [
        {
            id: uuidv4(),
            vin: 2374982347298374,
            name: 'Dodge Van',
            make: 'Dodge',
            model: 'E350',
        }
    ];

    const handleClose = () => {
        setOpen( false );
    };

    const getTestObject = (): ITest => {
        return {
            unit,
            type,
            name,
            id: uuidv4(),
            strainId: strain,
            results: {
                id: '',
                name: '',
                heavyMetals: [],
                potency: {
                    thcCbdRatio: '',
                    cannabinoidPerWeight: 0,
                    totalCannabinoidsPresent: 0,
                },
                terpeneProfile: [{
                    id: '',
                    name: '',
                }],
                chemicalContaminants: [''],
                microbialContaminants: [''],
            },
            testingFacilityId: testingFacility || '',
            facilityOriginatedId: originFacility,
            delivereeId: deliveree,
            totalWeight,
            milesDriven,
            dateDelivered,
            dateDepartedFromFacility,
            inProgress: true,
            weightDelivered,
            resultsExpected,
            transportVehicleId: transportVehicle,
        }
    };

    const handleSubmit = () => {
        if ( !user ) {
            return;
        }

        const collection = context.firestore.collection( Collections.tests );
        collection.doc( test.id ).update( getTestObject() )
            .then( () => {
                handleClose();
            } )
            .catch( ( e: Error ) => {
                dispatch( setError( e.message ) );
            } );

        const notification: Omit<INotification, 'id'> = {
            timestamp: Date.now(),
            seen: false,
            userId: user.uid,
            message: `Created New Test - ${name} in facility ${facility ? facility.metrc.DisplayName : ''}`,
        };

        sendNotification( notification, ( userItem ) => userItem.permissions.includes( UserPermissions.userAdmin ) );
    };

    return (
        <>
            <Button
                onClick={() => setOpen( true )}
                startIcon={<AddIcon/>}
                color="primary"
            >
                View
            </Button>

            <Dialog
                maxWidth="lg" fullScreen={fullScreen} open={open} onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>Create New Test for the Facility</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tests are required per weight of product depending on type.
                        <br/>
                        <strong>
                            This is serious and extremely important, please ensure and double check all info entered.
                        </strong>
                    </DialogContentText>
                    {test.dateDelivered && (
                        <>
                            <Divider/>
                            <TestResults test={test}/>
                            <Divider/>
                        </>
                    )}
                    <SelectValid
                        id="test-strain"
                        label="Strain Being Tested"
                        fullWidth
                        validators={[]}
                        value={strain}
                        onChange={( value ) => setStrain( value )}
                    >
                        <MenuItem value=""/>
                        {strains.map( ( strain: IStrain ) => (
                            <MenuItem
                                key={strain.id}
                                value={strain.id}
                            >
                                {strain.name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <SelectValid
                        id="test-type"
                        label="Type of Product Being Tested"
                        fullWidth
                        validators={[]}
                        value={!isUndefined( type ) ? type : ''}
                        onChange={( value ) => setType( ProductTypes[value as keyof typeof ProductTypes] )}
                    >
                        <MenuItem value=""/>
                        {Object.keys( ProductTypes ).map( ( type ) => (
                            <MenuItem
                                key={type}
                                value={type}
                            >
                                {type}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <ContactSelectValid
                        id="test-testing-facility"
                        label="Facility Testing At"
                        fullWidth
                        validators={[Validators.required( testingFacility || '' )]}
                        value={testingFacility}
                        onChange={( value ) => setTestingFacility( value )}
                        serviceToFocus={ContactServices.ProductTesting}
                    />
                    <SelectValid
                        id="test-origin-facility"
                        label="Facility Sent From"
                        fullWidth
                        validators={[Validators.required( originFacility || '' )]}
                        value={originFacility}
                        onChange={( value ) => setOriginFacility( value )}
                    >
                        <MenuItem value=""/>
                        {facilities.map( ( facility ) => (
                            <MenuItem
                                key={facility.id}
                                value={facility.id}
                            >
                                {facility.metrc.DisplayName}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <SelectValid
                        id="test-employee"
                        label="Employee Delivering the Test"
                        fullWidth
                        validators={[]}
                        value={deliveree}
                        onChange={( value ) => setDeliveree( value )}
                    >
                        <MenuItem value=""/>
                        {users.map( ( user ) => (
                            <MenuItem
                                key={user.uid}
                                value={user.uid}
                            >
                                {user.displayName}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <SelectValid
                        id="test-vehicle"
                        label="Vehicle Used to Deliver the Test"
                        fullWidth
                        validators={[]}
                        value={transportVehicle}
                        onChange={( value ) => setVehicleTransport( value )}
                    >
                        <MenuItem value=""/>
                        {vehicles.map( ( vechile ) => (
                            <MenuItem
                                key={vechile.id}
                                value={vechile.id}
                            >
                                {vechile.name}
                            </MenuItem>
                        ) )}
                    </SelectValid>
                    <TextValid
                        id="test-new-name"
                        label="Name"
                        autoComplete='none'
                        value={name}
                        onChange={( value ) => setName( value )}
                        fullWidth
                        validators={[Validators.required( name )]}
                    />
                    <TextValid
                        id="test-new-unit"
                        label="Unit"
                        autoComplete='none'
                        value={unit}
                        onChange={( value ) => setUnit( value )}
                        fullWidth
                        validators={[Validators.required( unit )]}
                    />
                    <TextValid
                        type="number"
                        autoComplete='none'
                        label="Miles Driven"
                        id="test-miles-driven"
                        value={milesDriven.toString()}
                        onChange={( value ) => setMilesDriven( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( milesDriven.toString() )]}
                    />
                    <TextValid
                        id="test-total-weight-sent"
                        label="Total Weight Sent ( grams )"
                        autoComplete='none'
                        value={totalWeight.toString()}
                        onChange={( value ) => setTotalWeight( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( totalWeight.toString() )]}
                    />
                    <TextValid
                        id="test-weight-delivered"
                        label="Total Weight Delivered"
                        autoComplete='none'
                        value={weightDelivered.toString()}
                        onChange={( value ) => setWeightDelivered( parseInt( value ) )}
                        fullWidth
                        validators={[Validators.required( weightDelivered.toString() )]}
                    />
                    <TextValid
                        id="test-date-departed"
                        label="Date Departed From Facility"
                        autoComplete='none'
                        value={dateDepartedFromFacility}
                        onChange={( value ) => setDateDepartedFromFacility( value )}
                        fullWidth
                        validators={[Validators.required( dateDepartedFromFacility )]}
                    />
                    <TextValid
                        id="test-date-delivered"
                        label="Date Delivered to Lab"
                        autoComplete='none'
                        value={dateDelivered}
                        onChange={( value ) => setDateDelivered( value )}
                        fullWidth
                        validators={[Validators.required( dateDepartedFromFacility )]}
                    />
                    <TextValid
                        id="test-results-expected"
                        label="Date Results Expected"
                        autoComplete='none'
                        value={resultsExpected}
                        onChange={( value ) => setResultsExpected( value )}
                        fullWidth
                        validators={[Validators.required( resultsExpected )]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViewTest;